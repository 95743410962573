import React from 'react';
import {Link} from 'react-router-dom';

const HomeReportar = React.forwardRef((props, ref) => (
    <div ref={ref} className="content-wrap actions-wrap" id="reportes-y-consultas">
        <div className="report-wrap">

            <p className="action-stit">Reportar</p>

            <p>
                Infórmanos sobre una reacción adversa a medicamento, inconveniente o evento inesperado  que consideres relacionado con los productos de las compañías farmacéuticas a las que prestamos servicios de farmacovigilancia
            </p>

            <Link to='#' className="button action-button" id="reportar-bt" onClick={props.toggle}>
                <i className="fas fa-edit"></i> Reportar
            </Link>

        </div>
    </div>
))
export default HomeReportar;
import React from 'react';
import PropTypes from 'prop-types';
import { getFileIcon } from '../../constants/fileAccept';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faTrash  } from '@fortawesome/free-solid-svg-icons';
import './style.css';

const FileAcceptedLoad = ({file, index, handleDelete}) => {
    return (
        <div className="upload-load">
            <div className="file-accepted-load">
                <FontAwesomeIcon size={'3x'} icon={getFileIcon(file.type)} />
            </div>
            <div className="file-name-load">
                <span>{file.name}</span>
            </div>
            <div className="file-action">
                <Link className="action-delete" to={'#'} onClick={()=> handleDelete(index)}><FontAwesomeIcon size="lg" icon={faTrash} /></Link>
            </div>
        </div>
    );
};

FileAcceptedLoad.propTypes = {
    file: PropTypes.object,
};

export default FileAcceptedLoad;
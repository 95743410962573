import React from 'react';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollIntoView from 'react-scroll-into-view'

const HomeMenuLateral = ({handleLogin, scrollInto,handleSelect}) =>(

    <ul className="menu-lateral">
        
        <li className={`inicio-bt ${scrollInto==="inicio"?'active-nav':''}`} onClick={()=>handleSelect('inicio')}>
        <ScrollIntoView className="siw" alignToTop={true} selector="#inicio">
                <span>Inicio <div className="div-arrow-right"></div></span></ScrollIntoView>
        </li>
        
        <li className={`objetivos-bt ${scrollInto==="objetivos"?'active-nav':''}`} onClick={()=>handleSelect('objetivos')}>
            <ScrollIntoView className="siw" alignToTop={true} selector="#objetivos"><span>Quiénes Somos <div className="div-arrow-right"></div></span></ScrollIntoView>
        </li>
        
        <li className={`compromiso-bt ${scrollInto==="compromiso"?'active-nav':''}`} onClick={()=>handleSelect('compromiso')}>
            <ScrollIntoView className="siw" alignToTop={true} selector="#compromiso"><span>Compromiso <div className="div-arrow-right"></div></span></ScrollIntoView>
        </li>
        
        <li className={`reportes-y-consultas-bt ${scrollInto==="reportes-y-consultas"?'active-nav':''}`} onClick={()=>handleSelect('reportes-y-consultas')}>
            <ScrollIntoView className="siw" alignToTop={true} selector="#reportes-y-consultas"><span>Reportar <div className="div-arrow-right"></div></span></ScrollIntoView>
        </li>
        
        <li className={`companias-bt ${scrollInto==="companias"?'active-nav':''}`} onClick={()=>handleSelect('companias')}>
            <ScrollIntoView className="siw" alignToTop={true} selector="#companias"><span>Compañías <div className="div-arrow-right"></div></span></ScrollIntoView>
        </li>
        
        <li className={`faqs-lateral faqs-bt ${scrollInto==="faqs"?'active-nav':''}`} onClick={()=>handleSelect('faqs')}>
            <ScrollIntoView className="siw" alignToTop={true} selector="#faqs"><span>Preguntas Frecuentes <div className="div-arrow-right"></div></span></ScrollIntoView>
        </li>
        
        <li className='login' onClick={handleLogin}>
            <FontAwesomeIcon icon={faLock} />
            <span>Ingresar <div className="div-arrow-right"></div></span>
        </li>
    </ul>
)
export default HomeMenuLateral;
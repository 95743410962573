import React from 'react';

const ListItemAssignLab = ({Item, classProp}) => {
    return (
        <tr className={`list-item ${classProp}`}>
            <td>{Item.Country}</td>
            <td>{Item.Company}</td>
            <td>{Item.Laboratory}</td>
        </tr>
    );
};

export default ListItemAssignLab;
import React from 'react';

const HomeCompanies = React.forwardRef((props, ref) => (
    <div ref={ref} className="content-wrap" id="companias">
        <h1>Compañías Megalabs</h1>
        <ul className="company-list">
            <li className="company-country">Argentina</li>
            <li>Gemabiotech S.A.U.</li>
            <li>Laboratorios Poen S.A.U.</li>
            <li>Megalabs Argentina S.A.U.</li>
        </ul><ul className="company-list">
            <li className="company-country">Bolivia</li>
            <li>Megalabs Bolivia S.R.L.</li>
        </ul><ul className="company-list">
            <li className="company-country">Brasil</li>
            <li>Megalabs farmacéutica S.A.</li>
            <li>Geyer Medicamentos S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">Chile</li>
            <li>Megalabs Chile S.A.</li>
            <li>Laboratorios Garden House farmacéutica S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">Colombia</li>
            <li>Megalabs Colombia S.A.S.</li>
        </ul><ul className="company-list">
            <li className="company-country">Costa Rica</li>
            <li>Leterago S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">Ecuador</li>
            <li>Acromax Laboratorio Quimico Farmaceutico S.A.</li>
            <li>Megalabs-Pharma S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">El Salvador</li>
            <li>Megalabs El Salvador S.A. De C.V.</li>
        </ul><ul className="company-list">
            <li className="company-country">Guatemala</li>
            <li>Megapharma Labs de CEAM S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">Honduras</li>
            <li>Megalabs Honduras S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">Mexico</li>
            <li>Italmex S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">Nicaragua</li>
            <li>Leterago S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">Panamá</li>
            <li>Leterago S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">Paraguay</li>
            <li>Megalabs Paraguay S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">Perú</li>
            <li>Megalabs LATAM S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">República Dominicana</li>
            <li>Laboratorios Rowe S.R.L.</li>
            <li>Megalabs S.R.L.</li>
        </ul><ul className="company-list">
            <li className="company-country">Uruguay</li>
            <li>Iclos Uruguay S.A.</li>
            <li>Laboratorios Celsius S.A.</li>
            <li>Laboratorios Haymann S.A.</li>
            <li>Megalabs Uruguay S.A.</li>
            <li>Servimedic S.A.</li>
            <li>Spefar S.A.</li>
            <li>Mega Labs S.A.</li>
        </ul><ul className="company-list">
            <li className="company-country">Venezuela</li>
            <li>Megalabs VZL C.A.</li>
        </ul>
    </div>
))
export default HomeCompanies;
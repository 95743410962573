import React, { Component } from 'react';
import AppHeader from './AppHeader';

class AppFrame  extends Component {
    state = {
        expandMenu:'hide-menu',
        expandBody:'show-body',
    }

    handleButtomShowMenu = ()=>{
        this.setState(
            {
                expandMenu: this.state.expandMenu ==='hide-menu'? 'expand-menu' :'hide-menu',
                expandBody: this.state.expandBody ==='show-body'? 'expand-body' :'show-body'
            }
        );
    }

    render(){
        const { children, show } = this.props;
        const { expandBody } = this.state;
        if(show){
            return (
                <div>
                    <AppHeader handleManu={this.handleButtomShowMenu}/>
                    <div className={`app-frame ${expandBody}`}>
                        {children}
                    </div>
                </div>
            );
        }
        else{
            return(
                <div>
                    {children}
                </div>
            )
        }
    }
};

export default AppFrame;

import React from 'react';
import {withRouter,NavLink} from 'react-router-dom';


const ListItemNoKpis = ({Item, classProp}) => {
    return (
        <tr className={`list-item ${classProp}`}>
            <td className='p-md' ><NavLink to={`/reportableevent/${Item.Meta}/view/details`}>{Item.NumeroReferencia}</NavLink></td>
            <td className="p-md">{Item.Estado}</td>
            <td className="p-md">{Item.FechaSolicitudDesc}</td>
            <td className="p-md">{Item.MetricsAlerts[0].Nombre}</td>
            <td className="p-md">{Item.MetricsAlerts[0].TiempoNombre}</td>
        </tr>
    );
};

export default withRouter(ListItemNoKpis);

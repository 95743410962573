import axios from 'axios';
import { urlBase } from '../environment'
import { url_downloadAuditFile, url_getContext } from './urls';
import CookiePrvider from './cookies';
import { secureStorage } from './secureStorage';
import { encodeParameters } from '.././helpers/utilities';
import { setMyUser, setCodiguerasContext } from '../redux/actions/context';
import  responseInterceptor from './interceptor';

const _url_login = '/api/login';
const _url_signature = `${urlBase}api/logedUserSignature`;

const instance = axios.create({
  baseURL: urlBase
})

instance.interceptors.request.use(
  config => {
    const token = secureStorage.getItem('token');
    if(token)
      config.headers['Authorization'] = `Bearer ${token.access_token}`;
    return config;
  },
  error =>{
    Promise.reject(error)
  });

responseInterceptor(instance);


const generateContext = async (redirect, isBlockScreen) =>{
  const response = await instance.get(url_getContext);
  setMyUser(response.data.User);
  setCodiguerasContext(response.data.Codiguera);
  secureStorage.setItem('user', response.data.User);
  secureStorage.setItem('sessionTimeout', response.data.SessionTimeout);
  if(redirect && !isBlockScreen){
    window.location.replace(response.data.User.Url);
  }
}

class Api {

  constructor(){
    this.client = instance;
  }
  clearToken = ()=> {
        secureStorage.clear();
    };

    setToken = (token)=> {
        secureStorage.setItem('token', token);
    };

    get = async(url) =>(
      await this.client.get(url)
    );

    getPDF = async(url) =>(
        await this.client.get(url, {
          responseType: 'arraybuffer'
        })
    );

    post = async(url, data, config = null) => (
      await this.client.post(url, data, config)
    );

    doLogin = async (email, password, grant_type, token, isBlockScreen, reCaptchaToken) =>{
      secureStorage.clear();
      let params = {
      }
      if(grant_type === 'password'){
        params = {
          password: password,
          username: email,
          grant_type: grant_type
        };
      }else if(grant_type === 'refresh_token'){
        params = {
          grant_type: grant_type,
          refresh_token: token
        };
      }
      try{
        const config = {
          headers: {
            'recaptcha-token': reCaptchaToken
          }
        };
        const response = await this.client.post(_url_login, encodeParameters(params), config);
        if(response.status === 200){
          secureStorage.setItem('token', response.data);
          const redirect = grant_type === 'password' ? true : false;
          await generateContext(redirect, isBlockScreen);


        }
        return response;

      }catch(error){

      }
    }

    signature = async(data) => (
        await this.client.post(_url_signature, data)
    );

    createAndClick = (fileName, urlRef) =>{
      let link = document.createElement('a');
      link.href = urlRef;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }

    download = async(url, fileName) => {
      const response = await this.client.get(url, {responseType: 'blob'});
      const blob = new Blob([response.data]);
      const urlRef = window.URL.createObjectURL(blob);
      this.createAndClick(fileName, urlRef);
    }

    downloadAuditFile = async(data) => {
        const response = await this.client.post(url_downloadAuditFile, {...data}, {responseType: 'blob'});
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        this.createAndClick(data.name, url);
    }

    logIn = (email, password, isBlockScreen, reCaptchaToken = null) => {
      return this.doLogin(email, password, 'password', null, isBlockScreen, reCaptchaToken);
    }

    logOut = () =>{
        CookiePrvider.clear();
        window.location.replace("/");
    }
}


const apiInstance = new Api();
export default apiInstance;

import { url_getContext } from './urls';
import { urlBase } from '../environment'
import axios from 'axios';
import { setMyUser } from '../redux/actions/context';
import { secureStorage } from './secureStorage';
import { encodeParameters } from '.././helpers/utilities';

const _url_login = `${urlBase}api/login`;
const _getRefreshToken = () => secureStorage.getItem('token') ? secureStorage.getItem('token').refresh_token : undefined;

const shouldIntercept = (error) => {
    try {
        return error.response.status === 401
    } catch (e) {
        return false;
    }
};

const setTokenData = async (tokenData = {}, axiosClient) => {
  secureStorage.setItem('token', tokenData);
  const response = await axios.get(url_getContext);
  setMyUser(response.data.User);
  secureStorage.setItem('user', response.data.User);
  secureStorage.setItem('sessionTimeout', response.data.SessionTimeout);
};

const handleTokenRefresh = () => {
    const refreshToken = _getRefreshToken();
    if(refreshToken === undefined){
      window.location.replace('/login');
      secureStorage.clear();
    }
    const body = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    };

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    return new Promise((resolve, reject) => {
        axios.post(_url_login, encodeParameters(body) , config)
            .then((response) => {
              resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            })
    });
};

const attachTokenToRequest = (request, token) => {
    request.headers['Authorization'] = 'Bearer ' + token;
};

export default (axiosClient, customOptions = {}) => {
    let isRefreshing = false;
    let failedQueue = [];

    const options = {
        attachTokenToRequest,
        handleTokenRefresh,
        setTokenData,
        shouldIntercept,
        ...customOptions,
    };
    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    const interceptor = (error) => {
        if (!options.shouldIntercept(error)) {
            return Promise.reject(error);
        }

        if (error.config._retry || error.config._queued) {
            return Promise.reject(error);
        }

        const originalRequest = error.config;
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject})
            }).then(token => {
                originalRequest._queued = true;
                options.attachTokenToRequest(originalRequest, token);
                return axiosClient.request(originalRequest);
            }).catch(err => {
                return Promise.reject(error);
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise((resolve, reject) => {
            options.handleTokenRefresh.call(options.handleTokenRefresh)
                .then((tokenData) => {
                    options.setTokenData(tokenData, axiosClient);
                    options.attachTokenToRequest(originalRequest, tokenData.idToken);
                    processQueue(null, tokenData.idToken);
                    resolve(axiosClient.request(originalRequest));
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                })
        });
    };

    axiosClient.interceptors.response.use(undefined, interceptor);
};

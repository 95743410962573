import { createSelector } from "reselect";

export const getIndicatorAges = createSelector(
  state => state.IndicatorAges,
  r => r
);
export const getIndicatorStatus = createSelector(
  state => state.IndicatorStatus,
  r => r
);
export const getIndicatorGroups = createSelector(
  state => state.IndicatorGroups,
  r => r
);
export const getIndicatorValues = createSelector(
  state => state.IndicatorValues,
  r => r
);
export const getIndicatorsCountries = createSelector(
  state => state.IndicatorCountriesFilters,
  r => r
);
export const getIndicatorsLabs = createSelector(
  state => state.IndicatorLabsFilters,
  r => r
);

import React, { Component } from 'react';
import { Modal,
    ModalHeader,
    ModalBody,ModalFooter } from 'reactstrap';
import UploadFile from '../UploadFile';
import { Button } from '@material-ui/core';

class ImportMedDRAModal extends Component {

    render(){
        const {handleUPload,handleAddMedDRA,files,toggle,open, handlePasswordChange} = this.props;
        
        return (
            <Modal fade={false} isOpen={open} toggle={() => toggle()}>
            <ModalHeader  toggle={() => toggle()} >Importar MedDRA</ModalHeader>
            <ModalBody>
                <UploadFile label="Archivo MedDRA en formato .ZIP" handleAddFile={handleAddMedDRA}  multiple={false}/>
            </ModalBody>
            <ModalFooter>
              <label for="zipPassword">Zip password</label>
              <input type="text" name="zipPassword" onChange={handlePasswordChange}/>
              <Button className='button-next' onClick={handleUPload}>Importar</Button>
            </ModalFooter>
            </Modal>
        );
    }
};

export default ImportMedDRAModal;

import React, {useState}  from 'react';
import { Input, FormFeedback , FormGroup, Button } from 'reactstrap';
import { faEdit, faCheck, faTrash, faTimes, faSpinner, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListActions from '../ListComponents/Item/ListActions';

const CustomRender = ({Item,property,handleAction,name,forEdit,load,action,handleInputChange}) => {
    const thisIs = (Item.Id===forEdit.Id)
    const Edit = (thisIs && (action==="forEdit" || action==="edit"));
    
    if(Edit && property!== "Id" && property!== "Eliminado" && property!== "_handleAction"){
        const [value, setValue] = useState(Item[property]);
        const nameInput = `${name}|${Item.Id}|${property}`;
        const hangeValue = (e) => {
            setValue(e.target.value);
            handleInputChange(e);
        }
        return (
        <FormGroup className="m-t m-b-sm">
            {value !== "" ? 
            <Input value={value || Item.Value} name={nameInput} className='input-field' type="text" onChange={hangeValue}/>:
            <Input value={value} name={nameInput} className='input-field' invalid type="text" onChange={hangeValue}/> }
            {value === "" && <FormFeedback >El valor no pueste estar vacío</FormFeedback>}
        </FormGroup>
        );
    }
    else{
        switch (property) {
            case "Eliminado":
                return Item[property] ? "NO": "SI";
            case "_handleAction":{
                const [codiguera, action] = load.split('-');
                const disabled = (codiguera===name);
                const loadDelete = (disabled && thisIs && action==="delete");
                const loadEdit = (disabled && thisIs && (action==="edit" || action==="add" || action==="create"));
                if(Edit || loadEdit){
                    return (<ListActions right={true} actions={[<Button title="Editar" size="sm" disabled={disabled} color="success"  onClick={() => handleAction(name,forEdit.Id != null ? "edit" : "create",Item)}><FontAwesomeIcon icon={loadEdit ? faSpinner : faSave} pulse={loadEdit} /></Button>,
                        <Button title="Cancelar" size="sm" disabled={disabled} color="secondary" onClick={() => handleAction(name,'cancel',Item)}><FontAwesomeIcon icon={faTimes} /></Button>,
                    ]} />);
                }else{
                    return (<ListActions right={true} actions={[<Button title="Editar" size="sm" disabled={disabled} color='link' to={'#'} onClick={() => handleAction(name,"forEdit",Item)}><FontAwesomeIcon color='#17a2b8' icon={faEdit}  /></Button>,
                        <Button title={`${Item.Eliminado? 'Habilitar' : 'Deshabilitar'}`} size="sm" disabled={disabled} color='link' to={'#'} onClick={() => handleAction(name,'delete',Item)}><FontAwesomeIcon color={`${Item.Eliminado? '#01b5af' : '#dc3545'}`} pulse={loadDelete}   icon={loadDelete ? faSpinner : Item.Eliminado?  faCheck : faTrash} /></Button>,
                    ]} />);
                }
            }
            default:return Item[property];
        }
    }
};

export default CustomRender;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, getFormValues  } from 'redux-form'; 
import PropTypes from 'prop-types';
import ProductDetails from './ProductDetails';
import { Row, Col,  } from 'react-flexbox-grid';
import { FieldDatePicker, FieldText, FieldSwitch } from '../../../../Fields';
import { Card, Button } from 'semantic-ui-react';
import { faTimes, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadFile from '../../../../UploadFile';
import FileView from '../../../../UploadFile/fileView';
import { Label  } from 'reactstrap';
import { downloadFileRecProductById } from '../../../../../api/urls';

class ProductRecoveryForm extends Component {
        state={
            upload: false,
            files: [],
            edit: false,
        }
        
        handleEditFile = () => this.setState({edit: true});

        handleAddFile = (files) => this.setState({files})

        render(){
            const {medicine, hiddePrincipios, togglePrincipios, recoverySave, loadSave,recoveryCancel, haveProduct, formValues, disabled,initialValues} = this.props;
            const {edit} = this.state;
            return (
                <Card.Group itemsPerRow={1} className="m-b-md">
                    <Card>
                        <Card.Content header="Recuperar producto"/>
                        <Card.Content>
                            <ProductDetails medicine={medicine} handleToggle={togglePrincipios} hidde={hiddePrincipios} />
                            <Row>
                                <Col lg={3} md={4} >
                                    <FieldSwitch disabled={disabled} label="Recuperado" icons={null}  name="Recuperado.HaveProduct" />
                                </Col>
                                <Col lg={3} md={4} hidden={!haveProduct}>
                                    <FieldDatePicker disabled={disabled}  label="Fecha de recuperación" allowFuture={false} allowPast={true} name="Recuperado.Date" />
                                </Col>
                                <Col lg={3} md={4} hidden={!haveProduct}>
                                    <FieldDatePicker disabled={disabled}  label="Fecha de Vencimiento" allowFuture={true} allowPast={true} name="DateExpiration" />
                                </Col>
                                <Col lg={3} md={4} hidden={!haveProduct}>
                                    <FieldText disabled={disabled}   type="text" label="Lote" name="LotNumber" />
                                </Col>
                            </Row>
                            <Row hidden={!haveProduct}>   
                                <Col lg={6}>
                                    <FieldText disabled={disabled}  size="sm" type="text" label="Contacto de recuperación" name="Recuperado.ContactName" />
                                </Col>
                                <Col lg={6}>
                                    <FieldText disabled={disabled}  size="sm" type="text" label="Dirección de recuperación" name="Recuperado.ContactAddress" />
                                </Col>
                                <Col lg={12}>
                                    <FieldText disabled={disabled}  size="sm" type="text" label="Sitio de fabricación" name="Recuperado.PlaceManufacture" />
                                </Col>
                                <Col lg={12}>
                                    <FieldText disabled={disabled}  size="sm" type="text" label="Condiciones de almacenamiento (temperatura, humedad, etc)" name="Recuperado.ConditionStorage" />
                                </Col>
                                <Col lg={12}>
                                    <FieldText disabled={disabled}  size="sm" type="text" label="Estado de conservación del envase primario (frasco o blister)" name="Recuperado.StateConsFirst" />
                                </Col>
                                <Col lg={12}>
                                    <FieldText disabled={disabled}  size="sm" type="text" label="Remanente del envase (mililitros o gramos de producto)" name="Recuperado.AmountComp" />
                                </Col>
                                <Col lg={12}>
                                    <FieldText disabled={disabled}  size="sm" type="text" label="Nombre del funcionario que recupera el producto" name="Recuperado.NameFun" />
                                </Col>
                                <Col lg={12}>
                                    <FieldText disabled={disabled}  size="sm" type="textarea" label="Información adicional" name="Recuperado.StateConsSecond" />
                                </Col>
                            </Row>
                            <Row hidden={!haveProduct}>
                                {(medicine.Recuperado.NombreArchivo && !edit )?
                                    <Col sm={12} >
                                        <Label>Archivo</Label>
                                        <FileView url={downloadFileRecProductById(initialValues.Recuperado.Id)} fileName={initialValues.Recuperado.NombreArchivo} type={initialValues.Recuperado.Extension} handleEdit={this.handleEditFile}  /> 
                                    </Col>:
                                        <Col sm={12} >
                                        <UploadFile  label={"Archivo"} handleAddFile={this.handleAddFile} multiple={false} />
                                    </Col>
                                }
                            </Row>
                        </Card.Content>
                        <Card.Content extra  className="card-actions">
                            <div hidden={disabled}>
                                <Button type="button" className="card-actions-button button-back" variant="contained" disabled={loadSave} onClick={recoveryCancel} ><FontAwesomeIcon size="lg" icon={faTimes} /></Button>
                                <Button type="button" className="card-actions-button button-next" variant="contained" disabled={loadSave} onClick={()=> recoverySave(formValues,this.state)} ><FontAwesomeIcon size="lg" pulse={loadSave} icon={loadSave? faSpinner :faSave} /></Button>
                            </div>
                        </Card.Content>
                    </Card>
                </Card.Group>
            );
        }
};

ProductRecoveryForm.propTypes = {
    medicine: PropTypes.object,
};

const validate = values =>{
    const error ={};
      
    return error;
}

const mapStateToProps = (state) =>({
    haveProduct: formValueSelector('ProductRecovery')(state, 'Recuperado.HaveProduct'),
    formValues : getFormValues('ProductRecovery')(state),
    archivo: formValueSelector('ProductRecovery')(state,'Recuperado.Archivo'),
});

export default connect(mapStateToProps,null)(reduxForm({form: 'ProductRecovery', validate})(ProductRecoveryForm));
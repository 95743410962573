import React from 'react';
import PropTypes from 'prop-types';
import ListComponent from '..';
import ListItemOH from '../Item/ListItemOH';

const titles=['Fecha','Usuario','Operación',''];

const ListOperationHisotry = ({list, View, handleAction, load}) => {
    return (
        <ListComponent list={list} load={load} empty={"operaciones"} titles={titles} ListComponent={ListItemOH} handleAction={handleAction}/>
    );
};

ListOperationHisotry.propTypes = {
    list: PropTypes.array,
    handleAction: PropTypes.func,
};

export default ListOperationHisotry;
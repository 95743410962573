import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { getCodigueraContext } from '../../../../../redux/selectors/Context';
import { getCodiguerasContext, setLoad } from '../../../../../redux/actions/context';
import { updateRAM, setER, setERActions,setERRAMCategoryAll,setERImputEvaluation } from '../../../../../redux/actions/eventoReportable';
import { getERRAMS  } from '../../../../../redux/selectors/ER';
import ListRAMs from '../../../../ListComponents/List/ListRAMs';
import RAMCalifiactorModal from '../../../../Modals/RAMCalifiactorModal';
import Signature from '../../../../Signature';
import { url_postRAMUpdate, url_getERMeta, url_getActionsERByMeta, uploadFileLocalSanitaryNotificaction, uploadFileProviderNotification, url_getImputEvaluationByMeta } from '../../../../../api/urls';
import api from '../../../../../api';
import { showError } from '../../../../../redux/actions/error';
class RAMSComponent extends Component {
  
    state={
        ram: null,
        openModal: false,
        upload:false,
        openSiganture:false,
        stateModal:{
            localSanitaryFiles : [],
            providerNotificationFiles : [],
        },
        
    }

    componentDidMount() {
   
        if(!this.props.codiguera)
            this.props.getCodiguerasContext()

   
    }

   

    handleEdit = (id) => {
        let ram = this.props.RAMS.filter(x=>x.Id === id)[0] || null;
        this.setState({ram,openModal:true});
    }

    saveCalificator = async (comentario) =>{
      
      try {
        this.props.setLoad(false);
        this.setState({ upload: true });
        let ram = this.props.RAMS.find(x => x.Id === this.props.form.Id);
        ram = { ram, ...this.props.form };
        const newRam = await api.post(url_postRAMUpdate, { ram, comentario });
      
        this.props.updateRAM(newRam.data);
        let ramUpdated = newRam.data;
        const localFiles = this.state.stateModal.localSanitaryFiles;
        

        if(localFiles.length > 0){
          const fileZero = localFiles[0];
          const fileName = fileZero.name;

          let aux = new FormData();
          aux.append("Id", newRam.data.Id);
          const exts = fileName.split('.');
          let extension = exts[exts.length - 1];
          aux.append("Extension", extension);
          aux.append("FileName", fileName);
          localFiles.forEach((file, i ) => {
            aux.append(file.name, new Blob([file]));
          });

          await api.post(uploadFileLocalSanitaryNotificaction, aux);
          ramUpdated.LocalSanitaryNotificactionFile = fileZero;
          ramUpdated.LocalSanitaryNotificactionFileName = fileName;
          ramUpdated.LocalSanitaryNotificactionExtension = extension;
          this.props.updateRAM(ramUpdated);
        }
        const providersFiles = this.state.stateModal.providerNotificationFiles;

        if(providersFiles.length > 0){
          const providerFile = providersFiles[0];

          let aux = new FormData();
          aux.append("Id", newRam.data.Id);
          const exts = providerFile.name.split('.');
          const extensionProv = exts[exts.length - 1];
          aux.append("Extension", extensionProv);
          aux.append("FileName", providerFile.name);
          providersFiles.forEach((file, i) => {
            aux.append(file.name, new Blob([file]));
          });

          await api.post(uploadFileProviderNotification, aux);
          ramUpdated.ProviderNotificationFile = providerFile;
          ramUpdated.ProviderNotificationFileName = providerFile.name;
          ramUpdated.ProviderNotificationExtension = extensionProv;
          this.props.updateRAM(ramUpdated);
        }

       
        
        const erMeta = await api.get(url_getERMeta(this.props.meta));
        const actions = await api.get(url_getActionsERByMeta(this.props.meta));
        
        //const inputEva = await  api.get(url_getImputEvaluationByMeta(this.props.meta));
       
       // this.props.setERImputEvaluation(inputEva.data);
        this.props.setERActions(actions.data);
      
        this.props.setER(erMeta.data);
        this.setState({ openModal: false, upload: false});
        
      } catch (e) {
        this.props.showError(e);
      } finally {
       
        this.props.setLoad(false);
        this.setState({upload: false })
      }
    }

    toggleSignature = (stateModal) => {
        if(stateModal)
            this.setState({openSiganture: !this.state.openSiganture, stateModal});
        else
            this.setState({openSiganture: !this.state.openSiganture});
    };

    toggle = () => this.setState({openModal: !this.state.openModal});

    render() {
        const {RAMS,codiguera} = this.props;
        const {openModal, upload, openSiganture } = this.state;
        return (
            <div>
                {(codiguera && this.state.ram && openModal) &&
                <RAMCalifiactorModal
                    codiguera={codiguera}
                    open={openModal}
                    upload={upload}
                    toggle={this.toggle}
                    handleCalificator={this.toggleSignature}
                    pristine={this.props.pristine}
                    initialValues={this.state.ram} />}
                { RAMS && <ListRAMs list={RAMS} View='edit' handleAction={this.handleEdit} codiguera={codiguera}/>}
                <Signature title="Calificar reacción adversa"
                    message="¿Está seguro qué quiere califcar la reacción adversa?"
                    open={openSiganture}
                    toggle={this.toggleSignature}
                    handleConfirm={this.saveCalificator} />
            </div>
        );
    }
}

RAMSComponent.propTypes = {
    codigueraRAM: PropTypes.object,
};

const mapDispatchToProps = {
    updateRAM,
    setER,
    setERActions,
    getCodiguerasContext,
    setERRAMCategoryAll,
    setLoad,
    setERImputEvaluation,
    showError
};

const mapStateToProps = (state) =>({
    codiguera: getCodigueraContext(state),
    RAMS: getERRAMS(state),
    form: getFormValues('RAMCalificator')(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(RAMSComponent);

import React from "react";
import { Radio, FormControlLabel } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faDotCircle,
  faCircle
} from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";

const colorGroup = group => {
  switch (group) {
    case 1:
      return "text-warning";
    case 2:
      return "text-primary";
    case 3:
      return "text-danger";
    case 4:
      return "text-info";
    case 5:
      return "text-success";
    default:
      return "";
  }
};

const icon = group => {
  switch (group) {
    case 3:
      return faHeart;
    default:
      return faCircle;
  }
};

const iconCheck = group => {
  switch (group) {
    case 3:
      return faHeartSolid;
    default:
      return faDotCircle;
  }
};

const SubGroupSelect = ({ subgroups, onSelectSubGroup, selected }) =>
  subgroups.length > 0 &&
  subgroups.map(g => (
    <FormControlLabel
      className="m-b-n m-t-n w100"
      key={g.Id}
      value={g.Id.toString()}
      control={
        <Radio
          className="m-r-sm m-b-n m-t-n"
          checked={Number(selected) === g.Id}
          onChange={onSelectSubGroup}
          value={g.Id.toString()}
          name="subGroup"
          title={g.Nombre}
          classes={{ root: colorGroup(g.GrupoId) }}
          icon={<FontAwesomeIcon icon={icon(g.GrupoId)} />}
          checkedIcon={<FontAwesomeIcon icon={iconCheck(g.GrupoId)} />}
        />
      }
      label={g.Nombre}
    />
  ));

export default SubGroupSelect;

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Col, Row } from 'react-flexbox-grid';
import { sortSoubGroups } from '../../../../../helpers/utilities';
import { getHomeCodiguera } from '../../../../../redux/selectors/home';
import { getCodiguera } from '../../../../../codigueras';
import { FieldSelect, iconRequired } from '../../../../Fields';
import { compareSort } from './../../../../../helpers/utilities';

const Step1 = (props) => {
   const { handleChangePais,codiguera, view} = props;
    if(view==="edit"){

        return (
            <div className='form-step'>
                <div className='step1'>
                    <Row>
                        <Col sm={12} md={6}>
                            <FieldSelect name="Pais" isRequired={true} label="Seleccione país de residencia" onChange={handleChangePais} items={codiguera && getCodiguera("Paises",codiguera).sort(compareSort('Nombre'))} valueField="Codigo" textField="Nombre"/>
                        </Col>
                        <Col sm={12}/>
                        <h6 className="w100 m-l-md m-b">Clasifique el tipo de acontecimiento que desea comunicar {iconRequired}</h6>
                        {(codiguera && codiguera.SubGrupo) &&
                            sortSoubGroups(codiguera.SubGrupo).map((s,i) => 
                                <Col sm={12} md={6} key={i}>
                                    <label>
                                        <Field name="SubGroup" component="input" type="radio" value={s.Id.toString()} />{' '}
                                        {s.Nombre}
                                    </label>
                                </Col>
                            )
                        }
                    </Row>
                </div>
            </div>
        );
    }

};
const validate = values =>{
    const error ={};
    if(!values.SubGroup){
        error.SubGroup ="Debe seleccionar un tipo de acontecimiento";
    }
    if(!values.Pais){
        error.Pais ="Debe seleccionar un país de residencia";
    }
    return error;
}
const mapStateToProps = (state) =>({
    codiguera: getHomeCodiguera(state),
});

export default connect(mapStateToProps, null)(reduxForm({form: 'HomeStep1', validate})(Step1));

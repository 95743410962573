import React, { Component } from "react";
import { connect } from "react-redux";
import AppContainer from "../components/AppComponents/AppContainer";
import setPropsAsInitial from "../helpers/setPropsAsInitial";
import MedDRAComponent from "../components/MedDRAComponent";
import { Row, Col } from "react-flexbox-grid";
import AppBox from "../components/AppComponents/AppBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import api from "../api";
import { Link } from "react-router-dom";
import ImportMedDRAModal from "../components/Modals/ImportMedDRAModal";
import {
  uploadMeDRA,
  url_getmeddraimport,
  url_getdataimport
} from "../api/urls";
import { setMedDRAImports } from "../redux/actions/medDRA";
import { setDataImports } from "../redux/actions/dataimport";
import { withRouter } from "react-router-dom";
import DataImportComponents from "../components/DataImportComponents";
import { urlBase } from "../environment";
import { setSweetAlert } from '../redux/actions/sweetAlert';
import { showError } from '../redux/actions/error';
import { getImportsMedDRA } from '../redux/selectors/MedDRA';
import { current } from "@reduxjs/toolkit";
import { setLoad } from "../redux/actions/context";
const $ = window.$;

class DataImportContainer extends Component {
  state = {
    files: [],
    showForm: false,
    importMedDRA_HubConnection: null,
      zipPassword: ''
   };
  UNSAFE_componentWillMount = () => {
    this.props.showAppFrame(this.props.location.pathname);
  };


  componentWillUnmount () {
    //se limpia el timer
    clearInterval(this.timerID);
    
    // Desconectarse de signalR
    console.log('componentWillUnmount');
    $.connection.hub.stop( console.log("Disconnected SignalR"));
  }

  notificaciones = () => {

    this.props.setSweetAlert({
      type:'info',
      title: "Nueva Importacion",
      text: "Se ha creado una nueva importacion, para poder visualizarla recargue la página.",
      show:true
     });

  }

  componentDidMount = () => {
    //si sale de la pagina y vuelve a entrar inicializa el timer para verificar
    this.timerID = setInterval(
      () => this.tick(),
        10000)
    
    var medraHub = $.connection.MedDRAHub;
       
    if ($.connection.hub && $.connection.hub.state === 4) {
      $.connection.hub.url = urlBase + "signalr"
      $.connection.hub.start({ transport: ['longPolling','webSockets'] })
      .done(function(){ 
      })
      .fail(function(err){ console.log('Could not Connect!');
      console.log(err); });

    }
    this._handleGetImports(true);
   
  };

  tick() {
    this._handleGetImports(false);
  }

  _handleGetImportCode = msg => {
    this._handleGetImports(msg);
  };



  _handleGetImports = WithLoad => {
    if (WithLoad) {
      this.setState({ load: true });
    }
  
    api.get(url_getmeddraimport).then(r => {
      const currentImportIndex = this._getLastIndexOfIncompleteImport(r.data);
      if (currentImportIndex >= 0) 
      {
        const currentCodeImport = r.data[currentImportIndex].code_import;
        if ($.connection.hub && $.connection.hub.state === 1) {
          var medraHub = $.connection.MedDRAHub;
          medraHub.invoke('getCompletionPercentage', currentCodeImport)
          .done(percentage => 
            {
              //verifica si es un numero valido y distinto de undefined
              if (!Number.isNaN(percentage) && (typeof percentage !== "undefined"))
              {
                r.data[currentImportIndex].percentage = percentage;
                this.props.setMedDRAImports([]);
                this.props.setMedDRAImports(r.data);
                if (WithLoad)
                {
                  this.setState({ load: false });
                }
              }
              else //si ya no hay porcentaje se para el timer
              {
                clearInterval(this.timerID);
              }
            });  
        }
      }

      this.props.setMedDRAImports(r.data);
      if (WithLoad) {
        this.setState({ load: false });
      }
    });

    api.get(url_getdataimport).then(r => {
      this.props.setDataImports(r.data);
      if (WithLoad) {
        this.setState({ load: false });
      }
    });
  };

  _handleImportPercentageUpdate = (importCode, percentage) => {
    const { imports } = this.props;
    if (imports) {
      const index = imports.findIndex( i => i.code_import === importCode);
      if (index >= 0) {
        imports[index].percentage = percentage;
        this.props.setMedDRAImports([...imports.slice(0, index), imports[index], ...imports.slice(index + 1)]);
      } else {
         this._handleGetImports(false);
      }
    }
  }

  _handleImportCompleted = () => {
    this._handleGetImports(true);
  }

  _handleAddMedDRA = files => this.setState({ files: files });

  _handlePasswordChange = (e) =>{
    this.setState({ zipPassword: e.target.value });
  }

  _handleUPload = async () => {
    this.props.setLoad(true);
    this.toggle();
    try{

      let aux = new FormData();
      const data = this.state.files;
      aux.append("FileName", data[0].name);
      data.forEach((file, i) => {
        aux.append(file.name, new Blob([file]));
      });
      aux.append("password", this.state.zipPassword);
      await api.post(uploadMeDRA, aux);

      this._handleGetImports(false);

      this.timerID = setInterval(
        () => this.tick(),
          10000)
            
    }catch(error){
      this.props.showError(error);
    }
    finally
    {
      this.props.setLoad(false);
    }
  };

  _getLastIndexOfIncompleteImport = (imports) => {
    for (let i = 0; i < imports.length; i++) {
      if (!imports[i].date_end) {
        return i;
      }
    }
    return -1;
  };

  toggle = () => this.setState({ showForm: !this.state.showForm });

  buttonImportProducts = () => (
    <Link className="plus-link" to={"#"}>
      <FontAwesomeIcon size="lg" icon={faDownload} />
    </Link>
  );
  renderBody = (files, showForm) => (
    <div className="p-l-md p-r-md">
      <Row>
        <Col sm={6} xs={12} className="text-center p-sm">
          <AppBox
            title="Productos"
            hiddenTools={true}
            className="text-left p-l-n p-r-n"
          >
            <DataImportComponents />
          </AppBox>
        </Col>
        <Col sm={6} xs={12} className="text-center p-sm">
          <AppBox
            title="MedDRA"
            hiddenTools={true}
            toolsCustom={
              <Link
                className="plus-link"
                style={{ color: "#007bff!important" }}
                to={"#"}
                onClick={() => this.setState({ showForm: true })}
              >
                <FontAwesomeIcon size="lg" icon={faDownload} />
              </Link>
            }
            className="text-left p-l-n p-r-n"
          >
            <MedDRAComponent />
            <ImportMedDRAModal
              open={showForm}
              toggle={this.toggle}
              files={files}
              handleUPload={this._handleUPload}
              handleAddMedDRA={this._handleAddMedDRA}
              handlePasswordChange={this._handlePasswordChange}
            />
          </AppBox>
        </Col>
      </Row>
    </div>
  );

  render = () => {
    const { files, showForm } = this.state;
    return (
      <AppContainer
        title="Importación de datos"
        className="background-f4f4f4 p-t-n"
      >
        {this.renderBody(files, showForm)}
      </AppContainer>
    );
  };
}

const mapStateToProps = (state)=>({
  imports: getImportsMedDRA(state),
});

const mapDispatchToProps = {
  setMedDRAImports,
  setDataImports,
  setSweetAlert,
  showError,
  setLoad 

};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(setPropsAsInitial(DataImportContainer))
);

import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import {
  FieldText,
  FieldRadioGroup,
  FieldDatePicker
} from "../../../../Fields";
import { emailValidator } from "../../../../../helpers/utilities";

const Step6 = props => {
  const {
    notificadoPorOtro,
    isMedic,
    contactoMedico,
    permitirContactoMedico
  } = props;
  return (
    <div className="form-step">
      <h3>{isMedic ? "Historia clínica" : "Contacto médico"}</h3>
      {isMedic && (
        <Row>
          <Col sm={12}>
            <FieldText
              name="HistoriaClinica"
              label="Datos importantes de la historia clínica 
                        (ej. Diagnósticos previos, fumador, consume alcohol, historial de alergias, embarazos previos, 
                        toma anticonceptivos, operaciones previas relevantes, enfermedades
                        crónicas, drogadicción, etc.)"
              type="textarea"
            />
          </Col>
          <Col md={12} sm={12}>
            <FieldRadioGroup
              isRequired={true}
              name="NotificadoPorOtro"
              labelBlock={true}
              inline={true}
              items={[
                { value: "SI", text: "Si" },
                { value: "NO", text: "No" },
                { value: "Desconoce", text: "Desconoce" }
              ]}
              label="¿El acontecimiento adverso / reacción adversa ha sido notificada a través de otra vía?"
              textField="text"
              valueField="value"
            />
          </Col>
          <Col sm={12} hidden={notificadoPorOtro !== "SI"}>
            <FieldText
              name="OtraViaNotificado"
              label="Mencione otras vías en las que fue notificado"
              type="textarea"
            />
          </Col>
        </Row>
      )}
      {!isMedic && (
        <Row>
          <Col md={6} sm={12}>
            <FieldRadioGroup
              isRequired={true}
              name="ContactoMedico"
              labelBlock={true}
              inline={true}
              items={[
                { value: "SI", text: "Si" },
                { value: "NO", text: "No" }
              ]}
              label="¿Ha informado a su médico de esta reacción?"
              textField="text"
              valueField="value"
            />
          </Col>
          {contactoMedico === "SI" && (
            <Col md={6} sm={12}>
              <FieldDatePicker
                name={`Medico.FechaContacto`}
                allowFuture={false}
                allowPast={true}
                label="Fecha de contacto médico"
              />
            </Col>
          )}
          {contactoMedico === "SI" && (
            <Col sm={12}>
              <FieldText
                name={`Medico.Intervencion`}
                label="Intervención realizada"
                type="textarea"
              />
            </Col>
          )}
          {contactoMedico === "SI" && (
            <Col md={6} sm={12}>
              <FieldRadioGroup
                isRequired={contactoMedico === "SI"}
                name="PermitirContactoMedico"
                labelBlock={true}
                inline={true}
                items={[
                  { value: "SI", text: "Si" },
                  { value: "NO", text: "No" }
                ]}
                label="¿Da su consentimiento para que contactemos a su médico?"
                textField="text"
                valueField="value"
              />
            </Col>
          )}

          {permitirContactoMedico === "SI" && (
            <Col sm={12} md={6}>
              <FieldText
                isRequired={true}
                name="Medico.Profesion"
                label="Especialidad del médico (Oncología,Dermatología, etc.)"
                type="text"
              />
            </Col>
          )}
          {permitirContactoMedico === "SI" && (
            <Col sm={12} md={6}>
              <FieldText
                isRequired={true}
                name="Medico.Nombre"
                label="Nombre del médico"
                type="text"
              />
            </Col>
          )}

          {permitirContactoMedico === "SI" && (
            <Col sm={12} md={6}>
              <FieldText
                name="Medico.Email"
                label="Correo eletrónico del médico"
                type="email"
              />
            </Col>
          )}
          {
          //FIX 10403  
          permitirContactoMedico === "SI" && (
            <Col sm={12} md={6}>
              <FieldText
                name="Medico.Telefono"
                label="Teléfono del médico"
                type="text"
                onChange={(event, value) => {
                  console.log(value);
                  if (!/^[0-9\b]+$/g.test(value)) {
                    event.preventDefault();
                  }
                }}
              />
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};
const validate = (values, props) => {
  let error = {};
  if (props.isMedic) {
    if (!values.NotificadoPorOtro) {
      error.NotificadoPorOtro =
        "Debe indicar si  el acontecimiento adverso / reacción adversa ha sido notificada a través de otra vía";
    }
  } else {
    if (!values.Medico || !values.Medico.Nombre) {
      error.Nombre = "El campo es obligatorio";
    }
    if (!values.Medico || !values.Medico.Profesion) {
      error.Profesion = "El campo es obligatorio";
    }
    if (values.Medico && values.Medico.Email && !emailValidator(values.Medico.Email) ) {
      error.Email = "El formato del correo es incorrecto";
    }
  }

  return error;
};

const mapStateToProps = state => ({
  notificadoPorOtro: formValueSelector("HomeStep6")(state, "NotificadoPorOtro"),
  contactoMedico: formValueSelector("HomeStep6")(state, "ContactoMedico"),
  permitirContactoMedico: formValueSelector("HomeStep6")(
    state,
    "PermitirContactoMedico"
  )
});
export default connect(
  mapStateToProps,
  null
)(reduxForm({ form: "HomeStep6", validate })(Step6));

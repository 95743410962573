import React from 'react';
import PropTypes from 'prop-types';
import ListComponent from '..';
import ListItemMR from '../Item/ListItemMR';

const TitlesTable = ['Fabricante','Producto','Principio activo',''];

const ListMedication = ({list, View, handleAction}) => {
    return (
        <ListComponent titles={TitlesTable} ListComponent={ListItemMR} View={View} handleAction={handleAction} list={list} empty="productos" />
    );
};

ListMedication.propTypes = {
    list: PropTypes.array,
    View: PropTypes.string,
    handleAction: PropTypes.func,
};

export default ListMedication;
import  React from 'react';
import {Link} from 'react-router-dom';

const HomeFooter = React.forwardRef((props, ref) =>(
    <div ref={ref} className='footer-wrap'>
       <div className="div-25">
            <img src={require('../images/cim-logo-color.png')}className="logo-footer" alt="CIM - Centro Interamericano de Monitoreo" />
        </div><div className="div-25">
            <ul className="footer-list">
                <li><Link to="#objetivos">Objetivos</Link></li>
                <li><Link to="#reportes-y-consultas">Reportes y consultas</Link></li>
                <li><Link to="#companias">Compañías</Link></li>
                <li><Link to="#faqs">Preguntas Frecuentes</Link></li>
            </ul>
        </div><div className="div-25">
            <ul className="footer-list">
                <li><Link to="#" className="footer-login-bt">Ingresar</Link></li>
                <li><Link to="Pages/RegisterUser.aspx">Registrarme</Link></li>
                <li><Link to="#reportes-y-consultas">Reportar Incidente</Link></li>
            </ul>
        </div><div className="div-25">
            <ul className="footer-list">
                <li><Link to="TermsAndConditions.aspx">Términos y Condiciones</Link></li>
                <li><Link to="PrivacyPolicy.aspx">Política de Privacidad</Link></li>
                <li>&nbsp;</li>
                <li>CIM &copy;2019</li>
            </ul>
        </div>
    </div>
))
export default HomeFooter;
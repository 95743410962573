import React from 'react';
import PropTypes from 'prop-types';
import ListComponent from '..';
import ListItemRAM from '../Item/ListItemRAM';

const ListRAMs = ({list,handleAction ,View, codiguera}) => {
    const titles = ['Termino MedDRA','Descripción','Es reacción adversa','Tipo','Categoría ','Notificó a la autoridad sanitaria local','Reportó al licenciante o fabricante',''];
    const titlesEdit = ['Termino MedDRA','Descripción','Es reacción adversa','Es una reacción esperada','Tipo','Categoría',''];

    return (
       <ListComponent codiguera={codiguera} titles={View==='edit' ? titlesEdit : titles} list={list} View={View} handleAction={handleAction} ListComponent={ListItemRAM} empty="rams" />
    );
};

ListRAMs.propTypes = {
    list: PropTypes.array,
    handleAction: PropTypes.func,
    View: PropTypes.string,
};

export default ListRAMs;

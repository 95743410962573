import {  faLock,  faLockOpen, faTimesCircle, faInfoCircle, faCheck, faShare, faBook, faFilePdf,faUnlock  } from '@fortawesome/free-solid-svg-icons'

export const iconActionER = (action)=>{
    switch(action){
        case "ActivarRLF": return faLockOpen;
        case "ActivarQPPV": return faLockOpen;
        case "AprobarRLF":return faCheck;
        case "ArchivarRLF":return faLock;
        case "MasInfo":return faInfoCircle;
        case "DescargarPdf": return faFilePdf;
        case "Audit": return faBook;
        case "ArchivarQPPV":return faLock;
        case "Imputable":return faCheck;
        case "NoImputable":return faTimesCircle;
        case "EnEsperaFabricante":return faShare;
        case "AprobarFabricante":return faCheck;
        case "DesarchivarRLF":return faUnlock;
        default: return null;
    }
}
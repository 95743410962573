import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLevelUpAlt, faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@material-ui/core';


class MetricBox extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.value === nextProps.value;
    }

    render(){
        const {title, value, porcentage, smallText, loading} = this.props;
        return (
            <div className="ibox ">
                <div className="ibox-title">
                    <h6>{title}</h6>
                </div>
                <div className={`ibox-content ${loading && 'text-center'}`}>
                <h4 className="m-n">{value && value}</h4>
                    {loading ? <CircularProgress className='text-center'/> :
                        porcentage !== 0 && (
                        <span className={`stat-percent font-bold ${porcentage > 0 ? 'text-success': 'text-danger'}`}>
                            { porcentage > 0 ? `${porcentage}%` : porcentage < 0 && `${(porcentage * -1)}%`} 
                            { porcentage !== 0 && <FontAwesomeIcon icon={porcentage > 0 ? faLevelUpAlt : faLevelDownAlt }/>}
                        </span>)}

                    {!loading && smallText && <small>{smallText}</small>}
                    
                </div>
            </div>
        );
    }
};

MetricBox.propTypes = {
    value: PropTypes.number,
    badge: PropTypes.string,
    title: PropTypes.string,
};

export default MetricBox;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showError } from '../../../../../redux/actions/error';
import { setLoad } from '../../../../../redux/actions/context';
import api from '../../../../../api';
import ListHistoryPdf from '../../../../ListComponents/List/ListHistoryPdf';
import { url_getPdfsFiles } from '../../../../../api/urls';

class HitorialPdfs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            load:"",
            items:[]
        };
    }

    componentDidMount = async () => {
      try {
        this.props.setLoad(true);
        const response = await api.get(url_getPdfsFiles(this.props.er.Id));
        this.setState({ items: response.data })
      } catch (e) {
        this.props.showError(e);
      } finally {
        this.props.setLoad(false);
      }
    }

    handleAction = async (action, item) =>{
      try {
         const id = this.props.er.Id;
         const reference = this.props.er.NumeroReferencia;
         const response = await api.getPDF(`/api/getERPDF/${id}/${item}/`);
         let doc = new Blob([response.data], {type: "application/pdf"});
         let link = document.createElement('a');
         link.href = window.URL.createObjectURL(doc);
         link.download = item;
         link.click();
      } catch (e) {
        this.props.showError(e);
      }
    }

    render(){
        const { load } = this.state;
        return (
            <div>
                <ListHistoryPdf list={this.state.items} handleAction={this.handleAction}  load={load}/>
            </div>
        );
    }
};

const mapDispatchToProps = {
  showError,
  setLoad
}

const mapStateToProps = (state) => {

}

export default connect(mapStateToProps, mapDispatchToProps)(HitorialPdfs);

import React from 'react';
import PropTypes from 'prop-types';
import {downloadFollowUp} from '../../../api/urls'
import api from '../../../api'
import ListActions from './ListActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt,} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { LabelDate } from '../../Fields';

const actions = (item) => [
    <Link to={'#'} onClick={() => api.download(downloadFollowUp(item.Id),item.FileName)} ><FontAwesomeIcon icon={faCloudDownloadAlt} size={'lg'}/></Link>
];

const ListItemFU = ({Item, classProp}) => {
    return (
        <tr className={`list-item ${classProp}`}>
           <td>{LabelDate(Item.Date)}</td>
           <td>{Item.UserFullName}</td>
           <td>{Item.FileName}</td>
           <td className="p-t-sm"><ListActions  actions={actions(Item)}/></td>
        </tr>
    );
};

ListItemFU.propTypes = {
    Item: PropTypes.object,
};

export default ListItemFU;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { equalsChartData } from '../../helpers/utilities';

class ChartPie extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const should = equalsChartData(this.props.data,nextProps.data) === false;
        return should;
    }

    render(){
        const {name, title, data, showInLegend} = this.props;
        const options = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
              text: title
            },
            tooltip: {
                formatter: function () {
                    return `<b>${this.point.name}</b><br/>
                            Total: ${this.point.y}<br/>
                            Porcentaje:${this.point.percentage.toFixed(2)}%`;
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        color: '#444',
                        align: 'center',
                        format: '{point.y}',
                        y: 0, 
                        style: {
                            fontSize: '13px',
                            fontFamily: 'Verdana, sans-serif'
                        }
                    },  
                    showInLegend
                }
            },
            series: [{
                name,
                colorByPoint: true,
                data: data,
            }]
        }
        return (
            <HighchartsReact
            highcharts={Highcharts}
            options={options}/>
        );
    };
};

ChartPie.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.array,
};

export default ChartPie;
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  Label,
  FormGroup,
  FormFeedback,
  Badge
} from "reactstrap";
import { url_signature } from "../../api/urls";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import { shake } from "react-animations";
import jss from "jss";
import preset from "jss-preset-default";
import "./style.css";
import api from "../../api";
import { setLoad } from "../../redux/actions/context";
import { showError } from '../../redux/actions/error';

jss.setup(preset());

const { classes } = jss
  .createStyleSheet({
    "@keyframes shake": shake,
    shake: {
      animationName: "shake",
      animationDuration: "0.6s"
    }
  })
  .attach();

class Signature extends Component {
  state = {
    email: "",
    pass: "",
    comment: "",
    loading: false,
    error: [],
    validEmail: false,
    validPass: false,
    alidComment: false
  };

  handleChangeEmail = e =>
    this.setState({
      email: e.target.value,
      validEmail: e.target.value.lenght > 0
    });

  handleChangePass = e =>
    this.setState({
      pass: e.target.value,
      validPass: e.target.value.lenght > 0,
      error: false
    });

  handleChangeComment = e =>
    this.setState({
      comment: e.target.value,
      alidComment: e.target.value.lenght > 0,
      error: false
    });

  validForm = () => {
    let ret = true;
    let error = [];
    if (this.state.comment.length === 0) {
      ret = false;
      error.push("comment");
    }
    if (this.state.email.length === 0) {
      ret = false;
      error.push("email");
    }
    if (this.state.pass.length === 0) {
      ret = false;
      error.push("pass");
    }
    this.setState({ error });
    return ret;
  };

  confirm = async (userEmail, password, comment) => {
    if (this.validForm()) {
      this.setState({ loading: true });
      this.props.setLoad(true);
      const { handleConfirm } = this.props;
      try{
        await api.post(url_signature, { userEmail, password });
        handleConfirm(comment);
        this.toggle();
      }catch(error){
        this.props.showError(error);
        this.setState({ error: [error.response.data.Message]});
      }finally{
        this.props.setLoad(false);
        this.setState({ loading:  false });
      }
    }
  };

  toError = err =>
    this.state.error && this.state.error.filter(x => x === err).length > 0;

  showError = () =>
    this.state.error.length === 1
      ? !(
          this.toError("comment") ||
          this.toError("email") ||
          this.toError("pass")
        ) && this.state.error[0]
      : "";

  renderBody = error => (
    <Form className={"from-signature"}>
      <FormGroup>
        <Label>Comentario</Label>
        {this.toError("comment") ? (
          <Input
            invalid
            autoComplete="off"
            type="textarea"
            value={this.state.comment}
            onChange={this.handleChangeComment}
          />
        ) : (
          <Input
            autoComplete="off"
            type="textarea"
            value={this.state.comment}
            onChange={this.handleChangeComment}
          />
        )}
        <FormFeedback>Comentario requerido</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Usuario</Label>
        {this.toError("email") ? (
          <Input
            invalid
            autoComplete="off"
            type="email"
            value={this.state.email}
            onChange={this.handleChangeEmail}
          />
        ) : (
          <Input
            autoComplete="off"
            type="email"
            value={this.state.email}
            onChange={this.handleChangeEmail}
          />
        )}
        <FormFeedback>Correo requerido</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label>Contraseña</Label>
        {this.toError("pass") ? (
          <Input
            invalid
            autoComplete="new-password"
            aria-autocomplete="none"
            type="password"
            value={this.state.pass}
            onChange={this.handleChangePass}
          />
        ) : (
          <Input
            autoComplete="new-password"
            aria-autocomplete="none"
            type="password"
            value={this.state.pass}
            onChange={this.handleChangePass}
          />
        )}
        <FormFeedback>Contraseña requerida</FormFeedback>
      </FormGroup>
    </Form>
  );

  toggle = () => {
    this.setState({
      email: "",
      pass: "",
      comment: "",
      loading: false,
      error: [],
      validEmail: false,
      validPass: false,
      alidComment: false
    });
    this.props.toggle();
  };

  render() {
    const { open, title, message } = this.props;
    const { email, pass, comment, loading, error } = this.state;
    return (
      <Modal
        backdrop={"static"}
        className={
          this.showError() !== ""
            ? [classes.shake, "signature-modal-error"]
            : "signature-modal"
        }
        fade={false}
        isOpen={open}
        toggle={() => this.toggle()}
      >
        <ModalHeader toggle={() => this.toggle()}>{title}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>{message}</Label>
          </FormGroup>
          {this.renderBody(error)}
          <div className="w100">
            {error.length > 0 && (
              <h5 className="text-center">
                <Badge color="danger">{this.showError(error)}</Badge>
              </h5>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="background-f4f4f4">
          <Button
            type="button"
            disabled={loading}
            className="card-actions-button button-back"
            onClick={() => this.toggle()}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            disabled={loading}
            className="card-actions-button button-next"
            onClick={() => this.confirm(email, pass, comment)}
          >
            Confirmar{" "}
            {loading && <CircularProgress className="m-l-md" size={15} />}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

Signature.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string
};

const mapDispatchToProps = {
  setLoad,
  showError
};

export default connect(
  null,
  mapDispatchToProps
)(Signature);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import LockForm from '../components/LoginComponents/LockForm';
import { setMyUser,
    setCodiguerasContext,
    setLoad} from '../redux/actions/context';
import { url_getContext } from '../api/urls';
import { secureStorage } from '../api/secureStorage';
import { getMyUser } from '../redux/selectors/Context';
import api from '../api';


class LockContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
            pass:'',
            load:false,
            validPass: true,
            msgError:'',
            openLock:false,
        };
        this._handleChangeInput = this._handleChangeInput.bind(this);
        this._handleLogin = this._handleLogin.bind(this);
    }


    _handleChangeInput = (e) => {
        const { value } = e.target
        this.setState({
            pass:value,
        });
    }

    _handleLogin = async (reCaptchaToken) =>{
        if(reCaptchaToken === null)
        {
            this.setState({msgError: 'Debe marcar la casilla  --No soy un robot-- '});
            return;
        }
        this.props.setLoad(true);
        const {pass} = this.state;
        const { Email } =this.props.User;
        try {
          const response = await api.logIn(Email, pass, true, reCaptchaToken);
          if(response.status === 200){
            this.setState({ openLock: false });
            this.props.handleHideLock();
            this.props.setLoad(false);

          }else {

          this.setState({msgError:'contraseña incorrecta'})
          }
          //const response = await api.get(url_getContext);
          //secureStorage.setItem('user', response.data.User);
          //secureStorage.setItem('sessionTimeout', response.data.SessionTimeout);
        } catch (e) {
          this.setState({msgError:'contraseña incorrecta'})
        } finally{
          this.props.setLoad(false);
        }
    }

    componentDidMount() {
        this.timerID = setInterval(
          () => this.tick(),
          60
        );
      }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({openLock:true});
      }


    render() {
        const {validPass,msgError,openLock} = this.state;
        const { User } = this.props;
        return (
            <div className={`background-login lock ${openLock?'open':''}`}>
                <div className="login-form">
                    <LockForm
                        handleLogin={this._handleLogin}
                        handleChangeInput={this._handleChangeInput}
                        validPass={validPass}
                        msgError={msgError}
                        User={User}/>
                </div>
            </div>
        );
    };
}

const mapDispatchToProps = {
    setMyUser,
    setCodiguerasContext,
    setLoad,
}

const mapStateToProps = (state) =>({
    User: getMyUser(state),
});


export default connect(mapStateToProps,mapDispatchToProps)(LockContainer);

import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RAM from "./Ram";
import { url_getmeddrahomebyllt } from "../../../../../api/url_home";
import api from '../../../../../api'


class Step3 extends Component {
  state = {
    RAMS: []
  };
  componentDidMount = () => {
    let RAMS = [];
    if (
      this.props.initialValues &&
      this.props.initialValues.RAM &&
      this.props.initialValues.RAM.length > 0
    ) {
      this.props.initialValues.RAM.forEach(r => {
        RAMS.push({
          component: RAM,
          open: true,
          allowDelete: false,
          medraLoading: false,
          llts: []
        });
      });
    } else {
      RAMS.push({
        component: RAM,
        open: true,
        allowDelete: false,
        medraLoading: false,
        llts: []
      });
    }
    this.setState({ RAMS });
  };

  handleAddRAM = () => {
    let { RAMS } = this.state;
    RAMS.push({
      component: RAM,
      open: true,
      allowDelete: false,
      medraLoading: false,
      llts: []
    });
    RAMS = RAMS.reduce((acc, ram) => {
      ram.allowDelete = true;
      return [...acc, ram];
    }, []);
    this.setState({ RAMS });
  };

  handleDelRAM = index => {
    let { RAMS } = this.state;
    let { RAM } = this.props;
    if(RAM)
    {
      RAM = RAM.reduce((acc, ram, i) => {
        if (i !== index) {
          return [...acc, ram];
        } else {
          return [...acc];
        }
      }, []);
    }
    
    RAMS = RAMS.reduce((acc, ram, i) => {
      if (i !== index) {
        return [...acc, ram];
      } else {
        return [...acc];
      }
    }, []);
    if (RAMS.length === 1) {
      RAMS[0].allowDelete = false;
    }
    this.props.change("RAM", RAM);
    this.setState({ RAMS });
  };

  toggleRAM = index => {
    let { RAMS } = this.state;
    RAMS = RAMS.reduce((acc, ram, i) => {
      if (i === index) {
        ram.open = !ram.open;
      }
      return [...acc, ram];
    }, []);
    this.setState({ RAMS });
  };

  handleSearchMeddrallt = async (index, query) => {
    let { RAMS } = this.state;
    RAMS = RAMS.reduce((acc, ram, i) => {
      if (i === index) {
        ram.medraLoading = true;
      }
      return [...acc, ram];
    }, []);
    this.setState({ RAMS });
    try{
      const response = await api.get(url_getmeddrahomebyllt(query));
      let { RAMS } = this.state;
      RAMS = RAMS.reduce((acc, ram, i) => {
        if(index === i){
          ram.medraLoading = false;
          ram.llts = response.data;
        }
        return [...acc, ram]
      }, []);
      this.setState({ RAMS });

    }catch(error){
      this.props.showError(error);
    }
  }

  render() {
    const { RAMS } = this.state;
    const { isMedic } = this.props;
    return (
      <div className="form-step">
        {RAMS.map((r, i) => (
          <r.component
            key={i}
            index={i}
            open={r.open}
            toggle={this.toggleRAM}
            handleDelete={this.handleDelRAM}
            allowDelete={r.allowDelete}
            llts={r.llts}
            loading={r.medraLoading}
            handleSearchLlt={this.handleSearchMeddrallt}
            isMedic={isMedic}
          />
        ))}
        <div className="from-footer">
          <Button
            outline
            className="login-btn w100"
            onClick={this.handleAddRAM}
          >
            <FontAwesomeIcon className="m-r-sm" icon={faPlus} /> Añadir
            acontecimiento adverso / reacción adversa
          </Button>
        </div>
      </div>
    );
  }
}

const isEmptyError = (error, isMedic) => {
  let ret = true;
  let i = 0;
  while (i < error.RAM.length && ret) {
    if (error.RAM[i].hasOwnProperty("Grave")) {
      ret = false;
    }
    if (isMedic && error.RAM[i].hasOwnProperty("MedDRA")) {
      ret = false;
    }
    if (!isMedic && error.RAM[i].hasOwnProperty("Reaccion")) {
      ret = false;
    }
    i++;
  }
  return ret;
};

const validate = (values, props) => {
  let error = {};
  if (values.RAM && values.RAM.length > 0) {
    values.RAM.forEach((r, i) => {
      if (!error.RAM) {
        error.RAM = [];
      }
      error.RAM.push({});
      if (props.isMedic && (!r.Grave || r.Grave === "none")) {
        error.RAM[i].Grave = "Debe ingresar gravedad";
      }

      if (!props.isMedic && !r.Reaccion) {
        error.RAM[i].Reaccion =
          "Debe ingresar una desripción del acontecimiento adverso / reacción adversa";
      }
    });
  }
  if (!values.RAM || values.RAM.length === 0) {
    error.RAM = [{}];
    if (!props.isMedic) {
      error.RAM[0].Reaccion =
        "Debe ingresar un acontecimiento adverso / reacción adversa";
    }
    error.RAM[0].Grave = "Debe ingresar gravedad";
  }
  if (isEmptyError(error, props.isMedic)) {
    error = {};
  }
  return error;
};

const mapStateToProps = state => ({
  RAM: formValueSelector("HomeStep3")(state, "RAM")
});

export default connect(
  mapStateToProps,
  null
)(reduxForm({ form: "HomeStep3", validate })(Step3));

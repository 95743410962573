import { faPlus, faPen, faTrash  } from '@fortawesome/free-solid-svg-icons'

export const iconAuditType = (type)=>{
    switch(type){
        case "I": return faPlus;
        case "U": return faPen;
        case "D":return faTrash;
        default: return null;
    }
}

export const titleAuditType = (type) => {
    switch(type){
        case "I": return "Creado";
        case "U": return "Actualizado";
        case "D": return "Borrado";
        default: return null;
    }
}
import React from 'react';
import SectionTitle from './SectionTitle'
import PropTypes from 'prop-types';

const Section = ({title,body, edit, add, step, children,className }) => {
    return (
        <div className={`summarySection ${className}`} >
            <SectionTitle title={title} edit={edit} add={add} step={step}/>
            {body}
            {children}
        </div>
    );
};

Section.propTypes = {
    body: PropTypes.element,   
};

export default Section;
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ListActions from "./ListActions";
import { LabelDate } from "../../Fields";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faComment,
  faTrashAlt,
  faExclamationTriangle,
  faPlusCircle,
  faFilePdf,
  faBook,
  faClock,
  faGlobeAmericas,
  faPrescriptionBottle
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import { Tooltip, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { url_getMedicalSuspShortMeta, url_delER, url_downloadERPdf } from "../../../api/urls";
import api from "../../../api";
import { LoadCircular } from "../../AppComponents/AppUtilities";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { bounceOut } from "react-animations";
import jss from "jss";
import preset from "jss-preset-default";
import NavLink from "react-router-dom/NavLink";
import Signature from "../../Signature";

jss.setup(preset());

const { classes } = jss
  .createStyleSheet({
    "@keyframes bounceOut": bounceOut,
    bounceOut: { animationName: "bounceOut", animationDuration: "0.6s" }
  })
  .attach();
class ListItemWF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
      popoverOpen: false,
      products: [],
      loadPopOver: false,
      dropdownOpen: false,
      popoverMetricOpen: false,
      openSignature: false
    };
  }

  componentDidMount(){}

  toggleToolTip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  togglePopOver = async (item) => {
    if (this.state.products.length === 0) {
      this.setState({
        loadPopOver: true
      });
      try {
        const response = await api.get(url_getMedicalSuspShortMeta(item.Meta));
        this.setState({
          popoverOpen: !this.state.popoverOpen,
          products: response.data,
          loadPopOver: false
        });
      } catch (e) {

      }
    } else {
      this.setState({
        popoverOpen: !this.state.popoverOpen
      });
    }
  };

  _pdf = async (ER) => {
    try{
        const id = ER.Id;
        const numeroReferencia = ER.NumeroReferencia
        const response = await api.getPDF(url_downloadERPdf(id));
        let doc = new Blob([response.data], {type: "application/pdf"});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(doc);
        link.download = "ER-" + numeroReferencia;
        link.click();
    }catch(error){
        console.log(error)
       // this.props.showError(error);
    }
  }

  toggleMetricPopOver = () =>
    this.setState({ popoverMetricOpen: !this.state.popoverMetricOpen });

  toggleSignature = () =>
    this.setState({ openSignature: !this.state.openSignature });


    delReport = async (er) => {
      try{
        await api.post(url_delER, er);
        window.location.reload(true)
      }catch(error){

      }

    }

  actions = (ER, handleAction, load, search, mini) => {
    let acts = [];
    if(ER.IsHistoric){
        acts.push(
          <Link className="m-l-sm" to={`#`} onClick={() => this._pdf(ER)}>
            {mini ? (
              "Exportar PDF"
            ) : (
              <FontAwesomeIcon className="m-l-sm" icon={faFilePdf} size={"lg"} />
            )}
          </Link>
        );
        return acts;
    }
    if (ER.Estado === "Pendiente") {
      acts.push(
        <Link
          className="m-l-sm"
          to={`/reportableEvent/${ER.Meta}/edit/`}
          onClick={() => handleAction(``)}
        >
          {mini ? (
            "Editar"
          ) : (
            <FontAwesomeIcon
              icon={faPencilAlt}
              className="m-l-sm"
              size={"lg"}
            />
          )}
        </Link>,
        <NavLink onClick={this.toggleSignature} to="#">
          {mini ? (
            "Eliminar"
          ) : (
            <FontAwesomeIcon className="m-l-sm" icon={faTrashAlt} size={"lg"} />
          )}
                <Signature
                    title = "Eliminar el evento reportable"
                    message = "¿Está seguro que quiere dejar eliminar el reporte?"
                    open={this.state.openSignature}
                    toggle={this.toggleSignature}
                    handleConfirm={(comment) => {
                      ER.Comentarios = comment
                      this.delReport(ER)
                    }}/>
        </NavLink>
      );
    } else {
      if (!search) {
        if (ER.Comentarios)
          acts.push(
            <Link
              className="m-l-sm"
              to={"#"}
              onClick={() => handleAction("comments", ER)}
            >
              {mini ? (
                "Comentarios"
              ) : (
                <FontAwesomeIcon
                  className="m-l-sm"
                  icon={faComment}
                  size={"lg"}
                />
              )}
            </Link>
          );
        if (ER.CountFollowUp > 0)
          acts.push(
            <Link
              className="m-l-sm"
              to={"#"}
              onClick={() => handleAction("followUp", ER)}
            >
              {load === `FollowUp${ER.Meta}` ? (
                <LoadCircular className="m-l-sm" size={15} />
              ) : (
                <FontAwesomeIcon
                  className="m-l-sm"
                  icon={faPlusCircle}
                  size={"lg"}
                />
              )}
            </Link>
          );
      }
    }
    if (search) {
      if(ER.Estado != 'Pendiente' && ER.Estado != 'Anulado'){
        acts.push(
          <Link className="m-l-sm" to={`#`} onClick={() => this._pdf(ER)}>
            {mini ? (
              "Exportar PDF"
            ) : (
              <FontAwesomeIcon className="m-l-sm" icon={faFilePdf} size={"lg"} />
            )}
          </Link>
        );
      }
      acts.push(
        <Link className="m-l-sm" to={`#`}>
          {mini ? (
            "Auditoria"
          ) : (
            <FontAwesomeIcon className="m-l-sm" icon={faBook} size={"lg"} />
          )}
        </Link>
      );
    }
    return acts;
  };

  handleNavigator = url => this.props.history.push(url);

  renderMoreProducts = (
    item,
    popoverOpen,
    togglePopOver,
    products,
    loadPopOver
  ) => (
    <span>
      <Button
        className="pull-rigth button-next"
        style={{
          padding: "0 5px",
          minHeight: "30px",
          minWidth: "30px",
          marginLeft: "10px"
        }}
        disabled={loadPopOver}
        size="small"
        id={`p${item.Meta}`}
        onClick={() => togglePopOver(item)}
      >
        {" "}
        {!loadPopOver ? (
          `+ ${item.CantProductos - 1}`
        ) : (
          <LoadCircular size={15} />
        )}{" "}
      </Button>
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target={`p${item.Meta}`}
        toggle={() => togglePopOver(item)}
      >
        <PopoverHeader>Medicamentos relacionados</PopoverHeader>
        <PopoverBody>
          {products.map((p, i) => (
            <span key={i}>
              <FontAwesomeIcon icon={faPrescriptionBottle} /> {p.Compania} -{" "}
              {p.Laboratorio} {p.NombreComercial}
              <br />
            </span>
          ))}
        </PopoverBody>
      </Popover>
    </span>
  );

  renderMetrics = (item, popoverMetricOpen, toggleMetricPopOver) => (
    <span>
      <Link
        to={"#"}
        className="pull-rigth"
        style={{ padding: "0 5px", marginLeft: "10px" }}
        id={`pm${item.Meta}`}
        onClick={() => toggleMetricPopOver()}
      >
        <FontAwesomeIcon
          className="m-l-sm"
          color="#fff"
          title="Métricas fuera de tiempo"
          icon={faClock}
          size={"lg"}
        />{" "}
      </Link>
      <Popover
        placement="bottom"
        isOpen={popoverMetricOpen}
        target={`pm${item.Meta}`}
        toggle={() => toggleMetricPopOver(item)}
      >
        <PopoverHeader>Métricas fuera de tiempo</PopoverHeader>
        <PopoverBody>
          {item.MetricsAlerts.map((m, i) => (
            <span key={i}>
              <h6>{m.Nombre}:</h6>
              <p>{m.Descripcion}</p>
              <hr />
            </span>
          ))}
        </PopoverBody>
      </Popover>
    </span>
  );

  renderItem = (
    Item,
    classProp,
    handleAction,
    load,
    search,
    popoverOpen,
    togglePopOver,
    products,
    loadPopOver,
    delItem
  ) => (
    <tr className={`list-item ${classProp}`}>
      <td>
        {Item.SincAlertaEstado && (
          <FontAwesomeIcon
            size="lg"
            icon={faExclamationTriangle}
            color={Item.SincAlertaEstado === "High" ? "red" : "orange"}
            className="pull-left"
          />
        )}
      </td>
      <td>
        <Link to={`/reportableEvent/${Item.Meta}/view/details`}>
          {Item.NumeroReferencia}
        </Link>
      </td>
      {search && <td>{Item.Pais}</td>}
      {search && <td>{Item.Compania}</td>}
      {search && <td>{Item.Laboratorio}</td>}
      {search && (
        <td>
          {Item.NombreComercial}
          {Item.CantProductos > 1 &&
            this.renderMoreProducts(
              Item,
              popoverOpen,
              togglePopOver,
              products,
              loadPopOver
            )}
        </td>
      )}
      <td>{Item.Estado}</td>
      {!search && <td>{Item.TipoRAM != null ? Item.TipoRAM : "---"}</td>}
      <td>{LabelDate(Item.FechaSolicitudDesc)}</td>
      <td className="td-list-actions">
        <ListActions
          actions={this.actions(Item, handleAction, load, search, false)}
        />
      </td>
      <td>{Item.IsHistoric ? "Si" : "No"}</td>
    </tr>
  );

  toggleDropdownOpen = () =>
    this.setState({ dropdownOpen: !this.state.dropdownOpen });

  handleCalcMiniCategory = Item => {
    if (Item.MetricsAlerts.length > 0) {
      return "danger-element all-danger";
    }
    if (Item.Estado !== "Pendiente") {
      switch (Item.SincAlertaEstado) {
        case "High":
          return "danger-element";
        case "Medium":
          return "warning-element";
        default:
          return "success-element";
      }
    } else {
      return "info-element";
    }
  };

  handleMetricsTitle = Item => {
    let ret = "";
    Item.MetricsAlerts.forEach(x => {
      ret += x + ", ";
    });
    return ret;
  };

  handleShowHours = date => date;

  delItem = (meta, delItem) => {
    if (meta === delItem) {
      return classes.bounceOut;
    }
    return "";
  };

  renderItemMini = (
    Item,
    handleAction,
    load,
    search,
    tooltipOpen,
    toggle,
    navbar,
    popoverOpen,
    togglePopOver,
    products,
    loadPopOver,
    popoverMetricOpen,
    toggleMetricPopOver,
    delItem
  ) => {
    let acciones = [];
    if (!navbar) acciones = this.actions(Item, handleAction, load, search);
    return (
      <li
        className={`${this.handleCalcMiniCategory(Item)} ${this.delItem(
          Item.Meta,
          delItem
        )} list-item p-b-md`}
      >
        <strong>
          <Link
            to={
              Item.Estado === "Pendiente"
                ? `/reportableEvent/${Item.Meta}/edit`
                : `/reportableEvent/${Item.Meta}/view/details`
            }
          >
            {Item.NumeroReferencia}{" "}
          </Link>{" "}
          {Item.MetricsAlerts &&
            Item.MetricsAlerts.length > 0 &&
            this.renderMetrics(Item, popoverMetricOpen, toggleMetricPopOver)}
        </strong>

        <div className="pull-right text-muted small">
          {!search && Item.TieneRAM && (
            <FontAwesomeIcon
              title={"Reaccion adversa clasificada"}
              icon={faExclamationTriangle}
              size={"lg"}
            />
          )}{" "}
        </div>
        <div className="agile-detail">
          {search && (
            <div className="">
              <div className="text-muted small block">
                <FontAwesomeIcon icon={faGlobeAmericas} className="m-r-sm" />
                {`${Item.Pais} - ${Item.Compania}`}
              </div>
              <div className="text-muted small block">
                <FontAwesomeIcon
                  icon={faPrescriptionBottle}
                  className="m-r-sm"
                />
                {`${Item.Laboratorio} - ${Item.NombreComercial}`}
                {Item.CantProductos > 1 &&
                  this.renderMoreProducts(
                    Item,
                    popoverOpen,
                    togglePopOver,
                    products,
                    loadPopOver
                  )}
              </div>
            </div>
          )}
        </div>

        <div className="agile-detail">
          <div className="float-right">
            {acciones.map((a, i) => (
              <span key={i}>{a}</span>
            ))}
          </div>
          <span className="text-muted small">
            {Item.Estado}
            <FontAwesomeIcon
              id={`upt${Item.Meta}`}
              icon={faCalendarAlt}
              className="m-l-md m-r-sm"
            />
            {LabelDate(Item.FechaSolicitud)}hs
            <Tooltip
              placement="right"
              isOpen={tooltipOpen}
              target={`upt${Item.Meta}`}
              toggle={toggle}
            >
              Fecha de última actualización
            </Tooltip>{" "}
          </span>
        </div>
        <div className="agile-detail">
          {Item.NumeroReferenciaHome && (
            <small>
              <Link to={"#"}>{Item.NumeroReferenciaHome}</Link>
            </small>
          )}
        </div>

      </li>
    );
  };

  render() {
    const {
      Item,
      classProp,
      handleAction,
      load,
      search,
      mini,
      navbar,
      delItem
    } = this.props;
    const {
      tooltipOpen,
      popoverOpen,
      products,
      loadPopOver,
      popoverMetricOpen
    } = this.state;
    return mini != null && mini
      ? this.renderItemMini(
          Item,
          handleAction,
          load,
          search,
          tooltipOpen,
          this.toggleToolTip,
          navbar,
          popoverOpen,
          this.togglePopOver,
          products,
          loadPopOver,
          popoverMetricOpen,
          this.toggleMetricPopOver,
          delItem
        )
      : this.renderItem(
          Item,
          classProp,
          handleAction,
          load,
          search,
          popoverOpen,
          this.togglePopOver,
          products,
          loadPopOver,
          delItem
        );
  }
}

ListItemWF.propTypes = {
  Item: PropTypes.object.isRequired
};

export default ListItemWF;

import React from 'react';
import { getFileIcon } from '../../constants/fileAccept';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './style.css';
import api from '../../api';
import { faCloudDownloadAlt, faPen } from '@fortawesome/free-solid-svg-icons';

const FileView = ({url, fileName, type , handleEdit }) => {
    return (
        <div className="upload-load view w100">
            <div className="file-accepted-load">
                <FontAwesomeIcon size={'3x'} icon={getFileIcon(type)} />
            </div>
            <div className="file-name-load">
                <span>{fileName}</span>
            </div>
            <div className="file-action">
                <Link className="action-download" to={'#'} onClick={()=> api.download(url,fileName)} ><FontAwesomeIcon size="lg" icon={faCloudDownloadAlt} /></Link>
                {handleEdit && <Link className="action-edit" to={'#'} onClick={()=> handleEdit()} ><FontAwesomeIcon size="lg" className="m-l" icon={faPen} /></Link>}
            </div>
        </div>
    );
};

export default FileView;

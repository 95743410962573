import React, {useState} from 'react';
import AppBox from '../AppComponents/AppBox';
import Table from '../_ARK/table';

const ParametersComponent = ({name, description, items,handleAction, load, handleInputChange,handleCancelEdit}) => {
    const [forEdit, setForEdit] = useState({Id:-1});
    const [view, setView] = useState("list");
    const _handleAction = (name,action,item)=> {
        switch (action) {
            case "forEdit":{
                setForEdit(item);
                setView(action);
                break;
            }
            case "cancel":{
                setForEdit({Id:-1});
                setView(action);
                handleCancelEdit();
                let id = items.findIndex(x => x.Id === null);
                if(id===0){
                   items.shift();
                }
                break;
            }
            case "add":{
                let id = items.findIndex(x => x.Id === null);
                if(id === -1){
                    let keys = Object.keys(items[0]).filter(x=> x !=="Id" && x !=="Eliminado" && x !== "ImportCodigo" );
                    for(let i = 0 ; i< keys.length;i++){
                      item = {...item,[keys[i]]:''};
                  }
                  
                  items.unshift(item);
                  setForEdit(item);
                  setView("edit");
                 
                }
                break;
            }
            default:{
                setForEdit(item);
                setView(action);
                handleAction(name,action,item,setView,setForEdit);
                break;
            }
        }
    }
    const getHedearByName = (name) => {
        let header=[];
        switch (name) {
            case "MedicamentoFrecuencia":{
                // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Unidad",header:"Frecuencia",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "Parentesco":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Nombre",header:"Parentesco",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "Grupo":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Title",header:"Grupo",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "SubGrupo":{
                //  header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Nombre",header:"Sub Grupo",order:2});
                header.push({name:"GrupoId",header:"Grupo",order:3});
                header.push({name:"Eliminado",header:"Activo",order:4});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "AccionMedicamento":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Nombre",header:"Acción",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "MedicamentoUnidad":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Unidad",header:"Frecuencia",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "MedicamentoUnidadUso":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Unidad",header:"Frecuencia",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "AlturaUnidad":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"NombreCompleto",header:"Descripción",order:2});
                header.push({name:"Abreviacion",header:"Abreviación",order:3});
                header.push({name:"Eliminado",header:"Activo",order:4});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "PesoUnidad":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"NombreCompleto",header:"Descripción",order:2});
                header.push({name:"Abreviacion",header:"Abreviación",order:3});
                header.push({name:"Eliminado",header:"Activo",order:4});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "ReporteOriginador_Origen":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Nombre",header:"Origen",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "ReporteOriginador_Receptor":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Nombre",header:"Receptor",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "ReporteOriginador_Tipo":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Nombre",header:"Tipo",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "NotasArchivosTipo":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Nombre",header:"Tipo",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "ViaDeComunicacion":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Nombre",header:"Via",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "Via":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Descripcion",header:"Via",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "RAMTipos":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Type",header:"Tipo",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                 return header;
            }
            case "RAMCategorias":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Type",header:"Tipo",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "MedicamentosAnteriores_Tipo":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Nombre",header:"Tipo",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "Paises":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Codigo",header:"Código",order:2});
                header.push({name:"Nombre",header:"Nombre",order:3});
                header.push({name:"TimeZone",header:"Zona horaria",order:4});
                header.push({name:"Eliminado",header:"Activo",order:5});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "PaisOrigen":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Codigo",header:"Código",order:2});
                header.push({name:"Nombre",header:"Nombre",order:3});
                header.push({name:"Eliminado",header:"Activo",order:4});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
            case "Especialidad":{
                 // header.push({name:"Id",header:"Id",order:1});
                header.push({name:"Description",header:"Especialidad",order:2});
                header.push({name:"Eliminado",header:"Activo",order:3});
                header.push({name:"_handleAction",header:"",order:9});
                return header;
            }
                
            default:
                return header;
        }
    }


    
    return (
        <AppBox 
            hide={true}
            handlePlus={()=>_handleAction(name,"add",{Id:null,Eliminado:false})}
            className='wf-list scroll-app-box'
            title={description} >
                <Table name={name} 
                    handleInputChange={handleInputChange} 
                    headerClass='' 
                    thClass='p-l-md' 
                    load={load} 
                    action={view} 
                    forEdit={forEdit} 
                    handleAction={_handleAction} 
                    headers={getHedearByName(name)} 
                    items={items} 
                    messageEmpty="No contiene elementos"/>
        </AppBox>
    );
};

export default ParametersComponent;
import React from 'react';

const ListItemLabAssign = ({Item, classProp,handleAction}) => {
    return (
        <tr className={`list-item ${classProp}`}>
            <td>{Item.Nombre}</td>
            <td><input type='checkbox' name={Item.Id} onChange={handleAction} checked={Item.Checked}/></td>
        </tr>
    );
};

export default ListItemLabAssign;
import React, { Component } from "react";
import PropTypes from "prop-types";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { Prompt } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import { FieldText, FieldSelect, FieldSwitch } from "../../../Fields";
import { getERPaciente } from "../../../../redux/selectors/ER";
import { getCodigueraContext } from "../../../../redux/selectors/Context";
import { getCodiguera } from "../../../../codigueras";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMale,
  faFemale,
  faHeart,
  faArrowRight,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { getMedicalCountryByMeta } from "../../../../redux/actions/list";
import { getCodiguerasContext } from "../../../../redux/actions/context";
import "./../style.css";
import { CardContent } from "@material-ui/core";
const EdadUnidad = [
  { Id: "Días", Nombre: "Días" },
  { Id: "Semanas", Nombre: "Semanas" },
  { Id: "Meses", Nombre: "Meses" },
  { Id: "Años", Nombre: "Años" }
];
const generes = [
  {
    Id: "M",
    Text: "Masculino",
    Nombre: <FontAwesomeIcon icon={faMale} />,
    className: "with-font"
  },
  {
    Id: "F",
    Text: "Femenino",
    Nombre: <FontAwesomeIcon icon={faFemale} />,
    className: "with-font"
  }
];

class PacienteData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      famale: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.codiguera) this.props.getCodiguerasContext();
  }

  handleChangeGenere = e => {
    this.props.change("Genere", e.target.value);
    if (e.target.value !== "F") {
      this.setState({ famale: false, pregnancy: false });
      this.props.change("Gestation", null);
      this.props.change("Pregnancy", false);
      this.props.change("Lactation", false);
    }
  };

  handleChangeAllowContact = e =>
    this.setState({ allowContact: e.target.checked });

  handleChangePregnancy = e => this.setState({ pregnancy: e.target.checked });

  render() {
    const {
      title,
      handleSubmit,
      codiguera,
      loadNext,
      ContactAllow,
      pregnancy,
      PCountry,
      genere
    } = this.props;
    return (
      <div className="m-t-n">
        <CardHeader className="p-l-n p-t-n" title={title} />
        <CardContent>
          <Row>
            <Row>
              <Col xs={4} md={3} lg={1}>
                <FieldText
                  name="Initials"
                  label="Alias"
                  maxLength={3}
                  isRequired={true}
                />
              </Col>
              <Col xs={4} md={2} lg={1}>
                <FieldText name="Age" label="Edad" type="number" min={0} />
              </Col>
              <Col xs={4} md={3} lg={2}>
                <FieldSelect
                  name="Escala"
                  label="Unidad Edad"
                  items={EdadUnidad}
                  valueField="Id"
                  textField="Nombre"
                />
              </Col>
              <Col xs={4} md={2} lg={2}>
                <FieldText
                  name="Height"
                  label="Altura mts."
                  type="number"
                  step=".01"
                  min={0}
                />
              </Col>
              <Col xs={4} md={2} lg={2}>
                <FieldText
                  name="Weight"
                  label="Peso Kg."
                  type="number"
                  step=".01"
                  min={0}
                />
              </Col>
              <Col xs={4} md={4} lg={3}>
                <FieldSelect
                  name="Genere"
                  label="Genero"
                  items={generes}
                  valueField="Id"
                  textField="Text"
                  onChange={this.handleChangeGenere}
                />
              </Col>
              {genere === "F" && (
                <Col xs={6} md={3} lg={1}>
                  <FieldSwitch
                    onChange={this.handleChangePregnancy}
                    name="Pregnancy"
                    label="Embarazo"
                    className="heart"
                    icons={{
                      checked: (
                        <FontAwesomeIcon
                          icon={faHeart}
                          color="#fff"
                          size={"lg"}
                        />
                      ),
                      unchecked: null
                    }}
                  />
                </Col>
              )}
              {genere === "F" && pregnancy && (
                <Col xs={6} md={4} lg={2}>
                  <FieldText
                    name="Gestation"
                    label="Semanas de gestación"
                    type="number"
                    max={40}
                    min={1}
                  />
                </Col>
              )}
              {genere === "F" && (
                <Col xs={6} md={4} lg={1}>
                  <FieldSwitch
                    name="Lactation"
                    label="Lactancia"
                    className="heart"
                    icons={{
                      checked: (
                        <FontAwesomeIcon
                          icon={faHeart}
                          color="#fff"
                          size={"lg"}
                        />
                      ),
                      unchecked: null
                    }}
                  />
                </Col>
              )}
              <Col xs={12} md={6} lg={2}>
                <FieldSelect
                  name="Country"
                  label="País"
                  disabled={PCountry}
                  onChange={this.handelChangePais}
                  items={codiguera && getCodiguera("Paises", codiguera)}
                  valueField="Codigo"
                  textField="Nombre"
                />
              </Col>
              <Col xs={12} md={6} lg={4}>
                <FieldText name="State" label="Departamento o provincia" />
              </Col>
              <Col xs={12} md={6} lg={2}>
                <FieldSelect
                  name="Residence"
                  label="Residencia"
                  items={codiguera && getCodiguera("Residencias", codiguera)}
                  valueField="Id"
                  textField="Nombre"
                />
              </Col>
              <Col xs={12} md={6} lg={4}>
                <FieldText name="InstMedica" label="Institución médica" />
              </Col>
              <Col xs={12}>
                <FieldText
                  name="CondicionesMedicas"
                  type="textarea"
                  label="Condiciones médicas (Por ejemplo: diabetes, presión arterial alta, cáncer, enfermedades cardíacas, etc.)"
                />
              </Col>
              <Col xs={12}>
                <FieldText
                  name="Alergias"
                  type="textarea"
                  label="Alergias (Por ejemplo: alergia a medicamentos, alimentos, polen, otros.)"
                />
              </Col>
              <Col xs={12} md={3} lg={2}>
                <FieldSwitch
                  onChange={this.handleChangeAllowContact}
                  name="ContactAllow"
                  label="Quiere ser contactado"
                  icons={null}
                />
              </Col>
              <Col xs={12} md={6} lg={3} hidden={!ContactAllow}>
                <FieldSelect
                  name="IdParentesco"
                  typeValue="number"
                  label="¿Qué relación tiene con el afectado?"
                  items={codiguera && getCodiguera("Parentesco", codiguera)}
                  valueField="Id"
                  textField="Nombre"
                />
              </Col>
              <Col xs={12} md={4} lg={3} hidden={!ContactAllow}>
                <FieldText name="Phone" label="Teléfono" />
              </Col>
              <Col xs={12} md={4} lg={4} hidden={!ContactAllow}>
                <FieldText name="Email" label="Correo" />
              </Col>
            </Row>
          </Row>
        </CardContent>
        <CardActions className="card-actions p-t-lg">
          <div>
            <Button
              type="button"
              disabled={loadNext}
              className="card-actions-button button-next"
              variant="contained"
              onClick={handleSubmit}
            >
              <FontAwesomeIcon
                size="lg"
                icon={loadNext ? faSpinner : faArrowRight}
                pulse={loadNext}
              />
            </Button>
          </div>
        </CardActions>
        <Prompt
          when={!this.props.pristine}
          message={"¡Se perderán los datos sí continúa!"}
        />
      </div>
    );
  }
}

PacienteData.propTypes = {
  title: PropTypes.string.isRequired
};

const validate = values => {
  const error = {};
  if (!values.Initials) error.Initials = "Es requerido";

  values.AlturaUnidad =
  values.AlturaUnidad === "none" ? 1 : values.AlturaUnidad;
  values.PesoUnidad = values.PesoUnidad === "none" ? 1 : values.PesoUnidad;
  values.Escala = values.Escala === "none" ? null : values.Escala;
  values.Residence = values.Residence === "none" ? null : values.Residence;

  return error;
};

const mapDispatchToProps = {
  getMedicalCountryByMeta,
  getCodiguerasContext
};

const mapStateToProps = state => ({
  initialValues: getERPaciente(state),
  codiguera: getCodigueraContext(state),
  ContactAllow: formValueSelector("EReportEditStep0")(state, "ContactAllow"),
  pregnancy: formValueSelector("EReportEditStep0")(state, "Pregnancy"),
  PCountry: formValueSelector("EReportEditStep0")(state, "Country"),
  genere: formValueSelector("EReportEditStep0")(state, "Genere")
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "EReportEditStep0", validate })(PacienteData));

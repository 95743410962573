import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-flexbox-grid";
import { connect } from "react-redux";
import ChartPie from "./ChartPie";
import ChartColumn from "./ChartColumn";
import { CircularProgress } from "@material-ui/core";
import {
  getIndicatorAges,
  getIndicatorGroups,
  getIndicatorStatus,
  getIndicatorValues,
  getIndicatorsCountries,
  getIndicatorsLabs
} from "../../redux/selectors/Indicators";
import {
  getIndicatorAges as getByAges,
  getIndicatorGroups as getByGroups,
  getIndicatorStatus as getByStatus,
  getIndicatorValues as getByValues,
  getIndicatorCountries as getCountries,
  getIndicatorLabs as getLabs
} from "../../redux/actions/indicators";
import MetricBox from "./MetricBox";
import AppBox from "../AppComponents/AppBox";
import FiltersIndicators from "./filtersIndicators";

class IndicatorsComponent extends Component {
  state = {
    paisId: null
  };
  async componentDidMount() {
    this.props.getCountries();
    this.props.getByValues();
    this.props.getByAges();
    this.props.getByGroups();
    this.props.getByStatus();
  }

  calcPorcentage = (ant, act) => {
    let calc = act > 0 ? act * 100 : act;
    calc = ant > 0 && act > 0 ? calc / ant : calc;
    return ant !== 0 ? calc.toFixed(0) - 100 : calc.toFixed(0);
  };

  onChangePais = event => {
    const paisId = !isNaN(event.target.value)
      ? Number(event.target.value)
      : null;
    this.setState({ paisId });
    this.props.getLabs(paisId);
    this.props.getByValues(paisId, null);
    this.props.getByAges(paisId, null);
    this.props.getByGroups(paisId, null);
    this.props.getByStatus(paisId, null);
  };

  onChangeLaboratorio = event => {
    const labId = !isNaN(event.target.value)
      ? Number(event.target.value)
      : null;
    const { paisId } = this.state;
    this.props.getByValues(paisId, labId);
    this.props.getByAges(paisId, labId);
    this.props.getByGroups(paisId, labId);
    this.props.getByStatus(paisId, labId);
  };

  render() {
    const colors = [
      "#3366cc",
      "#66aa00",
      "#dc3912",
      "#ff9900",
      "#7cb5ec",
      "#434348",
      "#90ed7d",
      "#f7a35c",
      "#8085e9",
      "#f15c80",
      "#e4d354",
      "#2b908f",
      "#f45b5b",
      "#91e8e1",
      "#78c47d",
      "#990099",
      "#2f7ed8",
      "#0d233a",
      "#8bbc21",
      "#910000",
      "#1aadce",
      "#492970",
      "#f28f43",
      "#77a1e5",
      "#c42525",
      "#a6c96a",
      "#4572A7",
      "#AA4643",
      "#89A54E",
      "#80699B",
      "#3D96AE",
      "#DB843D",
      "#92A8CD",
      "#A47D7C",
      "#B5CA92"
    ];
    const {
      byStatus,
      byGroups,
      byAges,
      values,
      countriesFilters,
      labsFilters
    } = this.props;
    return (
      <div>
        <FiltersIndicators
          paises={countriesFilters}
          laboratorios={labsFilters}
          onChangePais={this.onChangePais}
          onChangeLaboratorio={this.onChangeLaboratorio}
        />
        <Row>
          <Col xs={6} sm={3}>
            <MetricBox
              value={values && values.UltimoMes}
              title="Mes anterior"
              smallText="Reportes"
              loading={!values}
            />
          </Col>
          <Col xs={6} sm={3}>
            <MetricBox
              value={values && values.EsteMes}
              porcentage={this.calcPorcentage(
                values ? values.UltimoMes : 0,
                values ? values.EsteMes : 0
              )}
              title="Mes actual"
              smallText="Reportes"
              loading={!values}
            />
          </Col>
          <Col xs={6} sm={3}>
            <MetricBox
              value={values && values.UltimaSemana}
              title="Semana anterior"
              smallText="Reportes"
              loading={!values}
            />
          </Col>
          <Col xs={6} sm={3}>
            <MetricBox
              value={values && values.EstaSemana}
              porcentage={this.calcPorcentage(
                values ? values.UltimaSemana : 0,
                values ? values.EstaSemana : 0
              )}
              title="Semana actual"
              smallText="Reportes"
              loading={!values}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={6} className={`${!byStatus && "text-center p-lg"}`}>
            <AppBox
              title="Por estado"
              content={
                byStatus ? (
                  <ChartPie
                    name="Estados"
                    data={byStatus.map((r, i) => ({
                      name: r.Name,
                      metadata: r.MetaData,
                      color: r.ID === 11 ? "#aaa" : colors[i],
                      y: Number(r.Total)
                    }))}
                  />
                ) : (
                  <CircularProgress size={100} />
                )
              }
            />
          </Col>
          <Col lg={4} md={6} className={`${!byAges && "text-center p-lg"}`}>
            <AppBox
              title="Por edad"
              content={
                byAges ? (
                  <ChartColumn
                    hideLines={true}
                    type="column"
                    name="Total"
                    data={byAges.map((r, i) => ({
                      name: r.Name,
                      color: colors[i],
                      y: Number(r.Total)
                    }))}
                    showInLegend={false}
                    yTitle={null}
                    xTitle={null}
                  />
                ) : (
                  <CircularProgress size={100} />
                )
              }
            />
          </Col>
          <Col lg={5} md={12} className={`${!byGroups && "text-center p-lg"}`}>
            <AppBox
              title="Por tipo de reporte"
              content={
                byGroups ? (
                  <ChartColumn
                    hideLines={true}
                    type="column"
                    name="Total"
                    data={byGroups.map((r, i) => ({
                      name: r.SubGrupo,
                      color: colors[i],
                      y: Number(r.Total)
                    }))}
                    showInLegend={false}
                    yTitle={null}
                    xTitle={null}
                  />
                ) : (
                  <CircularProgress size={100} />
                )
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}

IndicatorsComponent.propTypes = {
  colors: PropTypes.array,
  byStatus: PropTypes.array,
  byGroups: PropTypes.array
};

const mapStateToProps = state => ({
  values: getIndicatorValues(state),
  byStatus: getIndicatorStatus(state),
  byGroups: getIndicatorGroups(state),
  byAges: getIndicatorAges(state),
  countriesFilters: getIndicatorsCountries(state),
  labsFilters: getIndicatorsLabs(state)
});

const mapDispatchToProps = {
  getByValues,
  getByAges,
  getByGroups,
  getByStatus,
  getCountries,
  getLabs
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndicatorsComponent);

import React from 'react';
import ListComponent from '..';
import ListItemUser from '../Item/ListItemUser';

const ListUser = ({list, handleAction}) => {
    return (
        <ListComponent mini={false} handleAction={handleAction} empty={'usuarios'} titles={['Nombre','Correo eléctronico','País de origen','Rol','Habilitado','']} list={list} ListComponent={ListItemUser}  />
    );
};

export default ListUser;
import React from 'react';
import { enterPressed } from '../../helpers/utilities';
import './LoginForm.css';
import { Input, FormGroup,FormFeedback, Button } from 'reactstrap';

const _renderInputPass = (name,value,placeholder, valid, handleChangeInput,msg,_keyPress) =>(
    <FormGroup  className="inputWithIcon m-t-md-m">
        {valid && 
            <div className="m-b-lg">
                <Input name={name} value={value} placeholder={placeholder} autoComplete="current-password" className="m-t-n " type="password" onKeyPress={_keyPress} onChange={e=> handleChangeInput(e)}/>
            </div>
        }
        {!valid &&   
            <div className="m-b-lg">
                <Input name={name} value={value}  invalid placeholder={placeholder} autoComplete="current-password" className="m-t-n" onKeyPress={_keyPress} type="password" onChange={e=> handleChangeInput(e)}/>
                <FormFeedback tooltip className="t-inherit">{msg}</FormFeedback>
            </div>
        }
    </FormGroup>
)
const ChangePasswordForm = ({vieja,nueva,nuevaRep,handleChangePass,handleChangeInput,validAntPass,validPass,validPassRep,msgValidOldPassword, msgValidNewPassword, msgValidNewPasswordRep}) => {
    const _keyPress = (e) => {
        if(enterPressed(e)){
            handleChangePass();
        }
    }   
    return (
        <div>
            <div className="login-form">
                <img className="img-cim" alt="logo CIM" src={require("../AppComponents/CIM_cim.png")}/>
                <img className="img-label" alt="logo CIM" src={require("../AppComponents/CIM_label.png")}/>
                <div className={'login-form-group m-t-md'}>
                    <div className="flip-card-inner ">
                        <form autoComplete="off" >
                            <h3 className="login-title">Cambiar contraseña</h3>
                            {
                                _renderInputPass("vieja",vieja,"Contraseña anterior",validAntPass,handleChangeInput,msgValidOldPassword,_keyPress)
                            }
                            {
                                _renderInputPass("nueva",nueva,"Contraseña nueva", validPass,handleChangeInput,msgValidNewPassword,_keyPress)
                            }
                            {
                                _renderInputPass("nuevaRep",nuevaRep,"Repetir contraseña nueva",validPassRep,handleChangeInput,msgValidNewPasswordRep,_keyPress)
                            }
                           
                            <div className='login-button m-t-lg'>
                                <Button outline  className="login-btn" onClick={handleChangePass} >Cambiar</Button>
                            </div>
                        </form>
                       
                    </div>
                </div>
                
            </div>
        </div> 
    );
}
export default ChangePasswordForm;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faCheckCircle, faStopCircle, faMinus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
export const renderStatusMedDRAImport = (status) =>{
    if(status==='E'){
        return <FontAwesomeIcon title={'Error'} icon={faStopCircle} className="text-danger"/>
    }else if (status==='D'){
        return <FontAwesomeIcon title={'Ejecutando'} icon={faSpinner} pulse={true}  className="text-primary"/>
    }
    else if (status==='C'){
        return <FontAwesomeIcon title={'Cancelado'} icon={faTimesCircle} className="text-muted"/>
    }
    else if (status==='S'){
        return <FontAwesomeIcon title={'OK'} icon={faCheckCircle} className="text-success"/>
    }
    else if (status==='P'){
        return <FontAwesomeIcon title={'Pendiente'} icon={faMinus} className="text-muted"/>
    }
}
import React from 'react';
import { Route, Redirect}  from 'react-router-dom';

const redirect = (props,auth) => {
    if(auth.isAuthenticated){
      return <Redirect to={{pathname:  auth.UrlPath, state: { from: props.location }}}/>
    }else{
      return <Redirect to={{pathname: "/", state: { from: props.location }}}/>
    }
}

const isAllow= (auth,url)  =>{
    let ok = auth.URLs.filter(x=>x.URL.split('/')[1] === url.split('/')[1]).length>0;
    return (auth.isAuthenticated  && ok);
}

const PrivateRoute = ({ component: Component, ...rest})=>{
    const {path,auth,hidden, newFilter} = rest;
    if(!hidden){
      return <Route
          {...rest}
          render={p => isAllow(auth,path)?
          <Component {...p} {...rest} meta={rest.meta && p.match.params.meta}
            type={rest.type && p.match.params.type}
            step={rest.step && p.match.params.step}
            userId={auth.id}
          />
          : redirect(p,auth)}/>
    }else{
      return <Route {...rest} render={p => redirect(p,auth)}/>
    }
}

export default PrivateRoute;

import React, { Component } from 'react';
import AppContainer from '../components/AppComponents/AppContainer';
import  setPropsAsInitial  from '../helpers/setPropsAsInitial';
import UserComponent from '../components/UsersComponents/User'
import { withRouter }  from 'react-router-dom';

class MyUserContainer extends Component {
    componentWillMount = () =>{
        this.props.showAppFrame(this.props.location.pathname);
    }
    render() {
        return (
            <AppContainer className='background-f4f4f4 p-b-n' title="Mí usuario">
                <UserComponent view='myUser' userId={this.props.userId}/>
            </AppContainer>
        );
    }
}

export default withRouter(((setPropsAsInitial)(MyUserContainer)));

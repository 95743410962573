import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import {
  getERMeta,
  getERPacienteMeta,
  getEROriginReportMeta,
  getERMedicalSusp,
  getERRAMS,
  getERDocsAndNotes,
  getERWhoReport,
  getEROperatinHistory,
  getERFollowUp,
  getERActions,
  getERImputEvaluation,
  getERContactoMedico,
  setER,
  setERRAMCategoryAll,
  getERMedicamentosAnt,
  setRAMS,
  getERFabricApproved,
  getEventoDeHome
} from "../redux/actions/eventoReportable";
import { getER, getERRAMS as ERRAMS } from "../redux/selectors/ER";
import { getCodigueraContext, getMyUser } from "../redux/selectors/Context";
import { getElementCodiguera } from "./../codigueras";
//components
import AppContainer from "../components/AppComponents/AppContainer";
import EReportData from "../components/EReportComponents/EReportView/EReportData";
import EReporteEdit from "../components/EReportComponents/EReportEdit/EReporteEdit";
import setPropsAsInitial from "../helpers/setPropsAsInitial";
import { getCodiguerasContext, setLoad } from "../redux/actions/context";
import { showError } from '../redux/actions/error';
import { LoadCircular } from "../components/AppComponents/AppUtilities";
import { PAGE_SIZE } from "../redux/constants/get";
import { withRouter } from "react-router-dom";
import { store } from '../redux/store';

class EReportContainer extends Component {
  state = {
    loadState: false
  };

  componentWillMount = () => {
    this.props.setLoad(true);
    this.props.showAppFrame(this.props.location.pathname);
  };

  mountComponent = async (meta) =>{
    const { myUser } = this.props;
    const params = {
      meta: meta
    }
    try{
      setLoad(true);
      await store.dispatch(getERMeta(meta))
      await store.dispatch(getERActions(params));
      await store.dispatch(getERRAMS(params))
      if(!this.props.codiguera){
        await store.dispatch(getCodiguerasContext());
      }
      await store.dispatch(getERPacienteMeta(params));
      await store.dispatch(getERWhoReport(params));
      await store.dispatch(getEROriginReportMeta(params));
      await store.dispatch(getERMedicamentosAnt(params));
      await store.dispatch(getERMedicalSusp(params));
      await store.dispatch(getERImputEvaluation(params));
      await store.dispatch(getERDocsAndNotes(params));
      await store.dispatch(getEventoDeHome(params));
      await store.dispatch(getERFollowUp(params));
      await store.dispatch(getEROperatinHistory(
        {
          obj:{
            meta: meta,
            referencia: null,
            from: null,
            to: null,
            orderBy: "Date DESC",
            pageNumber: 1,
            pageSize: PAGE_SIZE
          }
        }
      ));

      if (myUser.Rol !== "Fabricante") {
        await store.dispatch(getERContactoMedico(params));
      } else {
        await store.dispatch(getERFabricApproved(params));
      }

    }catch(error){
      showError(error);
    }finally{
      setLoad(false);
    }

  }

  componentDidMount = () => {
    const { meta } = this.props;
    this.mountComponent(meta);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.isERCurrent(this.props.meta) && this.props.EReport.Status) {
      this.props.setLoad(false);
    }
  }
  isERCurrent = meta =>
    this.props.EReport && this.props.EReport.MetaData === meta;

  renderBody = er => (
    <Route
      path="/reportableevent/:meta/view"
      children={({ match }) => {
        const EReportControl = match ? EReportData : EReporteEdit;
        return (
          <EReportControl
            changeER={this.loadER}
            step={this.props.step}
            meta={this.props.meta}
            type={this.props.type}
            ER={this.isERCurrent(this.props.meta) ? er : {}}
          />
        );
      }}
    />
  );



  render() {
    const { codiguera } = this.props;
    const { loadState } = this.state;
    return (
      <div>
        {!this.isERCurrent(this.props.meta) ? (
          <AppContainer
            title={
              <span>
                <span className="pull-left m-l-md p-t-sm">
                  Evento Reportable:
                </span>
                <span className="pull-right m-r-md p-t-sm">Estado:</span>
              </span>
            }
          >
            {this.renderBody(null)}
          </AppContainer>
        ) : (
          <AppContainer
            disabled={this.props.EReport && this.props.EReport.Status === 11}
            title={
              <span>
                <span className="pull-left m-l-md m-t-sm">
                  Evento Reportable: {this.props.EReport.NumeroReferencia}
                </span>
                <span className="pull-right m-r-md m-t-sm">
                  Estado:{" "}
                  {!loadState ? (
                    getElementCodiguera(
                      "estado",
                      this.props.EReport.Status,
                      codiguera
                    )
                  ) : (
                    <LoadCircular size={15} className="m-r-lg" />
                  )}
                </span>
              </span>
            }
          >
            {this.renderBody(this.props.EReport)}
          </AppContainer>
        )}
      </div>
    );
  }
}

EReportContainer.propTypes = {
  meta: PropTypes.string.isRequired
};

EReportContainer.defaultProps = {
  EReport: {}
};

const mapDispatchToProps = {
  getCodiguerasContext,
  getERMeta,
  getERPacienteMeta,
  getEROriginReportMeta,
  getERMedicalSusp,
  getERRAMS,
  getERDocsAndNotes,
  getERWhoReport,
  getEROperatinHistory,
  getERFollowUp,
  getERImputEvaluation,
  getERContactoMedico,
  getERMedicamentosAnt,
  setLoad,
  setER,
  setRAMS,
  setERRAMCategoryAll,
  getERActions,
  getERFabricApproved,
  getEventoDeHome
};

const mapStateToProps = state => ({
  EReport: getER(state),
  codiguera: getCodigueraContext(state),
  RAMS: ERRAMS(state),
  myUser: getMyUser(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(setPropsAsInitial(EReportContainer))
);

import React from 'react';
import PropTypes from 'prop-types';
import ListComponent from '..';
import ListItemHisroiPdf from '../Item/ListItemHistoriPdf';

const titles=['Archivo',''];

const ListHistoryPdf = ({list, handleAction, load}) => {
    return (
        <ListComponent list={list} load={load} empty={"Archivos"} titles={titles} ListComponent={ListItemHisroiPdf} handleAction={handleAction}/>
    );
};

ListHistoryPdf.propTypes = {
    list: PropTypes.array,
    handleAction: PropTypes.func,
};

export default ListHistoryPdf;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEROperationHistory } from '../../../../../redux/selectors/ER'
import ListOperationHisotry from '../../../../ListComponents/List/ListOperationHisotry';
import { url_getOperatinHistoryByEvent } from '../../../../../api/urls';
import LogDetailModal from '../../../../Modals/LogDetailModal';
import api from '../../../../../api';
import AppPaginator from '../../../../AppComponents/AppPaginator';
import { getEROperatinHistory } from '../../../../../redux/actions/eventoReportable';
import { showError } from '../../../../../redux/actions/error';
import { PAGE_SIZE } from '../../../../../redux/constants/get';

class OperationHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open:false,
            log:null,
            load:"",
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle = () =>{
        this.setState({open: !this.state.open, log:null,})
    }

    handlePagination = (page) => {
        const sh = {
            meta: this.props.meta,
            referencia:null,
            from: null,
            to: null,
            orderBy:'Date DESC',
            pageNumber:page,
            pageSize: PAGE_SIZE,
        };
        this.props.getEROperatinHistory(sh);
    }

    handleAction = async (action, item) =>{
        switch(action){
            case "view":{
              try {
                this.setState({ load:`view${item.DateUTC}`, log:null });
                const response = await api.post(url_getOperatinHistoryByEvent, item);
                item.log = response.data;
                this.setState({
                  open:true,
                  log: item,
                  load:""
                });
              } catch (e) {
                this.props.showError(e);
              }
              break;

            }
            default: break;
        }
    }

    render(){
        const { log , open, load } = this.state;
        return (
            <div>
                <ListOperationHisotry  list={this.props.EROperHistory && this.props.EROperHistory.Result} handleAction={this.handleAction}  load={load}/>
                <AppPaginator message='registros' result={this.props.EROperHistory && this.props.EROperHistory} handlePagination={this.handlePagination}/>
                {log && <LogDetailModal log={log} open={open} toggle={this.toggle}  title={<span><strong>{log.Description}</strong></span>}/>}
            </div>
        );
    }
};


OperationHistory.propTypes = {
    EROperHistory: PropTypes.array,
};

const mapStateToProps = (state) =>({
    EROperHistory: getEROperationHistory(state),
});

const mapDispatchToProps = {
    getEROperatinHistory,
    showError
}

export default connect(mapStateToProps,mapDispatchToProps)(OperationHistory);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faPlus, faSpinner, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader'
import { Row } from 'react-flexbox-grid';
import ListRAMs from '../../../ListComponents/List/ListRAMs';
import RAMEditModal from '../../../Modals/RAMEditModal';
import { getERRAMSInsert,getERRAMS, getER } from '../../../../redux/selectors/ER';
import { addRAM, updateRAM, setRAM, setRAMS } from '../../../../redux/actions/eventoReportable';
import{ showError } from '../../../../redux/actions/error';
import { url_getRAMNew, url_postRAMUpdate, url_postRAMInsert } from '../../../../api/urls';
import api from '../../../../api';
class AdverseReactions extends Component {

    state = {
        modal: false,
        editRAM: false,
        loadEdit:false,
    }

    toggle = () => {
        this.setState({
          modal: !this.state.modal
        });
    }

    componentDidMount = () =>{
        window.scrollTo(0, 0);
    }

    AddRAM = async () =>{
      try{
        if(!this.state.RAMNew){
          this.setState({ loadNewRAM: true });
          const response = await api.get(url_getRAMNew);
            this.setState({
              RAMNew: response.data,
              RAM: response.data,
              modal: true,
              editRAM: false,
              loadNewRAM:false,
            });
        }else{
          this.setState({
            RAM: this.state.RAMNew,
            modal: true,
            editRAM: false
          });
        }
      }catch(error){
        this.props.showError(error);
        this.setState({ loadNewRAM: false });
      }
    }

    EditRAM = (idRam) => {
        let RAM = this.props.RAMS.filter(r=>r.Id === idRam)[0] || null;
        if(RAM !== null)
            RAM.MedraLLT = RAM.MedraLLT != null ? [RAM.MedraLLT] : null;
        this.setState({
            RAM,
            modal: true,
            editRAM: true
        });
    }

    handleEditRAM = async (ram) => {
      try{
        this.setState({ loadEdit: true });
        const response = await api.post(url_postRAMUpdate, {ram});
        this.props.updateRAM(response.data);

        this.setState({
                RAM: response.data ,
                modal: false,
                loadEdit:false,
            });
      }catch(error){
        this.setState({ loadEdit: false });
        this.props.showError(error);
      }
    }

    handleAddRAM = async (ram) => {
        ram.IdER = this.props.ER.Id;
        try {
            const response = await api.post(url_postRAMInsert, {ram});
            this.props.setRAM(response.data);
            this.setState({
                RAM: response.data,
                modal: false
            });
          this.props.addRAM(response.data)
        } catch (e) {
          this.props.showError(e);
        }
    }

    handleCancelEdit = (ram) =>{
        this.props.updateRAM(ram);
        this.setState({
            RAM: undefined ,
            modal: false,
            loadEdit:false,
        });
    }
    render() {
        const { title, handleSubmit, handleBack, loadNext, RAMS  } = this.props;
        const { modal, editRAM, RAM, loadNewRAM, loadEdit } = this.state;
        return (
            <div>
                <CardHeader className="p-l-n" title={title}/>
                <Row>
                    <div className="text-right m-b-sm">
                        <Button type="button" onClick={() => this.AddRAM()} className="card-actions-button button-next" variant="contained" ><FontAwesomeIcon icon={loadNewRAM ? faSpinner : faPlus} pulse={loadNewRAM} className="m-r-md"/>Agregar evento</Button>
                        <FontAwesomeIcon color="#dc3545" size={"lg"} icon={faExclamationCircle} />
                    </div>
                    {
                      RAMS &&  <ListRAMs forEdit={true} list={RAMS} handleAction={this.EditRAM} View={'edit'}  />
                    }
                </Row>
                {modal && <RAMEditModal toggle={this.toggle} edit={editRAM} open={modal} initialValues={RAM} handleCancelEdit={this.handleCancelEdit} handleAddRAM={this.handleAddRAM} handleEditRAM={this.handleEditRAM} loadEdit={loadEdit}/>}
                <CardActions className="card-actions p-t-lg">
                    <div>
                        <Button type="button" disabled={loadNext} className="card-actions-button button-back" variant="contained" onClick={handleBack} ><FontAwesomeIcon size="lg" icon={faArrowLeft} /></Button>
                        <Button type="button" disabled={loadNext} className="card-actions-button button-next" variant="contained" onClick={() => handleSubmit(this.props.RAMSInsert)} ><FontAwesomeIcon size="lg" icon={loadNext ? faSpinner : faArrowRight} pulse={loadNext} /></Button>
                    </div>
                </CardActions>
            </div>
        );
    }
}

AdverseReactions.propTypes = {
    title : PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
    RAMS: PropTypes.array,
};

const mapDispatchToProps = {
    addRAM,
    setRAM,
    updateRAM,
    showError,
    setRAMS
};

const mapStateToProps = (state) =>({
    ER: getER(state),
    RAMS: getERRAMS(state),
    RAMSInsert: getERRAMSInsert(state),
    RAM:{},
});

export default connect(mapStateToProps, mapDispatchToProps)(AdverseReactions);

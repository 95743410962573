import React, { Component } from 'react';
import AppContainer from '../components/AppComponents/AppContainer';
import  setPropsAsInitial  from '../helpers/setPropsAsInitial';
import UserSerch from '../components/UsersComponents/Search';
import { withRouter }  from 'react-router-dom';
class UserAdminContainer extends Component {
    componentWillMount = () =>{
        this.props.showAppFrame(this.props.location.pathname);
    }
    render() {
        const {userId}= this.props;
        return (
            <AppContainer title="Administrador de usuarios">
                <UserSerch userId={userId}/>
            </AppContainer>
        );
    }
}

export default withRouter(((setPropsAsInitial)(UserAdminContainer)));
import { handleActions } from 'redux-actions';
import {GET_MEDDRA_IMPORTS,GET_MEDDRA_IMPORT_CODE,GET_CLEAN_MEDDRA_IMPORT_CODE } from '../constants/get';

export const MedDRAImports = handleActions ({
    [GET_MEDDRA_IMPORTS]: (state, action) => action.payload,
    [GET_MEDDRA_IMPORT_CODE]:(state,action)=> {
        if(action.payload.length>0){
            const table = action.payload;
            const res = state.reduce((acc, imp) =>{
                if(imp.code_import === Number(table[0].code_import)){
                    imp.Tables = table;
                }
                return [...acc, imp]; 
            },[]);
            return res;
        }
        else
            return action.payload;
    },
    [GET_CLEAN_MEDDRA_IMPORT_CODE]:(state,action)=> {
            const code = action.payload;
            const res = state.reduce((acc, imp) =>{
                if(imp.code_import === Number(code)){
                    delete imp.Tables;
                }
                return [...acc, imp]; 
            },[]);
            return res;
    },
},[]);

import {
  faInfoCircle,
  faPaperclip,
  faDiagnoses,
  faPrescriptionBottleAlt,
  faHistory,
  faCertificate,
  faNotesMedical,
  faFilePdf,
  faFile
} from "@fortawesome/free-solid-svg-icons";

export const questionYesNO = [
  { value: "NO", label: "NO" },
  { value: "SI", label: "SI" }
];

export const stepsERedit = [
  "Datos del paciente",
  "Origen del reporte",
  "Información de medicamento",
  "Evento reportable sospechado",
  "Historial de medicamentos administrados relevantes",
  "Consulta médica",
  "Información relevante",
  "Resumen"
];
export const stepsERview = [
  {
    Title: "Detalle",
    Description: "Detalles del reporte",
    icon: faInfoCircle,
    step: "details"
  },
  {
    Title: "Documentos",
    Description: "Documentos y notas",
    icon: faPaperclip,
    step: "docuemnts"
  },
  {
    Title: "RAM",
    Description: "Clasificación y Notificación del evento reportable",
    icon: faDiagnoses,
    step: "ram"
  },
  {
    Title: "Recuperación",
    Description: "Recuperación del producto",
    icon: faPrescriptionBottleAlt,
    step: "recovery"
  },
  {
    Title: "Operaciones",
    Description: "Historial de operaciones",
    icon: faHistory,
    step: "operations"
  },
  {
    Title: "Imputabilidad",
    Description: "Evaluación de imputabilidad",
    icon: faCertificate,
    step: "evaluation"
  },
  {
    Title: "Seguimiento",
    Description: "Reportes de seguimiento",
    icon: faNotesMedical,
    step: "followup"
  },
  {
    Title: "Historial de PDFs",
    Description: "Historial de PDFs",
    icon: faFilePdf,
    step: "pdfs"
  },
  {
    Title: "Eventos de Seguimiento",
    Description: "Eventos de Seguimiento",
    icon: faFile,
    step: "eventoSeguimiento"
  },
];

export const questionsStep3 = [
  { label: "Muerte", name: "Death" },
  {
    label: "Riesgo de vida",
    name: "LifeThreading",
    info:
      "Un evento que cause riesgo de vida es un evento en que el paciente tuvo riesgo de vida al momento del evento, no refiere a un evento que podría haber hipotéticamente causado riesgo de vida si hubiera sido más severo."
  },
  { label: "Requirió hospitalización", name: "HospitalizationRequired" },
  { label: "Prolongó hospitalización", name: "PersistedHospitalization" },
  {
    label: "Incapacidad transitoria",
    name: "TransitoryDisability",
    info:
      "Se debe utilizar el juicio médico y científico para decidir si amerita reporte expedito en otras situaciones, como eventos médicos importantes que no amenazan con la vida inmediatamente o resultan en muerte o hospitalización, pero pueden poner en peligro al paciente o pueden requerir intervención para prevenir alguno de estos resultados"
  },
  {
    label: "Incapacidad persistente o significativa",
    name: "PersistentDisability"
  },
  {
    label: "Anomalía congénita o defecto de nacimiento",
    name: "CongenitalAnomaly"
  },
  {
    label: "Otras condiciones medicamente significativas",
    name: "AnotherCondition"
  }

];

export const questionInputabilityEvaluation = [
  {
    label: "¿Hay informes previos concluyentes sobre esta reacción?",
    name: "r1",
    answer: [
      { index: 0, value: 0, label: "Seleccione..." },
      { index: 1, value: 0, label: "NO" },
      { index: 2, value: 1, label: "SI" },
      { index: 3, value: 0, label: "No sabe" }
    ],
    readFabr: false,
    editFabr: false,
    commentFabr: "",
    readQPPV: false,
    editQPPV: false,
    commentQPPV: ""
  },
  {
    label:
      "¿El evento adverso apareció cuando se administró el medicamento sospechoso?",
    name: "r2",
    answer: [
      { index: 0, value: 0, label: "Seleccione..." },
      { index: 1, value: -1, label: "NO" },
      { index: 2, value: 2, label: "SI" },
      { index: 3, value: 0, label: "No sabe" }
    ],
    readFabr: false,
    editFabr: false,
    commentFabr: "",
    readQPPV: false,
    editQPPV: false,
    commentQPPV: ""
  },
  {
    label:
      "¿La reacción mejoró cuando se suspendió el medicamento o se administró un antagonista?",
    name: "r3",
    answer: [
      { index: 0, value: 0, label: "Seleccione..." },
      { index: 1, value: 0, label: "NO" },
      { index: 2, value: 1, label: "SI" },
      { index: 3, value: 0, label: "No sabe" }
    ],
    readFabr: false,
    editFabr: false,
    commentFabr: "",
    readQPPV: false,
    editQPPV: false,
    commentQPPV: ""
  },
  {
    label:
      "¿Reapareció la reacción adversa cuando se readministró el medicamento?",
    name: "r4",
    answer: [
      { index: 0, value: 0, label: "Seleccione..." },
      { index: 1, value: -1, label: "NO" },
      { index: 2, value: 2, label: "SI" },
      { index: 3, value: 0, label: "No sabe" }
    ],
    readFabr: false,
    editFabr: false,
    commentFabr: "",
    readQPPV: false,
    editQPPV: false,
    commentQPPV: ""
  },
  {
    label:
      "¿Hay causas alternativas que pudieron por si solas, haber causado la reacción?",
    name: "r5",
    answer: [
      { index: 0, value: 0, label: "Seleccione..." },
      { index: 1, value: 2, label: "NO" },
      { index: 2, value: -1, label: "SI" },
      { index: 3, value: 0, label: "No sabe" }
    ],
    readFabr: false,
    editFabr: false,
    commentFabr: "",
    readQPPV: false,
    editQPPV: false,
    commentQPPV: ""
  },
  {
    label: "¿Reapareció la reacción cuando se administró un placebo?",
    name: "r6",
    answer: [
      { index: 0, value: 0, label: "Seleccione..." },
      { index: 1, value: 1, label: "NO" },
      { index: 2, value: -1, label: "SI" },
      { index: 3, value: 0, label: "No sabe" }
    ],
    readFabr: false,
    editFabr: false,
    commentFabr: "",
    readQPPV: false,
    editQPPV: false,
    commentQPPV: ""
  },
  {
    label:
      "¿El medicamento se detectó en la sangre (u otro fluido) en concentraciones tóxicas?",
    name: "r7",
    answer: [
      { index: 0, value: 0, label: "Seleccione..." },
      { index: 1, value: 0, label: "NO" },
      { index: 2, value: 1, label: "SI" },
      { index: 3, value: 0, label: "No sabe" }
    ],
    readFabr: false,
    editFabr: false,
    commentFabr: "",
    readQPPV: false,
    editQPPV: false,
    commentQPPV: ""
  },
  {
    label:
      "¿La reacción fue más severa cuando se aumentó la dosis o menos severa cuando se disminuyó?",
    name: "r8",
    answer: [
      { index: 0, value: 0, label: "Seleccione..." },
      { index: 1, value: 0, label: "NO" },
      { index: 2, value: 1, label: "SI" },
      { index: 3, value: 0, label: "No sabe" }
    ],
    readFabr: false,
    editFabr: false,
    commentFabr: "",
    readQPPV: false,
    editQPPV: false,
    commentQPPV: ""
  },
  {
    label:
      "¿El paciente ha tenido una reacción similar con el mismo medicamento u otros similares?",
    name: "r9",
    answer: [
      { index: 0, value: 0, label: "Seleccione..." },
      { index: 1, value: 0, label: "NO" },
      { index: 2, value: 1, label: "SI" },
      { index: 3, value: 0, label: "No sabe" }
    ],
    readFabr: false,
    editFabr: false,
    commentFabr: "",
    readQPPV: false,
    editQPPV: false,
    commentQPPV: ""
  },
  {
    label:
      "¿El evento adverso fue confirmado por medio de una evidencia objetiva?",
    name: "r10",
    answer: [
      { index: 0, value: 0, label: "Seleccione..." },
      { index: 1, value: 0, label: "NO" },
      { index: 2, value: 1, label: "SI" },
      { index: 3, value: 0, label: "No sabe" }
    ],
    readFabr: false,
    editFabr: false,
    commentFabr: "",
    readQPPV: false,
    editQPPV: false,
    commentQPPV: ""
  }
];

export const answersNOSI = [
  { Id: false, Text: "NO" },
  { Id: true, Text: "SI" }
];

export const eventEvolutionStep3 = [
  { Id: -1, Text: "Recuperado / resuelto" },
  { Id: 0, Text: "En recuperación / resolviéndose" },
  { Id: 2, Text: "No recuperado / no resuelto / continua" },
  { Id: 3, Text: "Recuperado con secuales / resuelto con secuales" },
  { Id: 4, Text: "Fatal" },
  { Id: 5, Text: "Desconocido" }
];

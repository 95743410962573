import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CardHeader from "@material-ui/core/CardHeader";
import {
  getER,
  getERDocsAndNotes,
  getERFollowUp,
  
  getERActions
} from "./../../../redux/selectors/ER";
import EReportActions from "./../EReportActions";
import { stepsERview } from "../../../constants/steps";
import { Menu, Segment } from "semantic-ui-react";
import CardContent from "@material-ui/core/CardContent";
import { CardFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../style.css";
import Details from "./Steps/Step0_Details";
import DocumentsAndNotesDetail from "./Steps/Step1_DocumentsAndNotes";
import RAMSComponent from "./Steps/Step2_RAM";
import ProductRecovery from "./Steps/Step3_ProductRecovery";
import OperationHistory from "./Steps/Step4_OperationHistory";
import FollowUp from "./Steps/Step6_FollowUp";
import ImputabilityComponent from "./Steps/Step5_ImputabilityEvaluation";
import { getMyUser } from "../../../redux/selectors/Context";
import { Label } from "semantic-ui-react";
import { LabelDate } from "../../Fields";
import { LoadCircular } from "../../AppComponents/AppUtilities";
import { setERActions } from "../../../redux/actions/eventoReportable";
import HistorialPdfs from "./Steps/Step7_HistorialPdfs";
import EventosdeSeguimiento from "./Steps/Step8_EventosdeSeguimiento";
import {getERImputEvaluation} from './../../../redux/actions/eventoReportable';
import { showError } from './../../../redux/actions/error';
class EReportData extends Component {
  state = {
    stepsCount: 7,
    activeItem: 0,
    ERImputEvaluation: null
  };

  componentDidMount = () => {
    window.scroll(0, 0);
  };


  handleAudit = () => {

  };

  handleItemClick = step =>
    this.props.type !== 3 ?
    this.props.history.push(`/reportableEvent/${this.props.meta}/view/${step}`):
    this.props.history.push(`/reportableEvent/${this.props.meta}/view/${step}/3`);

  renderMenu = (position, cant) => (
    <Menu attached={position} widths={cant} size="large">
      {stepsERview.map((step, index) => {
        let ret = "";
        if (
          (index !== 5 && index !== 6) ||
          (index === 5 && this.props.ER.IsRAM) ||
          (index === 6 && this.props.ER.TieneFollowUp)
        )
          ret = this.renderMenuItem(step, index);
        return ret;
      })}
    </Menu>
  );

  renderMenuItem = (step, index) => (
    <Menu.Item
      className="icon-view p-r-md p-t-sm p-b-sm"
      index={index}
      key={index}
      active={this.props.step === step.step}
      onClick={() => this.handleItemClick(step.step)}
    >
      <div>
        <FontAwesomeIcon
          className="m-l-md m-r-sm icon-view block icon-menu-expand"
          icon={step.icon}
          size={"2x"}
        />
        <label className="block m-t-sm description-hide">
          {step.Description}
        </label>
      </div>
    </Menu.Item>
  );

  handleChangeEvaluation = () => {};
  
  getStepContent = () => {
    
    switch (this.props.step) {
      case "details":
        return <Details meta={this.props.meta} />;
      case "docuemnts":
        return <DocumentsAndNotesDetail meta={this.props.meta} />;
      case "ram":
        return (
          <RAMSComponent
            meta={this.props.meta}
            handleChangeStep={this.handleItemClick}
          />
        );
      case "recovery":
        return <ProductRecovery meta={this.props.meta} />;
      case "operations":
        return <OperationHistory meta={this.props.meta} />;
      case "pdfs":
          return <HistorialPdfs er={this.props.ER}/>;
      case "eventoSeguimiento":
          return <EventosdeSeguimiento er={this.props.ER}/>;
      case "evaluation":
        try {
          const list =  this.props.getERImputEvaluation({meta: this.props.meta});
          return (
            <ImputabilityComponent
              meta={this.props.meta}
              er={this.props.ER}
              UserContext={this.props.UserContext}
              list = {list}
            />
          );
        } catch (e) {
          this.props.showError(e);
        }
       
      case "followup":
        return <FollowUp meta={this.props.meta} />;
      default:
        return <Details meta={this.props.meta} />;
    }
  };

  getStepCant = () => {
    let cant = this.state.stepsCount;
    if (this.props.ER) {
      if (this.props.ER.IsRAM) cant++;
      if (this.props.ER.TieneFollowUp) cant++;
    }
    return cant;
  };

  render() {
    const { ER, Actions, meta , UserContext} = this.props;
    const anulado = ER && ER.Status === 11;
    return (
      <div className={`${anulado ? "report-cancel" : ""}`}>
        {ER && (
          <CardHeader
            className={`p-t-n p-b-n p-r-md p-l-md titleSubGrup ${
              anulado ? "report-cancel" : ""
            }`}
            title={ER.SubGrupoNombre}
            action={
              this.props.type !== '3' ?
              Actions ? (
                <EReportActions meta={meta} user={UserContext} handleAudit={this.handleAudit} />
              ) : (
                <LoadCircular className="m-r" />
              ):null
            }
          />
        )}
        <CardContent className="p-b p-t-n">
          {ER && this.renderMenu("top", this.getStepCant())}
          <Segment attached className="p-n m-t-md border-lr-n border-b-n">
            {ER && this.getStepContent()}
          </Segment>
        </CardContent>
        <CardFooter className="p-t-n p-b-n">
          {ER && ER.User && (
            <Label className="text-muted m-t-md pull-left">
              Creado por {ER.User.FirstName} {ER.User.LastName}{" "}
              {LabelDate(ER.DateCreation)}
            </Label>
          )}
          {ER && ER.Modificado && (
            <Label className="text-muted m-t-md pull-right">
              Actualizado por {ER.Modificado.Usuario}{" "}
              {LabelDate(ER.Modificado.Fecha)}
            </Label>
          )}
        </CardFooter>
      </div>
    );
  }
}

EReportData.propTypes = {
  ER: PropTypes.object
};

const mapStateToProps = state => ({
  ER: getER(state),
  DocsANdNotes: getERDocsAndNotes(state),
  FollowUp: getERFollowUp(state),
  UserContext: getMyUser(state),
  //Evaluacion: getERImputEvaluation(state),
  Actions: getERActions(state)
});

const mapDispatchToProps = {
  setERActions,
  getERImputEvaluation,
  showError
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EReportData)
);

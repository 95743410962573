import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setLoad } from '../../redux/actions/context';
import { showError } from '../../redux/actions/error';
import { Row, Col } from 'react-flexbox-grid';
import AppBox from '../AppComponents/AppBox';
import api from '../../api';
import ListNoKpis from '../ListComponents/List/ListNoKpis';
import { getMetrics } from '../../redux/selectors/metrics';
import { setMetric, setMetrics } from '../../redux/actions/metrics';
import { url_getEventsNoKpi, url_indicators_filtercountry } from '../../api/urls';
import { CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLevelUpAlt, faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import { FieldSelect } from '../Fields';
import { reduxForm } from 'redux-form';

class NoKPIComponents extends Component {

    state = {
        openCreate : false,
        percentage: 0,
        cantReport:0,
        loading: true,
        listpais: [],
        firstselect: null
    }

    componentDidMount = async () => {
      try {
        this.props.setLoad(true);
        const response = await api.post(url_getEventsNoKpi, { pais: null });
        this.props.setMetrics(response.data.list);
        const percentage = response.data.porcentaje;
        const cantReport = response.data.cantReport;

        this.setState({
          percentage: -(percentage),
          cantReport: cantReport,
          loading: false
        });
        const filterCountries = await api.get(url_indicators_filtercountry);
        this.setState({
          listpais: filterCountries.data
        });
      } catch (e) {
        console.log(e)
        this.props.showError(e);
      } finally {
        this.setState({ loading: false })
        this.props.setLoad(false);
      }
    }

    handelChangePais = async (x) => {
      try{
        this.props.setLoad(true);
        this.setState({
          firstselect: x.target.value
        });
        const data = {
          firstselect: x.target.value
        };
        const response = await api.post(url_getEventsNoKpi, data);
        this.props.setMetrics(response.data.list);
        this.setState({
          percentage: -(response.data.porcentaje),
          cantReport: response.data.cantReport,
          loading: false
        });

      }catch(error){
        this.props.showError(error);
      }finally{
        this.setState({ loading: false })
        this.props.setLoad(false);
      }
    }


    render() {
        const {list} = this.props;
        return (
            <div className="p-l-md p-r-md m-t-md">
            <Row>
                <Col xs={6} sm={3}>
                    <div className="ibox ">
                    <div className="ibox-title">
                            <h6>Eventos</h6>
                        </div>
                        <div className={`ibox-content `}>
                        <h4 className="m-n">{this.state.cantReport}</h4>
                            {this.state.loading ? <CircularProgress className='text-center'/> :
                                this.state.percentage !== 0 && (
                                <span className={`stat-percent font-bold ${  this.state.percentage > 0 ? 'text-success': 'text-danger'}`}>
                                    {   this.state.percentage > 0 ? `${  this.state.percentage}%` :   this.state.percentage < 0 && `${(  this.state.percentage * -1)}%`}
                                    {   this.state.percentage !== 0 && <FontAwesomeIcon icon={  this.state.percentage > 0 ? faLevelUpAlt : faLevelDownAlt }/>}
                                </span>)}

                            {!this.state.loading  && <small>Eventos No KPI</small>}

                        </div>
                    </div>
                </Col>

                <Col xs={6} sm={3}>
                    <FieldSelect label="Paises" name="countries" items={this.state.listpais}  onChange={this.handelChangePais} typeValue="text" valueField="Code" textField="Name"   />
                </Col>

                <Col xs={12} md={12} xl={12}>
                    <AppBox
                        className='wf-list'
                        title="Eventos que no cumplen"
                        content={<div>
                             <ListNoKpis list={list} />
                             </div>}
                        />


                </Col>
            </Row>
        </div>
        );
    }
}

const mapStateToProps = (state) => ({
    list : getMetrics(state)
})

const mapDispatchToProps = {
    setLoad,
    setMetrics,
    setMetric,
    showError
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'NoKpi'})(NoKPIComponents));

import React, { Component } from 'react';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';
import AppContainer from '../components/AppComponents/AppContainer';
import api from '../api';
import { url_getSettings, url_updateSetting } from '../api/urls';
import { setLoad } from '../redux/actions/context';
import { showError } from '../redux/actions/error';
import SettingComponent from '../components/Settings';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSave } from '@fortawesome/free-solid-svg-icons';
import { setSweetAlert } from '../redux/actions/sweetAlert';
import { withRouter }  from 'react-router-dom';
import  setPropsAsInitial  from '../helpers/setPropsAsInitial';
class SettingContainer extends Component {

    state={
        list:[],
        settings:null,
        loadUpdate:false,
    }
    componentWillMount = () =>{
      this.props.showAppFrame(this.props.location.pathname);
    }
    componentDidMount() {
      this._getAll();
    }

    _getAll = async () => {
        this.props.setLoad(true);
        let settings = {};
        try {
          const response = await api.get(url_getSettings);
          response.data.forEach(item => {
            settings[item.Name_setting] = item.Value_setting;
          });
          this.setState({list:response.data, settings});
        } catch (e) {
          console.log(e)
          this.props.showError(e);
        }finally{
          this.props.setLoad(false);
        }
    }

    handleUpdate = async () =>{
        this.setState({loadUpdate:true});
        const {values} = this.props;
        let { list } = this.state;
        list.forEach(i=>{
           i.Value_setting = values[i.Name_setting];
        });
        try {
          const response = await api.post(url_updateSetting, { values: list });
          if(response.data.length > 0){
              this.props.setSweetAlert({
                  type:'success',
                  title:"Configuración actualizada",
                  text: `Se ha actualizado las configuraciones de ${response.data.join(', ')}`,
                  show:true
              });
          }else{
            this.props.setSweetAlert({
                type:'info',
                title:"No se realizaron cambios en la configuración",
                text: ``,
                show:true
            });
          }
        } catch (error) {
            let msg;
            if (error.response) {
                msg = error.response.message;
            } else if (error.request) {
                msg = error.request;
            } else {
                // Something happened in setting up the request and triggered an Error
                msg = error.message;
            }
            this.props.setSweetAlert({
                type:'error',
                title:"No se pudo realizar cambios en la configuración",
                text: `${msg}`,
                show:true
            });
          } finally {
            this.setState({ loadUpdate: false });
        }
    }

    render() {
        const {settings, list, loadUpdate} = this.state;
        return (
            <AppContainer title="Configuración" className='p-l p-r'>
               {settings &&  <SettingComponent initialValues={settings} settings={list}/>}
                <CardActions className="card-actions p-t-lg">
                    <div>
                        <Button type="button" disabled={loadUpdate} className="card-actions-button button-next" variant="contained" onClick={this.handleUpdate} ><FontAwesomeIcon size="lg" icon={loadUpdate ? faSpinner : faSave} pulse={loadUpdate} /></Button>
                    </div>
                </CardActions>
            </AppContainer>
        );
    }
}

const mapDispatchToProps={
    setLoad,
    setSweetAlert,
    showError
}
const mapStateToProps = state =>({
    values: getFormValues('Settings')(state),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)((setPropsAsInitial)(SettingContainer)))

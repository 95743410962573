import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListMetrics from '../ListComponents/List/ListMetrics';
import api from '../../api';
import { url_get_AllMetrics, url_active_Metric, url_desactve_Metric } from '../../api/url_metricas';
import { setLoad } from '../../redux/actions/context';
import { showError } from '../../redux/actions/error';
import { setMetric, setMetrics } from '../../redux/actions/metrics';
import { Row, Col } from 'react-flexbox-grid';
import AppBox from '../AppComponents/AppBox';
import { getMetrics } from '../../redux/selectors/metrics';

class MetricsComponent extends Component {

    state = {
      openCreate : false
    }

    loadMetrics = async() =>{
      try{
        const response = await api.get(url_get_AllMetrics)
        this.props.setMetrics(response.data);
      }catch(error){
        this.props.showError(error);
      }finally{
        this.props.setLoad(false);
      }
    }

    componentDidMount = () => {
        this.props.setLoad(true);
        this.loadMetrics();
      }

    _handleAction = async (active, item) => {
      try{
        const url = active ? url_active_Metric : url_desactve_Metric;
        await api.post(url, item);
      }catch(error){
        item.Activo = !item.Activo;
      }finally{
        this.props.setMetric(item);
      }
    }

    handleShowCreate = () => {
        this.setState({openCreate: !this.state.openCreate})
    };

    render() {
        const {list} = this.props;
        return (
            <div className="p-l-md p-r-md m-t-md">
            <Row>
                <Col xs={12} md={8} xl={6}>
                    <AppBox
                        handlePlus ={this.handleShowCreate}
                        className='wf-list'
                        title="Tiempos de notificación"
                        content={
                          <div>
                             <ListMetrics handleAction={this._handleAction} list={list} />
                          </div>
                        }
                        />
                </Col>
            </Row>
        </div>
        );
    }
}

const mapStateToProps = (state) => ({
    list : getMetrics(state)
})

const mapDispatchToProps = {
    setLoad,
    setMetrics,
    setMetric,
    showError
}

export default connect(mapStateToProps, mapDispatchToProps)(MetricsComponent);

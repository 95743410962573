import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { getMyUser, setLoad, userLogOut } from "../../redux/actions/context";
import { showError } from '../../redux/actions/error';
import { getERNew, insertERNew } from "../../redux/actions/eventoReportable";
import { setHomeCodiguera } from "../../redux/actions/home";
import { getER } from "../../redux/selectors/ER";
import {
  getMyUser as MyUser,
  getItemMenuAdmin,
  getItemMenuNavBar
} from "./../../redux/selectors/Context";
import { getHomeCodiguera } from "../../redux/selectors/home";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarPlus,
  faSignOutAlt,
  faSpinner,
  faUser,
  faCogs
} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import "./AppStyle.css";
import CreateEventModal from "../Modals/CreateEventModal";
import { url_getERNew, url_postERinsert } from "../../api/urls";
import api from "../../api";
import AppDrawerBtn from "./AppDrawerBtn";
import AppSideDrawer from "./AppSideDrawer";
import { secureStorage } from "../../api/secureStorage";
import HomeForm from "../HomeComponents/components/HomeForm";
import { url_getCodiguera } from "../../api/url_home";

var pckg = require('../../../package.json');

class AppHeader extends Component {
  state = {
    isOpen: false,
    isOpenModalCreate: false,
    errorPais: false,
    errorSubGroup: false,
    navbarSideDreawerOpen: false,
    adminSideDreawerOpen: false,
    isOpenPatientForm: false,
   
  };

  componentDidMount = () => {
    if (!this.props.myUser) {
      this.props.getMyUser();
    }
    if (this.props.myUser.Rol === "CL") {
      this._handleGetCodiguerHome();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.myUser &&
      this.props.myUser.Rol === "CL" &&
      this.props.codigueraHome === null
    ) {
      this._handleGetCodiguerHome();
    }
  }

  _handleGetCodiguerHome = async () => {

    const response = await api.get(url_getCodiguera);
    this.props.setHomeCodiguera(response.data);
  };
  _handleDrawerToggle = () =>
    this.setState({
      navbarSideDreawerOpen: false,
      adminSideDreawerOpen: false
    });

  _handleNavBarDrawerToggle = () =>
    this.setState({ navbarSideDreawerOpen: !this.state.navbarSideDreawerOpen });

  _handleAdminDrawerToggle = () =>
    this.setState({ adminSideDreawerOpen: !this.state.adminSideDreawerOpen });

  _handleModalCreateToggle = () =>
    this.setState({
      errorPais: false,
      errorSubGroup: false,
      isOpenModalCreate: !this.state.isOpenModalCreate,
      sideDreawerOpen: false
    });

  handleNavigation = page => this.props.history.push(`/${page}`);

  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  togglePatientForm = () =>
    this.setState({ isOpenPatientForm: !this.state.isOpenPatientForm });

  handleOpenModalCreate = () =>
    this.setState({
      errorPais: false,
      errorSubGroup: false,
      isOpenModalCreate: true
    });

  handleLogOff = () => {
    secureStorage.clear();
    api.logOut();
  };

  handleCreateReport = async (subG, country) => {
    this.setState({ errorPais: false, errorSubGroup: false});
    this.props.setLoad(true);
    if (country && country !== "none" && subG !== "0") {
      try{
        const response = await api.get(url_getERNew);
        response.data.SubGrupoId = subG;
        response.data.Country = country;
        const resp = await api.post(url_postERinsert, response.data);
        this.props.insertERNew(resp.data);
        this.setState({ isOpenModalCreate: false });
        window.location = `/reportableEvent/${resp.data.MetaData}/edit/`;
        
      }catch(error){
        this.props.showError(error);
        this.props.setLoad(false);
      }
    }
    if (!country || country === "none") {
        this.setState({ errorPais: true });
        this.props.setLoad(false);
    }
    if (subG === "0") {
        this.setState({ errorSubGroup: true });
        this.props.setLoad(false);
    }
  };

  renderNavBarSideDrawer = (list, className, rol) => {
    return (
      <ul className={className}>
        {(rol !== 'Fabricante') && (<li>
          <NavLink to="#" onClick={this._handleModalCreateToggle}>
            <FontAwesomeIcon
              className="icon-head m-r-sm"
              icon={faCalendarPlus}
            />{" "}
            Crear evento
          </NavLink>
        </li> ) }
        {list.map((item, index) => (
          <li key={index} title={item.TooltipES}>
            <NavLink to={item.URL} onClick={this._handleDrawerToggle}>
              <i className={`icon-head m-r-sm fa fa-${item.FontAwesomeIcon}`} />
              {item.NameES}
            </NavLink>
          </li>
        ))}
        <li>
          <NavLink to="#" onClick={this.handleLogOff}>
            <FontAwesomeIcon className="icon-head m-r-sm" icon={faSignOutAlt} />{" "}
            Cerrar sesión
          </NavLink>
        </li>
      </ul>
    );
  };

  renderAdminSideDrawer = (list, className) => (
    <ul className={className}>
      {list.map((item, index) => (
        <li key={index} title={item.TooltipES}>
          <NavLink to={item.URL} onClick={this._handleDrawerToggle}>
            <i className={`icon-head m-r-sm fa fa-${item.FontAwesomeIcon}`} />
            {item.NameES}
          </NavLink>
        </li>
      ))}
      <li className="m- t-lg">
        <NavLink to="#" onClick={this.handleLogOff}>
          <FontAwesomeIcon
            className="icon-head m-r-sm"
            size="lg"
            icon={faSignOutAlt}
          />{" "}
          Cerrar sesión
        </NavLink>
      </li>
      <li className="m- t-lg" style={{color:'#00695f',textDecoration: 'none',fontSize: '1.2rem'}}>Versión: {pckg.version}</li>
    </ul>
  );

  handleOpenFormCreate = myUser => {
    if (myUser && myUser.Rol !== "CL") {
      this.handleOpenModalCreate();
    } else if (myUser) {
      this.togglePatientForm();
    }
  };
  render() {
    const {
      isOpenModalCreate,
      errorPais,
      errorSubGroup,
      navbarSideDreawerOpen,
      adminSideDreawerOpen,
      isOpenPatientForm
      
    } = this.state;
    const { myUser, navbarAdmin, navbarItem, codigueraHome } = this.props;
    let backdrop;
    if (navbarSideDreawerOpen || adminSideDreawerOpen) {
      backdrop = (
        <div className="app-backdrop" onClick={this._handleDrawerToggle} />
      );
    }
    const iconList = Object.keys(Icons)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((idx) => Icons[idx]);
 
    library.add(...iconList)
    return (
      <div>
        <nav className="app-header">
          <div className="navigation">
            <div>
              <AppDrawerBtn
                handleSideDrawerToggle={this._handleNavBarDrawerToggle}
              />
            </div>
            <div className="app-header-logo">
              <NavLink to="/workflow" className="w86">
                <img
                  className="imgLogo"
                  alt="logo CIM"
                  src={require("./CIM_logo.png")}
                />
              </NavLink>
            </div>

            <div className="spacer" />
            <div className="navigation-items">
              <ul>
                
              {(myUser.Rol!=='Fabricante') && (<li title="Crear nuevo evento reportable">
                  <NavLink
                    to={"#"}
                    onClick={() => this.handleOpenFormCreate(myUser)}
                  >
                    <FontAwesomeIcon
                      className="icon-head"
                      icon={faCalendarPlus}
                    />
                  </NavLink>
                </li>)}
                {
                 
                navbarItem &&
                
                  navbarItem.map((item, indx) => (
                    <li key={indx} title={item.TooltipES}>
                      <NavLink to={item.URL}>
                      <FontAwesomeIcon
                         className="icon-head"
                         icon={['fa', item.FontAwesomeIcon]}
                      />
                      
                      </NavLink>
                    </li>
                  ))}
                <li onClick={this._handleAdminDrawerToggle}>
                  {myUser && myUser.Name ? (
                    <NavLink to="#">
                      <FontAwesomeIcon icon={faUser} className="icon-head" />
                      <span className="iconLabel">{myUser.Name}</span>
                      <FontAwesomeIcon
                        className="iconLabel"
                        icon={faCogs}
                      />{" "}
                    </NavLink>
                  ) : (
                    <NavLink to="#">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        pulse={true}
                        className="iconLabel m-r-sm"
                      />
                    </NavLink>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <AppSideDrawer
          width="70%"
          backgroundColor="#00695f"
          position="left"
          body={this.renderNavBarSideDrawer(
            navbarItem,
            "side-drawer-list-navbar",
            myUser.Rol
          )}
          show={navbarSideDreawerOpen}
        />
        {myUser && (
          <AppSideDrawer
            width="280px"
            backgroundColor="#fff"
            position="right"
            body={this.renderAdminSideDrawer(
              navbarAdmin,
              "side-drawer-list-admin"
            )}
            show={adminSideDreawerOpen}
          />
        )}
        {backdrop}
        {myUser.Rol !== 'Fabricante' && (
          <CreateEventModal
          open={isOpenModalCreate}
          toggle={this._handleModalCreateToggle}
          handleConfirm={this.handleCreateReport}
          errorPais={errorPais}
          errorSubGroup={errorSubGroup}
        />
        )
        }
        {codigueraHome && (
          <div className="home-cim">
            <HomeForm
              codiguera={codigueraHome}
              toggle={this.togglePatientForm}
              show={isOpenPatientForm}
              isMedic={false}
            />
          </div>
        )}
      </div>
    );
  }
}

AppHeader.propTypes = {
  myUser: PropTypes.object,
  Codigueras: PropTypes.object
};

AppHeader.defaultProps = {
  myUser: []
};

const mapDispatchToProps = {
  getMyUser,
  getERNew,
  insertERNew,
  setLoad,
  userLogOut,
  setHomeCodiguera,
  showError
};

const mapStateToProps = state => ({
  myUser: MyUser(state),
  ER: getER(state),
  navbarItem: getItemMenuNavBar(state),
  navbarAdmin: getItemMenuAdmin(state),
  codigueraHome: getHomeCodiguera(state)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppHeader)
);

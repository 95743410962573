import React from "react";
import { getElementCodiguera } from "../../../../codigueras";
import { Row, Col } from "react-flexbox-grid";
import { FieldLabel, LabelDate } from "../../../Fields";

const SummaryStep3 = ({ codiguera, values, isMedic }) => {
  return (
    <div className="form-step">
      {values &&
        values.RAM &&
        values.RAM.length > 0 &&
        values.RAM.map((r, i) => (
          <SummaryRAM
            key={i}
            values={r}
            codiguera={codiguera}
            isMedic={isMedic}
          />
        ))}
    </div>
  );
};

export default SummaryStep3;

const SummaryRAM = ({ codiguera, values, isMedic }) => {
  return (
    <div>
      <h3>Acontecimiento adverso / reacción adversa</h3>
      <Row className="form-step-row">
        {isMedic && (
          <Col sm={12} md={4}>
            <FieldLabel
              type="text"
              label="Termino MedDRA"
              value={values.MedDRA && values.MedDRA[0].llt_name}
            />
          </Col>
        )}
        {isMedic && (
          <Col sm={12} md={4}>
            <FieldLabel
              type="text"
              label="Es grave"
              value={values.Grave}
            />
          </Col>
        )}
        {!isMedic && (
          <Col sm={12} md={12}>
            <FieldLabel
              type="text"
              label="Acontecimiento adverso / reacción adversa (*)"
              value={values.Reaccion}
            />
          </Col>
        )}
        <Col sm={12} md={4}>
          <FieldLabel
            type="date"
            label="Fecha de inicio"
            value={values.FechaIniReaccion}
          />
        </Col>
        <Col sm={12} md={4}>
          <FieldLabel
            type="date"
            label="Fecha de finalización"
            value={values.FechaFinReaccion}
          />
        </Col>
        {isMedic && (
          <Col sm={12} md={4}>
            <FieldLabel
              type="text"
              label="Estado actual del paciente"
              value={
                values.Evolucion
                  ? values.Evolucion !== "none"
                    ? values.Evolucion
                    : "---"
                  : "---"
              }
            />
          </Col>
        )}
        {isMedic && (
          <Col sm={12} md={4}>
            <FieldLabel
              type="text"
              label="Acción tomada con el medicamento"
              value={
                codiguera &&
                getElementCodiguera(
                  "accionesMedicamentos",
                  values.AccionMeddicamento,
                  codiguera
                )
              }
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

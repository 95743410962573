import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import { getElementCodiguera } from '../../../../../codigueras';
import { getCodigueraContext } from '../../../../../redux/selectors/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const ProductDetails = ({medicine,codiguera,hidde,handleToggle}) => {
    return (
        <div>
            <Row>
                <Col lg={3} md={4}>
                    <FormGroup>
                        <Label>Medicamento</Label>
                        <Input bsSize="sm" type="text" disabled={true} title={medicine.Medicine.ComercialName} value={medicine.Medicine.ComercialName} />
                    </FormGroup>
                </Col>
                <Col lg={3} md={4}>
                    <FormGroup>
                        <Label>Laboratorio</Label>
                        <Input bsSize="sm" type="text" disabled={true}  title={medicine.Linea.Nombre} value={medicine.Linea.Nombre} />
                    </FormGroup>
                </Col>
                <Col lg={5} md={3} sm={11}>
                    <FormGroup>
                        <Label>Familia</Label>
                        <Input bsSize="sm" type="text" disabled={true}  title={medicine.Medicine.Family.Name} value={medicine.Medicine.Family.Name} />
                    </FormGroup>
                </Col>
                <Col lg={1} md={1} sm={1}>
                   { medicine.PrincipiosActivos.length > 0 && <Button bsSize="sm" className="m-t-lg" onClick={() =>handleToggle()}><FontAwesomeIcon icon={hidde ? faAngleDown  :faAngleUp}/></Button>}
                </Col>
            </Row>
            <Row hidden={hidde}>
                <Col lg={6} md={8} sm={12} >
                    {
                        medicine.PrincipiosActivos.map((p,i) =>(
                            <Row key={i}>
                                <Col sm={12} md={4} lg={6}>
                                    <FormGroup>
                                        <Label>Principio Activo</Label>
                                        <Input bsSize="sm" type="text" disabled={true} value={p.PrincipioActivo_Nombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={8} lg={6}>
                                    <Row>
                                        <Col sm={6} >
                                            <FormGroup>
                                                <Label>Concentración</Label>
                                                <Input bsSize="sm" type="text" disabled={true} value={p.Concentracion} />
                                            </FormGroup>
                                        </Col>
                                        <Col  sm={6} >
                                            <FormGroup>
                                                <Label>Unidad</Label>
                                                <Input bsSize="sm" type="text" disabled={true} value={getElementCodiguera("unidadMedicamento",p.Unidad,codiguera)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))
                    }
                </Col>
            </Row>
        </div>
    );
};

ProductDetails.propTypes = {
    medicine: PropTypes.object,
};

const mapStateToProps = (state) =>({
    codiguera: getCodigueraContext(state)
});

export default connect(mapStateToProps,null)(ProductDetails);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { getEROriginReport } from "../../../../redux/selectors/ER";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setLoad } from "../../../../redux/actions/context";
import {
  faArrowLeft,
  faArrowRight,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import UploadFile from "../../../UploadFile";
import { FieldText, FieldSwitch, FieldSelect } from "../../../Fields";
import { Row, Col } from "react-flexbox-grid";
import Button from "@material-ui/core/Button";
import { CardActions } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { CardHeader } from "@material-ui/core";
import { getCodigueraContext } from "../../../../redux/selectors/Context";
import { getCodiguera } from "../../../../codigueras";
import { downloadOriginReport, url_getOriginReportERMeta } from "../../../../api/urls";
import FileView from "../../../UploadFile/fileView";
import { Label } from "reactstrap";
import api from "../../../../api";
import { setOriginReport } from "../../../../redux/actions/eventoReportable";

class OriginReport extends Component {
  state = {
    upload: false,
    files: [],
    edit: false,
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.props.setLoad(true);
    await api.get(url_getOriginReportERMeta(this.props.meta))
    this.props.setLoad(false);
  }



  handleAddFile = files => {
    this.setState({ files })
  }

  handleInsert = () => {
    this.setState({ upload: true });
  };

  handleEditFile = () => {
    this.setState({
      edit: true,
      files: []
    });
  }

  render() {
    const {
      title,
      handleBack,
      handleSubmit,
      have,
      loadNext,
      codiguera,
      provieneDeLiteratura,
      tipo,
      initialValues,
      FileName,
      notHave,
      estudioClinico
    } = this.props;
    const { upload, edit, files } = this.state;
    return (
      <div>
        <CardHeader className="p-l-n p-t-n" title={title} />
        <CardContent>
          <Row>
            <Col lg={3} md={4}>
              <FieldSelect
                typeValue="number"
                name="OrigenId"
                label="Origen"
                items={
                  codiguera &&
                  getCodiguera("ReporteOriginador_Origen", codiguera)
                }
                valueField="Id"
                textField="Nombre"
                isRequired={true}
              />
            </Col>
            <Col lg={3} md={4}>
              <FieldSelect
                typeValue="number"
                name="ReceptorId"
                label="Receptor"
                items={
                  codiguera &&
                  getCodiguera("ReporteOriginador_Receptor", codiguera)
                }
                valueField="Id"
                textField="Nombre"
              />
            </Col>
            <Col lg={3} md={4}>
              <FieldSelect
                typeValue="number"
                name="TipoId"
                label="Tipo de reporte"
                items={
                  codiguera && getCodiguera("ReporteOriginador_Tipo", codiguera)
                }
                valueField="Id"
                textField="Nombre"
                isRequired={true}
              />
            </Col>

            <Col lg={3} md={4}>
              <FieldSwitch
                name="ProvieneDeLiteratura"
                icons={null}
                label="¿Proviene de Literatura?"
                inline={true}
              />
            </Col>

            <Col lg={6} md={8} hidden={!provieneDeLiteratura}>
              <FieldText
                name="ReferenciaBibliografica"
                label="Referencia bibliográfica"
                placeholder="Autor/es. Título del libro. Edición. Lugar de publicación: Editorial; año."
              />
            </Col>

            <Col lg={3} md={4} hidden={!estudioClinico}>
              <FieldSelect
                name="Country"
                label="País del estudio"
                items={codiguera && getCodiguera("PaisOrigen", codiguera)}
                valueField="Codigo"
                textField="Nombre"
              />
            </Col>
            <Col lg={6} md={8} hidden={!estudioClinico}>
              <FieldText name="NombreEstudio" label="Nombre del estudio" />
            </Col>
            <Col
              lg={tipo === 4 ? 3 : 6}
              md={tipo === 4 ? 6 : 8}
              hidden={!estudioClinico}
            >
              <FieldText name="TipoEstudio" label="Tipo estudio" />
            </Col>
            <Col lg={3} md={6} hidden={!estudioClinico}>
              <FieldText
                name="NumeroRegistroEstudio"
                label="Número de registro de estudio"
              />
            </Col>
            <Col lg={3} md={6} hidden={!estudioClinico}>
              <FieldText name="NumeroSponsor" label="Número de sponsor" />
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={4}>
              <FieldSwitch
                name="Have"
                icons={null}
                label="¿Posee documento del origen del reporte?"
                inline={true}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} hidden={have}>
              <FieldText
                name="NotHave"
                onChange={this.handleOnChangeNote}
                type="textarea"
                label="Motivo por el cual no posee reporte originador"
                tooltip={false}
                maxLength={2000}
                isRequired={true}
              />
            </Col>
          </Row>
          <Row hidden={!have}>
            {have && FileName != null && !edit ? (
              <Col sm={12}>
                <Label>Archivo</Label>
                <FileView
                  url={downloadOriginReport(initialValues.IdER)}
                  fileName={initialValues.FileName}
                  type={initialValues.Extension}
                  handleEdit={this.handleEditFile}
                />
              </Col>
            ) : (
              <Col md={12}>
                <UploadFile
                  disabled={upload}
                  label={"Archivo"}
                  handleAddFile={this.handleAddFile}
                  multiple={false}
                />
              </Col>
            )}
          </Row>
        </CardContent>
        <CardActions className="card-actions p-t-lg">
          <div>
            <Button
              type="button"
              disabled={loadNext}
              className="card-actions-button button-back"
              variant="contained"
              onClick={handleBack}
            >
              <FontAwesomeIcon size="lg" icon={faArrowLeft} />
            </Button>
            <Button
              type="button"
              disabled={loadNext}
              className="card-actions-button button-next"
              variant="contained"
              onClick={() =>
                handleSubmit({ noTiene: notHave, file: {name: have && ((!edit && FileName) || files && files[0] && files[0].name), haveFile: have, content: files} })
              }
            >
              <FontAwesomeIcon
                size="lg"
                icon={loadNext ? faSpinner : faArrowRight}
                pulse={loadNext}
              />
            </Button>
          </div>
        </CardActions>
      </div>
    );
  }
}

OriginReport.propTypes = {
  EROriginReport: PropTypes.object
};

const mapStateToProps = state => ({
  initialValues: getEROriginReport(state),
  have: formValueSelector("EReportEditStep1")(state, "Have"),
  estudioClinico:
    formValueSelector("EReportEditStep1")(state, "ReceptorId") === 2 ||
    formValueSelector("EReportEditStep1")(state, "TipoId") === 4
  ,
  provieneDeLiteratura: formValueSelector("EReportEditStep1")(
    state,
    "ProvieneDeLiteratura"
  ),
  tipo: formValueSelector("EReportEditStep1")(state, "TipoId"),
  notHave: formValueSelector("EReportEditStep1")(state, "NotHave"),
  codiguera: getCodigueraContext(state),
  FileName: formValueSelector("EReportEditStep1")(state, "FileName")
});

const validate = values => {
  const error = {};
  return error;
};

const mapDispatchToProps = {
  setOriginReport,
  setLoad
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "EReportEditStep1", validate })(OriginReport));

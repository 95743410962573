import React from 'react';
import ListComponent from '..';
import ListItemAuditUser from '../Item/ListItemAuditUser';

const ListAuditUsers = ({list,handleAction,load,loadList}) => {
    return (
        <ListComponent 
            loadList={loadList}
            mini={false} 
            load={load}  
            handleAction={handleAction} 
            empty={'registros'} 
            titles={['Tipo','Usuario','Generado por','Fecha','']} 
            list={list} 
            ListComponent={ListItemAuditUser}  />
    );
};

export default ListAuditUsers;
import { getFormValues, isDirty, isPristine, isValid, isInvalid, getFormSubmitErrors } from 'redux-form'; 

export const getEReportEditStep = (state, step) => ({
    values: getFormValues(`EReportEditStep${step}`)(state),
    dirty: isDirty(`EReportEditStep${step}`)(state),
    pristine: isPristine(`EReportEditStep${step}`)(state),
    valid: isValid(`EReportEditStep${step}`)(state),
    invalid: isInvalid(`EReportEditStep${step}`)(state)
});

export const getForm = (form,state) => ({
    values: getFormValues(form)(state),
    dirty: isDirty(form)(state),
    pristine: isPristine(form)(state),
    valid: isValid(form)(state),
    invalid: isInvalid(form)(state),
    errors: getFormSubmitErrors(form)(state),
});



import  React from 'react';
import { Accordion , Icon} from 'semantic-ui-react'

const panels = [
	{
	  key: '1',
	  title: {
		children:(
		   <div style={{textAlign:"center" ,color: '#50aaa9',fontSize:16}} ><Icon name='add' /> ¿Qué es la farmacovigilancia?</div>
	  )
   	},
	  content: {
		content: (
			<li>
				<p style={{textAlign:"left"}}>
	 				La Farmacovigilancia es  la ciencia y las actividades que se encargan de la detección, evaluación, comprensión y prevención de los eventos adversos o cualquier otro problema relacionado con los medicamentos. Si quiere saber más sobre la historia de la Farmacovigilancia,
	 			</p>
			</li>

		),
	  },
	},
	{
		key: '2',
		title:  {
			children:(
			   <div  style={{textAlign:"center" ,color: '#50aaa9',fontSize:16}} ><Icon name='add' /> ¿Qué es un evento inesperado vinculado a un medicamento?</div>
		  )
		},
		content: {
		  content: (
			  <li style={{maxWidth:600}}>

				<p style={{textAlign:"left"}}>
					Los eventos inesperados incluyen:<br /><br />

					&#9679; Efectos adversos o secundarios de los medicamentos.<br />
					&#9679; Errores en el uso.<br />
					&#9679; Errores en la administración (por ejemplo, errores en la dosis, en la vía de administración, la preparación o dilución).<br />
					&#9679; Error en la prescripción por parte del médico.<br />
					&#9679; Error en la dispensación en la farmacia.<br />
					&#9679; No haber obtenido el efecto esperado.<br />
					&#9679; Uso en el embarazo.<br />
					&#9679; Uso en la lactancia.<br />
					&#9679; Abuso de medicamentos.<br />
					&#9679; Uso fuera de las indicaciones del prospecto.<br />
					&#9679; Uso fuera de las condiciones autorizadas (por ejemplo: uso más allá de la fecha de vencimiento, uso luego de que el producto estuvo almacenado incorrectamente).<br />
					&#9679; Falsificación o adulteración de medicamentos.<br />
					&#9679; Exposición ocupacional únicamente con el producto terminado.<br />
					&#9679; Sobredosis.<br />
					&#9679; Uso indebido.<br />
					&#9679; Exposición paterna.<br />
					&#9679; Sospecha de transmisión de un agente infeccioso.
				</p>

			  </li>

		  ),
		},
	  },
	  {
		key: '3',
		title: {
			children:(
			   <div style={{textAlign:"center" ,color: '#50aaa9',fontSize:16}} ><Icon name='add' /> ¿Qué son los efectos adversos o efectos secundarios?</div>
		  )
		},
		content: {
		  content: (
			  <li>
				  <p style={{textAlign:"left"}}>
					Cualquier acontecimiento inesperado después del uso de un medicamento se considera un efecto adverso.
				   </p>
			  </li>

		  ),
		},
	  },
	  {
		key: '4',
		title: {
			children:(
			   <div style={{textAlign:"center" ,color: '#50aaa9',fontSize:16}} ><Icon name='add' /> ¿Qué importancia tiene esto?</div>
		  )
		},
		content: {
		  content: (
			  <li>
				 	<p style={{textAlign:"left"}}>
						Reportar eventos inesperados nos permite asegurar la seguridad y eficacia de los productos de las compañías farmacéuticas a las que prestamos servicios de farmacovigilancia y detectar en forma temprana cualquier inconveniente vinculado al uso de los mismos. Esto es de gran importancia no solo para ti, sino para toda la comunidad. Ante la duda de reportar o no, la decisión correcta es reportar siempre. <br /><br />
						Debes tener en cuenta que generalmente solo se tiene la sospecha de que el evento está vinculado con el medicamento, pero no se tiene una certeza. Por esta razón, nunca debes dejar de reportar una sospecha de evento inesperado porque así es como funciona la Farmacovigilancia.
					</p>
			  </li>

		  ),
		},
	  },
	  {
		key: '5',
		title: {
			 children:(
				<div style={{textAlign:"center" ,color: '#50aaa9',fontSize:16}}><Icon name='add' /> ¿Qué necesito para reportar un evento inesperado?</div>
		   )
		},
		content: {
		  content: (
			  <li>
				 	<p style={{textAlign:"left"}}>
						Solamente 4 datos básicos.<br /><br />

						1. Un paciente: ¿quién tuvo el evento inesperado?<br />
						2. El/los evento (s) inesperado (s): ¿qué le ocurrió a la persona que recibió el medicamento?<br />
						3. Un medicamento sospechoso de haber causado el evento. Puede ser más de un medicamento. En tal caso, hay que mencionar todos los medicamentos sospechosos.<br />
						4. Un notificador o reportante: ¿quién está reportando el acontecimiento? Solo te pediremos una dirección de correo o un teléfono, no se te pedirá información personal.
					</p>
			  </li>

		  ),
		},
	  },
  ];

const HomeFaqs = React.forwardRef((props, ref) => (
			<div ref={ref} id="faqs">
				<Accordion className='content-wrap' defaultActiveIndex={0} panels={panels} />
			</div>
	))



export default HomeFaqs;

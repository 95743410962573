import React, { Component } from 'react';
import './style-table.scss';
import CustomRender from '../../ParametersComponents/customRender';

class Table extends Component {


    _renderHeaders = (thClass) => {
        const { headers, items } = this.props;
        if(headers && headers.length > 0){
            return headers.map((h,i) => (<th className={thClass} key={i}>{h.header}</th>));
        }
        else if(items && items.length > 0){
            return Object.keys(this.props.items[0]).map((element, key) => (<th className={thClass} key={key}>{element}</th>));
        }
    }


    _filterElements = (e )  => {
        const { name, value } = e.target;
        const header = name.split("f_")[1];
        const items = this.props.items.filter(x=> value === "" || x[header].toString().toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0) || [];
        this.setState({items});
    }

    _renderFilters = () =>{
        const { headers, items } = this.props;
        if(headers && headers.length > 0){
            return headers.map((header,key) => (<td key={key}><input type="text" onChange={this._filterElements} name={`f_${header.name}`}/></td>));
        }
        else if(items && items.length>0){
            return Object.keys(this.props.items[0]).map((element,key) => (<th key={key}>{element}</th>));
        }
    }

    render(){
        const { items, headers, headerClass, thClass, hiddenHeaders, filters, handleAction, forEdit, load, name, action,handleInputChange } = this.props;
        const hs = headers.sort((a,b)=> (a.order - b.order));
        return (

                <table className='ark-table'>
                    {!hiddenHeaders && <thead className={headerClass}>
                        <tr>
                            {this._renderHeaders(thClass)}
                        </tr>
                    </thead>}
                    <tbody>
                    {filters &&
                        <tr className="ark-table-filters">
                            {this._renderFilters()}
                        </tr>
                    }
                    {
                      items &&  items.map((trElement, i)=>
                        (<tr key={i}>
                        {hs.map((tdElement,i)=>(
                                        <td key={i}>{
                                            <CustomRender
                                                handleInputChange={handleInputChange}
                                                Item={trElement}
                                                property={tdElement.name}
                                                handleAction={handleAction}
                                                forEdit={forEdit}
                                                load={load}
                                                name={name}
                                                action={action} />
                                            }
                                        </td>
                                    )
                                )
                        }
                        </tr>)
                        )

                    }
                    </tbody>
                </table>

        );
    }
};
export default Table;

/***Context */
export const SET_LOAD = "SET_LOAD";
export const SET_CODIGUERAS_CONTEXT = "SET_CODIGUERAS_CONTEXT";
export const SET_CODIGUERAS_RAM = "SET_CODIGUERAS_RAM";
export const SET_MY_USER = "SET_MY_USER";
export const SET_SWEETALERT = "SET_SWEETALERT";
/**end Context */

/***WorkFlow */
export const SET_WORKFLOW_LIST = "SET_WORKFLOW_LIST";
export const SET_HOME_ER = "SET_HOME_ER";
/**end WorlFlow */

/***EventoReportable */
export const SET_ER = "SET_ER";
export const SET_ER_PACIENTE = "SET_ER_PACIENTE";
export const SET_ER_ORIGIN_REPORT = "SET_ER_ORIGIN_REPORT";
export const SET_ER_RAM = "SET_ER_RAM";
export const SET_ER_RAMS = "SET_ER_RAMS";
export const SET_ER_IMPUTABILITY_EVALUATION = "SET_ER_IMPUTABILITY_EVALUATION";
export const SET_ER_MEDICATIONS_SUSP = "SET_ER_MEDICATIONS_SUSP";
export const SET_PACIENTE_ER_NEW = "SET_PACIENTE_ER_NEW";
export const SET_ER_ACTIONS = "SET_ER_ACTIONS";
export const SET_ER_CONTACTO_MEDICO = "SET_ER_CONTACTO_MEDICO";
export const SET_ER_RAM_CATEGORY = "SET_ER_RAM_CATEGORY";
export const SET_ER_MEDICATION_HISTORY = "SET_ER_MEDICATION_HISTORY";
export const SET_ER_FABRIC_APPROVED = "SET_ER_FABRIC_APPROVED";
/**end EventoReportable */

export const SET_METRICS_ALL = "SET_METRICS_ALL";
export const SET_METRIC = "SET_METRIC";

export const BLOCK_SESSION = "BLOCK_SESSION";
export const UNLOCK_SESSION = "UNLOCK_SESSION";

export const SET_LEGAL_TERMS = "SET_LEGAL_TERMS";

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Section from '../../../Section'
import { connect } from 'react-redux';
import { FieldLabel } from '../../../../Fields';
import { Row, Col } from 'react-flexbox-grid';
import { getElementCodiguera} from '../../../../../codigueras';
import { getCodigueraContext } from '../../../../../redux/selectors/Context';
import { getCodiguerasContext } from '../../../../../redux/actions/context'

class PacienteSection extends Component {
    getGenere = paciente => {
        const generes = [{Id:'M',Genere:'Masculino'},{Id:'F',Genere:'Femenino'}];
        return (
            <Row className="noRow">
            <Col md={3}>
                <FieldLabel label={'Sexo'} value={(paciente.Genere && paciente.Genere !== 'none' && paciente.Genere.length>0 && generes.filter(s => s.Id===paciente.Genere)[0].Genere)  }/>
            </Col> 
            {paciente.Genere==="F" &&
                <Col md={2}>
                    <FieldLabel label={'Embarazo'} type="bool" value={paciente.Pregnancy}/>
                </Col> 
            }
            {paciente.Genere==="F" &&
                <Col md={4}>
                    <FieldLabel label={'Semanas de gestación'} value={paciente.Gestation}/>
                </Col>
            }
            {paciente.Genere==="F" &&
                <Col md={2}>
                    <FieldLabel label={'Lactancia'} type="bool" value={paciente.Lactation}/>
                </Col>  
            }
           
        </Row>
        )
    }

    renderBody = (paciente, codiguera) => (
        <div>
            <Row className="noRow m-t-md">
                
                <Col md={2}>
                    <FieldLabel label={'Iniciales'} value={paciente.Initials}/>
                </Col> 
                <Col md={1}>
                    <FieldLabel label={'Edad'} value={paciente.Age ?  `${paciente.Age} ${paciente.Escala}`: '---'}/>
                </Col>
                <Col md={3}>
                    <FieldLabel label={'Altura '} value={paciente.Height ? `${paciente.Height} metros`: '---'}/>
                </Col>  
                <Col md={3}>
                    <FieldLabel label={'Peso'} value={paciente.Weight ? `${paciente.Weight} Kg.`: '---'}/>
                </Col> 
            </Row>
            {
                codiguera && paciente && this.getGenere(paciente,codiguera)
            } 
            <Row className="noRow">
                <Col md={3}>
                    <FieldLabel label={'País'} value={codiguera && paciente && getElementCodiguera("pais",paciente.Country,codiguera)}/>
                </Col> 
                <Col md={2}>
                    <FieldLabel label={'Departamento o provincia'} value={paciente.State}/>
                </Col> 
                <Col md={4}>
                    <FieldLabel label={'Residencia'} value={paciente.Residence}/>
                </Col>
                <Col md={2}>
                    <FieldLabel label={'Institución médica'} value={paciente.InstMedica}/>
                </Col>  
            </Row>
            <Row className="noRow ">
                <Col md={12}>
                    <FieldLabel label={'Condiciones médicas'} value={paciente.CondicionesMedicas}/>
                </Col>  
                <Col md={12}>
                    <FieldLabel label={'Alergias'} value={paciente.Alergias}/>
                </Col>  
            </Row>
        </div>
    )
    componentDidMount() {
        if(!this.props.codiguera)
            this.props.getCodiguerasContext()
    }
    render(){
        const {paciente, codiguera, handleBtnEdit, step,title} = this.props;
        return (
            <Section  title={title} edit={handleBtnEdit} step={step}>
                {(paciente && paciente.Initials) && this.renderBody(paciente, codiguera)}
            </Section>
        
        );
    }
};

PacienteSection.propTypes = {
    paciente : PropTypes.object,
};

const mapDispatchToProps = {
    getCodiguerasContext
};

const mapStateToProps = (state) =>({
    codiguera: getCodigueraContext(state)
});


export default connect(mapStateToProps,mapDispatchToProps)(PacienteSection);
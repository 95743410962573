import React from 'react';
import PropTypes from 'prop-types';
import ListComponent from '..';
import ListItemEventodeSeguimiento from '../Item/ListItemEventodeSeguimiento';

const titles=['Fecha','Usuario','Reporte de Seguimiento',''];

const ListEventosSeguimiento = ({list, handleAction, load}) => {
    return (
        <ListComponent list={list} load={load} empty={"Eventos de Seguimiento"} titles={titles} ListComponent={ListItemEventodeSeguimiento} handleAction={handleAction}/>
    );
};

ListEventosSeguimiento.propTypes = {
    list: PropTypes.array,
    handleAction: PropTypes.func
};

export default ListEventosSeguimiento;
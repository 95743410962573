import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { equalsChartData } from '../../helpers/utilities';

class  ChartColumn extends Component {
    
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const should = equalsChartData(this.props.data,nextProps.data) === false;
        return should;
    }
    
    render(){
        const {type,name, title, data, showInLegend, yTitle, hideLines} = this.props;
        const categories = data.map(d =>(d.name));
        const options = {
            chart: {
                type,
                
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
            text: title
            },

            xAxis: {
                categories,
                crosshair: true,

            },
            yAxis: {
                title: { text: yTitle },
                visible: !hideLines
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.point.name + '</b>: ' + this.point.y;
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        color: '#444',
                        align: 'center',
                        format: '{point.y}',
                        y: 0,
                        style: {
                            fontSize: '13px',
                            fontFamily: 'Verdana, sans-serif'
                        }
                    },               
                    showInLegend
                }
            },
            series: [{
                name,
                colorByPoint: true,
                data: data,
                showInLegend
            }]
        }

        return (
            <HighchartsReact 
            highcharts={Highcharts}
            options={options}
            lo/>
        );
    }
};

ChartColumn.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.array,
    categories: PropTypes.array,
};

export default ChartColumn;
import React from 'react';
import PropTypes from 'prop-types';
import {FieldLabel} from '../../../../Fields';
import Section from '../../../Section'
import { Row, Col } from 'react-flexbox-grid';
import { getElementCodiguera } from '../../../../../codigueras';

const handleWhoReport = wr =>{
    if(wr.MedicName || wr.MedicSurname || wr.MedicPhone || wr.MedicEmail){
        return (
            <Row className="noRow">
                <Col md={12}>
                    <h6>Datos del profesional de la salud</h6>
                </Col>
               
                <Col md={3}>
                    <FieldLabel label={'Nombre'} value={wr.MedicName}/>
                </Col>
                <Col md={3}>
                    <FieldLabel label={'Apellido'} value={wr.MedicSurname}/>
                </Col>
                <Col md={3}>
                    <FieldLabel label={'Teléfono'} value={wr.MedicPhone}/>
                </Col>
                <Col md={12}>
                    <FieldLabel label={'Profesión'} value={wr.MedicSpecialty}/>
                </Col>
                <Col md={3}>
                    <FieldLabel label={'Correo electrónico'} value={wr.MedicEmail}/>
                </Col>
            </Row> 
        )
    }
}

const handlePaciente= (paciente,codiguera) => (
    <Row className="noRow m-t-md">
        <Col md={12}>
            <h6>Datos de quien reporta</h6>
        </Col>
        <Col md={3}>
            <FieldLabel label={'Relación tiene con el afectado'} value={getElementCodiguera("parentesco",paciente.IdParentesco,codiguera)}/>
        </Col>
        <Col md={3}>
            <FieldLabel label={'Teléfono'} value={paciente.Phone}/>
        </Col>
        <Col md={3}>
            <FieldLabel label={'Correo electrónico'} value={paciente.Email}/>
        </Col>
    </Row> 
)

const handleER = ER =>(
    <Row className="noRow">
        <Col md={12}>
            <h6>Datos del usuario creador</h6>
        </Col>
        <Col md={3}>
            <FieldLabel label={'Nombre'} value={ER.User && ER.User.FirstName + ' '+ ER.User.LastName}/>
        </Col>
        <Col md={3}>
            <FieldLabel label={'Teléfono'} value={ER.User && ER.User.Phone}/>
        </Col>
        <Col md={3}>
            <FieldLabel label={'Correo electrónico'} value={ER.User && ER.User.Email}/>
        </Col>
    </Row> 
)

const handleMedico = (medico) => (
    <Row className="noRow">
        <Col md={12}>
            <h6>Datos del médico</h6>
        </Col>
        <Col md={3}>
            <FieldLabel label={'Nombre'} value={medico && medico.NombreMedico}/>
        </Col>
        <Col md={3}>
            <FieldLabel label={'Teléfono'} value={medico && medico.TelMedico}/>
        </Col>
        <Col md={3}>
            <FieldLabel label={'Correo electrónico'} value={medico && medico.EmailMedico}/>
        </Col>
    </Row> 
)

const renderBody = (ER,WR,Paciente,medico,codiguera) =>(
    <div>
        {
            Paciente && handlePaciente(Paciente,codiguera)
        }
        {
            (medico && medico.SeContacto) && handleMedico(medico)
        }
        {
            ER && handleER(ER)
        }
        {
            WR && handleWhoReport(WR)
        }
    </div>
)

const ContactDataDetail = ({ER, WR, paciente,medico,handleBtnEdit, step, title,codiguera}) => {
    return (
        <Section  title={title} edit={handleBtnEdit} step={step}>
            {renderBody(ER,WR,paciente,medico,codiguera)}
        </Section>
    );
};

ContactDataDetail.propTypes = {
    ER: PropTypes.object,
    WR: PropTypes.object,
    Paciente: PropTypes.object,
};

export default ContactDataDetail;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import DocAndNoteModal from '../../Modals/DocAndNoteModal';
import ListDocsAndNotes from '../../ListComponents/List/ListDocsAndNotes';
import { getERDocsAndNotes } from './../../../redux/selectors/ER';
import { uploadDocAndNotes, downloadDocAndNotes, url_deleteDocAndNotes} from '../../../api/urls';
import { getERDocsAndNotes as  getERDocsAndNotesMeta} from '../../../redux/actions/eventoReportable';
import api from '../../../api';
import  Signature from '../../Signature';
import { showError } from '../../../redux/actions/error';


class DocsAndNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            file : {},
            upload:false,
            openSignature:false,
            itemAnt: undefined
        };
    }

    toggle = () =>{
      this.setState({ 
            openModal: !this.state.openModal,
            file : {},
            upload: false,
            openSignature: false,
            itemAnt: undefined
        });
    }

    handelUploadDocument = async (state,idEr) =>{
        this.setState({ upload:true });
        let action = "insert";
        let data = new FormData();
        if(state.fileAnt){
            data.append("id", state.id);
            action = "update";
        }
        if(window.location.pathname.includes("view") && action == "insert") {
            // El usuario esta agregando un nuevo archivo en un evento ya creado
            action = "insertPostCreated"
        }
        if(state.files.length > 0){
            const exts = state.files[0].name.split('.');
            let extension = exts[exts.length-1];
            data.append("extension", extension);
            data.append("fileName",state.files[0].name);
        }
        data.append("action",action);
        data.append("note",state.nota);
        data.append("idEr",idEr);
        data.append("tipoId",state.tipoId);
        state.files.forEach((file, i) => {
            data.append(file.name, new Blob([file]));
        });
        try{
          const response = await api.post(uploadDocAndNotes, data);
          if(response.data === 'OK'){
                const note = await this.props.getERDocsAndNotesMeta({meta:this.props.meta});
                this.setState({
                    ERDocsAndNotes: note
                });
                this.toggle();
          }
        }catch(error){
          this.props.showError(error)
        }finally{
          this.setState({ upload:false });
        }
        this.setState({ upload:false });
    }

    handleAction = async (action,item)=>{
        switch (action) {
            case "download":{
              try{
                await api.download(downloadDocAndNotes(item.Id), item.FileName);
              }catch(error){
                this.props.showError(error)
              }
              break;
            }
            case "edit":{
                this.setState({ itemAnt:item, openModal:true });
                break;
            }
            case "delete":{
                this.setState({itemAnt:item,openSignature:true});
                break;
            }
            default:
                break;
        }
    }
    handleToggleSignature = () =>  this.setState({openSignature:!this.state.openSignature});
    handleDeleteFile = async (comments)  => {
        
        const data ={
            id: this.state.itemAnt.Id,
            comentario: comments
         };
      
        try{
            const response = await api.post(url_deleteDocAndNotes, data);
            console.log(response);
            if(response.statusText === 'OK'){
              const note = await this.props.getERDocsAndNotesMeta({meta:this.props.meta});
              this.setState({
                    ERDocsAndNotes: note
                });
              this.toggle();
            }
          }catch(error){
            this.props.showError(error)
          }finally{
           
          }
    }

    render() {
        const {openModal, upload, openSignature,itemAnt} = this.state;
        const {ERDocsAndNotes, } = this.props;
        return (
            <div>
                <div className="text-right m-b-sm">
                    <Button type="button" onClick={this.toggle} className="card-actions-button button-next" variant="contained" ><FontAwesomeIcon icon={faPaperclip} className="m-r-md"/>Adjuntar documento</Button>
                </div>
                {ERDocsAndNotes && <ListDocsAndNotes list={ERDocsAndNotes } handleAction={this.handleAction} />   }
                {openModal && <DocAndNoteModal itemAnt={itemAnt} handelUploadDocument={this.handelUploadDocument} upload={upload} open={openModal} toggle={this.toggle} />}
                <Signature toggle={this.handleToggleSignature} handleConfirm={this.handleDeleteFile} open={openSignature} title={'Eliminar adjunto'} message={'¿Está seguro qué quiere eliminar el adjunto'} />
            </div>
        );
    }
};

DocsAndNotes.propTypes = {
    ERDocsAndNotes: PropTypes.array,
};

const mapDispatchToProps = {
    getERDocsAndNotesMeta,
    showError,
   
}

const mapStateToProps = (state) =>({
    ERDocsAndNotes: getERDocsAndNotes(state),
});

export default  connect(mapStateToProps,mapDispatchToProps)(DocsAndNotes);

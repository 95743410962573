import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import Medicamento from './Medicamento';
import { getHomeMedicamentos } from '../../../../../redux/selectors/home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
class Step4 extends Component {
	state={
        Medicamentos: [],
	}
	
    componentDidMount =() => {
        let Medicamentos = [];
        if(this.props.initialValues && this.props.initialValues.Medicamento && this.props.initialValues.Medicamento.length>0){
            this.props.initialValues.Medicamento.forEach(r=>{
                Medicamentos.push({
                    component: Medicamento,
                    open:true,
                    allowDelete:false,
                    medicamentos:this.props.Productos
                });
            })
        }
        else{
            Medicamentos.push({
                component: Medicamento,
				open:true,
				allowDelete:false,
				medicamentos:this.props.Productos
            });
        }
        this.setState({Medicamentos});

    }
    
    handleAddMedicamento = () => {
        let { Medicamentos } = this.state;
        Medicamentos.push({
			component: Medicamento,
			open:true,
			allowDelete:false,
			medicamentos:this.props.Productos
        });
        Medicamentos = Medicamentos.reduce((acc, med) =>{
           med.allowDelete=true;
        return [...acc, med ];
        },[]);
        this.setState({Medicamentos});
    }

    handleDelMedicamento = index => {
        let { Medicamentos } = this.state;
        let { Medicamento } = this.props;
        if(Medicamento)
        {
            Medicamento = Medicamento.reduce((acc, med, i) =>{
                if(i !== index){
                    return [...acc, med ];
                }else{
                    return [...acc];
                }
            },[]);
        }
   
        Medicamentos = Medicamentos.reduce((acc, med, i) =>{
            if(i !== index){
                return [...acc, med ];
            }else{
                return [...acc];
            }
        },[]);
        if(Medicamentos.length===1){
            Medicamentos[0].allowDelete=false;
        }
        this.props.change('Medicamento',Medicamento);
        this.setState({Medicamentos});
    }

    toggleMedicamento = (index)=>{
        let { Medicamentos } = this.state;
        Medicamentos = Medicamentos.reduce((acc, med, i) =>{
            if(i === index){
                med.open = !med.open;
            }
        return [...acc, med ];
        },[]);
        this.setState({Medicamentos});
    }

	render(){
		const { Medicamentos } = this.state;
        const { isMedic } = this.props;
        return (
            <div className='form-step' >
                {Medicamentos.map((r,i) => 
                    <r.component key={i} 
                    index={i} 
                    open={r.open} 
                    toggle={this.toggleMedicamento} 
                    handleDelete={this.handleDelMedicamento}  
                    allowDelete={r.allowDelete}
                    medicamentos={r.medicamentos}
                    isMedic={isMedic}
                    />
                )}
                <div className="from-footer">
                    <Button outline  className="login-btn w100" onClick={this.handleAddMedicamento}  >
                        <FontAwesomeIcon className='m-r-sm' icon={faPlus}/> Añadir medicamento / producto sospechoso
                    </Button>
                </div>
            </div>
        );
	}
};

const isEmptyError = (error,isMedic) =>{
    let ret = true;
    let i = 0;
    while(i < error.Medicamento.length && ret){
        if(error.Medicamento[i].hasOwnProperty("Producto")){
            ret=false;
        }
        i++;
    }
    return ret;
}

const validate = (values,props) =>{
    let error ={};
    if(values.Medicamento && values.Medicamento.length > 0 ){
        values.Medicamento.forEach((r,i) => {
            if(!error.Medicamento){
                error.Medicamento = [];
            }
            error.Medicamento.push({});
            if(!r.Producto || r.Producto.length===0){
                error.Medicamento[i].Producto = "Debe ingresar un medicamento / producto sospechosoa";
            }
              
        })
    }
    if(!values.Medicamento || values.Medicamento.length === 0 ){
        error.Medicamento = [{}];
		error.Medicamento[0].Producto = "Debe ingresar un medicamento / producto sospechosoa";
	}
    
    if(isEmptyError(error,props.isMedic )){
        error ={};
    }
    return error;
}

const mapStateToProps = (state) =>({
	Medicamento: formValueSelector('HomeStep4')(state, 'Medicamento'),
	Productos: getHomeMedicamentos(state),
});

export default connect(mapStateToProps, null)(reduxForm({form: 'HomeStep4', validate})(Step4));

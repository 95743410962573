import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { iconRequired } from '../../../Fields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from "react-google-recaptcha";
import { reCaptchaSiteKey } from '../../../../environment';

const FormFooter = ({step,handleChangeStep,handleSend,isMedic, load, handleClose}) => {

    const [isValidCaptcha, setIsValidCaptcha] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);

    const onReCaptchaChange = (tokenValue) => {
        setCaptchaToken(tokenValue);
        setIsValidCaptcha(true);
    };

    const onRecaptchaInvalid = () => {
        setIsValidCaptcha(false);
    };

    const handleButtonClick = (e) => {
        handleSend(captchaToken);
    }

    return (
        
        <div className="from-footer m-t">
            <div className="d-flex justify-content-center align-items-cente">
            {step === 8 &&
            
            <div className="captcha-adjust">
                <ReCAPTCHA
                    sitekey={reCaptchaSiteKey}
                    onChange={onReCaptchaChange}
                    onExpired={onRecaptchaInvalid}
                    onErrored={onRecaptchaInvalid}
                    
                />
            </div>
                
            
            }
                <div>
                {((isMedic && step > 1 && step < 9)  ||  (!isMedic && step > 2 && step < 9)) && <Button outline disabled={load}   className="login-btn m-r" onClick={()=>handleChangeStep('back')} >Atras</Button>}
                            
                {step < 8 && <Button outline className="login-btn m-r" onClick={()=>handleChangeStep('next')} >Siguiente</Button>}

                {step === 8 && 
                <Button outline disabled={load}  className="login-btn" onClick={handleButtonClick} disabled={!isValidCaptcha} >
                    <FontAwesomeIcon className='m-r-sm' icon={load ? faSpinner : faPaperPlane} pulse={load} />
                    Enviar
                </Button>}
                
                {step < 9 && <small className='block m-t'>Los campos marcados con {iconRequired} son obligatorios</small>}
                {step === 9 && <Button outline className="login-btn" onClick={handleClose} >Cerrar</Button>}
                </div>
            </div>
        </div>
    );
};

export default FormFooter;

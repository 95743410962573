import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  FieldText,
  FieldSelect,
  iconTrash,
  FieldDatePicker
} from "../../../../Fields";
import { getCodiguera } from "../../../../../codigueras";
import { getHomeCodiguera } from "../../../../../redux/selectors/home";

const Concomitante = props => {
  const { index, codiguera, allowDelete, handleDelete, isMedic } = props;
  return (
    <div>
      <h3 className="w100 block m-b">
        {isMedic
          ? "Medicamento concomitante"
          : "Otra sustancia que se administra (Concomitante)"}
        {allowDelete && (
          <Link to="#" onClick={() => handleDelete(index)} className="m-l">
            {iconTrash("#B64C92", "#d6abc8")}
          </Link>
        )}
      </h3>
      <Row>
        <Col sm={12} md={isMedic ? 8 : 12}>
          <FieldText
            name={`Concomitante[${index}].Medicamento`}
            label="Medicamento, vitaminas, minerales, suplementos y/o remedios herbales"
            type="text"
          />
        </Col>
        <Col sm={12} md={4} hidden={!isMedic}>
          <FieldSelect
            name={`Concomitante[${index}].Tipo`}
            label="Tipo"
            items={
              codiguera &&
              getCodiguera("MedicamentosAnteriores_Tipo", codiguera)
            }
            valueField="Id"
            typeValue="number"
            textField="Nombre"
          />
        </Col>
        <Col sm={12} md={3}>
          <FieldDatePicker
            name={`Concomitante[${index}].FechaIni`}
            allowFuture={false}
            allowPast={true}
            label="Fecha inicio"
          />
        </Col>
        <Col sm={12} md={3}>
          <FieldDatePicker
            name={`Concomitante[${index}].FechaFin`}
            allowFuture={false}
            allowPast={true}
            label="Fecha finalización"
          />
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = state => ({
  codiguera: getHomeCodiguera(state)
});

export default connect(mapStateToProps, null)(Concomitante);

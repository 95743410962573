import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './LoginForm.css';
import { Input, FormGroup,FormFeedback,Badge, Button } from 'reactstrap';
import { enterPressed } from '../../helpers/utilities';
import ReCAPTCHA from "react-google-recaptcha";
import { reCaptchaSiteKey } from '../../environment';

const  _renderInputPass = (valid, handleChangeInput, keyPress) => (
    <FormGroup  className="inputWithIcon m-t-md-m">
        <div className="m-b-sm">
            <Input name="pass" invalid={!valid} placeholder="contraseña" className="m-t-n" type="password" onChange={handleChangeInput} onKeyDown={keyPress}/>
            <FormFeedback tooltip className="t-inherit">Debe ingresar una contraseña</FormFeedback>
        </div>
    </FormGroup>
);
const LockForm =({handleLogin, handleChangeInput, validPass, msgError, User}) => {

    const [isValidCaptcha, setIsValidCaptcha] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const loginRecaptchaRef = React.useRef();

    const keyPress = (e) => {
        if(enterPressed(e)){
            e.preventDefault();
            handleReLogin(e);
        }
    }

    const handleReLogin = (e) =>{
        e.preventDefault();
        handleLogin(captchaToken);
        loginRecaptchaRef.current.reset();
    }

    const onReCaptchaChange = (tokenValue) => {
        setCaptchaToken(tokenValue);
        setIsValidCaptcha(true);
    };

    const onRecaptchaInvalid = () => {
        setIsValidCaptcha(false);
    };

    const handleButtonClick = (e) => {
        handleLogin(captchaToken);
        loginRecaptchaRef.current.reset();
    }

    return (
        <div>
            <form autoComplete={'true'} className="login-form" onSubmit={null}>
                <img className="img-cim" alt="logo CIM" src={require("../AppComponents/CIM_cim.png")}/>
                <img className="img-label" alt="logo CIM" src={require("../AppComponents/CIM_label.png")}/>
                <div className="login-form-group m-t-md">  
                    <h3 className="login-title">Sesión bloqueada</h3>
                    <p className="login-title">
                       Debe ingresar su contraseña para volver a la aplicación
                    </p>
                    {
                        _renderInputPass(validPass,handleChangeInput, keyPress)
                    }

                    <div className="d-flex justify-content-center">
                        <ReCAPTCHA
                            sitekey={reCaptchaSiteKey}
                            onChange={onReCaptchaChange}
                            onExpired={onRecaptchaInvalid}
                            onErrored={onRecaptchaInvalid}
                            ref={loginRecaptchaRef}
                        />
                    </div>

                    {msgError && <div className="error"><Badge color="danger" pill>{msgError}</Badge></div>}
                    <div className='login-button m-t-lg'>
                        <Button outline  className="login-btn" onClick={handleButtonClick} disabled={!isValidCaptcha}> Desbloquear  </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

LockForm.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    handleChangeInput: PropTypes.func.isRequired,
    validPass: PropTypes.bool,
    msgError:PropTypes.string
};

export default LockForm;

import { createSelector } from "reselect";

export const getWorkFlowList = createSelector(
  state => state.workFlowList,
  r => r
);
export const getHOMEReports = createSelector(
  state => state.ReportsHome,
  r => r
);
export const getWorkFlowListInvert = createSelector(
  state => state.workFlowListInvert,
  r => r
);
export const getERFilterList = createSelector(
  state => state.ERFilter,
  r => r
);
export const getER = createSelector(
  state => state.ER,
  r => r
);
export const getERCreate = createSelector(
  state => state.ERCreate,
  r => r
);
/*DATA*/
export const getERPaciente = createSelector(
  state => state.ERPaciente,
  r => r
);
export const getEROriginReport = createSelector(
  state => state.EROriginReport,
  r => r
);
export const getERMedicamentosSosp = createSelector(
  state => state.ERMedicamentosSosp,
  r => r
);
export const getERRAMS = createSelector(
  state => state.ERRAMS,
  r => r
);
export const getERRAMSInsert = createSelector(
  state => state.ERRAMSInsert,
  r => r
);
export const getRAM = createSelector(
  (state, id) => state.ERRAM.filter(x => x.Id === id)[0] || null,
  r => r
);
export const getERDocsAndNotes = createSelector(
  state => state.ERDocsAndNotes,
  r => r
);
export const getERWhoReport = createSelector(
  state => state.ERWhoReport,
  r => r
);
export const getEROperationHistory = createSelector(
  state => state.EROperationHistory,
  r => r
);
export const getERFollowUp = createSelector(
  state => state.ERFollowUp,
  r => r
);
export const getERImputEvaluation = createSelector(
  state => state.ERImputEvaluation,
  r => r
);
export const getERContactoMedico = createSelector(
  state => state.ERContactoMedico,
  r => r
);
export const getMedicationHistory = createSelector(
  state => state.ERMedicamentosAnt,
  r => r
);
export const getERActions = createSelector(
  state => state.ERActions,
  r => r
);
export const getERFabricApproved = createSelector(
  state => state.ERFabricApproved,
  r => r
);
export const getERHomeReport = createSelector(
  state => state.ERHomeReport,
  r => r
);

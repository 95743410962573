 import { handleActions } from 'redux-actions';
import {GET_AUDIT_USERS,
    GET_AUDIT_ER,
    GET_AUDIT_CONFIG,
    GET_AUDIT_PARAMS
} from '../constants/get';

export const AuidtUsers = handleActions ({
    [GET_AUDIT_USERS]: (state, action) => action.payload,
    
},null);

export const AuidtER = handleActions ({
    [GET_AUDIT_ER]: (state, action) => action.payload,
    
},null);
export const AuidtConfig = handleActions ({
    [GET_AUDIT_CONFIG]: (state, action) => action.payload,
    
},null);

export const AuidtParams = handleActions ({
[GET_AUDIT_PARAMS]: (state, action) => action.payload,
    
},null);

import { createSelector } from "reselect";

export const getLoad = createSelector(
  state => state.Load,
  u => u
);
export const getMyUser = createSelector(
  state => state.MyUser,
  u => u
);

export const getCodigueraContext = createSelector(
  state => state.CodiguerasContext,
  u => u
);

export const getCodigueraRAM = createSelector(
  state => state.CodiguerasRAM,
  u => u
);

export const getFilterSearch = createSelector(
  state => state.FilterSearch,
  u => u
);

export const getSweetAlert = createSelector(
  state => state.SweetAlert,
  u => u
);

export const getBlocked = createSelector(
  state => state.Blocked,
  u => u
);

export const getItemMenuNavBar = createSelector(
  state => state.MyUser,
  u => (u ? u.MenuItems.filter(x => x.Menu === "NavBar") : [])
);
export const getItemMenuAdmin = createSelector(
  state => state.MyUser,
  u => (u ? u.MenuItems.filter(x => x.Menu === "Admin") : [])
);

export const getShowLegalTerms = createSelector(
  state => state.ShowLegalTerms,
  u => u
);

import React from 'react';
import Section from '../../../Section'
import { Row, Col } from 'react-flexbox-grid';
import { FieldLabel, LabelDate } from '../../../../Fields';
import FileView from '../../../../UploadFile/fileView';
import { Label } from 'reactstrap';
import { downloadContactoMedico } from '../../../../../api/urls';
import moment from "moment-timezone";

const renderBody = (contactoMed) =>{
    if(contactoMed){
      const contacto = contactoMed.data === undefined ? contactoMed.SeContacto : contactoMed.data.SeContacto;

      const profesion = contactoMed.data === undefined ? contactoMed.Profesion : contactoMed.data.Profesion;

      const fechaContacto = contactoMed.data === undefined ? contactoMed.FechaContacto : contactoMed.data.FechaContacto;

      const intervencion = contactoMed.data === undefined ? contactoMed.Intervencion : contactoMed.data.Intervencion;

      const archivo = contactoMed.Archivo;

      const nombreArchivo = contactoMed.data === undefined ? contactoMed.NombreArchivo : contactoMed.data.NombreArchivo;

      const extensionArchivo = contactoMed.data === undefined ? contactoMed.ExtensionArchivo : contactoMed.data.ExtensionArchivo;

      const erId = contactoMed.data === undefined ? contactoMed.ErId : contactoMed.data.ErId;
      
        return(
            <div>
                <Row className="noRow m-t-md">
                    <Col lg={3} md={4}><FieldLabel label={'¿Se realizó consulta médica?'} type="bool" value={contacto}/></Col>
                    <Col lg={3} md={4} hidden={!contacto}><FieldLabel label={'Profesión'} value={profesion}/></Col>
                    <Col lg={3} md={4} hidden={!contacto}><FieldLabel label={'Fecha'} value={moment(fechaContacto).format('DD/MM/YYYY')}/></Col>
                </Row>
                <Row className="noRow m-t-md" hidden={!contacto}>
                    <Col sm={12} ><FieldLabel label={'Intervención realizada'} value={intervencion}/></Col>
                </Row>
                <Row className="noRow m-b-md" hidden={!nombreArchivo}>
                    <Col sm={12}>
                        <Label>Documento adjunto</Label>
                        <FileView url={downloadContactoMedico(erId)} fileName={nombreArchivo} type={extensionArchivo} />
                    </Col>
                </Row>
            </div>
        );
    }
    else{
        return null;
    }
}

const MedicalContact = ({contactoMed, handleBtnEdit, step, title}) => {
    return (
        <Section className=""  title={title} edit={handleBtnEdit} step={step}>
            {renderBody(contactoMed)}
        </Section>
    );

};


export default MedicalContact;

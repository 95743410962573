
const regex_email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const emailValidator = (email) => regex_email.test(email);

const _equalsChartItem = (o,n) =>{
    return (o.color===n.color && o.metadata===n.metadata && o.name === n.name && o.y === n.y);
}

export const equalsChartData = (data,nexData) =>{
    let equal = data.length === nexData.length;
    if(equal){
        equal = nexData.every((c,i) => _equalsChartItem(data[i],c));
    }
    return equal;
}
export const enterPressed = event => {
    var code = event.keyCode || event.which;
    return code === 13;
}
export const esacpePressed = event => {
    var code = event.keyCode || event.which;
    return code === 27;
}
export const sortSoubGroups = SubGroups => {
    SubGroups.sort((a,b)=>{
        let ret = 0;
        if(a.GrupoId>b.GrupoId)
            ret= 1;
        else if( a.GrupoId < b.GrupoId)
            ret= -1;
        else if(a.Id>b.Id)
            ret= 1;
        else if(a.Id<b.Id)
            ret= -1;
        return ret;
    });
    return SubGroups;
}


export const isTokenExpired = (token) =>{
    const now = new Date();
    const expire = new Date(token[".expires"]);
    return now >= expire;
}

export const encodeParameters = (object) => {
  let params = [];
  for(let key in object){
      params.push(key + '=' + encodeURIComponent(object[key]));
  }
  return params.join('&');
}

export const dispatchObject = (type, data) => {
  return {
    type: type,
    payload: data
  };
}

export const compareSort = (field) => {
    return (a,b) => {
        if ( a[field] < b[field] ){
        return -1;
        }
        if ( a[field] > b[field] ){
        return 1;
        }
        return 0;
    }
}

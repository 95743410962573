import { handleActions } from "redux-actions";
import {
  GET_WORKFLOW_LIST,
  GET_ER,
  GET_ER_CREATE,
  GET_ER_PACIENTE,
  GET_ER_ORIGIN_REPORT,
  GET_ER_MEDICATIONS_SUSP,
  GET_ER_RAMS,
  GET_RAM,
  GET_ER_DOCS_AND_NOTES,
  GET_ER_WHO_REPORT,
  GET_ER_OPERATION_HISTORY,
  GET_ER_FOLLOW_UP,
  GET_ER_IMPUTABILITY_EVALUATION,
  GET_ER_FILTER,
  GET_WORKFLOW_LIST_INVERT,
  GET_ER_ACTIONS,
  GET_ER_CONTACTO_MEDICO,
  GET_ER_RAM_CATEGORY,
  GET_ER_MEDICATION_HISTORY,
  GET_ER_FABRIC_APPROVED,
  GET_HOME_ER,
  GET_WF_HOME_ER,
  GET_ER_HOME_ER
} from "../constants/get";
import { ADD_RAM_ER } from "../constants/add";
import {
  INSERT_ER_NEW,
  INSERT_ER_MEDICATION_HISTORY
} from "../constants/insert";
import {
  SET_ER_PACIENTE,
  SET_ER_ORIGIN_REPORT,
  SET_ER_RAM,
  SET_ER_RAMS,
  SET_ER,
  SET_ER_MEDICATIONS_SUSP,
  SET_WORKFLOW_LIST,
  SET_ER_ACTIONS,
  SET_ER_CONTACTO_MEDICO,
  SET_ER_RAM_CATEGORY,
  SET_ER_MEDICATION_HISTORY,
  SET_ER_FABRIC_APPROVED,
  SET_HOME_ER
} from "../constants/set";
import {
  UPD_ER_MEDICATIONS_SUSP,
  UPD_ER_IMPUTABILITY_EVALUATION,
  UPD_ER_RAM,
  UPD_ER_FABRIC_APPROVED
} from "../constants/update";
import {
  DEL_ER_MEDICATIONS_SUSP,
  DEL_ER_RAM_CATEGORY,
  DEL_ER_WORK_FLOW,
  DEL_ER_MEDICATION_HISTORY
} from "../constants/delete";

export const ER = handleActions(
  {
    [GET_ER]: (state, action) => action.payload,
    [GET_ER_CREATE]: (state, action) => action.payload,
    [INSERT_ER_NEW]: (state, action) => action.payload,
    [SET_ER]: (state, action) => action.payload
  },
  null
);

export const ERPaciente = handleActions(
  {
    [GET_ER_PACIENTE]: (state, action) => action.payload,
    [SET_ER_PACIENTE]: (state, action) => action.payload
  },
  null
);

export const EROriginReport = handleActions(
  {
    [GET_ER_ORIGIN_REPORT]: (state, action) => action.payload,
    [SET_ER_ORIGIN_REPORT]: (state, action) => action.payload
  },
  null
);

export const ERMedicamentosSosp = handleActions(
  {
    [GET_ER_MEDICATIONS_SUSP]: (state, action) => action.payload,
    [SET_ER_MEDICATIONS_SUSP]: (state, action) => {
      const add = action.payload;
      const res = state.reduce((acc, mr) => {
        return [...acc, mr];
      }, []);
      res.push(add);
      return res;
    },
    [UPD_ER_MEDICATIONS_SUSP]: (state, action) => {
      const edit = action.payload;
      const res = state.reduce((acc, mr) => {
        if (mr.Id === edit.Id) {
          return [...acc, edit];
        } else {
          return [...acc, mr];
        }
      }, []);
      return res;
    },
    [DEL_ER_MEDICATIONS_SUSP]: (state, action) => {
      const res = state.reduce((acc, mr) => {
        if (mr.Id !== action.payload) {
          return [...acc, mr];
        } else {
          return [...acc];
        }
      }, []);
      return res;
    }
  },
  null
);

export const ERRAMS = handleActions(
  {
    [GET_ER_RAMS]: (state, action) => action.payload,
    [SET_ER_RAMS]: (state, action) => action.payload,
    [SET_ER_RAM]: (state, action) => [...state, action.payload],
    [UPD_ER_RAM]: (state, action) => {
      const edit = action.payload;
      const res = state.reduce((acc, mr) => {
        if (mr.Id === edit.Id) {
          return [...acc, edit];
        } else {
          return [...acc, mr];
        }
      }, []);
      return res;
    },
    [GET_ER_RAM_CATEGORY]: (state, action) => {
      const { idRAM, CategoryRAM } = action.payload;
      const res = state.reduce((acc, mr) => {
        if (mr.Id === idRAM) {
          mr.CategoryRAM = CategoryRAM;
        }
        return [...acc, mr];
      }, []);
      return res;
    },
    [SET_ER_RAM_CATEGORY]: (state, action) => {
      const add = action.payload;
      const res = state.reduce((acc, mr) => {
        if (mr.Id === add.RAMId) {
          mr.CategoryRAM = [...mr.CategoryRAM, add];
        }
        return [...acc, mr];
      }, []);
      return res;
    },
    [DEL_ER_RAM_CATEGORY]: (state, action) => {
      const del = action.payload;
      const res = state.reduce((acc, mr) => {
        if (mr.Id === del.RAMId) {
          const types = mr.CategoryRAM.reduce((acc, ram) => {
            if (ram.RAMCategoryId !== del.RAMCategoryId) {
              return [...acc, ram];
            } else {
              return [...acc];
            }
          }, []);
          mr.CategoryRAM = types;
        }
        return [...acc, mr];
      }, []);
      return res;
    }
  },
  null
);

export const ERRAMSInsert = handleActions(
  {
    [ADD_RAM_ER]: (state, action) => [...state, action.payload]
  },
  []
);

export const ERRAM = handleActions(
  {
    [GET_RAM]: (state, action) => action.payload
  },
  null
);

export const ERWhoReport = handleActions(
  {
    [GET_ER_WHO_REPORT]: (state, action) => action.payload
  },
  null
);

export const workFlowList = handleActions(
  {
    [GET_WORKFLOW_LIST]: (state, action) => action.payload,
    [SET_WORKFLOW_LIST]: (state, action) => action.payload,
    [DEL_ER_WORK_FLOW]: (state, action) => {
      const del = action.payload;
      let ret = state;
      ret.Result = state.Result.reduce((acc, wf) => {
        if (wf.Meta !== del.Meta) {
          return [...acc, wf];
        } else {
          return acc;
        }
      }, []);
      return ret;
    }
  },
  null
);

export const workFlowListInvert = handleActions(
  {
    [GET_WORKFLOW_LIST_INVERT]: (state, action) => action.payload
  },
  {}
);

export const ERFilter = handleActions(
  {
    [GET_ER_FILTER]: (state, action) => action.payload
  },
  null
);

export const ERDocsAndNotes = handleActions(
  {
    [GET_ER_DOCS_AND_NOTES]: (state, action) => action.payload
  },
  []
);

export const EROperationHistory = handleActions(
  {
    [GET_ER_OPERATION_HISTORY]: (state, action) => action.payload
  },
  null
);

export const ERFollowUp = handleActions(
  {
    [GET_ER_FOLLOW_UP]: (state, action) => action.payload
  },
  []
);

export const ERImputEvaluation = handleActions(
  {
    [GET_ER_IMPUTABILITY_EVALUATION]: (state, action) => action.payload,
    [UPD_ER_IMPUTABILITY_EVALUATION]: (state, action) => {
      const edit = action.payload;
      const res = state.reduce((acc, mr) => {
        if (mr.RAMId === edit.RAMId) {
          return [...acc, edit];
        } else {
          return [...acc, mr];
        }
      }, []);
      return res;
    }
  },
  null
);

export const ERActions = handleActions(
  {
    [GET_ER_ACTIONS]: (state, action) => action.payload,
    [SET_ER_ACTIONS]: (state, action) => action.payload
  },
  null
);

export const ERContactoMedico = handleActions(
  {
    [GET_ER_CONTACTO_MEDICO]: (state, action) => action.payload,
    [SET_ER_CONTACTO_MEDICO]: (state, action) => action.payload
  },
  null
);

export const ERMedicamentosAnt = handleActions(
  {
    [GET_ER_MEDICATION_HISTORY]: (state, action) => action.payload,
    [SET_ER_MEDICATION_HISTORY]: (state, action) => action.payload,
    [INSERT_ER_MEDICATION_HISTORY]: (state, action) => {
      const res = [...state, action.payload];
      return res;
    },

    [DEL_ER_MEDICATION_HISTORY]: (state, action) => {
      const indexDel = action.payload;
      const res = state.reduce((acc, mr, idx) => {
        if (indexDel !== idx) {
          return [...acc, mr];
        } else {
          return [...acc];
        }
      }, []);
      return res;
    }
  },
  []
);

export const ERFabricApproved = handleActions(
  {
    [GET_ER_FABRIC_APPROVED]: (state, action) => action.payload,
    [SET_ER_FABRIC_APPROVED]: (state, action) => action.payload,
    [UPD_ER_FABRIC_APPROVED]: (state, action) => action.payload
  },
  []
);

export const ReportsHome = handleActions(
  {
    [GET_HOME_ER]: (state, action) => action.payload,
    [SET_HOME_ER]: (state, action) => action.payload,
    [GET_WF_HOME_ER]: (state, action) => action.payload
  },
  null
);

export const ERHomeReport = handleActions(
  {
    [GET_ER_HOME_ER]: (state, action) => action.payload
  },
  null
);

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Section from "../../../Section";
import { Row, Col } from "react-flexbox-grid";
import { FieldLabel } from "../../../../Fields";
import { getCodigueraContext } from "../../../../../redux/selectors/Context";
import { getElementCodiguera } from "../../../../../codigueras";
import FileView from "../../../../UploadFile/fileView";
import { Label } from "reactstrap";
import { downloadOriginReport } from "../../../../../api/urls";

const renderBody = (OriginReport, codiguera) => {
  if (OriginReport) {
    return (
      <div>
        <Row className="noRow m-t-md">
          <Col lg={3} md={4}>
            <FieldLabel
              label={"¿Posee origen del reporte?"}
              type="bool"
              value={OriginReport.Have}
            />
          </Col>
          <Col lg={9} md={8} hidden={OriginReport.Have}>
            <FieldLabel
              label={"Motivo por el cual no posee origen del reporte?"}
              value={OriginReport.NotHave}
            />
          </Col>
          <Col lg={3} md={4} hidden={!OriginReport.Have}>
            <FieldLabel
              label={"Origen"}
              value={
                OriginReport.OrigenId &&
                codiguera &&
                getElementCodiguera(
                  "origenesReportes",
                  OriginReport.OrigenId,
                  codiguera
                )
              }
            />
          </Col>
          <Col lg={3} md={4} hidden={!OriginReport.Have}>
            <FieldLabel
              label={"Receptor"}
              value={
                OriginReport.ReceptorId &&
                codiguera &&
                getElementCodiguera(
                  "receptoresReportes",
                  OriginReport.ReceptorId,
                  codiguera
                )
              }
            />
          </Col>
          <Col lg={3} md={4} hidden={!OriginReport.Have}>
            <FieldLabel
              label={"Tipo de origen"}
              value={
                OriginReport.TipoId &&
                codiguera &&
                getElementCodiguera(
                  "tiposOrigenesReportes",
                  OriginReport.TipoId,
                  codiguera
                )
              }
            />
          </Col>
          <Col lg={3} md={4} hidden={!OriginReport.Have}>
            <FieldLabel
              label={"¿Proviene de Literatura?"}
              type="bool"
              value={OriginReport.ProvieneDeLiteratura}
            />
          </Col>
          {OriginReport.ProvieneDeLiteratura && (
            <Col lg={6} md={8} hidden={!OriginReport.Have}>
              <FieldLabel
                label={"Referencia biliográfica"}
                value={OriginReport.ReferenciaBilbliografica}
              />
            </Col>
          )}
          <Col lg={3} md={4} hidden={!OriginReport.Have}>
            <FieldLabel
              label={"País del estudio"}
              value={
                OriginReport.Country &&
                codiguera &&
                getElementCodiguera(
                  "paisOrigenes",
                  OriginReport.Country,
                  codiguera
                )
              }
            />
          </Col>
          {OriginReport.TipoId === 4 && (
            <Col lg={6} md={8} hidden={!OriginReport.Have}>
              <FieldLabel
                label={"Nombre del estudio"}
                value={OriginReport.NombreEstudio}
              />
            </Col>
          )}
          <Col
            lg={OriginReport.TipoId === 4 ? 3 : 6}
            md={OriginReport.TipoId === 4 ? 6 : 8}
            hidden={!OriginReport.Have}
          >
            <FieldLabel value={OriginReport.TipoEstudio} label="Tipo estudio" />
          </Col>
          <Col lg={3} md={6} hidden={!OriginReport.Have}>
            <FieldLabel
              label={"Número de registro de estudio"}
              value={OriginReport.NumeroRegistroEstudio}
            />
          </Col>
          <Col lg={3} md={6} hidden={!OriginReport.Have}>
            <FieldLabel
              label={"Número de sponsor"}
              value={OriginReport.NumeroSponsor}
            />
          </Col>
        </Row>
        <Row className="noRow m-b-md" hidden={!OriginReport.Have}>
          <Col sm={12}>
            <Label>Archivo</Label>
            <FileView
              url={downloadOriginReport(OriginReport.IdER)}
              fileName={OriginReport.FileName}
              type={OriginReport.Extension}
            />
          </Col>
        </Row>
      </div>
    );
  } else {
    return null;
  }
};

const OriginReportDetail = props => {
  const { OriginReport, handleBtnEdit, step, codiguera, title } = props;
  return (
    <Section className="" title={title} edit={handleBtnEdit} step={step}>
      {renderBody(OriginReport, codiguera)}
    </Section>
  );
};

OriginReportDetail.propTypes = {
  OriginReport: PropTypes.object
};

const mapStateToProps = state => ({
  codiguera: getCodigueraContext(state)
});

export default connect(mapStateToProps, null)(OriginReportDetail);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form'; 
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import UploadFile from '../UploadFile';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FieldText, FieldSelect } from '../Fields';
import { getER } from '../../redux/selectors/ER';
import { getCodigueraContext } from '../../redux/selectors/Context';
import { getCodiguera } from '../../codigueras';
class DocAndNoteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:undefined,
            files:[],
            disabled:true,
            nota:'',
            tipoId: null,
            fileAnt:undefined
        };
    }

    dropzone = {};

    handleChange = (e, results) => {
       this.setState({files:results,disabled: false});
    }
    
    handleAddFile = (files) => this.setState({files,disabled:false});

    handleOnChangeNote = (e) =>  this.setState({nota:e.target.value});
    
    handleOnChangeType = (e) => {
       this.setState({tipoId: e.target.value})
    }
   
    componentDidMount() {
        const {itemAnt} = this.props;
        if(itemAnt){
            this.setState({
                id: itemAnt.Id
                ,fileAnt: [{
                    name: itemAnt.FileName,
                    type: itemAnt.Extension,
                }]
                ,disabled:false
                ,nota:itemAnt.Note
                ,tipoId: itemAnt.TipoId
            });
            this.props.change("Nota",itemAnt.Note);
            this.props.change("TipoId",itemAnt.TipoId);
            
        }
       
    }

    render(){
        const {open, toggle, handelUploadDocument, idER, upload, codigueras } = this.props;
        const { disabled, nota, fileAnt  } = this.state;
        return (
            <Modal backdrop={'static'} fade={false} isOpen={open} toggle={() => toggle()}>
            <ModalHeader  toggle={() => toggle()} >Adjuntar documento</ModalHeader>
            <ModalBody>
                <FieldSelect disabled={upload} label="Tipo de documento" items={codigueras && getCodiguera('NotasArchivosTipo',codigueras)} name="TipoId" textField="Nombre" valueField="Id" typeValue="number" onChange={this.handleOnChangeType} />
                <FieldText disabled={upload} withFocus={open} value={nota} onChange={this.handleOnChangeNote} type="textarea" label="Descripción" name="Nota" tooltip={true}  maxLength={2000}/>
                <UploadFile fileAnts={fileAnt} disabled={upload} label={"Documento"} handleAddFile={this.handleAddFile} multiple={false} />
            </ModalBody>
            <ModalFooter>
                    <Button disabled={upload} type="button" className="card-actions-button button-back" onClick={() => toggle()} variant="contained">Cancelar</Button>
                    <Button disabled={disabled || upload} type="button" className="card-actions-button button-next" onClick={() => handelUploadDocument(this.state,idER)} variant="contained">
                     {fileAnt ? 'Guardar' : 'Adjuntar'}   {upload && <FontAwesomeIcon size="lg" icon={faSpinner} pulse={true}/>}
                    </Button>
            </ModalFooter>
            </Modal>
        );
    }
};

DocAndNoteModal.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    idER: PropTypes.number,
};

const validate = values =>{
    const error ={};
    if(!values.Nota)
        error.Nota="La nota es requerida";
    
    return error;
}


const mapStateToProps = state => ({
    idER: getER(state).Id,
    codigueras: getCodigueraContext(state)
})

export default connect(mapStateToProps, null)(reduxForm({form: 'DocAndNoteModal',validate})(DocAndNoteModal));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import api from '../../api';
import { url_getmeddraimportcode } from '../../api/urls';
import { setMedDRAImports,setMedDRAImportsCode, setCleanMedDRAImportsCode } from '../../redux/actions/medDRA';
import { getImportsMedDRA } from '../../redux/selectors/MedDRA';
import ListMedDRAImports from '../ListComponents/List/ListMedDRAImports';
import { showError } from '../../redux/actions/error';

class MedDRAComponent extends Component {

    state = {
        imports:[],
        load:false,
    }


    _handleGetImportCode = async (item, show) =>{
        if(!show){
          try{
            this.setLoad({ load: true });
            const response = await api.get(url_getmeddraimportcode(item.code_import));
            this.props.setMedDRAImportsCode(response.data);
          }catch(error){
            this.props.showError(error);
          }finally{
            this.setState({ load: false });
          }
        }else{
            this.props.setCleanMedDRAImportsCode(item.code_import);
            this.setState({load:false});
        }
    }

    toggle=()=> this.setState({showForm:!this.state.showForm});

    render() {
        const {load} = this.state;
        const { imports } = this.props;
        return (
            <div className='m-r m-l m-t'>
                <ListMedDRAImports list={imports} loadList={load} handleAction={this._handleGetImportCode}/>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setMedDRAImports,
    setMedDRAImportsCode,
    setCleanMedDRAImportsCode,
    showError
}

const mapStateToProps = (state)=>({
    imports: getImportsMedDRA(state),
});

const validate = values =>{
    const error ={};

    return error;
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'MedDRA', validate})(MedDRAComponent));

import React from "react";
import CardContent from "@material-ui/core/CardContent";

const AppContainer = ({ title, className, children, disabled }) => {
  return (
    <div className="card border-lr-n border-b-n">
      <div className={`sectionTitle ${disabled ? "disabled" : ""}`}>
        <span className="appTitle">{title}</span>
      </div>
      <CardContent className={`p-r-n p-l-n ${className}`}>
        {children}
      </CardContent>
    </div>
  );
};

export default AppContainer;

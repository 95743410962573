import React from 'react';
import PropTypes from 'prop-types';
import ListComponent from '..';
import ListItemIE from '../Item/ListItemIE';

const ListImputability = ({list,handleAction ,View }) => {
    const titles = ['Descripción RAM','Total','Estado','Modificado','']; 
    
    return (
           <ListComponent titles={titles} list={list} View={View} handleAction={handleAction} ListComponent={ListItemIE} empty="rams" />
    );

};

ListImputability.propTypes = {
    list: PropTypes.array,
    handleAction: PropTypes.func,
    View: PropTypes.string,
};

export default ListImputability;
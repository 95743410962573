import { createAction } from 'redux-actions';
import { GET_AUDIT_USERS, GET_AUDIT_ER, GET_AUDIT_CONFIG, GET_AUDIT_PARAMS } from '../constants/get';
import { url_getAuditUsers } from '../../api/urls';
import api from '../../api';

export const getAuditUsers = createAction(GET_AUDIT_USERS, (dateF, dataT, oderd, page, size) => api.get(url_getAuditUsers(dateF, dataT, oderd, page, size)));
export const setAuditUsers = createAction(GET_AUDIT_USERS, (items) => items);
export const setAuditER = createAction(GET_AUDIT_ER, (items) => items);
export const setAuditConfig = createAction(GET_AUDIT_CONFIG, (items) => items);
export const setAuditParams = createAction(GET_AUDIT_PARAMS, (items) => items);

import React from 'react';
import PropTypes from 'prop-types';
import {FieldLabel} from '../../../../Fields';
import Section from '../../../Section'
import { Row, Col } from 'react-flexbox-grid';

const renderBody = ER =>(
    <div>
         <Row className="noRow m-t-md">
            <Col md={12}>
                <FieldLabel label={'Indique otra información importante acerca del paciente (Por ejemplo: si es fumador, si consume alcohol, etc). De ser posible añadir historia clínica, pruebas de laboratorio u otro que considere de interés.'} 
                value={ER.AdditionalInformation}/>
            </Col>
        </Row> 
    </div>
)
const RelevantInformationDetail = ({ER,handleBtnEdit, step,title}) => {
    return (
        <Section  title={title} edit={handleBtnEdit} step={step}>
            {ER && renderBody(ER)}
        </Section>
    );
};

RelevantInformationDetail.propTypes = {
    ER: PropTypes.object,
};

export default RelevantInformationDetail;
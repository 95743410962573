import React from 'react';
import PropTypes from 'prop-types';
import ListItemWF from '../Item/ListItemWF';
import ListComponent from '..';

const titles = (search) => {
    let titles = [];
    if(!search)
        titles = ['','Identificador','Estado','Tipo RAM', 'Actualizado',''];
    else
        titles = ['','Identificador','País','Compañía','Fabricante','Producto','Estado','Actualizado','', 'Es historico'];
    return titles;
} 

const ListWorkFlow = ({loadList, load,list,handleAction, search,mini,navbar}) => {
    return (
        <ListComponent mini={mini} delItem navbar={navbar} titles={titles(search)} loadList={loadList} load={load} search={search} 
        list={list} handleAction={handleAction} ListComponent={ListItemWF} empty="reportes"></ListComponent>
    );
};

ListWorkFlow.propTypes = {
    list: PropTypes.array,
};

export default ListWorkFlow;
import React from 'react';
import * as microsoftTeams from "@microsoft/teams-js";


microsoftTeams.initialize();
microsoftTeams.settings.registerOnSaveHandler(function(saveEvent){

    var radios = document.getElementsByName("maptype");
    if (radios[0].checked) {
       microsoftTeams.settings.setSettings({
         entityId: "bing",
         contentUrl: "https://www.bing.com/maps/embed",
         suggestedDisplayName: "Bing Map",
         websiteUrl: "https://www.bing.com/maps",
         removeUrl: "https://teams-get-started-sample.azurewebsites.net/tabremove.html",
      });
    } else {
       microsoftTeams.settings.setSettings({
         entityId: "google",
         contentUrl: "https://www.google.com/maps/embed",
         suggestedDisplayName: "Google Map",
         websiteUrl: "https://www.google.com/maps",
         removeUrl: "https://teams-get-started-sample.azurewebsites.net/tabremove.html",
      });
    }

    saveEvent.notifySuccess();
});

const TeamsConfig = () => {
  return (
      <form>
        <input type="radio" name="maptype" value="bing" onClickick={microsoftTeams.settings.setValidityState(true)}/> Bing Maps<br/>
        <input type="radio" name="maptype" value="google" onClick={microsoftTeams.settings.setValidityState(true)}/> Google Maps
      </form>
  );
};

export default TeamsConfig;

import React from 'react';
import ListItemERHome from '../Item/ListItemERHome';
import ListComponent from '..';

const titles = (search) => {
    let titles = [];
    if(!search)
        titles = ['','Identificador','Estado','Tipo RAM', 'Actualizado',''];
    else
        titles = ['','Identificador','País','Compañía','Laboratorio','Producto','Estado','Actualizado',''];
    return titles;
} 

const ListERHome = ({loadList, load,list,handleAction, search,mini,navbar}) => {
    return (
        <ListComponent mini={mini} delItem navbar={navbar} titles={titles(search)} loadList={loadList} load={load} search={search} list={list} handleAction={handleAction} ListComponent={ListItemERHome} empty="reportes"></ListComponent>
    );
};

export default ListERHome;
import React from 'react';
import ListComponent from '..';
import ListItemAuditDetail from '../Item/ListItemAuditDetail';

const titles = (log) => {

    switch(log.OperationType){
        case 'I':{
            return ['Campo', 'Valor inicial'];
        }
        default:{
            return ['Campo', 'Valor anterior', 'Valor nuevo'];
        }
    }
}
const ListAuditDetail = ({log,list,load,handleAction}) => {

    return (
        <ListComponent mini={false} handleAction={handleAction} load={load} parent={log}  empty={'registros'} titles={titles(log)} list={list} ListComponent={ListItemAuditDetail}  />
    );
};

export default ListAuditDetail;
import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { Col, Row } from "react-flexbox-grid";
import { questionYesNO } from "../../../../../constants/steps";
import {
  FieldText,
  FieldRadioGroup,
  FieldSelect,
  FieldDatePicker
} from "../../../../Fields";
import { getCodiguera } from "../../../../../codigueras";
import { getHomeCodiguera } from "../../../../../redux/selectors/home";
import { compareSort } from './../../../../../helpers/utilities';

const Step2 = props => {
  const { handleChangePais, sexo, embarazo, isMedic, codiguera } = props;
  
  return (
    <div className="form-step" id="paso-2">
      <h3>Información sobre el paciente</h3>
      <Row>
        {!isMedic && (
          <Col sm={12} md={6}>
            <FieldSelect
              name="Pais"
              isRequired={true}
              label="Seleccione país de residencia"
              onChange={handleChangePais}
              items={codiguera && getCodiguera("Paises", codiguera).sort(compareSort('Nombre'))}
              valueField="Codigo"
              textField="Nombre"
            />
          </Col>
        )}
        <Col md={6} sm={12}>
          <FieldText
            name="Iniciales"
            label="Iniciales del paciente"
            isRequired={true}
            type="text"
            maxLength={3}
          />
        </Col>
        <Col md={6} sm={12}>
          <FieldRadioGroup
            name="Sexo"
            isRequired={true}
            labelBlock={true}
            inline={true}
            items={[
              { value: "M", text: "Hombre" },
              { value: "F", text: "Mujer" }
            ]}
            label="Sexo"
            textField="text"
            valueField="value"
          />
        </Col>
        <Col md={6} sm={12}>
          <FieldDatePicker
            name="FechaNacimiento"
            allowFuture={false}
            allowPast={true}
            label="Fecha de nacimiento"
          />
        </Col>
        <Col md={3} sm={12}>
          <FieldText
            name="Peso"
            label="Peso Kg."
            type={"number"}
            step=".10"
            min={0}
          />
        </Col>
        <Col md={3} sm={12}>
          <FieldText
            name="Altura"
            label="Altura Mts."
            type={"number"}
            step=".10"
            min={0}
          />
        </Col>
        <Col md={6} sm={12} hidden={sexo !== "F"}>
          <FieldSelect
            name="Embarazo"
            label="¿Se encuentra embarazada?"
            items={questionYesNO}
            textField="label"
            valueField="value"
          />
        </Col>
        <Col
          md={6}
          sm={12}
          hidden={sexo !== "F" || (sexo === "F" && embarazo === "NO")}
        >
          <FieldText
            name="Gestacion"
            label="Semanas de gestación"
            type={"number"}
            step="1"
            min={0}
            max={40}
          />
        </Col>
        <Col
          md={6}
          sm={12}
          hidden={sexo !== "F" || (sexo === "F" && embarazo === "SI")}
        >
          <FieldSelect
            name="Lactancia"
            label="¿Se encuentra en período de lactancia?"
            items={questionYesNO}
            textField="label"
            valueField="value"
          />
        </Col>
        <Col md={6} sm={12} hidden={isMedic}>
          <FieldText
            name="CentroMedico"
            label="Centro medico"
            type="text"
          />
        </Col>
      </Row>
    </div>
  );
};

const validate = (values, props) => {
  const error = {};
  if (!values.Iniciales) {
    error.Iniciales = "Debe ingresar las iniciales del paciente";
  }
  if (!values.Sexo) {
    error.Sexo = "Debe ingresar el sexo del paciente";
  }
  if (!props.isMedic && !values.Pais) {
    error.Pais = "Debe seleccionar un país de residencia";
  }
  return error;
};
const mapStateToProps = state => ({
  sexo: formValueSelector("HomeStep2")(state, "Sexo"),
  embarazo: formValueSelector("HomeStep2")(state, "Embarazo"),
  codiguera: getHomeCodiguera(state)
});
export default connect(
  mapStateToProps,
  null
)(reduxForm({ form: "HomeStep2", validate })(Step2));

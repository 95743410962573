import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setMyUser,
    setCodiguerasContext,
    setLoad,
    getFilterSearch} from '../redux/actions/context';
import { showError } from '../redux/actions/error';
import { NavLink, withRouter } from 'react-router-dom';
import LoginForm from '../components/LoginComponents/LoginForm';
import { regexEmail } from '../constants/regex';
import { url_userresetpassword } from '../api/urls';
import api from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class LoginContainer extends Component {

    state = {
        email:'',
        pass:'',
        reset:'',
        load:false,
        validEmail: true,
        validPass: true,
        msgValidEmail:'',
        msgError:'',
        msgReset:'',
        show:'login',
    };

    componentWillMount=()=> {
       this.props.showAppFrame(this.props.location.pathname);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
         if(nextState.email !== this.state.email){
            return true;
        }
        if(nextState.pass !== this.state.pass){
            return true;
        }
        if(nextState.reset !== this.state.reset){
            return true;
        }
        if(nextState.load !== this.state.load){
            return true;
        }
        if(nextState.validEmail !== this.state.validEmail){
            return true;
        }
        if(nextState.validPass !== this.state.validPass){
            return true;
        }
        if(nextState.msgValidEmail !== this.state.msgValidEmail){
            return true;
        }
        if(nextState.msgError !== this.state.msgError){
            return true;
        }
        if(nextState.msgReset !== this.state.msgReset){
            return true;
        }
        if(nextState.show !== this.state.show){
            return true;
        }
        return false;
    }

    _handleChangeInput = (e) => {
        const {name, value } = e.target
        this.setState({
            [name]:value
        });
    }

    _handleValid = () =>{
        let validEmail = true;
        let validPass = true;
        let msgValidEmail= "";

        if(!this.state.email){
            validEmail=false;
            msgValidEmail= "Debe ingresar un correo electrónico";
        }
        else if(!regexEmail.test(this.state.email)){
            validEmail=false;
            msgValidEmail= "El formato no es correcto";
        }

        if(!this.state.pass){
            validPass=false;
        }
        this.setState({validEmail,validPass,msgValidEmail});
        return validEmail && validPass;
    }

    _handlelogin = async (captchaToken) =>{
      try{
        if(captchaToken === null)
        {
            this.setState({msgError: 'Debe marcar la casilla  --No soy un robot-- '});
            return;
        }
        if(this._handleValid()){
            this.props.setLoad(true);
            const {email, pass} = this.state;
            const response = await api.logIn(email, pass,false, captchaToken);
            if(response.status !== 200)
                this.setState({msgError: 'Usuario y contraseña incorrectos'});
            else{
              this.props.getFilterSearch();
            }
            this.props.setLoad(false);
        }
      }catch (error){
          this.props.setLoad(false);
          this.setState({msgError: 'Usuario y contraseña incorrectos'});
      }
    }

    _handleResetPassword = (reCaptchaToken) => {
        this.props.setLoad(true);
        const config = {
            headers: {
              'recaptcha-token': reCaptchaToken
            }
          };
        api.post(url_userresetpassword,{email:this.state.reset}, config).then(r=>{
            this.setState({
                show:"login",
            });
            this.props.setLoad(false);

        }).catch(e=>{
            this.props.setLoad(false);
            this.props.showError(e);
        })
    }

    _handleToggle =()=>{
        this.setState({
            show:this.state.show==="login"?"reset":"login",
        });
    }

    render() {
        const {validEmail, msgValidEmail, validPass,msgError,msgReset,show} = this.state;
        return (
            <div className="background-login">
                <NavLink to="/" className="login-close"><FontAwesomeIcon icon={faTimes}/></NavLink>
                <div className="login-form">
                    <LoginForm
                        handleLogin={this._handlelogin}
                        handleChangeInput={this._handleChangeInput}
                        validEmail={validEmail}
                        validPass={validPass}
                        msgValidEmail={msgValidEmail}
                        msgError={msgError}
                        handleResetPassword={this._handleResetPassword}
                        msgReset={msgReset}
                        handleToggle={this._handleToggle}
                        show={show}
                        />

                </div>

            </div>
        );
    }
}

LoginContainer.propTypes = {
    email: PropTypes.string,
    pass: PropTypes.string,
};

const mapDispatchToProps = {
    setMyUser,
    setCodiguerasContext,
    setLoad,
    getFilterSearch,
    showError
}

export default withRouter(connect(null, mapDispatchToProps)(LoginContainer));

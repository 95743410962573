import { SwitchComponent } from '../../Fields';
import api from "../../../api";
import { url_getMetricsTime } from '../../../api/urls';
import { Select, MenuItem } from '@material-ui/core';
import React, { Component } from 'react';
import { url_updateTime } from '../../../api/url_metricas';

class ListItemMetric extends Component {

    constructor(props) {
        super(props);

        this.state = {
         tiempo: [],
         firstselect: null
        };
      }

    loadMetrics = async () =>{
      const response = await api.get(url_getMetricsTime);
      this.setState({tiempo: response.data})
    }

    componentDidMount = () => {
      this.loadMetrics();
    }

    handleChange = async (x) => {
        this.setState({firstselect:x.target.value})
        const data = {
          id: x.target.name,
          tiempo: x.target.value
        };
        await api.post(url_updateTime,data);

    }

    Input = (Item) => ({
                value: Item.Activo,
                onChange: () => {
                    Item.Activo = !Item.Activo;
                    this.props.handleAction(Item.Activo, Item);
                }
            });

    render() {
     const tiempo = this.state.tiempo;

        return (
          <tr className={`list-item ${this.props.classProp}`}>
            <td className="p-md">{this.props.Item.Nombre}</td>
            <td className="p-md">{this.props.Item.Siglas}</td>
            <td>
              <Select labelid="label" name={this.props.Item.MetricaId + ""} value={this.state.firstselect === null ? this.props.Item.TiempoId : this.state.firstselect} onChange={this.handleChange}>
                  {
                    tiempo.map((x,i) => {
                        return <MenuItem key={i} value={x.Id} name={x.Nombre}>{x.Nombre}</MenuItem>
                    })
                  }
                </Select>
            </td>
            <td className="p-md text-center"><SwitchComponent input={this.Input(this.props.Item)} icons={null} /></td>
        </tr>
        );
    }
}

export default ListItemMetric;

import React from 'react';
import PropTypes from 'prop-types';
import ListActions from './ListActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { LabelDate } from '../../Fields';

const actions = (handleAction,item) => {
    let actions = [<Link to={'#'} onClick={() =>  handleAction("download",item)} ><FontAwesomeIcon icon={faCloudDownloadAlt} size={'lg'}/></Link>,
                <Link to={'#'} onClick={() =>  handleAction("edit", item)}><FontAwesomeIcon icon={faEdit} size={'lg'}/></Link>,
                <Link to={'#'} onClick={() =>  handleAction("delete",item)} ><FontAwesomeIcon icon={faTrashAlt} size={'lg'}/></Link>];
    return actions;
};

const LIstItemDN = ({Item, classProp,View, handleAction}) => {
    return (
        <tr className={`list-item ${classProp}`}>
            <td>{Item.FileName}</td>
            <td>{Item.TipoNombre}</td>
            <td title={Item.Note} className="text-limit-100">{Item.Note}</td>
            <td>{Item.UserFullName}</td>
            <td>{LabelDate(Item.Date)}</td>
            <td className="p-t-sm"><ListActions  actions={actions(handleAction,Item)}/></td>
        </tr>
    );
};

LIstItemDN.propTypes = {
     Item: PropTypes.object,
};


export default LIstItemDN;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ERSearchComponent from '../components/EReportComponents/Search';
import AppContainer from '../components/AppComponents/AppContainer';
import { getFilterSearch, getCodiguerasContext } from '../redux/actions/context';
import { getCodigueraContext, getFilterSearch  as FilterSearch } from '../redux/selectors/Context';
import setPropsAsInitial from '../helpers/setPropsAsInitial';
import { withRouter }  from 'react-router-dom';
import { store } from '../redux/store'
import { CardActions } from "@material-ui/core";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Form,
    Label,
    FormGroup,
    FormFeedback,
    Badge
  } from "reactstrap";
import {
    faArrowLeft
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import Button from "@material-ui/core/Button";

class ERSearchContainer extends Component {
    componentWillMount = () =>{
        this.props.showAppFrame(this.props.location.pathname);
    }
    
    componentDidMount = () => {
        if(this.props.codiguera===null)
            store.dispatch(getCodiguerasContext());
        if(this.props.filters===null)
            store.dispatch(getFilterSearch());
    }

    render() {
        return (
            <AppContainer title="Ver eventos reportables" menuSelected="eventsearch">
                <ERSearchComponent newFilter={this.props.newFilter} />
                <ModalFooter className="background-f4f4f4 pull-left">
                <div>
                    <Button
                        type="button"
                        disabled={false}
                        className="card-actions-button button-next"
                        variant="contained"
                        onClick={() => this.props.history.goBack()}
                        >
                        <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                    </Button>
                </div>
                </ModalFooter>
            </AppContainer> 
        );
    }
}

ERSearchContainer.propTypes = {
    codiguera: PropTypes.object,
    codigueraRAM: PropTypes.object,
    filters: PropTypes.object,
    getFilterSearch: PropTypes.func,
    getCodiguerasContext: PropTypes.func,
};

const mapDispatchToProps= {
    getFilterSearch,
    getCodiguerasContext, 
}
const mapStateToProps = state => ({
    codiguera: getCodigueraContext(state),
    filters: FilterSearch(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)((setPropsAsInitial)(ERSearchContainer)));
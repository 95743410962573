import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const HomeModal = ({
  show,
  toggle,
  handleShowForm,
  handleShowLegalWarning
}) => {
  return (
    <div className={`cover-wrap full-wrap ${show ? "show" : ""}`}>
      <div className="modal-wrap" id="start-report">
        <Link to="#" onClick={toggle} className="close-modal-home">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </Link>
        <p className="modal-tit">
          Has accedido al servicio de Farmacovigilancia del CIM
        </p>
        <p>Comienza seleccionando</p>
        <p className="modal-q">¿Eres profesional de la salud?</p>
        <Link
          to="#"
          className="round-button"
          onClick={() => handleShowForm(true)}
        >
          Si
        </Link>
        <Link
          to="#"
          className="round-button"
          onClick={() => handleShowForm(false)}
        >
          No
        </Link>
        <hr />
        <p className="modal-d">
          He leído y apruebo el{" "}
          <Link to="#" onClick={() => handleShowLegalWarning()}>
            Acuerdo de privacidad y términos de uso
          </Link>
        </p>
      </div>
    </div>
  );
};

export default HomeModal;

import React from 'react';
import './AppStyle.css';

const AppSideDrawer = ({backgroundColor, width, position, show, body }) => {
    let sideclass = `app-side-drawer ${position ==="right" ? 'app-side-drawer-right' : 'app-side-drawer-left'}`;
    if(show) sideclass= `${sideclass} open`;
    return (
        <nav className={sideclass} style={{backgroundColor:backgroundColor,width:width}}>
            {body}
        </nav>
    );
};

export default AppSideDrawer;

import React from 'react';
import PropTypes from 'prop-types';
import ListActions from './ListActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { LabelDate } from '../../Fields';

const ListItemIE =({Item, classProp, handleAction, View})=> {
    const actions = (handleAction, Item,View) => [
        <Link to='#' onClick={() => handleAction(Item)} ><FontAwesomeIcon icon={View ==='edit' ? faPencilAlt : faEye} size={'lg'}/></Link>
    ];
    return (
        <tr className={`list-item ${classProp}`}>
           <td>{Item.DescripcionRAM}</td>
           <td>{Item.Total!==null ? Item.Total : '---' }</td>
           <td>{Item.Estado ? Item.Estado : '---'}</td>
           <td> {(Item.Modificador && Item.Modificador.Fecha) ? `${Item.Modificador.Usuario} - ${LabelDate(Item.Modificador.Fecha)} hs.` : '---'} </td>
           <td className="p-t-sm"><ListActions  actions={actions(handleAction,Item,View)}/></td> 
        </tr>
    );
};

ListItemIE.propTypes = {
    Item: PropTypes.object,
    classProp: PropTypes.string,
    handleAction: PropTypes.func,
    View: PropTypes.string,
};

export default ListItemIE;
import React from 'react';
import './AppStyle.css';

const AppDrawerBtn = ({handleSideDrawerToggle}) => {
    return (
        <button className="app-drawerbtn" onClick={handleSideDrawerToggle}>
            <div className="drawerbtn-line"></div>
            <div className="drawerbtn-line"></div>
            <div className="drawerbtn-line"></div>
        </button>
    );
};

export default AppDrawerBtn;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col,Row,Grid } from 'react-flexbox-grid';
import UserDetails from './UserDetails';
import api from '../../../api';
import { getCodiguerasContext as getCodigueras, setLoad, setMyUser} from '../../../redux/actions/context';
import { getCodigueraContext, getMyUser } from '../../../redux/selectors/Context';
import { getForm } from '../../../redux/selectors/Forms';
import { url_userDetails, url_updateUserDetails, url_getContext, url_createUserDetails } from '../../../api/urls';
import ListAssignLaboratories from '../../ListComponents/List/ListAssigLaboratories';
import Section from '../../EReportComponents/Section';
import SectionFooter from '../../EReportComponents/Section/SectionFooter';
import { Button } from '@material-ui/core';
import LaboratoriesAssignModal from '../../Modals/LaboratoriesAssignModal';
import { showError } from '../../../redux/actions/error';

class UserComponent extends Component {
    state ={
        usuario:null,
        openAddLaboratory:false,
    }

    componentWillUnmount =() => {
        this.setState({usuario:null,
            openAddLaboratory:false})
    }

    componentDidMount=() => {
        if(this.props.codiguera === null){
            this.props.getCodigueras();

        }
        this.handleGetUser();
    }

    handleGetUser = async ()=>{
        if(this.props.userId !== null && this.props.userId !== -1){
          try {
            this.props.setLoad(true);
            const response = await api.get(url_userDetails(this.props.userId));
            const usuario = response.data
            this.setState({ usuario: usuario });
            // const id = this.props.userId
          } catch (e) {
            this.props.showError(e);
          } finally {
            this.props.setLoad(false);
          }
        }
    }

    componentDidUpdate=(prevProps, prevState)=>{
        if(this.props.userId!== prevProps.userId){
            this.handleGetUser();
        }
    }

    handleCreate = async () =>{
      try{
        this.props.setLoad(true);
        const user = this.props.fromUserDetails.values.Details;
        const errorVal = {};
        if (user.Speciality.Id === 'none' || user.Speciality.Id ==='Seleccionar...' || !user.Speciality.Id) errorVal.Error="Especialidad requerido";
        if (user.UserType === 'none' || user.UserType==='Seleccionar...' || !user.UserType) errorVal.Error = "Rol requerido";
        if (user.Country === 'none' || user.Country === 'Seleccionar...' || !user.Country) errorVal.Error = "País requerido";
        if (!user.LastName || user.LastName.trim().length === 0) errorVal.Error = "Apellido requerido";
        if (!user.FirstName || user.FirstName.trim().length === 0) errorVal.Error = "Nombre requerido";
        if (!user.Email) errorVal.Error = "Correo electrónico requerido";
        
        if(errorVal.Error)
        {
            alert(errorVal.Error);
            throw new Error(errorVal.Error);
        }

        const response = await api.post(url_createUserDetails, user);
        alert('Usuario creado');
        this.props.createSuccess(response.data);
      }catch(error){
        this.props.showError(error);
      }finally{
        this.props.setLoad(false);
      }
    }

    handleUpdate = async () => {
      try{
        this.props.setLoad(true);
        const user = this.props.fromUserDetails.values.Details;

        const errorVal = {};
        if (user.Speciality.Id === 'none' || user.Speciality.Id ==='Seleccionar...' || !user.Speciality.Id) errorVal.Error="Especialidad requerido";
        if (user.UserType === 'none' || user.UserType==='Seleccionar...' || !user.UserType) errorVal.Error = "Rol requerido";
        if (user.Country === 'none' || user.Country === 'Seleccionar...' || !user.Country) errorVal.Error = "País requerido";
        if (!user.LastName || user.LastName.trim().length === 0) errorVal.Error = "Apellido requerido";
        if (!user.FirstName || user.FirstName.trim().length === 0) errorVal.Error = "Nombre requerido";
        if (!user.Email) errorVal.Error = "Correo electrónico requerido";
        
        if(errorVal.Error)
        {
            alert(errorVal.Error);
            throw new Error(errorVal.Error);
        }

        const response = await api.post(url_updateUserDetails, user);
        if(this.props.userId === -1){
          const context = await api.get(url_getContext)
          this.props.setMyUser(context.data.User);
          let { usuario } = this.state;
          usuario.Details = response.data;
          usuario.Laboratories = [];
          this.setState({ usuario });
        }
        else{
          
           alert('Usuario actualizado');
           this.props.updateSuccess();
           let { usuario } = this.state;
           usuario.Details = response.data;
           this.setState({ usuario });
               
        }
      }catch(error){
        this.props.showError(error);
      }
      finally{
        this.props.setLoad(false);
      }
    }

    openAddLaboratory= () =>{
        this.setState({openAddLaboratory:true});
    }

    toggleAddLaboratory= () =>{
        this.setState({openAddLaboratory:!this.state.openAddLaboratory});
    }
    saveSuccess = () =>{
        this.handleGetUser();
        this.toggleAddLaboratory();
    }
    render() {
        const { codiguera, view, hidden, userId, goBack ,myUser, fromUserDetails } =this.props;
        let { openAddLaboratory , usuario} = this.state;
        const {Id} = myUser;
        if(view === 'create'){
            usuario = null
        }
        if(hidden){
            return null;
        }
        else{
            return (
                <div>
                    <Row className='m-t'>
                        <Col md={userId===null?12:6}>
                            <Section title={view==='create'? 'Crear usuario':'Editar usuario'} className='background-fff'  >
                                <Grid className='m-t-sm'>
                                    <UserDetails view={view} codiguera={codiguera} initialValues={usuario} myUser={myUser} isToggleEnable={Id != userId}/>
                                </Grid>
                                <SectionFooter className='text-center'>
                                    {view!=='create' && <Button type="button" variant="contained" onClick={this.handleUpdate} disabled={fromUserDetails.invalid} className='button-next'>Actualizar</Button>}
                                    {view!=='myUser' && <Button onClick={goBack} className='button-cancel'>Cancelar</Button>}
                                    {view==='create' && <Button type="button" variant="contained" onClick={this.handleCreate} disabled={fromUserDetails.invalid} className='button-next'>Crear</Button>}
                                </SectionFooter>
                            </Section>
                        </Col>
                        <Col md={6} hidden={view==="myUser" || userId===null}>
                            <Section title='Laboratorios asignados' add={this.openAddLaboratory} className='background-fff' buttons={this.openAddLaboratory} >
                                <ListAssignLaboratories  list={usuario && usuario.Laboratories} />
                            </Section>
                        </Col>
                        <Col md={6} hidden={view!=="myUser" || userId===null}>
                            <Section title='Laboratorios asignados'  className='background-fff' >
                                <ListAssignLaboratories  list={usuario && usuario.Laboratories} />
                            </Section>
                        </Col>
                    </Row>
                    <LaboratoriesAssignModal
                        countries={codiguera && codiguera.Paises}
                        user={usuario}
                        toggle={this.toggleAddLaboratory}
                        open={openAddLaboratory}
                        saveSuccess={this.saveSuccess}
                        allLabs={usuario && usuario.Laboratories.find(l=>l.CountryId===null)}/>
                </div>
            );
        }
    }
}

const mapDispatchToProps= {
    getCodigueras,
    setLoad,
    setMyUser,
    showError,
}

const mapStateToProps = state => ({
    codiguera: getCodigueraContext(state),
    fromUserDetails: getForm('UserDetails',state),
    myUser:getMyUser(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);

import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { FieldSelect } from "../Fields";
import { Row, Col } from "react-flexbox-grid";
const FiltersIndicators = ({
  paises,
  laboratorios,
  onChangePais,
  onChangeLaboratorio
}) => {
  return (
    <div className="p-md">
      <Row>
        <Col xs={6}>
          <FieldSelect
            name="paisId"
            label="País"
            items={paises}
            valueField="Id"
            textField="Name"
            onChange={onChangePais}
          />
        </Col>
        <Col xs={6}>
          <FieldSelect
            name="labId"
            label="Laboratorio"
            items={laboratorios}
            valueField="Id"
            textField="Nombre"
            onChange={onChangeLaboratorio}
          />
        </Col>
      </Row>
    </div>
  );
};

const validate = values => {
  const error = {};
  return error;
};

export default connect(
  null,
  null
)(reduxForm({ form: "FilterIndicators", validate })(FiltersIndicators));

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faSpinner,
  faPlus,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import { Row, Col } from "react-flexbox-grid";
import {
  FieldDatePicker,
  FieldText,
  FieldLabel,
  FieldSelect
} from "../../../Fields";
import { getMedicationHistory } from "../../../../redux/selectors/ER";
import {
  deleteERMedicationAnt,
  updateERMedicationAnt,
  insertERMedicationAnt,
  setERMedicationAnt
} from "../../../../redux/actions/eventoReportable";
import { Prompt } from "react-router-dom";
import api from "../../../../api";
import { url_deleteMedicamentoAnterior } from "../../../../api/urls";
import { theme } from "../../../../constants/theme";
import { MuiThemeProvider } from "@material-ui/core";
import { getCodigueraContext } from "../../../../redux/selectors/Context";
import { showError } from "../../../../redux/actions/error";
import { setLoad } from '../../../../redux/actions/context';
import { getCodiguera } from "../../../../codigueras";

class LastMonths extends Component {
  state = {
    open: false
  };

  handleAddMedication = () => {
    this.props.setERMedicationAnt(this.props.Medicamentos);
    const id = this.props.ER.Id;
    let antAdd = {
      Id: null,
      EvRepId: id,
      Medicamento: "",
      FechaIni: null,
      FechaFIn: null
    };
    this.props.insertERMedicationAnt(antAdd);
  };

  handleShowModalAddMedication = () =>
    this.setState({ open: !this.state.open });

  handleDeleteItem = async (item, index) => {
    this.props.deleteERMedicationAnt(index);
    if (item.Id !== null) {
      try{
        await api.post(url_deleteMedicamentoAnterior, item);
      }catch(error){
        this.props.showError(error);
      }
    }
  };

  componentDidMount = () => window.scrollTo(0, 0);

  render() {
    const {
      ER,
      title,
      handleSubmit,
      handleBack,
      loadNext,
      Medicamentos,
      codiguera
    } = this.props;
    return (
      <div>
        <CardHeader
          className="p-l-n"
          title={
            <span>
              {title}
              <MuiThemeProvider theme={theme}>
                <Button
                  color="primary"
                  className="m-l-md btn btn-sm button-next"
                  onClick={() => this.handleAddMedication()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </MuiThemeProvider>
            </span>
          }
        />
        <div>
          {Medicamentos &&
            Medicamentos.map((m, i) => (
              <MedicationHistoryItem
                key={i}
                index={i}
                handleDelete={this.handleDeleteItem}
                Medicamento={m}
                tipos={
                  codiguera &&
                  getCodiguera("MedicamentosAnteriores_Tipo", codiguera)
                }
              />
            ))}
        </div>
        <Row
          hidden={
            !ER.AdministeredMedicationsTextA && !ER.AdministeredMedicationsTextB
          }
        >
          <Col xs={12} hidden={!ER.AdministeredMedicationsTextA}>
            <FieldLabel
              value={ER.AdministeredMedicationsTextA}
              label="Indique otros medicamentos recibidos"
            />
          </Col>
          <Col xs={12} hidden={!ER.AdministeredMedicationsTextB}>
            <FieldLabel
              value={ER.AdministeredMedicationsTextB}
              label="Indique el consumo de vitaminas, minerales, suplementos, remedios herbales"
              type="textarea"
            />
          </Col>
        </Row>
        <CardActions className="card-actions p-t-lg">
          <div>
            <Button
              type="button"
              disabled={loadNext}
              className="card-actions-button button-back"
              variant="contained"
              onClick={handleBack}
            >
              <FontAwesomeIcon size="lg" icon={faArrowLeft} />
            </Button>
            <Button
              type="button"
              disabled={loadNext}
              className="card-actions-button button-next"
              variant="contained"
              onClick={() => handleSubmit(Medicamentos)}
            >
              <FontAwesomeIcon
                size="lg"
                icon={loadNext ? faSpinner : faArrowRight}
                pulse={loadNext}
              />
            </Button>
          </div>
        </CardActions>
        <Prompt
          when={!this.props.pristine}
          message={"¡Se perderán los datos sí continúa!"}
        />
      </div>
    );
  }
}

LastMonths.propTypes = {
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  initialValues: PropTypes.array
};

const mapStateToProps = state => ({
  initialValues: getMedicationHistory(state),
  Medicamentos: getFormValues("EReportEditStep4")(state),
  codiguera: getCodigueraContext(state)
});

const mapDispatchToProps = {
  deleteERMedicationAnt,
  updateERMedicationAnt,
  insertERMedicationAnt,
  setERMedicationAnt,
  showError,
  setLoad
};

const validate = values => {
  const error = {};
  return error;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "EReportEditStep4", validate })(LastMonths));

const MedicationHistoryItem = ({ Medicamento, index, handleDelete, tipos }) => {
  return (
    <Row>
      <Col md={5} sm={12}>
        <FieldText
          name={`[${index}].Medicamento`}
          type="text"
          label="Medicamento, vitaminas, minerales, suplementos y/o remedios herbales"
        />
      </Col>
      <Col md={2} sm={12}>
        <FieldSelect
          name={`[${index}].Tipo`}
          label="Tipo"
          items={tipos && tipos}
          valueField="Id"
          typeValue="number"
          textField="Nombre"
        />
      </Col>
      <Col md={2} sm={6}>
        <FieldDatePicker
          name={`[${index}].FechaIni`}
          allowPast={true}
          allowFuture={false}
          label="Fecha de inicio"
        />
      </Col>
      <Col md={2} sm={6}>
        <FieldDatePicker
          name={`[${index}].FechaFin`}
          allowPast={true}
          allowFuture={false}
          label="Fecha de fin"
        />
      </Col>
      <Col md={1} sm={12}>
        <button
          className="m-t-lg btn btn-sm btn-danger"
          onClick={() => handleDelete(Medicamento, index)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />{" "}
        </button>
      </Col>
    </Row>
  );
};

import React from "react";
import Section from "../../../Section";
import Summary from "../../../../HomeComponents/components/Summary";

export const SummaryERHome = ({ title, ehome, codiguera }) => {
  const form = {
    step1: ehome.Data.Tipo,
    step2: ehome.Data.Paciente,
    step3: { RAM: ehome.Data.RAM },
    step4: { Medicamento: ehome.Data.Medicamento },
    step5: { Concomitante: ehome.Data.Concomitante },
    step6: ehome.Data.ContactoMedico,
    step7: ehome.Data.InformacionContacto
  };
  return (
    <div>
      <Section title={`${title} ( ${ehome.Reference} )`}>
        <div className="summary-modal-body summary-details">
          <Summary form={form} codiguera={codiguera} />
        </div>
      </Section>
    </div>
  );
};

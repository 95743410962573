import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ListFollowUp from '../ListComponents/List/ListFollowUp';

const FollowUpModal = ({ER,list,toggle,open}) => {
    return (
        <Modal size={'lg'} fade={false} isOpen={open} toggle={() => toggle()}>
        <ModalHeader  toggle={() => toggle()} >{ER.NumeroReferencia}
        </ModalHeader>
        <ModalBody>
          <ListFollowUp list={list} />
        </ModalBody>
      </Modal>
    );
};

FollowUpModal.propTypes = {
    list: PropTypes.array,
    ER: PropTypes.object,
    toggle: PropTypes.func,
    open: PropTypes.bool,
};

export default FollowUpModal;
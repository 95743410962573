import React from 'react';

const SectionFooter = ({children,className}) => {
    return (
        <div className={`sectionFooter ${className}`}>
            {children}
        </div>
    );
};

export default SectionFooter;
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { LabelDate } from '../../Fields';
import { faUserMd, faExclamationCircle, faBabyCarriage } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';

class ListItemERHome extends Component {

    constructor(props) {
        super(props);

        this.state = {
          tooltipOpen: false,
          popoverOpen: false,
          loadPopOver: false,
          dropdownOpen:false,
          popoverMetricOpen:false,
        }
    }



    handleCalcMiniCategory = (Item) => {
        switch (Item.Status) {
            case 1: return 'info-element';
            case 2: return 'warning-element';
            case 4: return 'danger-element';
            default:
                return 'default-element';
        }
    }

    handleCalcRAMGrave = Item => {
        let grave = false;
        if(Item.IsMedic){
            Item.Data.RAM.forEach(ram=>{
                if(ram.Grave && ram.Grave==="SI"){
                    grave = true;
                }
            })
        }
        return grave;
    }

    handleContactAccept = Item => {
        let accept = false;
        if(Item.Data.InformacionContacto.AceptaContacto==="SI"){
            accept = true;
        }
        return accept;
    }

    handleLactation = Item => {
        let accept = false;
        if(Item.Data.Paciente.Sexo === "F" && Item.Data.Paciente.Lactancia==="SI"){
            accept = true;
        }
        return accept;
    }

    handlePregnancy = Item => {
        let accept = false;
        if(Item.Data.Paciente.Sexo === "F" && Item.Data.Paciente.Embarazo==="SI"){
            accept = true;
        }
        return accept;
    }

    handleType = Item => {

    }

    handleShowHours = (date) => {
        const newDate = DateTime.fromISO(date).toFormat('f');
        return newDate;
    };

    renderItem = (Item, handleAction) => {
        return (
        <li className={`${this.handleCalcMiniCategory(Item)} list-item p-b-md`}>
            <strong>
                <Link  to="#" onClick={()=> handleAction("show",Item)} >{Item.Reference}</Link> {Item.IsMedic && <FontAwesomeIcon title="Generado por profesional de la salúd" icon={faUserMd} />}
                {this.handleCalcRAMGrave(Item) && <FontAwesomeIcon className='m-l-sm' title="Informa reacción adversa grave" icon={faExclamationCircle} />}
                {this.handleContactAccept(Item) && <FontAwesomeIcon className='m-l-sm' title="Acepta ser contactado" icon={faAddressCard}/>}
                {(this.handleLactation(Item) || this.handlePregnancy(Item)) && <FontAwesomeIcon className='m-l-sm' title={`${this.handleLactation(Item)?'En lactancia' : 'Embarazada'}`} icon={faBabyCarriage}/> }
            </strong>
                <div className="agile-detail">
                  {(Item.IsMedic) && <span className="text-muted small block">{Item.Data.Tipo.SubGroupName}</span>}
                <span className="text-muted small block">{Item.StatusName}<FontAwesomeIcon icon={faCalendarAlt} className="m-l-md m-r-sm"/>{LabelDate(Item.Date)}hs</span>
                  </div>
                  </li>)
    }

    render(){
        const {Item, handleAction} = this.props;
        return (
            this.renderItem(Item, handleAction)

        )
    }
};

export default ListItemERHome;

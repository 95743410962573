import { faFilePdf, faFileImage, faFileWord, faFile, faFileArchive, faFileExcel } from '@fortawesome/free-solid-svg-icons';

export const AllowedFileAccept = "image/jpeg, image/tiff, application/pdf, text/rtf, text/tiff, text/html, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/x-7z-compressed,application/x-rar-compressed,application/x-zip-compressed";
export const ImporDataFileAccept = ``;
export const MaxFileSize = 20000000;
export const MaxFileSizeUser = 3000000;
export const getFileIcon = (type) => {
    if(type){
        switch (type.toLowerCase()) {
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return faFileWord;
            case "application/msword":
                return faFileWord;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return faFileExcel;
            case "application/vnd.ms-excel":
                return faFileExcel;
            case "image/jpeg":
                return faFileImage;
            case "image/tiff":
                return faFileImage;
            case "application/dicom":
                return faFileImage;
            case "application/pdf":
                return faFilePdf;
            case "text/rtf":
                return faFile;
            case "text/tiff":
                return faFile;
            case "text/html":
                return faFile;
            case "text/plain":
                return faFile;
            case "docx":
                return faFileWord;
            case "doc":
                return faFileWord;
            case "xls":
                return faFileExcel;
            case "xlsx":
                return faFileExcel;
            case "pdf":
                return faFilePdf;
            case "jpeg":
                return faFileImage;
            case "jpg":
                return faFileImage;
            case "png":
                return faFileImage;
            case "gif":
                return faFileImage;
            case "tiff":
                return faFileImage;
            case "zip":
                return faFile;
            default:
                return faFileArchive;
        }
    }
    else{
        return faFile;
    }
}

export const getFileIconColor = (type) => {
    if(type){
        switch (type) {
            case "docx":
                return '#1c84c6';
            case "doc":
                return '#1c84c6';
            case "xls":
                return '#1ab394';
            case "xlsx":
                return '#1ab394';
            case "pdf":
                return '#ed5565';
            case "jpeg":
                return '#1c84c6';
            case "png":
                return '#1c84c6';
            case "gif":
                return '#1c84c6';
            case "tiff":
                return '#1c84c6';
            default:
                return '#444';
        }
    }
    else{
        return '#444';
    }
} 

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSpinner} from '@fortawesome/free-solid-svg-icons';
import UploadFile from '../../../UploadFile';
import { FieldText, FieldSwitch, FieldDatePicker } from '../../../Fields';
import { Row, Col } from 'react-flexbox-grid';
import Button from '@material-ui/core/Button';
import { CardActions } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { CardHeader } from '@material-ui/core';
import FileView from '../../../UploadFile/fileView';
import { Label  } from 'reactstrap';
import { getERContactoMedico } from '../../../../redux/selectors/ER';
import { downloadContactoMedico } from '../../../../api/urls';

class MedicInformation extends Component {
    state = {
        files: [],
        edit: false
    }

    componentWillMount(){
    }

    handleEditFile = () => this.setState({ edit: true });

    _handleAddFile = (files) => this.setState({files});
    _handleChange = (e)=> this.props.change(e.target.name,e.target.value);
    render() {
        const {seContacto,title,handleSubmit,handleBack, loadNext,initialValues } = this.props;
        const { edit } = this.state;
        return (
            <div>
                <CardHeader className="p-l-n p-t-n" title={title}/>
                <CardContent>
                    <Row>
                        <Col lg={3} md={4}>
                            <FieldSwitch  name='SeContacto' onChange={this._handleChange} icons={null} label='¿Se realizó consulta médica?' inline={true}  />
                        </Col>
                        <Col lg={2} md={3} hidden={!seContacto} ><FieldDatePicker allowFuture={false} allowPast={true} name="FechaContacto" label="Fecha" /></Col>

                    </Row>
                    <Row hidden={!seContacto}>
                        <Col lg={3} md={4} ><FieldText type="text" name="NombreMedico" label="Nombre del médico" /></Col>
                        <Col lg={3} md={4} ><FieldText type="text" name="Profesion" label="Profesión" /></Col>
                        <Col lg={3} md={4} ><FieldText type="text" name="EmailMedico" label="Correo eléctronico" /></Col>
                        <Col lg={3} md={4} ><FieldText type="text" name="TelMedico" label="Teléfono de contacto" /></Col>
                        <Col sm={12} ><FieldText type="textarea" name="Intervencion" label="Intervencion realizada" /></Col>
                        <Col sm={12} >
                            { initialValues.Id != null  && !edit?
                                <div>
                                    <Label>Documento adjunto</Label>
                                    <FileView
                                     url={downloadContactoMedico(initialValues.ErId)}
                                     fileName={initialValues.NombreArchivo}
                                     type={initialValues.ExtensionArchivo}
                                     handleEdit={this.handleEditFile}
                                      />
                                </div>:
                                <UploadFile label="Adjuntar documento" handleAddFile={this._handleAddFile}  multiple={false} />
                            }
                        </Col>
                    </Row>
                </CardContent>
                <CardActions className="card-actions p-t-lg">
                    <div>
                        <Button type="button" disabled={loadNext} className="card-actions-button button-back" variant="contained" onClick={handleBack} ><FontAwesomeIcon size="lg" icon={faArrowLeft} /></Button>
                        <Button type="button" disabled={loadNext} className="card-actions-button button-next" variant="contained" onClick={()=> handleSubmit(this.state.files)} >
                            <FontAwesomeIcon size="lg" icon={loadNext ? faSpinner : faArrowRight} pulse={loadNext} />
                        </Button>
                    </div>
                </CardActions>
            </div>
        );
    }
}
const validate = values =>{
    const error ={};
    return error;
}
const mapStateToProps = state =>({
    initialValues: getERContactoMedico(state),
    seContacto: formValueSelector('EReportEditStep5')(state, 'SeContacto'),
    archivo: formValueSelector('EReportEditStep5')(state, 'Archivo'),
})


export default connect(mapStateToProps, null)(reduxForm({form: 'EReportEditStep5', validate})(MedicInformation));

import { handleActions } from 'redux-actions';
import {HOME_GET_CODIGUERAS,HOME_GET_MEDICAMENTOS } from '../constants/home';

export const Home = handleActions ({
    [HOME_GET_CODIGUERAS]: (state, action) => {
        delete action.payload.Grupo;
        delete action.payload.AlturaUnidad;
        delete action.payload.NotasArchivosTipo;
        delete action.payload.PaisOrigen;
        delete action.payload.PesoUnidad;
        delete action.payload.RAMTipos;
        delete action.payload.RAMCategorias;
        delete action.payload.ReporteOriginador_Origen;
        delete action.payload.ReporteOriginador_Receptor;
        delete action.payload.ReporteOriginador_Tipo;
        delete action.payload.ViaDeComunicacion;
        delete action.payload.Status;
        delete action.payload.Roles;
      return {...state, Codigueras :  action.payload}
    },
    [HOME_GET_MEDICAMENTOS]: (state, action) => ({...state, Medicamentos :  action.payload}),
},{
    Codigueras:null,
    Medicamentos:[]
});


import { handleActions } from 'redux-actions';
import { SET_METRIC , SET_METRICS_ALL } from '../constants/set';

export const Metrics = handleActions ({
    [SET_METRICS_ALL]: (state, action) => action.payload,
    [SET_METRIC]:(state, action) => {
        const edit = action.payload;
        const res = state.reduce((acc, mr) =>{
            if(mr.MetricaId === edit.MetricaId){
                return [...acc, edit ];
            }
            else {
                return [...acc, mr]; 
            }
        },[]);
        return res;
    },
    
},[]);
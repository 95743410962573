import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import Summary from "../HomeComponents/components/Summary";
import { getCodigueraContext } from "../../redux/selectors/Context";
import { setLoad } from "../../redux/actions/context";
import api from "../../api";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { showError } from '../../redux/actions/error';
import {
  url_createerfromerhome,
  url_updatestatiserfromerhome
} from "../../api/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Signature from "../Signature";
import { Row, Col } from "react-flexbox-grid";
class SummaryERHomeModal extends Component {
  state = {
    openSignature: false,
    data: null,
    load: false,
    loadNewStatus: false,
    loadNewStatusDes: false,
    titleSignature: "",
    messageSignature: "",
    statusSelected: 0,
    changeSubGroup: false
  };

  toggleSignature = () =>
    this.setState({ openSignature: !this.state.openSignature });

  handleTitle = data => {
    if (data.IsMedic) {
      return "médico";
    } else {
      return "paciente";
    }
  };

  handleUpdateStatus = (data, status) => {
    const item = this.state.changeSubGroup ? this.state.data : data;
    if (this.state.changeSubGroup || item.Data.Tipo.SubGroup !== "0" || status == 3) {
      let title = "";
      let message = "";
      switch (status) {
        case 2: {
          title = "Revisar evento reportable recibido";
          message = "¿Está seguro que quiere dejar el reporte en revisión?";
          break;
        }

        case 3: {
          title = "Descartar envento reportable recibido";
          message = "¿Está seguro que quiere descartar el reporte?";
          break;
        }

        default: {
          title = "Crear evento reportable";
          message = "Crear envento reportable  recibido";
          break;
        }
      }
      this.setState({
        data: item,
        openSignature: true,
        titleSignature: title,
        messageSignature: message,
        statusSelected: status,
        changeSubGroup: false
      });
    } else {
    }
  };

  close =()=>{
    this.setState({ changeSubGroup: false });
  };

  handleSelectSubGroup = (data, selected) => {
    let item = data;
    let { Data } = item;
    Data.Tipo.SubGroup = selected.target.value;
    item = { ...item, Data };
    this.setState({ data: item, changeSubGroup: true });
  };

  handleConfirm = async (comentario) => {
    const { data } = this.state;

    data.SubGroup = data.Data.Tipo.SubGroup;

    const obj = {
      homeERId: data.Id,
      subGroup: data.SubGroup,
      newStatus: this.state.statusSelected,
      updData: data.Data,
      comentario
    };
    try {
      this.props.setLoad(true);
      this.setState({ load: true });
      const url = this.state.statusSelected ? url_updatestatiserfromerhome : url_createerfromerhome;
      await api.post(url, obj);
      this.props.handleUpdateList(true);
      this.props.toggle();
      window.location.reload();
    } catch (e) {
      this.props.showError(e);
    } finally {
      this.props.setLoad(false);
      this.setState({ load: false });
    }
  };

  render() {
    const { item, toggle, open, codiguera } = this.props;
    const {
      data,
      load,
      loadNewStatus,
      loadNewStatusDes,
      openSignature,
      messageSignature,
      titleSignature,
      changeSubGroup
    } = this.state;
    if (item !== null) {
      let form;
      let aux;
      if (data && changeSubGroup) {
        aux = data;
      } else {
        aux = item;
      }
      form = {
        step1: aux.Data.Tipo,
        step2: aux.Data.Paciente,
        step3: { RAM: aux.Data.RAM },
        step4: { Medicamento: aux.Data.Medicamento },
        step5: { Concomitante: aux.Data.Concomitante },
        step6: aux.Data.ContactoMedico,
        step7: aux.Data.InformacionContacto
      };

      return (
        <Modal
          size="lg"
          backdrop={"static"}
          fade={false}
          isOpen={open}
          toggle={() => toggle()}
          className="summary-modal"
          onClosed={() => this.close()}
        >
          <ModalHeader toggle={() => toggle()}>
            Reporte generado por {this.handleTitle(item)}
          </ModalHeader>
          <Row> 
            <Col sm={12} hidden={this.state.tipoReporte}>
                <Alert color="danger">Debe seleccionar un tipo de reporte</Alert>
            </Col>
          </Row>
          <ModalBody className="summary-modal-body">
            <Summary
              form={form}
              isMedic={item.IsMedic}
              codiguera={codiguera}
              edit={true}
              onChangeSubGroup={e => this.handleSelectSubGroup(item, e)}
            />
            <Signature
              open={openSignature}
              toggle={this.toggleSignature}
              title={titleSignature}
              message={messageSignature}
              handleConfirm={this.handleConfirm}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              disabled={load}
              className="card-actions-button button-back"
              variant="contained"
              onClick={() => toggle()}
            >
              Cancelar
            </Button>
            {(item.Status === 1 || item.Status === 2) && (
              <Button
                type="button"
                disabled={loadNewStatus || load || loadNewStatusDes}
                className="card-actions-button btn-danger"
                variant="contained"
                onClick={() => this.handleUpdateStatus(item, 3)}
              >
                Descartar{" "}
                {loadNewStatusDes && (
                  <FontAwesomeIcon
                    className="m-l-s"
                    icon={faSpinner}
                    pulse={true}
                  />
                )}
              </Button>
            )}
            {(item.Status === 1 || item.Status === 3) && (
              <Button
                type="button"
                disabled={loadNewStatus || load || loadNewStatusDes}
                className="card-actions-button btn-warning"
                variant="contained"
                onClick={() => this.handleUpdateStatus(item, 2)}
              >
                Revisar{" "}
                {loadNewStatus && (
                  <FontAwesomeIcon
                    className="m-l-s"
                    icon={faSpinner}
                    pulse={true}
                  />
                )}
              </Button>
            )}
            {(item.Status === 1 || item.Status === 2) && (
              <Button
                type="button"
                disabled={load || loadNewStatus || loadNewStatusDes}
                className="card-actions-button button-next"
                variant="contained"
                onClick={() => this.handleUpdateStatus(item, null)}
              >
                Ingresar{" "}
                {load && (
                  <FontAwesomeIcon
                    className="m-l-s"
                    icon={faSpinner}
                    pulse={true}
                  />
                )}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  codiguera: getCodigueraContext(state)
});

const mapDispatchToProps = {
  setLoad,
  showError
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SummaryERHomeModal)
);

import React from 'react';
import ListComponent from '..';
import ListItemAudit from '../Item/ListItemAudit';

const ListAuditER = ({list,handleAction,load,loadList}) => {
    return (
        <ListComponent 
            loadList={loadList}
            mini={false} 
            load={load}  
            handleAction={handleAction} 
            empty={'registros'} 
            titles={['Tipo','Reporte','Instancia','Generado por','Fecha','']} 
            list={list} 
            ListComponent={ListItemAudit}  />
    );
};

export default ListAuditER;
import React, { Component } from "react";
import { connect } from "react-redux";
import api from "../api";
import IdleTimer from "react-idle-timer";
import LockContainer from "../containers/LockContainer";
import { blockSession, unlockSession } from "../redux/actions/context";
import { getBlocked } from "../redux/selectors/Context";
import { secureStorage } from "../api/secureStorage";

const mapStateToProps = state => ({
  blocked: getBlocked(state)
});

const mapDispatchToProps = {
  blockSession,
  unlockSession
};
const setPropsAsInitial = WrappedComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class extends Component {
      constructor(props){
        super(props);
        this.state={
          isTimedOut: false
        }
        this.idleTimer = null; 
        this._handleHideLock = this._handleHideLock.bind(this);
        this._onActive = this._onActive.bind(this);
        this._onAction = this._onAction.bind(this);
        this._onIdle = this._onIdle.bind(this);
      }
      _handleHideLock = () => {
        this.props.unlockSession();
      };
      _onActive = e => {

        let timeout = secureStorage.getItem("sessionTimeout");
        this.timeout = timeout * 60;
      };

      _onAction = (e) =>{
        this.setState({'isTimedOut':false});

      }

      _onIdle = e => {
        const { isTimedOut } = this.state;
        if(isTimedOut){
          this.props.blockSession();
          api.clearToken();
        }else{
          this.idleTimer.reset();
          this.setState({ isTimedOut: true });
        }
      };

      render() {
        const { blocked } = this.props;
        let timeout = secureStorage.getItem("sessionTimeout");
        timeout /= 2;
        return (
          <div>
            <IdleTimer
              ref={ref => {
                this.idleTimer = ref;
              }}
              onIdle={this._onIdle}
              onAction={this._onAction}
              timeout={timeout * 60 * 1000}
            />
            {blocked && <LockContainer handleHideLock={this._handleHideLock} />}
            <WrappedComponent {...this.props} initialValues={this.props} />
          </div>
        );
      }
    }
  );

export default setPropsAsInitial;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ChangePasswordForm from '../components/LoginComponents/ChangePasswordForm';
import api from '../api';
import { url_userchangepassword } from '../api/urls';
import { setMyUser,
    setCodiguerasContext,
    setLoad,
    getFilterSearch} from '../redux/actions/context';
import { getMyUser } from '../redux/selectors/Context';
import { showError } from '../redux/actions/error';


class ChangePasswordContainer extends Component {
    state = {
        vieja:"",
        nueva:"",
        nuevaRep:"",
        validOldPassword:true,
        validNewPassword:true,
        validNewPasswordRep:true,
        msgValidOldPassword:"",
        msgValidNewPassword:"",
        msgValidNewPasswordRep:"",
    }

    componentWillMount = () =>{
        this.props.showAppFrame(this.props.location.pathname);

    }
     _handleChangeInput = (e) =>  {
        const {name, value } = e.target
        this.setState({
            [name]:value
        });

    }
    _handleValidOld = (value) => {
        let {validOldPassword, msgValidOldPassword } = this.state;
        if(value.length===0) {
            validOldPassword=false;
            msgValidOldPassword="Debe ingresar una contraseña";
        }else{
            validOldPassword=true;
            msgValidOldPassword="";
        }
        this.setState({
            validOldPassword,msgValidOldPassword
        });
        return validOldPassword;
    }
    _handleValidNew = (value) => {
        let validNewPassword = true;
        let msgValidNewPassword = "";
        if( value.length===0) {
            validNewPassword=false;
            msgValidNewPassword="Debe ingresar una contraseña";
        } else if(value.length <= 6) {

            validNewPassword = false;
            msgValidNewPassword="La contraseña debe tener más de 6 caracteres";

        } else{
            validNewPassword=true;
            msgValidNewPassword="";
        }
        this.setState({
            validNewPassword, msgValidNewPassword,
        });
        return validNewPassword;
    }
    _handleValidNewRep = (value,valueNew)=>{
        let {validNewPasswordRep,msgValidNewPasswordRep } = this.state;
        if(value.length===0){
            validNewPasswordRep = false;
            msgValidNewPasswordRep="Debe ingresar una contraseña";

        } else{
            validNewPasswordRep=true;
            msgValidNewPasswordRep="";
        }
        if(validNewPasswordRep && value!==valueNew){
            validNewPasswordRep = false;
            msgValidNewPasswordRep="Las contrseñas nuevas deben coincidir";
        }

        this.setState({validNewPasswordRep, msgValidNewPasswordRep});
        return validNewPasswordRep;
    }
    _handleValidsPass = (toValid) =>{
        const {vieja, nueva, nuevaRep } = this.state;
        let ret= true;
        if(toValid==="vieja" || !toValid){
            ret= this._handleValidOld(vieja);
        }
        if(toValid==="nueva" || !toValid){
            ret= this._handleValidNew(nueva);
        }
        if(toValid==="nuevaRep" || !toValid){
            ret= this._handleValidNewRep(nuevaRep,nueva);
        }
        return ret;
    }

    handleChangePass = async () => {
        if(this._handleValidsPass()){
          try{
            this.props.setLoad(true);
            const { vieja, nueva } = this.state;
            await api.post(url_userchangepassword, { vieja, nueva });
            await api.logIn(this.props.user.Email, nueva);
            this.props.getFilterSearch();
          }catch(error){
            const msg = error.response.data.Message.split(' - ');
            if(msg[0] === 'ERROR_1'){
              this.setState({
                validOldPassword: false,
                msgValidOldPassword: msg[1]
              });
            }else if (msg[0] === 'ERROR_2') {
              this.setState({
                validNewPassword: false,
                msgValidNewPassword: msg[1]
              })
            }
          }finally{
            this.props.setLoad(false);
          }
        }
    }

    render() {
        const {vieja,nueva,nuevaREp,validOldPassword,validNewPassword,validNewPasswordRep, msgValidOldPassword, msgValidNewPassword, msgValidNewPasswordRep} = this.state;
        return (
            <div className="background-login">
                <div className="login-form">
                    <ChangePasswordForm
                        vieja={vieja}
                        nueva={nueva}
                        nuevaREp={nuevaREp}
                        handleChangePass={this.handleChangePass}
                        handleChangeInput={this._handleChangeInput}
                        validAntPass={validOldPassword}
                        validPassRep={validNewPasswordRep}
                        validPass={validNewPassword}
                        msgValidOldPassword={msgValidOldPassword}
                        msgValidNewPassword={msgValidNewPassword}
                        msgValidNewPasswordRep={msgValidNewPasswordRep}

                        />

                </div>

            </div>
        );
    }
}
const mapDispatchToProps = {
    setMyUser,
    setCodiguerasContext,
    setLoad,
    getFilterSearch,
    showError
}
const mapStateToProps = state => ({
    user: getMyUser(state),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordContainer));

import React from 'react';
import PropTypes from 'prop-types';
import '../ListStyle.css';

const ListEmpty = ({mensaje}) => {
    return (
        <div className="list-component">
        <table className="list-table">
            <tbody>
                <tr className={`list-item`}>
                    <td>{mensaje}</td>
                </tr>
            </tbody>
        </table></div>
    );
};

ListEmpty.propTypes = {
    mensaje: PropTypes.string.isRequired,
};

export default ListEmpty;
import { combineReducers } from "redux";
import { reducer as reduxForm } from "redux-form";
import { MedicamentosPais } from "./list";
import {
  workFlowList,
  workFlowListInvert,
  ER,
  ERActions,
  ERPaciente,
  EROriginReport,
  ERMedicamentosSosp,
  ERRAMS,
  ERRAMSInsert,
  ERRAM,
  ERDocsAndNotes,
  ERWhoReport,
  EROperationHistory,
  ERFollowUp,
  ERImputEvaluation,
  ERContactoMedico,
  ERMedicamentosAnt,
  ERHomeReport,
  ERFabricApproved,
  ERFilter,
  ReportsHome
} from "./ER";
import {
  Load,
  Blocked,
  MyUser,
  CodiguerasContext,
  FilterSearch,
  SweetAlert,
  SaveEventFilters,
  ShowLegalTerms
} from "./Context";
import { Metrics } from "./metrics";
import {
  IndicatorAges,
  IndicatorGroups,
  IndicatorStatus,
  IndicatorValues,
  IndicatorCountriesFilters,
  IndicatorLabsFilters
} from "./Indicators";
import { UsersFilter } from "./user";
import { AuidtUsers, AuidtER, AuidtConfig, AuidtParams } from "./Auidt";
import { MedDRAImports } from "./MedDRA";
import { DataImports } from "./dataimport";
import { reducer as Toastr } from "react-redux-toastr";
import { Home } from "./home";
export default combineReducers({
  form: reduxForm,
  Home,
  ShowLegalTerms,
  Load,
  Blocked,
  SweetAlert,
  toastr: Toastr,
  MyUser,
  CodiguerasContext,
  FilterSearch,
  workFlowList,
  ReportsHome,
  workFlowListInvert,
  ERFilter,
  ER,
  ERActions,
  ERPaciente,
  EROriginReport,
  ERMedicamentosSosp,
  ERMedicamentosAnt,
  MedicamentosPais,
  ERRAMS,
  ERRAMSInsert,
  ERRAM,
  ERDocsAndNotes,
  ERWhoReport,
  EROperationHistory,
  ERFollowUp,
  ERImputEvaluation,
  ERContactoMedico,
  ERFabricApproved,
  ERHomeReport,
  Metrics,
  IndicatorAges,
  IndicatorGroups,
  IndicatorStatus,
  IndicatorValues,
  IndicatorCountriesFilters,
  IndicatorLabsFilters,
  UsersFilter,
  AuidtUsers,
  AuidtER,
  AuidtConfig,
  AuidtParams,
  MedDRAImports,
  DataImports,
  eventFilters: SaveEventFilters
});

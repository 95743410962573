import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ListStyle.css';
import ListEmpty from './List/ListEmpty'
import { LoadCircular } from '../AppComponents/AppUtilities';


class ListComponent extends Component {
    state = {
        mini : this.props.mini ? this.props.mini : false,

    }

    load  = () => <LoadCircular className="listload-load"/>;

    componentDidMount() {
        this.setState({mini: this.props.mini ? this.props.mini : window.innerWidth <= 900});
        window.addEventListener('resize',this.handleResize)
    }

    handleResize = () =>{
        if(this.props.mini){
            this.setState({mini: this.props.mini ? this.props.mini : window.innerWidth <= 900});
        }
    }

    render(){
        const { name,parent, titles, list, ListComponent, View, action, handleAction, loadList, load, empty, search ,navbar,codiguera,whitChildren,textLeft,forEdit } = this.props;
        const { mini } = this.state;
        if(!list || list.length === 0){
            return <div className={`list-component ${loadList ? 'listload-backdload' :''}`}>
                        {this.load()}
                        {!mini &&  <table className="list-table">
                            <thead className="list-table-head">
                                <tr>{
                                    titles.map((t,i) =><th key={i}>{t}</th>)
                                }</tr>
                            </thead>
                        </table>}
                        <ListEmpty mensaje={`No se encontraron ${empty} asociados`} />
                </div>
        }
        else if(!mini) {
            return ( <div className={`list-component ${loadList  ? 'listload-backdload' :''}`}>
                        {this.load()}
                        <table className={`list-table ${textLeft && 'text-left'}`}>
                            <thead className="list-table-head">
                                <tr>{
                                    titles.map((t,i) =><th key={i}>{t}</th>)
                                }</tr>
                            </thead>
                            <tbody>{
                                    list.map((er,index) => <ListComponent name={name} action={action} forEdit={forEdit}  index={index} parent={parent} whitChildren={whitChildren} codiguera={codiguera} min={mini} load={load} search={search} Item={er} handleAction={handleAction} View={View} classProp={ index < (list.length-1) ? "border-bottom" : "" } key={index}/>)
                            }</tbody>
                        </table>
                    </div>
            )
        }
        else{
            return (
            <div className={`list-component ${loadList  ? 'listload-backdload' :''}`}>
               {this.load()}
                <ul className="list-table-mini agile-list p-t-n p-b-n m-t-n m-b-n">
                    {
                        list.map((er,index) => <ListComponent index={index} parent={parent} whitChildren={whitChildren} codiguera={codiguera} mini={mini} navbar={navbar} load={load} search={search} Item={er} handleAction={handleAction} View={View} classProp={ index === (list.length-1) ? "m-b-n" : "" } key={index}/>)
                    }
                </ul>
            </div>
        )}
    }
};

ListComponent.propTypes = {
    list: PropTypes.array,
    handleAction: PropTypes.func,
};

export default ListComponent;

import { createAction } from "redux-actions";
import {
  GET_MY_USER,
  USER_LOGOUT,
  GET_CODIGUERAS_CONTEXT,
  GET_FILTERS_SEARCH,
  GET_PRODUCTS_LABS_FILTERS_SEARCH
} from "../constants/get";
import {
  url_getMyUser,
  url_getCodiguerasBasic,
  url_getFilterSearch,
  url_getProductFilters
} from "../../api/urls";
import {
  SET_CODIGUERAS_CONTEXT,
  SET_MY_USER,
  SET_LOAD,
  BLOCK_SESSION,
  UNLOCK_SESSION,
  SET_LEGAL_TERMS
} from "../constants/set";
import api from "../../api";
import { createAsyncThunk } from '@reduxjs/toolkit'
import { dispatchObject } from '../../helpers/utilities';

const client = api;


//GET
export const getMyUser = createAsyncThunk(
  GET_MY_USER,
  async (args, thunkApi) =>{
    const response = await client.get(url_getMyUser);
    thunkApi.dispatch(dispatchObject(GET_MY_USER, response.data));
    return response.data;
  }
);

export const getCodiguerasContext = createAsyncThunk(
  GET_CODIGUERAS_CONTEXT,
  async (args,  thunkApi) => {
    const response = await client.get(url_getCodiguerasBasic);
    thunkApi.dispatch(dispatchObject(GET_CODIGUERAS_CONTEXT, response.data));
    return response.data;
  }
);

export const getFilterSearch = createAsyncThunk(
  GET_FILTERS_SEARCH,
  async (args, thunkApi) => {
    const response = await client.get(url_getFilterSearch);
    thunkApi.dispatch(dispatchObject(GET_FILTERS_SEARCH, response.data));
    return response.data;
  }
);

export const getProductosFilterSearch = createAsyncThunk(
  GET_PRODUCTS_LABS_FILTERS_SEARCH,
  async (productId, thunkApi) =>{
    const response = await client.get(url_getProductFilters(productId))
    thunkApi.dispatch(dispatchObject(GET_PRODUCTS_LABS_FILTERS_SEARCH, response.data))
    return response.data;
  }
);
//SET
export const setLoad = createAction(SET_LOAD, l => l);
export const setCodiguerasContext = createAction(
  SET_CODIGUERAS_CONTEXT,
  cods => cods
);
export const setMyUser = createAction(SET_MY_USER, u => u);
export const userLogOut = createAction(USER_LOGOUT, u => u);
export const blockSession = createAction(BLOCK_SESSION, () => true);
export const unlockSession = createAction(UNLOCK_SESSION, () => false);
export const setShowLegalTerms = createAction(SET_LEGAL_TERMS, u => u);
export const setEventFilters = createAction("SET_EVENT_FILTERS", e => e);
import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import FilterERSearch from './FilterSearch';
import ListWorkFlow from '../../ListComponents/List/ListWorkFlow';
import AppPaginator from '../../AppComponents/AppPaginator';
import { getForm } from '../../../redux/selectors/Forms';
import { getERFilter } from '../../../redux/actions/eventoReportable';
import { setLoad } from '../../../redux/actions/context';
import { getERFilterList } from '../../../redux/selectors/ER';
import { PAGE_SIZE } from '../../../redux/constants/get';
import { showError } from '../../../redux/actions/error';
import { url_getERByFilters } from '../../../api/urls';
import api from '../../../api';
import { ConsoleView } from 'react-device-detect';
import { CSVLink } from "react-csv";
import { useState } from "react";
import { listenerCount } from 'process';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFileExcel } from '@fortawesome/free-solid-svg-icons';

class ERSearchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader:false,
            avanced:false,
            ERs: [],
            filters:{
                company: null,
                country: null,
                status: null,
                typeER: null,
                typeRAM: null,
                identifier: null,
                laboratory: null,
                product: null,
                NombreComercial: null,
                pActive: null,
                startCreate: null,
                startUpdate: null,
                endCreate: null,
                endUpdate: null,
            },
            headers: [
                { label:"ID del CIM del evento", key: "idEv" },
                { label: "ID Licenciante (si aplica)", key: "idLic" },
                { label: "Fecha de recepción al CIM (Día/Mes/Año)", key: "date" },
                { label: "País del evento", key: "country" },
                { label: "Compañía", key: "company" },
                { label: "Tipo de evento reportable", key: "type" },
                { label: "Origen del reporte", key: "origin" },
                { label: "Reacción/evento ", key: "reaccion" },
                { label: "Seriedad del evento reportable", key: "seriedad" },
                { label: "Principio activo", key: "principioActivo" },
                { label: "Producto", key: "product" }
            ],
            data: []
        };
        this.handleAvancedChange = this.handleAvancedChange.bind();
        this.handleSearch = this.handleSearch.bind();
    }
    handleAvancedChange = () => {
        this.setState({avanced: !this.state.avanced});
    }
    handleSearch = async (pagNumber) => {
        this.props.setLoad(true);
        const  axuFilters = getForm('FilterSearch',this.props.reduxState).values;
        let filter = this.state.filters;
        if(axuFilters){
            axuFilters.country = axuFilters.country === "none" ? null : axuFilters.country;
            axuFilters.company = axuFilters.company === "none" ? null : axuFilters.company;
            axuFilters.laboratory = axuFilters.laboratory === "none" ? null : axuFilters.laboratory;
            axuFilters.NombreComercial = axuFilters.product === "none" ? null : axuFilters.product;
            axuFilters.pActive = axuFilters.principioActivo === "none" ? null : axuFilters.principioActivo; 
            filter =axuFilters;
        }
        filter.pageSize = PAGE_SIZE;
        filter.pageNumber = pagNumber;
        filter.orderBy = '';
        try{
          const response = await api.post(url_getERByFilters, filter);
          if(response.status === 200){
            this.props.getERFilter(response.data);
          }
          
          filter.pageSize = 200;
          const response2 = await api.post(url_getERByFilters, filter);
          this.setState({ERs: response2});
        }catch(error){
          this.props.showError(error);
        }finally{
          this.props.setLoad(false);
        }
        this.setState({data: []});
        this.state.ERs.data.Result.forEach(Result => {
            console.log(Result)
            this.setState({
                data: [...this.state.data, {
                    idEv: Result.Id,
                    idLic:null, 
                    date:Result.FechaCreacion,
                    country:Result.Pais,
                    company:Result.Compania,
                    type:Result.Grupo, 
                    origin:Result.NumeroReferencia,
                    reaccion:"---",
                    seriedad:"---",
                    principioActivo:Result.pActive, 
                    product:Result.NombreComercial,
                    tipoRAM: Result.TipoRAM,
                    tipoReporte: Result.TipoReporte,
                    Reaccion: Result.Reaccion
                }]
            });
        });
    }
    
      
      
    render(){
        const { avanced } = this.state;
        const { list } = this.props;
        const {headers} = this.state;
        const {data} = this.state;
        console.log(data.principioActivo);
        return (
            <div className="p-l p-r">
                <FilterERSearch data={data} headers={headers} avanced={avanced} handleSearch={this.handleSearch} handleAvancedChange={this.handleAvancedChange} newFilter={this.props.newFilter} />               
                <ListWorkFlow search={true} list={list && list.Result}/>
                <AppPaginator message='Eventos reportables' result={list} handlePagination={this.handleSearch} />
            </div>
        );
    }
};

ERSearchComponent.propTypes = {
    avanced : PropTypes.bool,
    result : PropTypes.array,
};

const mapDispatchToProps = {
    getERFilter,
    setLoad,
    showError
}

const mapStateToProps = state => ({
   reduxState: state,
   list: getERFilterList(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ERSearchComponent);

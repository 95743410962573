import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader'
import { Row, Col } from 'react-flexbox-grid';
import { FieldText } from '../../../Fields';
import { getER, getERDocsAndNotes } from '../../../../redux/selectors/ER';
import DocsAndNotes from '../../DocsAndNotes';
import { Prompt } from 'react-router-dom';

class RelevantInformation extends Component {

    componentDidMount = () => window.scrollTo(0, 0);

    render() {
        const { title,handleSubmit,handleBack, docsAndNotes, loadNext, meta } = this.props;
        return (
            <div>
                <CardHeader className="p-l-n" title={title}/>
                <Row>
                    <Col xs={12}>
                        <FieldText name="AdditionalInformation" label="Indique otra información importante acerca del paciente (Por ejemplo: si es fumador, si consume alcohol, etc). De ser posible añadir historia clínica, pruebas de laboratorio u otro que considere de interés" type="textarea"/>
                    </Col>
                </Row>
                <DocsAndNotes meta={meta} ERDocsAndNotes={docsAndNotes}/>
                <CardActions className="card-actions p-t-lg">
                    <div>
                        <Button type="button" disabled={loadNext} className="card-actions-button button-back" variant="contained" onClick={handleBack} ><FontAwesomeIcon size="lg" icon={faArrowLeft} /></Button>
                        <Button type="button" disabled={loadNext} className="card-actions-button button-next" variant="contained" onClick={handleSubmit} ><FontAwesomeIcon size="lg" icon={loadNext ? faSpinner : faArrowRight} pulse={loadNext} /></Button>
                    </div>
                </CardActions>
                <Prompt when={!this.props.pristine} message={"¡Se perderán los datos sí continúa!"}/>
            </div>
        );
    }
}

RelevantInformation.propTypes = {

};

RelevantInformation.propTypes = {
    title : PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
};

const mapStateToProps = (state) =>({
    initialValues: getER(state),
    docsAndNotes: getERDocsAndNotes(state),
});

const validate = values =>{
    const error ={};
    return error;
}   

export default connect(mapStateToProps, null)(reduxForm({form: 'EReportEditStep6', validate})(RelevantInformation));

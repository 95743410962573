import React, { Component } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFilePdf, faPenAlt, faFileCode  } from '@fortawesome/free-solid-svg-icons'
import {NavLink} from 'react-router-dom';
import Signature from '../Signature';
import { url_sendAccion, url_getActionsERByMeta, url_downloadERPdf} from '../../api/urls';
import api from '../../api';
import { setER, getWorkFlowList, setERActions } from '../../redux/actions/eventoReportable';
import { getER,getERActions } from '../../redux/selectors/ER';
import { setLoad } from '../../redux/actions/context';
import { showError } from '../../redux/actions/error'
import { iconActionER } from '../../constants/actionsER';
import { questionInputabilityEvaluation } from '../../constants/steps';
import { getMyUser } from "../../redux/actions/context";
import {
    getMyUser as MyUser
  } from "./../../redux/selectors/Context";


class EReportActions extends Component {
    state={
        openSiganture:false,
        actionSelected:null,
        titleSignature:'',
        textSignature:'',

    }

    componentDidMount(){
        if (!this.props.myUser) {
            this.props.getMyUser();
        }
    }



    _handleSignature = (action) => {
        if(action){
            const actionSelected = action;
            const titleSignature = actionSelected.Accion_Title;
            const textSignature = actionSelected.Accion_Text;
            this.setState({
                openSiganture:! this.state.openSiganture,
                actionSelected,
                titleSignature,
                textSignature
            });
        }
        else{
            this.setState({ openSiganture:false });
        }
    }



    _handleSendAction = async (comments) => {
            const questions = questionInputabilityEvaluation.map(x => x.label);
            const data ={
               Event:{
                    Accion: this.state.actionSelected,
                    Event: this.props.ER,
                    Comments:comments,
                },
                Questions: questions
            };
            try {
              this.props.setLoad(true);
              const response = await api.post(url_sendAccion, data);
              const actions = await api.get(url_getActionsERByMeta(this.props.meta));
              this.props.setERActions(actions.data);
              const params = {
                  pageNumber: 1,
                  orderBy: 'FechaSolicitud DESC',
                  statusId: null,
                  idEr: null,
                  from: null,
                  to: null,
                  categoryId: null,
                  subGroupId: null
              };
             
              this.props.getWorkFlowList(params);
              this.props.setER(response.data);
              this.setState({
                actionSelected: null,
                actionUrl: '',
                titleSignature: '',
                textSignature: ''
              });
            } catch (e) {
              this.props.showError(e)
            } finally {
              this.props.setLoad(false);
            }

    }

    handleAudit = () =>{

    }

    _renderActions = actions =>{
        return actions.map((a,i)=> {
            return(<li key={i}>
                <NavLink to={"#"} onClick={() =>this._handleSignature(a)} title={a.Accion_Title}><FontAwesomeIcon icon={iconActionER(a.Accion)} size={'3x'}/></NavLink>
            </li>);
        })
    };

    _pdf = async () => {
        try{
            const id = this.props.ER.Id;
            const numeroReferencia = this.props.ER.NumeroReferencia
            const response = await api.getPDF(url_downloadERPdf(id));
            let doc = new Blob([response.data], {type: "application/pdf"});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(doc);
            link.download = "ER-" + numeroReferencia;
            link.click();
        }catch(error){
          this.props.showError(error);
        }
    }




    render(){
        const {myUser, ER,Actions} = this.props;
        const {openSiganture,titleSignature,textSignature} = this.state;

        if(ER && Actions){
        return (
            <div className="erport-actions">
                <ul>
                    <li><NavLink to={"/reportableEvent/"+this.props.meta+"/edit/2"}  title="Crear Reporte de Seguimiento" hidden={(myUser.Rol == "QPPV" && ER.Status == 3)}><FontAwesomeIcon icon={faPenAlt} size={'3x'} /></NavLink></li>
                    <li><NavLink to={"#"} onClick={this._pdf} title="Descargar Reporte PDF" ><FontAwesomeIcon icon={faFilePdf} size={'3x'}/></NavLink></li>
                    {
                       Actions.length> 0 && this._renderActions(Actions)
                    }
                 </ul>
                <Signature title={titleSignature}
                    message={textSignature}
                    open={openSiganture}
                    toggle={this._handleSignature}
                    handleConfirm={this._handleSendAction}/>

            </div>
        );
        }else return null
    }
}

const mapDispatchToProps = {
    getMyUser,
    setER,
    setLoad,
    getWorkFlowList,
    setERActions,
    showError
}

const mapStateToProps = (state) =>({
    myUser: MyUser(state),
    ER : getER(state),
    Actions: getERActions(state),
});

export default  connect(mapStateToProps, mapDispatchToProps)(EReportActions) ;

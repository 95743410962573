import { handleActions } from "redux-actions";
import {
  GET_MY_USER,
  USER_LOGOUT,
  GET_CODIGUERAS_CONTEXT,
  GET_FILTERS_SEARCH,
  GET_PRODUCTS_LABS_FILTERS_SEARCH
} from "../constants/get";
import {
  SET_LOAD,
  SET_CODIGUERAS_CONTEXT,
  SET_MY_USER,
  SET_SWEETALERT,
  BLOCK_SESSION,
  UNLOCK_SESSION,
  SET_LEGAL_TERMS
} from "../constants/set";
import { secureStorage } from "../../api/secureStorage";

export const Blocked = handleActions(
  {
    [BLOCK_SESSION]: (state, action) => true,
    [UNLOCK_SESSION]: (state, action) => false
  },
  false
);

export const Load = handleActions(
  {
    [SET_LOAD]: (state, action) => action.payload
  },
  false
);
export const SaveEventFilters = handleActions(
  {
    ["SET_EVENT_FILTERS"]: (state, action) => action.payload || {}
  },
  false
);
export const SweetAlert = handleActions(
  {
    [SET_SWEETALERT]: (state, action) => action.payload
  },
  { type: "info", title: "", text: "", show: false }
);

export const MyUser = handleActions(
  {
    [GET_MY_USER]: (state, action) => action.payload,
    [SET_MY_USER]: (state, action) => action.payload,
    [USER_LOGOUT]: (state, action) => null
  },
  secureStorage.getItem("user") !== null ? secureStorage.getItem("user") : null
);

export const CodiguerasContext = handleActions(
  {
    [GET_CODIGUERAS_CONTEXT]: (state, action) => action.payload,
    [SET_CODIGUERAS_CONTEXT]: (state, action) => action.payload,
  },
  null
);

export const FilterSearch = handleActions(
  {
    [GET_FILTERS_SEARCH]: (state, action) => action.payload,
    [GET_PRODUCTS_LABS_FILTERS_SEARCH]: (state, action) => {
      const filters = state;
      if (action.payload.length > 0) {
        const lab =
          filters.Laboratories.filter(
            l => l.Id === action.payload[0].LineaId
          )[0] || null;
        if (lab && lab.Productos.length === 0) {
          filters.Laboratories.reduce((acc, lab) => {
            if (lab.Id === action.payload[0].LineaId) {
              lab.Productos = action.payload;
            }
            return [...acc, lab];
          }, []);
        }
      }
      return filters;
    }
  },
  null
);

export const ShowLegalTerms = handleActions(
  {
    [SET_LEGAL_TERMS]: (state, action) => action.payload
  },
  false
);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { stepsERedit } from "../../../constants/steps";
/*STEPS*/
import PacienteData from "./Steps/Step1_PacienteData";
import OriginReport from "./Steps/Step2_OriginReport";
import SuspectMedications from "./Steps/Step3_SuspectMedications";
import AdverseReactions from "./Steps/Step4_AdverseReactions";
import LastMonths from "./Steps/Step5_LastMonths";
import RelevantInformation from "./Steps/Step6_RelevantInformation";
import Summary from "./Steps/Step8_Summary";
/**/
import {
  getER,
  getERPaciente,
  getERMedicamentosSosp,
  getERRAMS,
  getEROriginReport,
  getERHomeReport
} from "./../../../redux/selectors/ER";
import { getEReportEditStep } from "./../../../redux/selectors/Forms";
import { getMedicalCountryByMeta } from "../../../redux/actions/list";
import {
  setPaciente,
  setOriginReport,
  setRAMS,
  setER,
  setERContactoMedico,
  setERMedicationAnt,
  getEROriginReportMeta
} from "../../../redux/actions/eventoReportable";
import {
  url_postPacienteUpdate,
  url_postRAMSInsert,
  url_postERupdate,
  url_postContactoMedicoInsert,
  url_postContactoMedicoUpdate,
  uploadContactoMedico,
  uploadFileOriginReport,
  url_postOriginReportInsert,
  url_postOriginReportUpdate,
  url_postERMedicationHistorySet,
  url_getDocsAndNotesMeta
} from "../../../api/urls";
import { store } from '../../../redux/store'
import { withStyles, StepButton } from "@material-ui/core";
import { Label } from "semantic-ui-react";

import "./style.css";
import { CardFooter } from "reactstrap";
import api from "../../../api";
import { LabelDate } from "../../Fields";
import MedicInformation from "./Steps/Step7_Medic";
import { showError } from "../../../redux/actions/error";
import { setLoad } from '../../../redux/actions/context'
import { SummaryERHome } from "./Steps/Step2_SummaryERHome";
import { getCodigueraContext } from "../../../redux/selectors/Context";

const styles = theme => ({
  root: {
    width: "100%",
    marginBottom: "0"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: "inline-block"
  },
  instructions: {
    marginTop: "0",
    marginBottom: "0",
    marginLeft: "0",
    padding: "0"
  }
});

class EReporteEdit extends Component {
  state = {
    activeStep: 0,
    skipped: new Set(),
    loadNextStep: false,
    country: ""
  };

  componentDidMount() {
    this.props.getMedicalCountryByMeta(this.props.meta);
  }

  getSteps() {
    return stepsERedit;
  }

  getForm = step => getEReportEditStep(this.props.reduxState, step);

  uploadOriginReport = async (form) => {
    try {
      this.props.setLoad(true);
      await api.post(uploadFileOriginReport, form);
      this.props.setOriginReport(store.dispatch(getEROriginReportMeta({ meta: this.props.ER.MetaData })));
    } catch (error) {
      this.props.showError(error)
    } finally {
      this.props.setLoad(false)
    }
  }

  _handleStepZero = async (form) => {
    try {
      const response = await api.post(url_postPacienteUpdate, form.values);
      this.props.setPaciente(response.data);
    } catch (error) {
      this.props.showError(error);
    }
  }

  _handleStepOne = async (form, data) => {
    try {
      let origRep = form.values;
      data = data.file.content;

      if (this.props.ER.IdEventoHome === null) {
        if (origRep) {
          const url = origRep.Id ? url_postOriginReportUpdate : url_postOriginReportInsert;
          if (!origRep.idEr) {
            origRep.IdER = this.props.ER.Id;
          }
          const response = await api.post(url, origRep);
          this.props.setOriginReport(response.data);
          if (data.length > 0) {
            let aux = new FormData();
            aux.append("Id", response.data.Id);
            const exts = data[0].name ? data[0].name.split('.') : data[0].FileName.split('.');
            const extension = exts[exts.length - 1];
            const name = data[0].name ? data[0].name : data[0].FileName;
            aux.append("Extension", extension);
            aux.append("FileName", name);
            data.forEach((file, i) => {
              aux.append(file.name, new Blob([file]));
            });
            this.uploadOriginReport(aux);
          }
        }
      }
    } catch (error) {
      this.props.showError(error);
    }
  }

  _handleStepTwo = async () => {
    return;
  }

  _handleStepThree = async (data) => {
    return;
  }

  _stepFourHandler = async (form, data) => {
    if (data.length > 0) {
      const body = {
        EvRepId: this.props.ER.Id,
        Medications: data
      }
      try {
        const response = await api.post(url_postERMedicationHistorySet, body);
        this.props.setERMedicationAnt(response.data);
        this.setER(this.props.ER)
      } catch (error) {
        this.props.showError(error);
      }

    }
  }

  _handleStepFive = async (form, data) => {
    try {
      let contMed = form.values;
      const url = contMed.Id ? url_postContactoMedicoUpdate : url_postContactoMedicoInsert;
      if (!contMed.ErId) {
        contMed.ErId = this.props.ER.Id;
      }
      this.props.ER.MedicalContact = contMed.SeContacto;
      const responseMed = await api.post(url, contMed);
      this.props.setERContactoMedico(responseMed.data);
      if (data.length > 0) {
        let aux = new FormData();
        aux.append("Id", responseMed.data.Id);
        const exts = data[0].name ? data[0].name.split(".") : data.Extension;
        let extension = exts[exts.length - 1];
        aux.append("ExtensionArchivo", extension);
        aux.append("NombreArchivo", data[0].name);

        if (data[0].name === null) {
          data.forEach((file, i) => {
            aux.append(file.FileName, new Blob([file.File]));
          });
        } else {
          data.forEach((file, i) => {
            aux.append(file.name, new Blob([file]));
          });
        }

        const response = await api.post(uploadContactoMedico, aux);
        this.setState({ loadNextStep: true });
        let med = response.data;
        med.Archivo = data[0];
        med.NombreArchivo = data[0].name;
        med.ExtensionArchivo = extension;

        responseMed.data.Archivo = med.Archivo;
        responseMed.data.NombreArchivo = med.NombreArchivo;
        responseMed.data.ExtensionArchivo = med.ExtensionArchivo;

        this.props.setERContactoMedico(responseMed.data);
        this.setState({ loadNextStep: false });
      }
    } catch (error) {
      this.props.showError(error);
    }
  }

  _handleStepSix = async (form) => {

    if (form.values.AdditionalInformation !== this.props.ER.AdditionalInformation) {
      try {
        const response = await api.post(url_postERupdate, form.values);
        this.props.setER(response.data);
      } catch (error) {
        this.props.showError(error);
      }
    }


  }

  handleActionStep = (activeStep, form, data) => {
    this.props.setLoad(true)
    switch (activeStep) {
      case 0: {
        this._handleStepZero(form);
        break;
      }
      case 1: {
        this._handleStepOne(form, data);
        break;
      }
      case 2: {
        break;
      }
      case 3: {
        this._handleStepThree(data);
        break;
      }
      case 4: {
        this._stepFourHandler(form, data);
        break;
      }

      case 5: {
        this._handleStepFive(form, data);
        break;
      }
      case 6: {
        this._handleStepSix(form);
        break;
      }
      case 7: {
        break;
      }
      default:
        break;
    }
    this.props.setLoad(false);
  };

  goStep = (skipped, activeStep) => {
    this.setState({ activeStep, skipped });
  };

  handleGoStep = step => {
    let { skipped, activeStep } = this.state;
    let form = this.getForm(activeStep);
    if (form.valid) {
      this.handleActionStep(activeStep, form);
      this.goStep(skipped, step);
    }
  };

  handleNextStep = (data) => {
    const { skipped, activeStep } = this.state;
    const { MedicamentosRelacionados, RAMs, ER } = this.props;
    let form = this.getForm(activeStep);
    let valid = false;
    const haveFile = data && data.file && data.file.haveFile && (data.file.name || data.file.content.length > 0);
    const haveComment = data && data.noTiene && data.noTiene.length > 0
    switch (activeStep) {
      case 1: {
        valid = ER.IdEventoHome !== null;
        if (!valid)
          valid = (!haveFile && haveComment) || haveFile;

        break;
      }
      case 2: {
        if (MedicamentosRelacionados && MedicamentosRelacionados.length > 0) {
          valid = true;
        }
        break;
      }
      case 3: {
        if (ER.GrupoId === 1 && ER.SubGrupoId === 1) {
          if (RAMs && RAMs.length > 0) {
            valid = true;
          }
        } else {
          valid = true;
        }
        break;
      }
      case 5: {
        valid = true;
        break;
      }
      default: {
        valid = form.valid;
        break;
      }
    }
    if (valid) {
      this.handleActionStep(activeStep, form, data);
      this.goStep(skipped, activeStep + 1);
    }
  };

  getStepContent = (step, loadNextStep) => {
    switch (step) {
      case 0:
        return (
          <PacienteData
            meta={this.props.meta}
            loadNext={loadNextStep}
            key={`Step${step}`}
            handleChangeCountry={this.handleChangeCountry}
            title={stepsERedit[0]}
            handleSubmit={this.handleNextStep}
            enableReinitialize
          />
        );
      case 1: {
        if (this.props.ER.IdEventoHome !== null) {
          return (
            <SummaryERHome
              eReportHome={this.props.ERHome}
              codiguera={this.props.codigueras}
              handleSubmit={this.handleNextStep}
            />
          );
        } else {
          return (
            <OriginReport
              meta={this.props.meta}
              loadNext={loadNextStep}
              key={`Step${step}`}
              title={stepsERedit[1]}
              handleSubmit={this.handleNextStep}
              handleBack={this.handleBack}
              enableReinitialize
            />
          );
        }
      }
      case 2:
        return (
          <SuspectMedications
            meta={this.props.meta}
            country={this.props.Paciente.Country}
            loadNext={loadNextStep}
            key={`Step${step}`}
            title={`${stepsERedit[2]}(s)`}
            handleSubmit={this.handleNextStep}
            handleBack={this.handleBack}
            enableReinitialize
          />
        );
      case 3:
        return (
          <AdverseReactions
            meta={this.props.meta}
            loadNext={loadNextStep}
            key={`Step${step}`}
            title={stepsERedit[3]}
            handleSubmit={this.handleNextStep}
            handleBack={this.handleBack}
            enableReinitialize
          />
        );
      case 4:
        return (
          <LastMonths
            ER={this.props.ER}
            meta={this.props.meta}
            loadNext={loadNextStep}
            key={`Step${step}`}
            title={stepsERedit[4]}
            handleSubmit={this.handleNextStep}
            handleBack={this.handleBack}
            enableReinitialize
          />
        );
      case 5:
        return (
          <MedicInformation
            meta={this.props.meta}
            loadNext={loadNextStep}
            key={`Step${step}`}
            title={stepsERedit[5]}
            handleSubmit={this.handleNextStep}
            handleBack={this.handleBack}
            enableReinitialize
          />
        );
      case 6:
        return (
          <RelevantInformation
            meta={this.props.meta}
            loadNext={loadNextStep}
            key={`Step${step}`}
            title={stepsERedit[6]}
            handleSubmit={this.handleNextStep}
            handleBack={this.handleBack}
            enableReinitialize
          />
        );
      case 7:
        return (
          <Summary
            meta={this.props.meta}
            type={this.props.type}
            key={`Step${step}`}
            handleBack={this.handleBack}
            handleBtnEdit={this.handleGoStep}
            enableReinitialize
          />
        );
      default:
        return stepsERedit[7];
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  getClassStep = (activeStep, index) => {
    let ret = "step-default";
    if (index < activeStep) ret = "step-success";
    else if (index === activeStep) ret = "step-primary";
    return ret;
  };

  render() {
    const steps = this.getSteps();
    const { activeStep, loadNextStep } = this.state;
    const { ER, classes } = this.props;
    return (
      <div>
        <CardHeader
          className="p-t-n p-b-n p-r-md p-l-md titleSubGrup"
          title={ER && ER.SubGrupoNombre}
        />
        <CardContent className={classes.root}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            className="p-b-n p-t-n"
          >
            {steps.map((label, index) => {
              return (
                <Step
                  onClick={() => activeStep > index && this.handleGoStep(index)}
                  key={index}
                >
                  <StepButton
                    icon={
                      <Label
                        className={`step ${this.getClassStep(
                          activeStep,
                          index
                        )}`}
                      >
                        {index + 1}
                      </Label>
                    }
                  >
                    <Label
                      className={`step-label ${activeStep > index &&
                        "step-label-success"} ${activeStep === index &&
                        "step-label-primary"}`}
                    >{`${label}${index === 2 ? "(s)" : ""}`}</Label>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          {this.getStepContent(activeStep, loadNextStep)}
        </CardContent>
        <CardFooter className="p-t-n p-b-n">
          {ER && ER.User && (
            <Label className="text-muted m-t-md pull-left">
              Creado por {ER.User.FirstName} {ER.User.LastName}{" "}
              {LabelDate(ER.DateCreation)}
            </Label>
          )}
          {ER && ER.Modificado && (
            <Label className="text-muted m-t-md pull-right">
              Actualizado por {ER.Modificado.Usuario}{" "}
              {LabelDate(ER.Modificado.Fecha)}
            </Label>
          )}
        </CardFooter>
      </div>
    );
  }
}

EReporteEdit.defaultProps = {
  ER: {}
};

EReporteEdit.propTypes = {
  ER: PropTypes.object
};

const mapDispatchToProps = {
  getMedicalCountryByMeta,
  setPaciente,
  setOriginReport,
  setRAMS,
  setERContactoMedico,
  setER,
  setERMedicationAnt,
  showError,
  setLoad
};

const mapStateToProps = state => ({
  reduxState: state,
  ER: getER(state),
  Paciente: getERPaciente(state),
  MedicamentosRelacionados: getERMedicamentosSosp(state),
  OReport: getEROriginReport(state),
  RAMs: getERRAMS(state),
  codigueras: getCodigueraContext(state),
  ERHome: getERHomeReport(state)
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(EReporteEdit)
);

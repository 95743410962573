import React from 'react';

const FormFinished = ({identificador,contacto}) => {
    return (
        <div className="form-finished">
            <h2>¡Muchas gracias por completar el informe en línea!</h2>
            <h4>Apreciamos mucho su contribución  ya que la misma nos ayuda a conocer mejor la seguridad de nuestros medicamentos.</h4>
            <p className="finished">El evento ingresado tiene el código: <span className="identificador">{identificador}</span>, por favor guarde este identificador </p>
            <p className="finished">
                Si tiene más información sobre el evento reportado, o sí hay algún cambio en los datos que nos ha facilitado,
                envíenos un correo electrónico a <a  href={`mailto:${contacto}`}>{contacto}.</a>
            </p>
            <p className="finished">
                Por favor, en toda comunicación con nosotros incluya siempre el código identificador de reporte
                que se informa en la parte superior del mensaje.
            </p>
        </div>
    );
};

export default FormFinished;

import React from "react";
import PropTypes from "prop-types";
import { FieldLabel } from "../../../../Fields";
import Section from "../../../Section";
import { Row, Col } from "react-flexbox-grid";
import { getElementCodiguera } from "../../../../../codigueras";

const renderBody = (ER, medicamentosAnteriores, codiguera) => (
  <div>
    <div
      hidden={
        !(ER.AdministeredMedicationsTextA && ER.AdministeredMedicationsTextB)
      }
    >
      <Row className="noRow m-t-md">
        <Col md={12}>
          <FieldLabel
            label={"Indique otros medicamentos recibidos"}
            value={ER.AdministeredMedicationsTextA}
          />
        </Col>
      </Row>
      <Row className="noRow">
        <Col md={12}>
          <FieldLabel
            label={
              "Indique el consumo de vitaminas, minerales, suplementos, remedios herbales"
            }
            value={ER.AdministeredMedicationsTextB}
          />
        </Col>
      </Row>
    </div>
    <div>
      {medicamentosAnteriores &&
        medicamentosAnteriores.map((ant, key) => (
          <Row key={key} className={`noRow ${key === 0 ? "m-t-md" : ""}`}>
            <Col md={6} sm={12}>
              <FieldLabel
                label={
                  "Medicamento, vitaminas, minerales, suplementos y/o remedios herbales"
                }
                value={ant.Medicamento}
              />
            </Col>
            <Col md={2} sm={12}>
              <FieldLabel
                label={"Tipo"}
                value={getElementCodiguera(
                  "tiposMedAnteriores",
                  ant.Tipo,
                  codiguera
                )}
              />
            </Col>
            <Col md={2} sm={6}>
              <FieldLabel
                label={"Fecha de inicio"}
                value={ant.FechaIni}
                type="date"
              />
            </Col>
            <Col md={2} sm={6}>
              <FieldLabel
                label={"Fecha de fin"}
                value={ant.FechaFin}
                type="date"
              />
            </Col>
          </Row>
        ))}
    </div>
  </div>
);

const LastMonthsDetail = ({
  ER,
  handleBtnEdit,
  step,
  title,
  medicamentosAnteriores,
  codiguera
}) => {
  return (
    <Section title={title} edit={handleBtnEdit} step={step}>
      {ER && renderBody(ER, medicamentosAnteriores, codiguera)}
    </Section>
  );
};

LastMonthsDetail.propTypes = {
  ER: PropTypes.object
};

export default LastMonthsDetail;

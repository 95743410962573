import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { FieldLabel, LabelDate } from "../../../Fields";
import { getElementCodiguera } from '../../../../codigueras';
const SummaryStep7 = ({ values, isMedic, codiguera }) => {
  return (
    <div className="form-step">
      <h3>Información del reportante</h3>
      <Row className="form-step-row">
        <Col sm={12}>
          <FieldLabel
            type="text"
            label="Autoriza usted a ser contactado por nosotros en caso de ser necesaria información adicional para la evaluación"
            value={values.AceptaContacto}
          />
        </Col>
        {isMedic && values.AceptaContacto === "SI" && (
          <Col sm={12} md={6}>
            <FieldLabel
              type="text"
              label="Nombre"
              value={
                values.Medico && values.Medico.Nombre
                  ? values.Medico.Nombre
                  : "---"
              }
            />
          </Col>
        )}
        {isMedic && values.AceptaContacto === "SI" && (
          <Col sm={12} md={6}>
            <FieldLabel
              type="text"
              label="Profesión"
              value={
                values.Medico && values.Medico.Profesion
                  ? values.Medico.Profesion
                  : "---"
              }
            />
          </Col>
        )}
        {isMedic && values.AceptaContacto === "SI" && (
          <Col sm={12} md={6}>
            <FieldLabel
              type="text"
              label="Correo electrónico"
              value={
                values.Medico && values.Medico.Email
                  ? values.Medico.Email
                  : "---"
              }
            />
          </Col>
        )}
        {isMedic && values.AceptaContacto === "SI" && (
          <Col sm={12} md={6}>
            <FieldLabel
              type="text"
              label="Teléfono"
              value={
                values.Medico && values.Medico.Telefono
                  ? values.Medico.Telefono
                  : "---"
              }
            />
          </Col>
        )}
        {isMedic && values.AceptaContacto === "SI" && (
          <Col sm={12} md={6}>
            <FieldLabel
              type="text"
              label="Centro de atención"
              value={
                values.Medico && values.Medico.CentroAtencion
                  ? values.Medico.CentroAtencion
                  : "---"
              }
            />
          </Col>
        )}
        {isMedic && values.AceptaContacto === "SI" && (
          <Col sm={12} md={6}>
            <FieldLabel
              type="date"
              label="Fecha de contacto médico"
              value={
                values.Medico && values.Medico.FechaContacto
                  ? values.Medico.FechaContacto
                  : "---"
              }
            />
          </Col>
        )}
        {!isMedic && values.AceptaContacto === "SI" && (
          <Col sm={12} md={6}>
            <FieldLabel
              type="text"
              label="Relación con el paciente"
              value={  values.Reporta && values.Reporta.Parentesco  
                ? getElementCodiguera('parentesco', values.Reporta.Parentesco, codiguera) 
                : "---"}
            />
          </Col>
        )}
        {!isMedic && values.AceptaContacto === "SI" && (
          <Col sm={12} md={6}>
            <FieldLabel
              type="text"
              label="Correo eletrónico"
              value={
                values.Reporta && values.Reporta.Email
                  ? values.Reporta.Email
                  : "---"
              }
            />
          </Col>
        )}
        {!isMedic && values.AceptaContacto === "SI" && (
          <Col sm={12} md={6}>
            <FieldLabel
              type="text"
              label="Teléfono"
              value={
                values.Reporta && values.Reporta.Telefono
                  ? values.Reporta.Telefono
                  : "---"
              }
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SummaryStep7;

import React from 'react';
import PropTypes from 'prop-types';
import { Modal,
    // ModalHeader,
    ModalBody } from 'reactstrap';
import Section from '../EReportComponents/Section';
import ListComponent from '../ListComponents';
import ListItemComments from '../ListComponents/Item/ListItemComments';

const renderBody = er =>{
    let titles =['Comentario'];
    let comments="";
    comments = er.Comentarios && er.Comentarios.replace("{{FechaCreacion}}",er.FechaSolicitudDesc).replace("\\n"," ");
    return (<ListComponent list={[comments]} titles={titles} ListComponent={ListItemComments}/>)
}

const CommentsModal = ({ER,toggle,open}) => {
    return (
        <Modal fade={false} isOpen={open} toggle={() => toggle()}>
        
          <ModalBody>
                <Section title={`Comentarios del reporte ${ER.NumeroReferencia}`} body={renderBody(ER)}/>
          </ModalBody>
        </Modal>
    );
};

CommentsModal.propTypes = {
    ER: PropTypes.object,
    toggle: PropTypes.func,
    open: PropTypes.bool,
};

export default CommentsModal;

import React from 'react';
import { LabelDate } from '../../Fields';
import { renderStatusMedDRAImport } from '../../../constants/MedDRA';
import { DateTime } from 'luxon'

const renderDuracion = (Item) =>{
    if(Item.date_start && Item.date_end){
        const dateOne = DateTime.fromISO(Item.date_start);
        const dateTwo = DateTime.fromISO(Item.date_end);
        const diff =  dateTwo.diff(dateOne, ["hours", "minutes", "seconds"])
        const values = diff.values
        return `${values.hours}h ${values.minutes}m ${values.seconds}s`
        //return moment(moment(Item.date_end).diff(moment(Item.date_start))).format("m[m] s[s]")
    }
    else return '---';
}

const ListItemMedDRAImport = ({Item, classProp}) => {
    return (
        <tr className={`list-item ${classProp}`}>
            <td className="p-md text-left">
            {Item.table}
            </td>
            <td className="p-md">
                {renderStatusMedDRAImport(Item.status)}
            </td>
            <td className="p-md">
                {LabelDate(Item.date_start)}
            </td>
            <td className="p-md">
                {renderDuracion(Item)}
            </td>
            <td className="p-md">
                {Item.insterted}
            </td>
        </tr>
    );
};

export default ListItemMedDRAImport;

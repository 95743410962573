import { createStore, compose, applyMiddleware } from 'redux';
import reducers from './../reducers';
import { showError } from '../actions/error';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const isPromise = p => {
  if(Promise && Promise.resolve){
    return Promise.resolve(p) === p;
  }
  else{
    console.log(new Error("No se pueden resolver promesas"));
  }
}

const catchError = store => next => action => {
    try {
      if(isPromise(action.payload)){
        action.payload.then(r => {
            let act = action;
            act.payload = r.data;
            next(act);
        }).catch(showError);
      }else{
        next(action);
      }
    }catch (error) {
      
    }
};
export const store = createStore(reducers, {},
    composeEnhancers(applyMiddleware(catchError, thunk)));

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Alert
} from "reactstrap";
import { Button } from "@material-ui/core";
import { Row, Col } from "react-flexbox-grid";
import api from "../../api";
import { url_getSubGroups, url_getCountries } from "../../api/urls";
import { getCodigueraContext, getMyUser } from "../../redux/selectors/Context";
import { getCodiguerasContext, setLoad, getMyUser as MyUser } from "../../redux/actions/context";
import { showError } from '../../redux/actions/error';
import { shake } from "react-animations";
import jss from "jss";
import preset from "jss-preset-default";
import { sortSoubGroups } from "../../helpers/utilities";
import SubGroupComponent from "../SubGroup";
import { compareSort } from './../../helpers/utilities';

jss.setup(preset());

const { classes } = jss
  .createStyleSheet({
    "@keyframes shake": shake,
    shake: { animationName: "shake", animationDuration: "0.6s" }
  })
  .attach();

class CreateEventModal extends Component {
  state = {
    ant: 0,
    subGroup: "0",
    country: "",
    subGroups: [],
    countries: [],
    loadSubGroups: true
  };

  handleSelectSubGroup = e => this.setState({ subGroup: e.target.value });

  handleSelectCountry = e => this.setState({ country: e.target.value });

  componentDidMount = async () => {
    if (this.props.codiguera) {
      this.setState({
        subGroups: sortSoubGroups(this.props.codiguera.SubGroups)
      });
      this.setState({
        countries: sortSoubGroups(this.props.codiguera.Countries)
      });
    } else {
      try{
        this.props.getCodiguerasContext();
        this.props.setLoad(true);
        const response = await api.get(url_getCountries);
        this.setState({ countries: response.data});
        const resp = await api.get(url_getSubGroups);
        this.setState({ subGroups: sortSoubGroups(resp.data) });
      }catch(error){
        this.props.showError(error)
      }finally{
        this.props.setLoad(false);
      }
    }
  };

  componentWillUnmount = () => {
    this.setState({
      ant: 0,
      subGroup: "0",
      country: "",
      subGroups: [],
      countries: [],
      loadSubGroups: true
    });
  }

  showError = () => this.props.errorPais || this.props.errorSuGrup;

  miToggle = () => {
    this.setState({
      subGroup: "0",
      country: ""
    });
    this.props.toggle();
  };

  render() {
    const { open, handleConfirm, errorPais, errorSubGroup } = this.props;
    const { subGroups, subGroup, countries, country } = this.state;
    return (
      <Modal
        size="lg"
        fade={false}
        backdrop={"static"}
        isOpen={open}
        toggle={() => this.miToggle()}
        className={
          this.showError()
            ? [classes.shake, "signature-modal-error"]
            : "signature-modal"
        }
      >
        <ModalHeader toggle={() => this.miToggle()}>
          Crear Evento Reportable
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12} hidden={!errorPais}>
              <Alert color="danger">Debe seleccionar un país</Alert>
            </Col>
            <Col sm={12} hidden={!errorSubGroup}>
              <Alert color="danger">
                Debe indicar el tipo de reporte que quiere crear
              </Alert>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <FormGroup>
                <Label>País</Label>
                {(() => {
                if(this.props.myUser.Rol == 'RLF'){
                  return (
                    <Input type="select" onChange={this.handleSelectCountry}>
                      <option value="none">Seleccionar...</option>
                      {countries.filter(x => x.Codigo == this.props.myUser.Country).map(c => (
                        <option value={c.Codigo} key={c.Id}>
                          {c.Nombre}
                        </option>
                      ))}
                    </Input>
                  )
                } else {
                  return (
                    <Input type="select" onChange={this.handleSelectCountry}>
                      <option value="none">Seleccionar...</option>
                      {countries.filter(x => !x.Eliminado).sort(compareSort('Nombre')).map(c => (
                        <option value={c.Codigo} key={c.Id}>
                          {c.Nombre}
                        </option>
                      ))}
                    </Input>
                  )
                }
                  
                })()}
              </FormGroup>
            </Col>
          </Row>
          <SubGroupComponent
            selected={subGroup}
            subGroups={subGroups}
            onSelect={this.handleSelectSubGroup}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            className="card-actions-button button-back"
            variant="contained"
            onClick={() => this.miToggle()}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            className="card-actions-button button-next"
            variant="contained"
            onClick={() => handleConfirm(subGroup, country)}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

CreateEventModal.propTypes = {
  handleConfirm: PropTypes.func,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  codiguera: PropTypes.object
};

const mapDispatchToProps = {
  getCodiguerasContext,
  setLoad,
  showError
};

const mapStateToProps = state => ({
  myUser: getMyUser(state),
  codiguera: getCodigueraContext(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEventModal);

import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import { FieldText, FieldSelect, FieldSwitch } from "../../Fields";
import { getCodiguera } from "../../../codigueras";

const UserDetails = props => {
  const roles = getCodiguera("Roles", props.codiguera);
  
  return (
    <Row>
      {
        props.isToggleEnable &&
          <Col sm={12}>
            <FieldSwitch
              label="Habilitado"
              icons={null}
              name="Details.IsActive"
            />
          </Col>
      }
      <Col sm={12}>
        <FieldText
          label="Correo eléctronico"
          type="text"
          name="Details.Email"
          disabled={props.view !== "create"}
          isRequired={true}
        />
      </Col>
      <Col sm={12}>
        <FieldText
          label="Nombre"
          type="text"
          name="Details.FirstName"
          isRequired={true}
        />
      </Col>
      <Col sm={12}>
        <FieldText
          label="Apellido"
          type="text"
          name="Details.LastName"
          isRequired={true}
        />
      </Col>
      <Col sm={12}>
        <FieldSelect
          label="País"
          name="Details.Country"
          items={getCodiguera("Paises", props.codiguera)}
          valueField="Codigo"
          textField="Nombre"
          isRequired={true}
        />
      </Col>
      <Col sm={12}>
        <FieldSelect
          disabled={
            props.myUser &&
            props.myUser.Rol !== "QPPV" && props.myUser.Rol !== "ADMIN"
          }
          label="Rol"
          name="Details.UserType"
          items={roles ? roles : [{ Name: props.myUser.Rol }]}
          valueField="Name"
          textField="Name"
          isRequired={true}
        />
      </Col>
      <Col sm={12}>
        <FieldSelect
          label="Especialidad"
          name="Details.Speciality.Id"
          items={getCodiguera("Especialidad", props.codiguera)}
          valueField="Id"
          textField="Description"
          isRequired={true}
        />
      </Col>
    </Row>
  );
};

const validate = values => {
  const error = {};
  if(!values.Details) return error;
  if (!values.Details.Email) error.Email = "Correo electrónico requerido";
  if (!values.Details.FirstName || values.Details.FirstName.trim().length === 0 ) error.FirstName = "Nombre requerido";
  if (!values.Details.LastName  || values.Details.LastName.trim().length === 0) error.LastName = "Apellido requerido";
  if (!values.Details.Country || values.Details.Country === 'none' || values.Details.Country === 'Seleccionar...') error.Country = "País requerido";
  if (!values.Details.UserType || values.Details.UserType === 'none' || values.Details.UserType==='Seleccionar...' ) error.UserType = "Rol requerido";
  if (!values.Details.Speciality || !values.Details.Speciality.Id || values.Details.Speciality.Id === 'none' || values.Details.Speciality.Id==='Seleccionar...') error.Speciality="Especialidad requerido";
  
  return error;
};

export default connect(
  null,
  null
)(reduxForm({ form: "UserDetails", validate, enableReinitialize: true })(UserDetails));

export const PAGE_SIZE = 15;

export const GET_MY_TOKEN = "GET_MY_TOKEN";
export const GET_MY_USER = "GET_MY_USER";
export const USER_LOGOUT = "USER_LOGOUT";

export const GET_CODIGUERAS_CONTEXT = "GET_CODIGUERAS_CONTEXT";
export const GET_CODIGUERAS_RAM = "GET_CODIGUERAS_RAM";
export const GET_FILTERS_SEARCH = "GET_FILTERS_SEARCH";
export const GET_PRODUCTS_LABS_FILTERS_SEARCH =
  "GET_PRODUCTS_LABS_FILTERS_SEARCH";
export const SET_PRODUCTS_LABS_FILTERS_SEARCH =
  "SET_PRODUCTS_LABS_FILTERS_SEARCH";
export const GET_MEDICATIONS_BY_COUNTRY = "GET_MEDICATIONS_BY_COUNTRY";

export const GET_WORKFLOW_LIST = "GET_WORKFLOW_LIST";
export const GET_WORKFLOW_LIST_INVERT = "GET_WORKFLOW_LIST_INVERT";

export const GET_ER = "GET_ER";
export const GET_ER_CREATE = "GET_ER_CREATE";
export const GET_ER_PACIENTE = "GET_ER_PACIENTE";
export const GET_ER_ORIGIN_REPORT = "GET_ER_ORIGIN_REPORT";
export const GET_ER_MEDICATIONS_SUSP = "GET_ER_MEDICATIONS_SUSP";
export const GET_RAM = "GET_RAM";
export const GET_ER_RAMS = "GET_ER_RAMS";
export const GET_ER_DOCS_AND_NOTES = "GET_ER_DOCS_AND_NOTES";
export const GET_ER_WHO_REPORT = "GET_ER_WHO_REPORT";
export const GET_ER_OPERATION_HISTORY = "GET_ER_OPERATION_HISTORY";
export const GET_ER_FOLLOW_UP = "GET_ER_FOLLOW_UP";
export const GET_ER_IMPUTABILITY_EVALUATION = "GET_ER_IMPUTABILITY_EVALUATION";
export const GET_ER_CONTACTO_MEDICO = "GET_ER_CONTACTO_MEDICO";
export const GET_ER_FILTER = "GET_ER_FILTER";
export const GET_ER_ACTIONS = "GET_ER_ACTIONS";
export const GET_ER_RAM_CATEGORY = "GET_ER_RAM_CATEGORY";
export const GET_INDICATOR_VALUES = "GET_INDICATOR_VALUES";
export const GET_INDICATOR_STATUS = "GET_INDICATOR_STATUS";
export const GET_INDICATOR_GROUPS = "GET_INDICATOR_GROUPS";
export const GET_INDICATOR_AGES = "GET_INDICATOR_AGES";
export const GET_ER_MEDICATION_HISTORY = "GET_ER_MEDICATION_HISTORY";
export const GET_USERS_FILTER = "GET_USERS_FILTER";
export const GET_AUDIT_USERS = "GET_AUDIT_USERS";
export const GET_AUDIT_USERS_DETAILS = "GET_AUDIT_USERS_DETAILS";
export const GET_AUDIT_ER = "GET_AUDIT_ER";
export const GET_AUDIT_CONFIG = "GET_AUDIT_CONFIG";
export const GET_MEDDRA_IMPORTS = "GET_MEDDRA_IMPORTS";
export const GET_MEDDRA_IMPORT_CODE = "GET_MEDDRA_IMPORT_CODE";
export const GET_CLEAN_MEDDRA_IMPORT_CODE = "GET_CLEAN_MEDDRA_IMPORT_CODE";
export const GET_ER_FABRIC_APPROVED = "GET_ER_FABRIC_APPROVED";
export const GET_HOME_ER = "GET_HOME_ER";
export const GET_WF_HOME_ER = "GET_WF_HOME_ER";
export const GET_ER_HOME_ER = "GET_ER_HOME_ER";
export const GET_AUDIT_PARAMS = "GET_AUDIT_PARAMS";
export const GET_DATA_IMPORTS = "GET_DATA_IMPORTS";
export const GET_DATA_IMPORT_CODE = "GET_DATA_IMPORT_CODE";
export const GET_CLEAN_DATA_IMPORT_CODE = "GET_CLEAN_DATA_IMPORT_CODE";
export const GET_INDICATOR_COUNTRIESFILTERS = "GET_INDICATOR_COUNTRIESFILTERS";
export const GET_INDICATOR_LABSFILTERS = "GET_INDICATOR_LABSFILTERS";

import { createAction } from "redux-actions";
import {
  GET_MEDDRA_IMPORTS,
  GET_MEDDRA_IMPORT_CODE,
  GET_CLEAN_MEDDRA_IMPORT_CODE
} from "../constants/get";


export const setMedDRAImports = createAction(GET_MEDDRA_IMPORTS, u => u);
export const setMedDRAImportsCode = createAction(
  GET_MEDDRA_IMPORT_CODE,
  u => u
);
export const setCleanMedDRAImportsCode = createAction(
  GET_CLEAN_MEDDRA_IMPORT_CODE,
  u => u
);

import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import { FieldText, FieldSwitch } from "../Fields";

const disabledFileds= ["Notificaciones_Error"]

const getField = setting => {
  if (setting.Type_value === "boolean")
    return (
      <FieldSwitch
        label={setting.Description_setting}
        name={setting.Name_setting}
        icons={null}
      />
    );
  else
    return (
      <FieldText
        label={setting.Description_setting}
        name={setting.Name_setting}
        type={setting.Type_value}
        disabled = {disabledFileds.indexOf(setting.Name_setting) > -1}
      />
    );
};

const SettingComponent = ({ settings }) => {
  return (
    <Row>
      <Col sm={12}>
        <h2>Envío de correo</h2>
      </Col>
      {settings.map(
        (s, i) =>
          s.Group_setting === "SMTP" && (
            <Col key={i} sm={6} md={4} lg={3}>
              {getField(s)}
            </Col>
          )
      )}
      <Col sm={12}>
        <h2>Usuario</h2>
      </Col>
      {settings.map(
        (s, i) =>
          s.Group_setting === "Usuarios" && (
            <Col key={i} sm={6} md={4} lg={3}>
              {getField(s)}
            </Col>
          )
      )}
      <Col sm={12}>
        <h2>Evento Reportable</h2>
      </Col>
      {settings.map(
        (s, i) =>
          s.Group_setting === "Evento Reportable" && (
            <Col key={i} sm={6} md={4} lg={3}>
              {getField(s)}
            </Col>
          )
      )}
      <Col sm={12}>
        <h2>Web</h2>
      </Col>
      {settings.map(
        (s, i) =>
          s.Group_setting === "Web" && (
            <Col key={i} sm={6} md={4} lg={3}>
              {getField(s)}
            </Col>
          )
      )}
            <Col sm={12}>
        <h2>Notificación de errores</h2>
      </Col>
      {settings.map(
        (s, i) =>
          s.Group_setting === "Notificaciones_Error" && (
            <Col key={i} sm={6} md={4} lg={3} disabled>
              {getField(s)}
            </Col>
          )
      )}
    </Row>
  );
};

const validate = values => {
  const error = {};

  return error;
};

export default connect(
  null,
  null
)(reduxForm({ form: "Settings", validate })(SettingComponent));

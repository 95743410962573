import { createAction } from "redux-actions";
import {
  url_indicator_getAges,
  url_indicator_getEstados,
  url_indicator_getGrupos,
  url_indicator_getValores,
  url_indicators_filtercountry,
  url_indicators_filterlab
} from "../../api/urls";
import {
  GET_INDICATOR_AGES,
  GET_INDICATOR_GROUPS,
  GET_INDICATOR_STATUS,
  GET_INDICATOR_VALUES,
  GET_INDICATOR_COUNTRIESFILTERS,
  GET_INDICATOR_LABSFILTERS
} from "../constants/get";
import api from "../../api";

export const getIndicatorAges = createAction(
  GET_INDICATOR_AGES,
  (paisId, labId) => api.get(url_indicator_getAges(paisId, labId))
);
export const getIndicatorStatus = createAction(
  GET_INDICATOR_STATUS,
  (paisId, labId) =>  api.get(url_indicator_getEstados(paisId, labId))
);
export const getIndicatorGroups = createAction(
  GET_INDICATOR_GROUPS,
  (paisId, labId) => api.get(url_indicator_getGrupos(paisId, labId))
);
export const getIndicatorValues = createAction(
  GET_INDICATOR_VALUES,
  (paisId, labId) => api.get(url_indicator_getValores(paisId, labId))
);

export const getIndicatorCountries = createAction(
  GET_INDICATOR_COUNTRIESFILTERS,
  () => api.get(url_indicators_filtercountry)
);

export const getIndicatorLabs = createAction(
  GET_INDICATOR_LABSFILTERS,
  paisId => api.get(url_indicators_filterlab(paisId))
);

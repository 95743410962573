import React from 'react';
import PropTypes from 'prop-types';
import ListActions from './ListActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPrescriptionBottleAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const actions = (view, handleAction, Item) => {
    let ret= [];
    switch(view){
        case "edit":{ 
            ret =[ <Link to={'#'} onClick={() => handleAction("edit",Item)}><FontAwesomeIcon icon={faPen} size={'lg'}/></Link>
             ,<Link to={'#'} onClick={() => handleAction("delete",Item)}><FontAwesomeIcon icon={faTrashAlt} size={'lg'}/></Link>
                ];
            break;
        }
        case "recovery":{ 
            ret =[<Link to={'#'} onClick={() => handleAction("recovery",Item)}><FontAwesomeIcon icon={faPrescriptionBottleAlt} size={'lg'}/></Link>];
            break;
        }
        default: break;
    }
    return ret;
} 
const ListItemMR = ({Item, classProp,View, handleAction}) => {
    return (
        <tr className={`list-item ${classProp}`}>
            <td>{`${Item.Linea.Nombre}`}</td>
            <td>{Item.NombreComercial}</td>
            <td>{Item.Familia}</td>
            <td className="p-t-sm"><ListActions  actions={actions(View,handleAction,Item)}/></td>
     </tr>
    );
};

ListItemMR.propTypes = {
    Item: PropTypes.object,
};

export default ListItemMR;
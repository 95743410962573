import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft,faAngleDoubleRight,faAngleRight,faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import './AppStyle.css';


const AppPaginator = ({ handlePagination, result, mini, message }) => {
    return (
        result && result.Total > 0 &&
        <nav className="app-pagination">
            <ul className="pagination">
                <li className="page-item m-t-sm m-r-md">
                    <span>{!mini && `${result.Total} ${message} encontrados.`}</span>
                </li>
            </ul>
            {result.PageCount>0 &&
            <ul className="pagination">
                <li className={`page-item ${ 1 === result.PageNumber && 'disabled'}`} >
                    <Link className={`page-link`}  to={'#'} onClick={() => handlePagination(1)} ><FontAwesomeIcon icon={faAngleDoubleLeft} />&nbsp;{!mini && 'Primera'}</Link>
                </li>
                <li className={`page-item ${!result.PageBack && 'disabled'}`}>
                    <Link className={`page-link`}  to={'#'} onClick={() => handlePagination(result.PageBack)}><FontAwesomeIcon icon={faAngleLeft} />&nbsp;{!mini && 'Anterior'}</Link>
                </li>
                <li className="page-item m-t-sm m-l-md m-r-md" >
                    <span className="pull-left">{result.PageNumber} {mini ? '/' :'de'} {result.PageCount} {!mini && 'páginas'}</span>
                </li>
                <li className={`page-item ${!result.PageNext && 'disabled'}`} >
                    <Link className={`page-link`} to={'#'} onClick={() => handlePagination(result.PageNext)}>{!mini && 'Siguiente'}&nbsp;<FontAwesomeIcon icon={faAngleRight} /></Link>
                </li>
                <li className={`page-item ${(!result.PageCount || result.PageNext === result.PageCount) && 'disabled'}`}>
                    <Link className={`page-link`}  to={'#'} onClick={() => handlePagination(result.PageCount)}>{!mini && 'Última'}&nbsp;<FontAwesomeIcon icon={faAngleDoubleRight} /></Link>
                </li>

            </ul>}
        </nav>
    );
};

AppPaginator.propTypes = {
    result : PropTypes.object,
};

export default AppPaginator;

import React, { Component } from 'react';
import AppContainer from '../components/AppComponents/AppContainer';
import IndicatorsComponent from '../components/Indicactors';
import setPropsAsInitial from '../helpers/setPropsAsInitial';
import { withRouter }  from 'react-router-dom';
class IndicatorsContainer extends Component {
    componentWillMount = () =>{
        this.props.showAppFrame(this.props.location.pathname);
    }
    render(){
        return (
            <AppContainer title="Indicadores" className="background-f4f4f4 p-t-n" menuSelected="indicators">
                <IndicatorsComponent/>
            </AppContainer>
        );
    }
};

IndicatorsContainer.propTypes = {

};

export default withRouter((setPropsAsInitial)(IndicatorsContainer));

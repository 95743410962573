import React from 'react';
import PropTypes from 'prop-types';
import ListComponent from '..';
import ListItemFU from '../Item/ListItemFU';

const ListFollowUp = ({list}) => {
    let titles =['Fecha','Usuario','Reporte de seguimiento',''];
    return (<ListComponent  titles={titles} list={list} ListComponent={ListItemFU} empty="reportes de seguimiento"></ListComponent>);
};

ListFollowUp.propTypes = {
    FollowUp: PropTypes.array,
};

export default ListFollowUp;
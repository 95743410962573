import React from 'react';
import ListComponent from '..';
import ListItemMedDRAImport from '../Item/ListItemMedDRAImport';
import { LabelDate } from '../../Fields';
import { renderStatusMedDRAImport } from '../../../constants/MedDRA';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

const ListMedDRAImports = ({list,handleAction ,View,loadList }) => {
    const titles = ['Tabla','Estado','Inicio','Duración','Insertados'];  
    return (
        <ul className='ul-table'>
            <li className='li-table li-head-table'>
                <div className='li-row-table row-5 li-row-head-table'>
                    <div className='li-cell-table li-cell-head-table'>Import-ID</div>
                    <div className='li-cell-table li-cell-head-table'>Versión</div>
                    <div className='li-cell-table li-cell-head-table'>Usuario</div>
                    <div className='li-cell-table li-cell-head-table'>Inicio</div>
                    <div className='li-cell-table li-cell-head-table'>Finalizado</div>
                    <div className='li-cell-table li-cell-head-table'>Estado</div> 
                </div>
            </li>
        {list.map(item=>{
            return (<li className='li-table' key={item.code_import}>
                <div className='li-row-table row-5'>
                    <div className='li-cell-table' onClick={()=>handleAction(item,(item.Tables))}><FontAwesomeIcon className="m-r-sm" icon={(item.Tables) ? faCaretDown : faCaretRight }/> #{item.code_import}</div>
                    <div className='li-cell-table' >{item.meddra_version}</div>
                    <div className='li-cell-table' >{item.Usuario}</div>
                    <div className='li-cell-table'>{LabelDate(item.date_start)} </div>
                    <div className='li-cell-table'>{LabelDate(item.date_end)}</div>
                    <div className='li-cell-table'>{renderStatusMedDRAImport(item.status)} {item.percentage ? Math.trunc(item.percentage) + '%' : ''}</div> 
                </div>
                {(item.Tables) &&
                <div className='li-row-table p-t-sm p-l p-r p-b'>
                    <ListComponent titles={titles} list={item.Tables} View={View} loadList={loadList} ListComponent={ListItemMedDRAImport} empty="importaciones" />
                </div>}
            </li>)
        })}
        </ul>
    );

};

export default ListMedDRAImports;
import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {

      main: '#01b5af',
      dark: '#00695f',

    },
    secondary: {
      main: '#b5539d',
    
    },
  },
});

import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ListAuditDetail from "../ListComponents/List/ListAuditDetail";
import { titleAuditType } from "../../constants/audit";
import { Label } from "semantic-ui-react";
import api from "../../api";
//import moment from "moment";
import { Label as LabelReacStrap, Input, FormGroup } from "reactstrap";
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { showError } from '../../redux/actions/error';
import { LabelDate } from "../Fields";

const showDate = (date) => {
    const newDate = DateTime.fromISO(date).toFormat('f');
    return newDate
}

const downloadFile = async (value, log, details) => {
  try {
    let name = value === "old" ? details.OldValue : details.NewValue;
    const response = await api.downloadAuditFile({value, log, details, name});
  } catch (e) {
    showError(e);
  }
};

const LogDetailModal = ({ log, toggle, open, title }) => {
  return (
    <Modal
      backdrop={"static"}
      fade={false}
      isOpen={open}
      toggle={() => toggle()}
    >
      <ModalHeader toggle={() => toggle()}>{title}</ModalHeader>
      <ModalBody>
        <ListAuditDetail handleAction={downloadFile} log={log} list={log.log} />
        {log.log[0].Comentario && (
          <FormGroup className="m-t-md">
            <LabelReacStrap>Comentario de firma</LabelReacStrap>
            <Input
              value={log.log[0].Comentario}
              className="form-control"
              type="textarea"
              disabled={true}
            />
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Label className="text-muted">{`${titleAuditType(
          log.OperationType
        )} por ${log.User} a las ${LabelDate(log.DateUTC)}hs.`}</Label>
      </ModalFooter>
    </Modal>
  );
};

LogDetailModal.propTypes = {
  log: PropTypes.object
};
const mapStateToProps = (state) =>{

};

const mapDispatchToProps = {
  showError
}
export default connect(mapStateToProps, mapDispatchToProps)(LogDetailModal);

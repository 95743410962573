import React from 'react';
import ListComponent from '..';
import ListItemAuditConfig from '../Item/ListItemAuditConfig';

const ListAuditConfig = ({list,handleAction,load,loadList}) => {
    return (
        <ListComponent 
            loadList={loadList}
            mini={false} 
            load={load}  
            handleAction={handleAction} 
            empty={'registros'} 
            titles={['Tipo','Registro','Generado por','Fecha','']} 
            list={list} 
            ListComponent={ListItemAuditConfig}  />
    );
};

export default ListAuditConfig;
import  React from 'react';
import {Link} from 'react-router-dom';

const HomeContent =  React.forwardRef((props, ref) => (
    <div ref={ref}>
        <div className="content-wrap" id="objetivos">
            <h1>Quiénes Somos</h1>
            <p className="objetivos-stit">
                El <b>CIM</b> es el Centro Interamericano de Monitoreo, un servicio de Farmacovigilancia creado por Megalabs.
            </p>
            <p className="objetivos-stit">
                Nuestro objetivo es proporcionar un canal para informar eventos no deseados relacionados con el uso de nuestros productos.
            </p>
        </div>
    </div>
))
export default HomeContent;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ListMedication from '../../../../ListComponents/List/ListMedication';
import { getERMedicamentosSosp, getER } from './../../../../../redux/selectors/ER';
import ProductRecoveryForm from './ProductRecoveryForm';
import { url_postMedicamentoRecuperadoUpdate, uploadFileRecProduct } from '../../../../../api/urls';
import { updateERMedicalSusp } from '../../../../../redux/actions/eventoReportable';
import api from '../../../../../api';
import { getMyUser } from '../../../../../redux/selectors/Context';
import { setLoad } from '../../../../../redux/actions/context';
import { showError } from '../../../../../redux/actions/error';

class ProductRecovery extends Component {

    state = {
        medicine:null,
        hiddePrincipios:true,
        load:false
    }

    medicineBackUp = null;

    handleTogglePrincipios = () => this.setState({hiddePrincipios: !this.state.hiddePrincipios});

    handleToggleRecovery = () => this.setState({hiddeRecovery: !this.state.hiddeRecovery});

    handleSelect = (medicine) => {
        if(this.medicineBackUp == null) {
            this.setState({medicine,hiddePrincipios:true});
            this.medicineBackUp = medicine;
        } else {
            this.setState({medicine:this.medicineBackUp,hiddePrincipios:true});
        }
    }

    handleCancel =  () => this.setState({medicine:null, hiddePrincipios:true,load:false});

    handleSave = async (medicine, state) => {
        try{
            this.setState({ load: true });
            this.props.setLoad(true);
            const response = await api.post(url_postMedicamentoRecuperadoUpdate, medicine);
            this.props.updateERMedicalSusp(response.data);
            if(state.files.length > 0){
                let aux = new FormData();
                aux.append("Id", medicine.Recuperado.Id);
                const file = state.files[0];
                const exts = file.name.split('.');
                let extension = exts[exts.length - 1];
                aux.append("Extension", extension);
                aux.append("FileName", file.name);
                state.files.forEach((file, i) => {
                    aux.append(file.name, new Blob([file]));
                });
                medicine.Recuperado.Extension = extension;
                medicine.Recuperado.NombreArchivo = file.name;
                this.setState({medicine});
                await api.post(uploadFileRecProduct, aux);
            }
        } catch(error) {
            this.props.showError(error);
        } finally {
            this.handleCancel();
            this.props.setLoad(false);
            this.medicineBackUp = medicine;
        }
    }

    handleAction = (action, medication) =>{
        switch(action){
            case "recovery":{
                this.handleSelect(medication);
                break;
            }
            default: break;
        }
    }

    disabledImput = () =>{
        let ret = false;
        const {User, ER} = this.props;
        if(User.Rol==="QPPV"){
            ret = User.Id !== ER.User.UserID;
        }
        return  ret;
    }

    render (){
        const { medicine, hiddePrincipios, load } = this.state;
        return (
            <div>
                {medicine && <ProductRecoveryForm disabled={this.disabledImput()} medicine={medicine}
                                hiddePrincipios={hiddePrincipios}
                                togglePrincipios={this.handleTogglePrincipios}
                                initialValues={medicine}
                                recoverySave={this.handleSave}
                                recoveryCancel={this.handleCancel}
                                loadSave={load} />}
                <ListMedication list={this.props.ERMedications} View={'recovery'} handleAction={this.handleAction}  />
            </div>
        );
    }
};

ProductRecovery.propTypes = {
    ERMedications: PropTypes.array,
};

const mapStateToProps = (state) =>({
    ERMedications: getERMedicamentosSosp(state),
    ER: getER(state),
    User: getMyUser(state),
});

const mapDispatchToProps = {
    updateERMedicalSusp,
    setLoad,
    showError
};


export default connect(mapStateToProps,mapDispatchToProps)(ProductRecovery);;

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {Link,withRouter,NavLink} from 'react-router-dom';
import ListActions from './ListActions';
import { iconAuditType, titleAuditType } from '../../../constants/audit';
import moment from "moment-timezone";
import { LabelDate } from "../../Fields";

class ListItemAuditUser extends Component {
    actions = (Item,handleAction,load) => [
        <Link to={'#'} onClick={() => handleAction(Item)}>{load===`${Item.DateUTC}` ?<CircularProgress size={15} />:<FontAwesomeIcon icon={faEye} size={'lg'}/>}</Link>
    ];
    render(){
        const {Item, classProp,load, handleAction}  = this.props;
        return (
            <tr className={`list-item ${classProp}`}>
                <td>{<FontAwesomeIcon color="#01b5af" title={titleAuditType(Item.OperationType)} icon={iconAuditType(Item.OperationType)}/>}</td>
                <td className='text-left' ><NavLink to={`/adminusers/${Item.PKId}`}>{Item.ReferenceNumber}</NavLink></td>
                <td>{Item.User}</td>
                <td>{LabelDate(Item.DateUTC)}hs.</td>
                <td><ListActions actions={this.actions(Item, handleAction, load)}/></td>
            </tr>
        );
    }
};

export default withRouter(ListItemAuditUser);

import { createAction } from "redux-actions";
import {
  url_getWorkFlow,
  url_getERMeta,
  url_getPacienteERMeta,
  url_getOriginReportERMeta,
  url_getMedicalSuspMeta,
  url_getRAMERMeta,
  url_getDocsAndNotesMeta,
  url_getWhoReportERMeta,
  url_getOperatinHistoryERMeta,
  url_getFollowUpERMeta,
  url_getImputEvaluationByMeta,
  url_getWorkFlowInvert,
  url_getActionsERByMeta,
  url_getERNew,
  url_getContactoMedicoByMeta,
  url_getRAMCategoriaByRAMId,
  url_getMedicationHistoryByMeta,
  url_getApprovedFabricByMeta,
  url_getReportHome,
  url_getWFReportHome,
  url_getEventoDeHomeByMeta
} from "../../api/urls";
import {
  GET_WORKFLOW_LIST,
  GET_WORKFLOW_LIST_INVERT,
  GET_ER,
  GET_ER_CREATE,
  GET_ER_PACIENTE,
  GET_ER_ORIGIN_REPORT,
  GET_ER_MEDICATIONS_SUSP,
  GET_ER_RAMS,
  GET_RAM,
  GET_ER_DOCS_AND_NOTES,
  GET_ER_WHO_REPORT,
  GET_ER_OPERATION_HISTORY,
  GET_ER_FOLLOW_UP,
  GET_ER_IMPUTABILITY_EVALUATION,
  GET_ER_FILTER,
  GET_ER_ACTIONS,
  GET_ER_CONTACTO_MEDICO,
  GET_ER_RAM_CATEGORY,
  GET_ER_MEDICATION_HISTORY,
  GET_ER_FABRIC_APPROVED,
  GET_HOME_ER,
  GET_WF_HOME_ER,
  GET_ER_HOME_ER
} from "../constants/get";
import { ADD_RAM_ER } from "../constants/add";
import {
  INSERT_ER_NEW,
  INSERT_ER_MEDICATION_HISTORY
} from "../constants/insert";
import {
  SET_ER,
  SET_ER_PACIENTE,
  SET_ER_ORIGIN_REPORT,
  SET_ER_RAM,
  SET_ER_IMPUTABILITY_EVALUATION,
  SET_ER_MEDICATIONS_SUSP,
  SET_WORKFLOW_LIST,
  SET_ER_ACTIONS,
  SET_ER_CONTACTO_MEDICO,
  SET_ER_RAM_CATEGORY,
  SET_ER_MEDICATION_HISTORY,
  SET_ER_FABRIC_APPROVED,
  SET_HOME_ER
} from "../constants/set";
import {
  UPD_ER_MEDICATIONS_SUSP,
  UPD_ER_IMPUTABILITY_EVALUATION,
  UPD_ER_RAM,
  UPD_ER_MEDICATION_HISTORY,
  UPD_ER_FABRIC_APPROVED
} from "../constants/update";
import {
  DEL_ER_MEDICATIONS_SUSP,
  DEL_ER_RAM_CATEGORY,
  DEL_ER_MEDICATION_HISTORY,
  DEL_ER_WORK_FLOW
} from "../constants/delete";
import api from "../../api";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { dispatchObject } from '../../helpers/utilities'


const client = api;

/*GET LIST*/
export const getWorkFlowList = createAsyncThunk(
  GET_WORKFLOW_LIST,
  async (args, thunkApi) =>{
    const response = await  client.get(url_getWorkFlow(
        args.pageNumber,
        args.orderBy,
        args.statusId,
        args.idEr,
        args.from,
        args.to,
        args.categoryId,
        args.subGroupId
    ));
    thunkApi.dispatch(dispatchObject(GET_WORKFLOW_LIST, response.data));
    return response.data;
});

export const getWorkFlowListInvert = createAsyncThunk(
  GET_WORKFLOW_LIST_INVERT,
  async (args, thunkApi) =>{
    const response = await client.get(url_getWorkFlowInvert(
      args.pageNumber,
      args.orderBy
    ));
    thunkApi.dispatch(dispatchObject(GET_WORKFLOW_LIST_INVERT, response.data));
    return response.data;
});

export const setWorkFlowList = createAction(SET_WORKFLOW_LIST, list => list);

/*GETs*/
export const getERFilter = createAction(GET_ER_FILTER, result => result);

export const getERMeta = createAsyncThunk(
  GET_ER,
  async (args, thunkApi) => {
    const response = await client.get(url_getERMeta(args));
    thunkApi.dispatch(dispatchObject(GET_ER, response.data));
    return response.data;
  }
);
export const getERPacienteMeta = createAsyncThunk(
  GET_ER_PACIENTE,
  async (args, thunkApi) =>{
    const response = await client.get(url_getPacienteERMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_PACIENTE, response.data));
    return response.data;
  }

);
export const getEROriginReportMeta = createAsyncThunk(
  GET_ER_ORIGIN_REPORT,
  async (args, thunkApi) =>{
    const response = await client.get(url_getOriginReportERMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_ORIGIN_REPORT, response.data));
    return response.data;
  }
);

export const getERMedicalSusp = createAsyncThunk(
    GET_ER_MEDICATIONS_SUSP,
    async (args, thunkApi) =>{
      const response = await client.get(url_getMedicalSuspMeta(args.meta, args.type));
      thunkApi.dispatch(dispatchObject(GET_ER_MEDICATIONS_SUSP, response.data));
      return response.data;
    }
);

export const getERNew = createAsyncThunk(
  GET_ER_CREATE,
  async (args, thunkApi) =>{
    const response = await client.get(url_getERNew);
    thunkApi.dispatch(dispatchObject(GET_ER_CREATE, response.data));
    return response.data;
  }
);

export const getERRAMS = createAsyncThunk(
  GET_ER_RAMS,
  async (args, thunkApi) =>{
    const response = await client.get(url_getRAMERMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_RAMS, response.data));
    return response.data;
  }
);

export const getRAM = createAsyncThunk(
  GET_RAM,
  async (args, thunkApi) => {
    const response = await client.get(url_getRAMERMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER, response));
    return response.data;
  }
);

export const getERDocsAndNotes = createAsyncThunk(
  GET_ER_DOCS_AND_NOTES,
  async (args, thunkApi) =>{
    const response = await client.get(url_getDocsAndNotesMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_DOCS_AND_NOTES, response.data));
    return response.data;

  }
);

export const getERWhoReport = createAsyncThunk(
    GET_ER_WHO_REPORT,
    async(args, thunkApi) =>{
      const response = await client.get(url_getWhoReportERMeta(args.meta));
      thunkApi.dispatch(dispatchObject(GET_ER_WHO_REPORT, response.data));
      return response.data;
    }
);

export const getEROperatinHistory = createAsyncThunk(
  GET_ER_OPERATION_HISTORY,
  async(args, thunkApi) =>{
    const response = await client.post(url_getOperatinHistoryERMeta, args.obj);
    thunkApi.dispatch(dispatchObject(GET_ER_OPERATION_HISTORY, response.data));
    return response.data;
});

export const getERFollowUp = createAsyncThunk(
  GET_ER_FOLLOW_UP,
  async(args, thunkApi)=>{
    const response = await client.get(url_getFollowUpERMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_FOLLOW_UP, response.data));
    return response.data;
});

export const getERImputEvaluation = createAsyncThunk(
  GET_ER_IMPUTABILITY_EVALUATION,
  async(args, thunkApi) =>{
    const response = await client.get(url_getImputEvaluationByMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_IMPUTABILITY_EVALUATION, response.data));
    return response.data;
});

export const getERActions = createAsyncThunk(
  GET_ER_ACTIONS,
  async(args, thunkApi) =>{
    const response = await client.get(url_getActionsERByMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_ACTIONS, response.data));
    return response.data;
  }
);

export const getERContactoMedico = createAsyncThunk(
  GET_ER_CONTACTO_MEDICO,
  async(args, thunkApi) =>{
    const response = await client.get(url_getContactoMedicoByMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_CONTACTO_MEDICO, response.data));
    return response.data;
  }
);

export const getERRAMCategorias = createAsyncThunk(
  GET_ER_RAM_CATEGORY,
  async(args, thunkApi) =>{
    const response = await client.get(url_getRAMCategoriaByRAMId(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_RAM_CATEGORY, response.data));
    return response.data;
});

export const getERMedicamentosAnt = createAsyncThunk(
  GET_ER_MEDICATION_HISTORY,
  async(args, thunkApi)=>{
    const response = await client.get(url_getMedicationHistoryByMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_MEDICATION_HISTORY, response.data));
    return response.data;
});

export const getERFabricApproved = createAsyncThunk(
  GET_ER_FABRIC_APPROVED,
  async(args, thunkApi)=>{
    const response = await client.get(url_getApprovedFabricByMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_FABRIC_APPROVED, response.data));
    return response.data;
  });

export const getHOMEReports = createAsyncThunk(
  GET_HOME_ER,
  async(args, thunkApi)=>{
    const response = await client.get(url_getReportHome(
      args.pageNumber,
      args.orderBy,
      args.id,
      args.isMedic,
      args.status,
      args.country,
      args.from,
      args.to
    ));
    thunkApi.dispatch(dispatchObject(GET_HOME_ER, response.data));
    return response.data;
});

export const getWFHOMEReports = createAsyncThunk(
  GET_WF_HOME_ER,
  async(args, thunkApi)=>{
    const response = await client.get(url_getWFReportHome(args.pageNumber, args.orderBy));
    thunkApi.dispatch(dispatchObject(GET_WF_HOME_ER, response.data));
    return response.data;
});

export const getEventoDeHome = createAsyncThunk(
  GET_ER_HOME_ER,
  async(args, thunkApi)=>{
    const response = await client.get(url_getEventoDeHomeByMeta(args.meta));
    thunkApi.dispatch(dispatchObject(GET_ER_HOME_ER, response.data));
    return response.data;
});


/*SET*/

export const addRAM = createAction(ADD_RAM_ER, RAM => RAM);
export const setER = createAction(SET_ER, ER => ER);
export const setRAM = createAction(SET_ER_RAM, r => r);
export const setRAMS = createAction(GET_ER_RAMS, RAMS => RAMS);
export const setPaciente = createAction(SET_ER_PACIENTE, p => p);
export const setOriginReport = createAction(SET_ER_ORIGIN_REPORT, o => o);
export const setERImputEvaluation = createAction(
  SET_ER_IMPUTABILITY_EVALUATION,
  es => es
);
export const setERMedicalSusp = createAction(SET_ER_MEDICATIONS_SUSP, m => m);
export const setERActions = createAction(SET_ER_ACTIONS, actions => actions);
export const setERContactoMedico = createAction(SET_ER_CONTACTO_MEDICO, m => m);
export const setERRAMCategory = createAction(SET_ER_RAM_CATEGORY, m => m);
export const setERRAMCategoryAll = createAction(GET_ER_RAM_CATEGORY, m => m);
export const setERMedicationAnt = createAction(
  SET_ER_MEDICATION_HISTORY,
  m => m
);
export const setERFabricApproved = createAction(SET_ER_FABRIC_APPROVED, m => m);
export const setHOMEREports = createAction(SET_HOME_ER, m => m);
/*Insert */
export const insertERNew = createAction(INSERT_ER_NEW, ER => ER);
export const insertERMedicationAnt = createAction(
  INSERT_ER_MEDICATION_HISTORY,
  MR => MR
);
/*UPDATE */
export const updateRAM = createAction(UPD_ER_RAM, r => r);
export const updateERMedicalSusp = createAction(
  UPD_ER_MEDICATIONS_SUSP,
  m => m
);
export const updateERImputEvaluation = createAction(
  UPD_ER_IMPUTABILITY_EVALUATION,
  e => e
);
export const updateERMedicationAnt = createAction(
  UPD_ER_MEDICATION_HISTORY,
  m => m
);
export const updateERFabricApproved = createAction(
  UPD_ER_FABRIC_APPROVED,
  m => m
);

/*DELETE*/
export const deleteMedicalSusp = createAction(
  DEL_ER_MEDICATIONS_SUSP,
  id => id
);
export const deleteRAMCategory = createAction(
  DEL_ER_RAM_CATEGORY,
  type => type
);
export const deleteERMedicationAnt = createAction(
  DEL_ER_MEDICATION_HISTORY,
  indx => indx
);
export const delERLisWork = createAction(DEL_ER_WORK_FLOW, m => m);

import React, { Component } from 'react';
import AppContainer from '../components/AppComponents/AppContainer';
import  setPropsAsInitial  from '../helpers/setPropsAsInitial';
import NoKPIComponents from '../components/NoKPIComponents';
import { withRouter }  from 'react-router-dom';

class NoKpisContainer extends Component {
    componentWillMount = () =>{
        this.props.showAppFrame(this.props.location.pathname);
    }
    render() {
        return (
            <AppContainer className='background-f4f4f4 p-b-n' title="Eventos que no cumplen KPI's">
                <NoKPIComponents/>
            </AppContainer>
        );
    }
}

export default withRouter(((setPropsAsInitial)(NoKpisContainer)));
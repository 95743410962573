import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import Concomitante from './Concomitante';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

class Step5 extends Component {
  state={
    Concomitantes: [],
}

componentDidMount =() => {
    let Concomitantes = [];
    if(this.props.initialValues && this.props.initialValues.Concomitante && this.props.initialValues.Concomitante.length>0){
        this.props.initialValues.Concomitante.forEach(r=>{
          Concomitantes.push({
                component: Concomitante,
                open:true,
                allowDelete:false,
            });
        })
    }
    else{
      Concomitantes.push({
        component: Concomitante,
        open:true,
        allowDelete:false,
      });
    }
    this.setState({Concomitantes});

}

handleAddMedicamento = () => {
    let { Concomitantes } = this.state;
    Concomitantes.push({
      component: Concomitante,
      open:true,
      allowDelete:false,
    });
    Concomitantes = Concomitantes.reduce((acc, med) =>{
       med.allowDelete=true;
    return [...acc, med ];
    },[]);
    this.setState({Concomitantes});
}

handleDelMedicamento = index => {
    let { Concomitantes } = this.state;
    let { Concomitante } = this.props;
    if(Concomitante)
    {
      Concomitante = Concomitante.reduce((acc, med, i) =>{
        if(i !== index){
            return [...acc, med ];
        }else{
            return [...acc];
        }
    },[]);
    }
    
    Concomitantes = Concomitantes.reduce((acc, med, i) =>{
        if(i !== index){
            return [...acc, med ];
        }else{
            return [...acc];
        }
    },[]);
    if(Concomitantes.length===1){
      Concomitantes[0].allowDelete=false;
    }
    this.props.change('Concomitante',Concomitante);
    this.setState({Concomitantes});
  }
  render(){
    const { Concomitantes } = this.state;
    const {isMedic }=this.props;
    return (
      <div className='form-step' >
        {Concomitantes.map((r,i) => 
            <r.component key={i} 
            index={i} 
            handleDelete={this.handleDelMedicamento}  
            allowDelete={r.allowDelete}
            isMedic={isMedic}
            />
        )}
        <div className="from-footer">
            <Button outline  className="login-btn w100" onClick={this.handleAddMedicamento}  >
                <FontAwesomeIcon className='m-r-sm' icon={faPlus}/>Agregar otro medicamento
            </Button>
        </div>
      </div>
    );
  }
};
const validate = (values,props) =>{
  let error ={};
  return error;
}

const mapStateToProps = (state) =>({
  Concomitante: formValueSelector('HomeStep5')(state, 'Concomitante'),

});

export default connect(mapStateToProps, null)(reduxForm({form: 'HomeStep5', validate})(Step5));
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ListActions from './ListActions';
import { CircularProgress } from '@material-ui/core';
import { LabelDate } from '../../Fields';

const actions = (handleAction, Item,load) => [
    <Link to={'#'} onClick={() => handleAction("view",Item)}>{load===`view${Item.DateUTC}` ?<CircularProgress size={15} />:<FontAwesomeIcon icon={faEye} size={'lg'}/>}</Link>
];

const ListItemOH = ({Item, handleAction, classProp,load}) => {
    return (
        <tr className={`list-item ${classProp}`}>
            <td>{LabelDate(Item.Date)}</td>
            <td>{Item.User}</td>
            <td>{Item.Description}</td>
            <td className="p-t-sm"><ListActions  actions={actions(handleAction,Item,load)}/></td>
        </tr>
    );
};

ListItemOH.propTypes = {
    Item: PropTypes.object,
};

export default ListItemOH;
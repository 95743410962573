import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import {
  FieldAutoComplete,
  FieldSelect,
  FieldText,
  iconTrash,
  FieldDatePicker
} from "../../../../Fields";
import { getCodiguera } from "../../../../../codigueras";
import { getHomeCodiguera } from "../../../../../redux/selectors/home";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Medicamento = props => {
  const {
    index,
    codiguera,
    Medicamento,
    medicamentos,
    allowDelete,
    handleDelete,
    toggle,
    open
  } = props;
  return (
    <div>
      <h3>
        Información sobre el medicamento / producto sospechoso
        {allowDelete && (
          <Link to="#" onClick={() => handleDelete(index)} className="m-l">
            {iconTrash("#B64C92", "#d6abc8")}
          </Link>
        )}
        <Link to="#" onClick={() => toggle(index)} className="pull-right">
          <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
        </Link>
      </h3>
      <Row hidden={!open}>
        <Col md={6} sm={12}>
          <FieldAutoComplete
            multiple={false}
            selected={
              Medicamento &&
              Medicamento.length > 0 &&
              Medicamento[index] &&
              Medicamento[index].Producto
                ? Medicamento[index].Producto
                : null
            }
            isRequired={true}
            name={`Medicamento[${index}].Producto`}
            label="Medicamento"
            highlightOnlyResult={true}
            items={medicamentos}
            labelKey="ComercialName"
            emptyLabel="No hay resultados"
          />
        </Col>
        <Col md={6} sm={12}>
          <FieldSelect
            name={`Medicamento[${index}].Via`}
            label="Vía de administración"
            items={codiguera && getCodiguera("Via", codiguera)}
            textField="Descripcion"
            valueField="Id"
            typeValue="number"
          />
        </Col>
        <Col md={3} sm={12}>
          <FieldSelect
            name={`Medicamento[${index}].FrecuenciaTipo`}
            label="Frecuencia"
            items={
              codiguera && getCodiguera("MedicamentoFrecuencia", codiguera)
            }
            textField="Unidad"
            valueField="Id"
            typeValue="number"
          />
        </Col>
        <Col md={3} sm={12}>
          <FieldText
            name={`Medicamento[${index}].Frecuencia`}
            label="Tiempo entre dosis (horas)"
            type="number"
          />
        </Col>
        <Col sm={6} md={3}>
          <FieldText
            name={`Medicamento[${index}].Dosis`}
            label="Dosis"
            type="number"
            min={0}
          />
        </Col>
        <Col sm={6} md={3}>
          <FieldSelect
            name={`Medicamento[${index}].UnidadUso`}
            label="Unidad de dosis"
            items={codiguera && getCodiguera("MedicamentoUnidadUso", codiguera)}
            textField="Unidad"
            valueField="Id"
          />
        </Col>
        <Col md={6} sm={12}>
          <FieldDatePicker
            name={`Medicamento[${index}].FechaIni`}
            allowFuture={false}
            allowPast={true}
            label="Fecha de inicio de la administración"
          />
        </Col>
        <Col md={6} sm={12}>
          <FieldDatePicker
            name={`Medicamento[${index}].FechaFin`}
            allowFuture={false}
            allowPast={true}
            label="Fecha de finalización de la administración (sí aplica)"
          />
        </Col>
        <Col sm={12}>
          <FieldText
            name={`Medicamento[${index}].Indicacion`}
            type="textarea"
            label="Motivo de indicación"
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  Medicamento: formValueSelector("HomeStep4")(state, "Medicamento"),
  codiguera: getHomeCodiguera(state)
});

export default connect(mapStateToProps, null)(Medicamento);

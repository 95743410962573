import React from 'react';
import PropTypes from 'prop-types';



const ListActions = ({actions,mini, right}) => {
    return (
        !mini ? <div className="list-actions w100">
            <ul className={`m-b-sm m-t-sm  p-n ${right ? 'pull-right' : 'pull-left'}`} >
            {
                actions.map((a,i) => (<li key={i}>{a}</li>))
            }
            </ul>
        </div>
        :
        <ul className="pull-left m-b-sm m-t-sm">
            {
                actions.map((a,i) => (<li  key={i}>{a}</li>))
            }
        </ul>
    );
};

ListActions.propTypes = {
    ER: PropTypes.object,
};

export default ListActions;
import { handleActions } from "redux-actions";
import {
  GET_INDICATOR_AGES,
  GET_INDICATOR_GROUPS,
  GET_INDICATOR_STATUS,
  GET_INDICATOR_VALUES,
  GET_INDICATOR_COUNTRIESFILTERS,
  GET_INDICATOR_LABSFILTERS
} from "../constants/get";

export const IndicatorAges = handleActions(
  {
    [GET_INDICATOR_AGES]: (state, action) => action.payload
  },
  null
);

export const IndicatorGroups = handleActions(
  {
    [GET_INDICATOR_GROUPS]: (state, action) => action.payload
  },
  null
);

export const IndicatorStatus = handleActions(
  {
    [GET_INDICATOR_STATUS]: (state, action) => action.payload
  },
  null
);

export const IndicatorValues = handleActions(
  {
    [GET_INDICATOR_VALUES]: (state, action) => action.payload
  },
  null
);
export const IndicatorCountriesFilters = handleActions(
  {
    [GET_INDICATOR_COUNTRIESFILTERS]: (state, action) => action.payload
  },
  []
);

export const IndicatorLabsFilters = handleActions(
  {
    [GET_INDICATOR_LABSFILTERS]: (state, action) => action.payload
  },
  []
);

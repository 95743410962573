import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HomeMainCarrousel from "../components/HomeComponents/components/HomeMainCarrousel";
import HomeContent from "../components/HomeComponents/components/HomeContent";
import HomeFaqs from "../components/HomeComponents/components/HomeFaqs";
import HomeFooter from "../components/HomeComponents/components/HomeFooter";
import "../components/HomeComponents/css/tpl/tpl.css";
import HomeMenuLateral from "../components/HomeComponents/components/HomeMenuLateral";
import HomeReportar from "../components/HomeComponents/components/HomeReportar";
import HomeCompanies from "../components/HomeComponents/components/HomeCompanies";
import HomeSeparator from "../components/HomeComponents/components/HomeSeparator";
import HomeCompromiso from "../components/HomeComponents/components/HomeCompromiso";
import HomeModal from "../components/HomeComponents/components/HomeModal";
import { getHomeCodiguera } from "../redux/selectors/home";
import { setHomeCodiguera } from "../redux/actions/home";
import { url_getCodiguera } from "../api/url_home";
import api from '../api'
import HomeForm from "../components/HomeComponents/components/HomeForm";
import LegalWarningModal from "../components/Modals/LegalWarningModal";


class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inTo: "inicio",
      classNavBar: "",
      showModal: false,
      showForm: false,
      isMedic: null,
      openLegalWarning: false
    };
    this.MainCarrousel = React.createRef();
    this.Content = React.createRef();
    this.Compromiso = React.createRef();
    this.Reportar = React.createRef();
    this.Companies = React.createRef();
    this.Faqs = React.createRef();
    this.Footer = React.createRef();
  }

  componentWillMount = () => {
    this.props.showAppFrame(this.props.location.pathname);

    window.addEventListener("scroll", this.handleScroll, true);
    if (this.props.codiguera === null) {
      api.get(url_getCodiguera).then(r => {
        this.props.setHomeCodiguera(r.data);
      });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = () => {
    try {
      let scroll = window.scrollY || document.documentElement.scrollTop;
      let margin = 450;
      const items = {
        inicio: this.MainCarrousel.current.offsetTop - margin,
        objetivos: this.Content.current.offsetTop - margin,
        compromiso: this.Compromiso.current.offsetTop - margin,
        reporte: this.Reportar.current.offsetTop - margin,
        companias: this.Companies.current.offsetTop - margin,
        faqs: this.Faqs.current.offsetTop - margin
      };
      if (scroll >= items.inicio && scroll < items.objetivos) {
        this.handleChangeScrollInto("inicio");
      } else if (scroll >= items.objetivos && scroll < items.compromiso) {
        this.handleChangeScrollInto("objetivos");
      } else if (scroll >= items.compromiso && scroll < items.reporte) {
        this.handleChangeScrollInto("compromiso");
      } else if (scroll >= items.reporte && scroll < items.companias) {
        this.handleChangeScrollInto("reportes-y-consultas");
      } else if (scroll >= items.companias && scroll < items.faqs) {
        this.handleChangeScrollInto("companias");
      } else if (scroll >= items.faqs) {
        this.handleChangeScrollInto("faqs");
      }
      if (scroll > 60) {
        this.setState({ classNavBar: "top-menu-wrap-solid" });
      } else {
        this.setState({ classNavBar: "" });
      }
    } catch (error) {

    }
  };

  handleLogin = () => {
    this.props.history.push("/login");
  };

  handleChangeScrollInto = inTo => this.setState({ inTo });

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal, isMedic: null });
  };
  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm, isMedic: null });
  };

  handleShowForm = isMedic =>
    this.setState({
      isMedic,
      showForm: true,
      showModal: false
    });

  toggleLegalWarning = () =>
    this.setState({ openLegalWarning: !this.state.openLegalWarning });

  render() {
    const {
      inTo,
      classNavBar,
      showModal,
      showForm,
      isMedic,
      openLegalWarning
    } = this.state;
    const { codiguera } = this.props;
    return (
      <div className="home-cim">
        <HomeMainCarrousel
          ref={this.MainCarrousel}
          handleLogin={this.handleLogin}
          className={classNavBar}
          toggle={this.toggleModal}
        />
        {window.screen.width > 800 &&
          (
            <HomeMenuLateral
            handleLogin={this.handleLogin}
            scrollInto={inTo}
            handleSelect={this.handleChangeScrollInto}
           />
          )

        }

        <HomeContent ref={this.Content} />
        <HomeSeparator className="objetivo-sep" />
        <HomeCompromiso ref={this.Compromiso} />
        <HomeReportar ref={this.Reportar} toggle={this.toggleModal} />
        <HomeCompanies ref={this.Companies} />
        <HomeSeparator className="compania-sep" />
        <HomeFaqs ref={this.Faqs} />
        <HomeFooter ref={this.Footer} />
        <HomeModal
          toggle={this.toggleModal}
          show={showModal}
          handleShowForm={this.handleShowForm}
          handleShowLegalWarning={this.toggleLegalWarning}
        />
        {codiguera && isMedic !== null && (
          <HomeForm
            home={true}
            toggle={this.toggleForm}
            show={showForm}
            isMedic={isMedic}
            showLegalWarning={this.toggleLegalWarning}
          />
        )}
        <LegalWarningModal
          open={openLegalWarning}
          toggle={this.toggleLegalWarning}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  codiguera: getHomeCodiguera(state)
});

const mapDispatchToProps = {
  setHomeCodiguera
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
);

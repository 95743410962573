import React, { Component } from "react";
import { Field } from "redux-form";
import { Label, Input, FormFeedback, FormGroup, Progress } from "reactstrap";
import { Radio, FormControlLabel } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faInfoCircle,
  faDotCircle,
  faCircle,
  faTrashAlt,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import Toggle from "react-toggle";
import { Typeahead, AsyncTypeahead } from "react-bootstrap-typeahead";
import "./fields.css";
import "react-datez/dist/css/react-datez.css";
import { ReduxReactDatez } from "react-datez";
import { answersNOSI } from "../../constants/steps";
import { Col } from "react-flexbox-grid";
import moment from "moment-timezone";
import { emailValidator } from "../../helpers/utilities";

export const LabelDate = (date, withoutHours) => {
  if (date !== null) {
    const timezone = new Date().getTimezoneOffset();

    return moment(date).add((-timezone), 'm').format(`DD/MM/YYYY ${withoutHours ? "" : "HH:mm"}`);
  } else {
    return "---";
  }
};

const isTextRequired = value => !value && "Requerido";

const isRadioRequired = value => !value && "Debe seleccionar una opción";

const isTextNumber = value =>
  !value
    ? "Requerido"
    : isNaN(Number(value)) && "El campo debe ser un númerico";

const isSelectRequired = value =>
  (!value || value === "none") && "Debe selecionar una opción";

const isEmailRequired = value =>
  !value
    ? "Correo requerido"
    : !emailValidator(value) && "Formato de correo incorrecto";

const isEmailValid = value =>  value && !emailValidator(value) && "Formato de correo incorrecto";

const getValidate = (isRequired, type) => {

  if(isRequired){
    switch (type) {
      case "number":
        return isTextNumber;
      case "radio":
        return isRadioRequired;
      case "email":
        return isEmailRequired;
      case "select":
        return isSelectRequired;
      default:
        return isTextRequired;
    }
    
  }else if (!isRequired && type === "email") {
    return isEmailValid;
  }
};

const HiddenComponent = ({ input, valueComp }) => (
  <Input hidden={true} value={valueComp} {...input} />
);

const toNumber = value => (value && value !== "none" ? Number(value) : null);

const toBoolean = value =>
  value && value !== "none" ? (value === "true" ? true : false) : null;
const toText = value => value && value.toString();

export const formControl = {
  display: "block",
  width: "100%",
  height: "calc(2.25rem + 2px)",
  padding: ".375rem .75rem",
  fontSize: "1rem",
  lineHeight: "1.5",
  color: "#495057",
  backgroundColor: "#fff",
  backgroundClip: "padding-box",
  border: "1px solid #ced4da",
  borderRadius: ".25rem"
};

export const iconRequired = (
  <span className="fa-layers fa-fw">
    <FontAwesomeIcon color="#fff" icon={faCircle} inverse={true} />
    <FontAwesomeIcon color="#dc3545" size={"lg"} icon={faExclamationCircle} />
  </span>
);

export const iconInfo = info => (
  <FontAwesomeIcon icon={faInfoCircle} title={info} className="m-l-sm" />
);

export const iconTrash = (primaryColor, secundaryColor) => (
  <span className="fa-layers fa-fw">
    <FontAwesomeIcon color={secundaryColor} icon={faTrash} />
    <FontAwesomeIcon color={primaryColor} icon={faTrashAlt} />
  </span>
);

export class TextComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSelectedValue: this.props.defaultSelectedValue || "",
      initialzed: false
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.txt) this.txt.focus();

    if (!this.state.initialzed) this.setState({initialzed: true});
  }

  render() {
    const {
      input,
      meta,
      label,
      value,
      type,
      tooltip,
      isRequired,
      min,
      max,
      step,
      maxLength,
      disabled,
      withFocus,
      hidden,
      size,
      placeholder
    } = this.props;
    if (!isRequired && type === "email") {
      return (
        <FormGroup hidden={hidden ? true : false}>
          <Label htmlFor={`input${label}`}>
            {label}
          </Label>
          {meta.error ? (
            <Input
              placeholder={placeholder}
              value={value && value}
              className={
                size ? `form-control-${size} input-field` : "input-field"
              }
              name={`input${label}`}
              autoFocus={withFocus}
              disabled={disabled}
              invalid
              {...input}
              type={!type ? "text" : type}
              maxLength={maxLength}
              min={min}
              max={max}
              step={step}
            />
          ) : (
            <Input
              placeholder={placeholder}
              value={value && value}
              className={
                size ? `form-control-${size} input-field` : "input-field"
              }
              name={`input${label}`}
              autoFocus={withFocus}
              disabled={disabled}
              {...input}
              type={!type ? "text" : type}
              maxLength={maxLength}
              min={min}
              max={max}
              step={step}
            />
          )}
          {tooltip
            ? meta.error && <FormFeedback tooltip>{meta.error}</FormFeedback>
            : meta.error && <FormFeedback>{meta.error}</FormFeedback>}
          {type === "textarea" && maxLength > 0 && input.value.length > 0 && (
            <Progress
              tooltip={`Máximo de caracteres (${maxLength})`}
              striped
              color="success"
              className="progress-textarea pull-right"
              value={(input.value.length * 100) / maxLength}
            />
          )}
        </FormGroup>
      );
      
    }else if (!isRequired) {
      const moreProps = {};
      if (this.state.defaultSelectedValue && !this.state.initialzed) {
        moreProps.value = this.state.defaultSelectedValue;
      }
      return (
        <FormGroup hidden={hidden ? true : false}>
          <Label htmlFor={`input${label}`}>{label}</Label>
          <Input
            placeholder={placeholder}
            className={size && `form-control-${size}`}
            name={`input${label}`}
            autoFocus={withFocus}
            {...input}
            value={value && value}
            type={!type ? "text" : type}
            disabled={disabled}
            maxLength={maxLength}
            min={min}
            max={max}
            step={step}
            {...moreProps}
          />
        </FormGroup>
      );
    } else {
      return (
        <FormGroup hidden={hidden ? true : false}>
          <Label htmlFor={`input${label}`}>
            {label} {iconRequired}
          </Label>
          {meta.error ? (
            <Input
              placeholder={placeholder}
              value={value && value}
              className={
                size ? `form-control-${size} input-field` : "input-field"
              }
              name={`input${label}`}
              autoFocus={withFocus}
              disabled={disabled}
              invalid
              {...input}
              type={!type ? "text" : type}
              maxLength={maxLength}
              min={min}
              max={max}
              step={step}
            />
          ) : (
            <Input
              placeholder={placeholder}
              value={value && value}
              className={
                size ? `form-control-${size} input-field` : "input-field"
              }
              name={`input${label}`}
              autoFocus={withFocus}
              disabled={disabled}
              {...input}
              type={!type ? "text" : type}
              maxLength={maxLength}
              min={min}
              max={max}
              step={step}
            />
          )}
          {tooltip
            ? meta.error && <FormFeedback tooltip>{meta.error}</FormFeedback>
            : meta.error && <FormFeedback>{meta.error}</FormFeedback>}
          {type === "textarea" && maxLength > 0 && input.value.length > 0 && (
            <Progress
              tooltip={`Máximo de caracteres (${maxLength})`}
              striped
              color="success"
              className="progress-textarea pull-right"
              value={(input.value.length * 100) / maxLength}
            />
          )}
        </FormGroup>
      );
    }
  }
}

export const SelectComponent = ({
  meta,
  input,
  items,
  valueField,
  textField,
  withOutSelectdefault,
  disabled,
  size,
  isRequired,
  inline,
  label,
  name,
  selectedValue
}) => {
  if (meta.error) {
    
    return (
      <FormGroup className="w100">
        <Label className={inline && "inline"} htmlFor={name}>
          {label} {isRequired ? iconRequired : ""}
        </Label>
        <Input
          {...input}
          invalid
          type="select"
          disabled={disabled}
          className={size && `form-control-${size}`}
        >
          {!withOutSelectdefault && (
            <option value={null} key={`nameNone`}>
              Seleccionar...
            </option>
          )}
          {items &&
            items.map((i, x) => {
              return(
              <option key={x} value={i[valueField]} selected={i[valueField] === "UY"}>
                {i[textField]}
              </option>
              )
              })}
        </Input>
        {meta.error && <FormFeedback>{meta.error}</FormFeedback>}
      </FormGroup>
    );
  } else {
    return (
      <FormGroup className="w100">
        <Label className={inline && "inline"} htmlFor={name}>
          {label} {isRequired ? iconRequired : ""}
        </Label>
        <Input {...input} type="select" disabled={disabled} value={input.value || selectedValue || ""}>
          {!withOutSelectdefault && (
            <option value={"none"} key={`nameNone`}>
              Seleccionar...
            </option>
          )}
          {items &&
            items.map((i, x) => {
              return (
              <option key={x} value={i[valueField]}>
                {i[textField]}
              </option>
            )})}
        </Input>
      </FormGroup>
    );
  }
};

export const SwitchComponent = ({
  name,
  size,
  input,
  icons,
  className,
  disabled
}) => (
  <Toggle
    disabled={disabled}
    size={size}
    className={className}
    checked={
      input.value === "" || input.value === false ? false : true
    }
    name={`switch${name}`}
    icons={icons}
    onChange={input.onChange}
  />
);

export const AutoCompleteComponent = ({
  meta,
  name,
  highlightOnlyResult,
  items,
  labelKey,
  input,
  selected,
  multiple
}) => {
  if (meta.error)
    return (
      <Typeahead
        id={`${1}_${name}`}
        clearButton={true}
        className="input-field"
        isInvalid
        multiple={multiple}
        selected={selected ? selected : null}
        name={`auto${name}`}
        placeholder="Seleccionar..."
        highlightOnlyResult={highlightOnlyResult}
        options={items}
        onChange={input.onChange}
        labelKey={labelKey}
      />
    );
  else
    return (
      <Typeahead
        id={`${1}_${name}`}
        clearButton={true}
        multiple={multiple}
        selected={selected ? selected : null}
        name={`auto${name}`}
        placeholder="Seleccionar..."
        highlightOnlyResult={highlightOnlyResult}
        options={items}
        onChange={input.onChange}
        labelKey={labelKey}
      />
    );
};

export const AutoAsyncCompleteComponent = ({
  meta,
  name,
  highlightOnlyResult,
  onSearch,
  labelKey,
  input,
  selected,
  items,
  multiple,
  isLoading,
  seach,
  searchText,
  emptyLabel
}) => {
  if (meta.error)
    return (
      <AsyncTypeahead
        id={`${1}_${name}`}
        promptText={searchText}
        isLoading={isLoading}
        onSearch={onSearch}
        seach={seach}
        searchText={searchText}
        emptyLabel={emptyLabel}
        clearButton={true}
        className="input-field"
        isInvalid
        multiple={multiple}
        selected={selected ? selected : null}
        name={`auto${name}`}
        placeholder="Escribe para buscar..."
        highlightOnlyResult={highlightOnlyResult}
        options={items}
        onChange={input.onChange}
        labelKey={labelKey}
      />
    );
  else
    return (
      <AsyncTypeahead
        id={`${1}_${name}`}
        promptText={searchText}
        isLoading={isLoading}
        onSearch={onSearch}
        seach={seach}
        searchText={searchText}
        emptyLabel={emptyLabel}
        clearButton={true}
        multiple={multiple}
        selected={selected ? selected : null}
        name={`auto${name}`}
        placeholder="Escribe para buscar..."
        highlightOnlyResult={highlightOnlyResult}
        options={items}
        onChange={input.onChange}
        labelKey={labelKey}
      />
    );
};

export const FormControl = ({ value, name, color, label, selected }) => (
  <FormControlLabel
    className="m-b-n m-t-n w100"
    value={value}
    control={
      <Radio
        className="m-r-sm m-b-n m-t-n"
        checked={selected && selected === value}
        value={value}
        title={label}
        classes={{ root: color }}
        icon={<FontAwesomeIcon icon={faCircle} />}
        checkedIcon={<FontAwesomeIcon icon={faDotCircle} />}
      />
    }
    label={label}
  />
);

export const FieldFormControl = ({
  value,
  name,
  color,
  label,
  key,
  selected
}) => (
  <Field
    key={key}
    selected={selected}
    name={name}
    label={label}
    component={FormControl}
    value={value}
    color={color}
  />
);

export const FieldText = ({
  name,
  label,
  type,
  isRequired,
  min,
  max,
  step,
  maxLength,
  value,
  disabled,
  tooltip,
  onChange,
  withFocus,
  hidden,
  size,
  placeholder
}) => (
  <Field
    disabled={disabled}
    size={size}
    placeholder={placeholder}
    hidden={hidden}
    name={name}
    parse={type === "number" ? toNumber : toText}
    label={label}
    withFocus={withFocus}
    component={TextComponent}
    min={min}
    max={max}
    onChange={onChange}
    tooltip={tooltip}
    value={value}
    maxLength={maxLength}
    step={step}
    type={type}
    isRequired={isRequired}
    validate={getValidate(isRequired, type)}
  />
);

export const FieldSelect = ({
  name,
  label,
  items,
  valueField,
  textField,
  onChange,
  typeValue,
  inline,
  withOutSelectDefault,
  disabled,
  isRequired,
  size,
  selectedValue
}) => (
  <Field
    className={inline && "inline"}
    label={label}
    isRequired={isRequired}
    size={size}
    withOutSelectdefault={withOutSelectDefault}
    disabled={disabled}
    name={name}
    items={items}
    parse={
      typeValue === "number"
        ? toNumber
        : typeValue === "boolean"
        ? toBoolean
        : toText
    }
    valueField={valueField}
    onChange={onChange}
    textField={textField}
    component={(props) => SelectComponent({...props, selectedValue})}
    validate={isRequired && getValidate("select")}
  ></Field>
);

export const FieldRadioGroup = ({
  name,
  label,
  items,
  valueField,
  textField,
  inline,
  onChange,
  labelBlock,
  isRequired
}) => (
  <FormGroup>
    <Label className={`${labelBlock ? "block" : ""}`}>
      {label} {isRequired ? iconRequired : ""}
    </Label>
    {items.map(s => (
      <Label
        className={inline ? "inline m-r-md" : "block"}
        key={name + s[valueField]}
      >
        <Field
          name={name}
          className={s["className"]}
          onChange={onChange}
          component="input"
          type="radio"
          value={s[valueField]}
          validate={isRequired && getValidate("radio")}
        />
        {s[textField]}
      </Label>
    ))}
    {getValidate("radio") && <FormFeedback>Seleccione una opción</FormFeedback>}
  </FormGroup>
);

export const FieldRadioGroupCol = ({
  name,
  label,
  items,
  valueField,
  textField,
  inline,
  onChange,
  labelBlock,
  md,
  sm,
  lg
}) => (
  <FormGroup>
    {label && <Label>{label}</Label>}
    {items.map(s => (
      <Col key={name + s[valueField]} className="w100" md={md} sm={sm}>
        <Label>
          <Field
            name={name}
            className={s["className"]}
            onChange={onChange}
            component="input"
            type="radio"
            value={s[valueField]}
          />
          {s[textField]}
        </Label>
      </Col>
    ))}
  </FormGroup>
);

export const FieldSwitch = ({
  name,
  label,
  icons,
  className,
  onChange,
  inline,
  disabled
}) => (
  <FormGroup>
    <Label className={inline && "inline m-r-md"}>{label}</Label>
    <Field
      name={name}
      disabled={disabled}
      icons={icons}
      className={className}
      component={SwitchComponent}
      onChange={onChange}
    ></Field>
  </FormGroup>
);

export const FieldAutoComplete = ({
  name,
  label,
  highlightOnlyResult,
  items,
  onChange,
  labelKey,
  selected,
  multiple,
  isRequired
}) => (
  <FormGroup>
    <Label>
      {label} {isRequired ? iconRequired : ""}
    </Label>
    <Field
      multiple={multiple}
      name={name}
      highlightOnlyResult={highlightOnlyResult}
      selected={selected}
      items={items}
      onChange={onChange}
      labelKey={labelKey}
      component={AutoCompleteComponent}
    ></Field>
  </FormGroup>
);

export const FieldAsyncAutoComplete = ({
  name,
  label,
  highlightOnlyResult,
  onSearch,
  items,
  onChange,
  labelKey,
  selected,
  multiple,
  isRequired,
  isLoading,
  searchText,
  emptyLabel
}) => (
  <FormGroup>
    <Label>
      {label} {isRequired ? iconRequired : ""}
    </Label>
    <Field
      multiple={multiple}
      isLoading={isLoading}
      name={name}
      items={items}
      highlightOnlyResult={highlightOnlyResult}
      selected={selected}
      onSearch={onSearch}
      searchText={searchText}
      emptyLabel={emptyLabel}
      onChange={onChange}
      labelKey={labelKey}
      component={AutoAsyncCompleteComponent}
    ></Field>
  </FormGroup>
);

export const FieldHidden = ({ name, value }) => (
  <Field name={name} valueComp={value} component={HiddenComponent} />
);

export const FieldDatePicker = ({
  label,
  name,
  allowFuture,
  allowPast,
  value,
  disabled,
  onChange,
  placeholder
}) => (
  <FormGroup>
    <Label className="block">{label}</Label>

    {disabled ? (
      <Label>{typeValueFieldLabel("datetime", value)}</Label>
    ) : (
      <Field
        name={name}
        value={value}
        locale="es"
        dateFormat="DD/MM/YYYY"
        disableInputIcon={true}
        inputStyle={formControl}
        allowFuture={allowFuture}
        allowPast={allowPast}
        component={ReduxReactDatez}
        highlightWeekends
        firstDayOfWeek='lu'
        onChange={onChange}
        placeholder={placeholder}
      />
    )}
  </FormGroup>
);

export const FieldQuestionWithAnswerYesNo = ({
  name,
  label,
  onChange,
  info
}) => (
  <FormGroup className="w100">
    <Field
      label={
        <span>
          {label} {info && iconInfo(info)}
        </span>
      }
      className="hiddenLabel"
      name={name}
      items={answersNOSI}
      valueField="Id"
      textField="Text"
      onChange={onChange}
      component={SelectComponent}
    ></Field>
  </FormGroup>
);

const typeValueFieldLabel = (type, value) => {
  if (value === null || value === undefined || value === "---") {
    return "---";
  } else {
    switch (type) {
      case "bool": {
        return value ? "SI" : "NO";
      }
      case "date": {
        return moment(value).format("DD/MM/YYYY");
      }
      case "datetime": {
        return moment(value).format("DD/MM/YYYY HH:mm");
      }
      default: {
        return value ? value : "---";
      }
    }
  }
};

export const FieldLabel = ({ label, value, type, inline, withOutStrong }) => (
  <FormGroup>
    <Label
      className={`${inline ? "inline pull-left" : "block"} ${
        withOutStrong ? "" : "strong"
      }`}
    >
      {label}
    </Label>
    <Label className={`${inline ? "inline pull-right" : "block"}`}>
      {typeValueFieldLabel(type, value)}
    </Label>
  </FormGroup>
);

export const FieldCheck = ({
  label,
  name,
  value,
  onChange,
  disabled,
  inline,
  isRequired
}) => (
  <FormGroup check className={`${inline && "inline"} p-n`}>
    <Label check>
      <Field
        name={name}
        className="check p-n"
        component="input"
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
        value={value}
      />{" "}
      {label} {isRequired ? iconRequired : ""}
    </Label>
  </FormGroup>
);

import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { connect } from "react-redux";
import LoginContainer from "./containers/LoginContainer";
import WorkFlowContainer from "./containers/WorkFlowContainer";
import EReportContainer from "./containers/EReportContainer";
import ERSearchContainer from "./containers/ERSearchContainer";
import IndicatorsContainer from "./containers/IndicatorsContainer";
import AppLoader from "./components/AppComponents/AppLoader";
import {
  getLoad,
  getMyUser,
  getSweetAlert,
  getShowLegalTerms
} from "../src/redux/selectors/Context";
import TeamsConfig from "./containers/TeamsConfig";
import AuditTrailContainer from "./containers/AuditTrailContainer";
import DataImportContainer from "./containers/DataImportContainer";
import UserAdminContainer from "./containers/UserAdminContainer";
import MyUserContainer from "./containers/MyUserContainer";
import MetricsContainer from "./containers/MetricsContainer";
import LegalWarningModal from "./components/Modals/LegalWarningModal";
import api from "./api";
import { url_acceptTerms } from "./api/urls";
import {
  setLoad,
  userLogOut,
  getMyUser as MyUser
} from "./redux/actions/context";
import { showError } from './redux/actions/error';
import SettingContainer from "./containers/SettingContainer";
import SweetAlert from "sweetalert-react";
import ReduxToastr from "react-redux-toastr";
import { setSweetAlert } from "./redux/actions/sweetAlert";
import "sweetalert/dist/sweetalert.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import CookiePrvider from "./api/cookies";
import AppFrame from "./components/AppComponents/AppFrame";
import PrivateRoute from "./helpers/PrivateRoute";
import HomeContainer from "./containers/HomeContainer";
import "./App.css";
import ChangePasswordContainer from "./containers/ChangePasswordContainer";
import { secureStorage } from "./api/secureStorage";
import ParametersContainer from "./containers/ParametersContainer";
import NoKpisContainer from "./containers/NoKpisContainer";
import { urlBase } from "./environment";
import CacheBuster from './CacheBuster';
const $ = window.$;

class App extends Component {
  state = {
    openLegalWarning: false,
    showAppFram: false
  };

  _handleShowLegalWarning = () => {
    const { myUser } = this.props;
    let openLegalWarning = false;
    if (
      myUser !== null &&
      !myUser.AcceptTerms &&
      this.showAppFrame(window.location.pathname)
    ) {
      openLegalWarning = true;
    }
    this.setState({ openLegalWarning });
  };

  componentDidMount = () => {
    var conec = $.connection(urlBase + "signalr");
    conec.stateChanged(function (change) {
      if (change.newState === $.signalR.connectionState.reconnecting) {
          console.log('Re-csonnecting');
      }
      else if (change.newState === $.signalR.connectionState.connected) {
          console.log('The server is online');
      }
  });
    conec.start(() => {
      console.log('Now connected');
    }).done((x) => console.log('connected'));
     conec.received(function(data) {
      console.log('Connection stablished');
  });
  }

  componentWillUnmount = () => {
      // Desconectarse de signalR
    $.connection.hub.stop( console.log("Disconnected SignalR"))
  }

  componentDidUpdate = (prevProps, prevState) => {
    this._handleShowLegalWarning();
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.load !== nextProps.load) {
      return true;
    }
    if (this.state.openLegalWarning !== nextState.openLegalWarning) {
      return true;
    }
    if (this.state.showAppFram !== nextState.showAppFram) {
      return true;
    }
    if (this.props.sweetAlert.show !== nextProps.sweetAlert.show) {
      return true;
    }
    if (this.props.showLegalTerms !== nextProps.showLegalTerms) {
      return true;
    }
    if (this.props.myUser === null && nextProps.myUser !== null) {
      return true;
    } else if (this.props.myUser !== null) {
      if (this.props.myUser.Rol !== nextProps.myUser.Rol) {
        return true;
      }
      if (this.props.myUser.AcceptTerms !== nextProps.myUser.AcceptTerms) {
        return true;
      }
      if (this.props.myUser.Country !== nextProps.myUser.Country) {
        return true;
      }
      if (this.props.myUser.Email !== nextProps.myUser.Email) {
        return true;
      }
      if (this.props.myUser.Name !== nextProps.myUser.Name) {
        return true;
      }
      if (this.props.myUser.Id !== nextProps.myUser.Id) {
        return true;
      }
      if (this.props.myUser.Url !== nextProps.myUser.Url) {
        return true;
      }
    }
    return false;
  }

  aceptLegalWarning = async () => {
    try {
      this.props.setLoad(true);
      await api.post(url_acceptTerms);
      this.props.myUser.AcceptTerms = true;
    } catch (e) {
      this.props.showError(e);
    } finally {
      this.props.setLoad(false);
    }
  }

  cancelLegalWarning = () => {
    CookiePrvider.clear();
    secureStorage.clear();
    window.location.replace("/login");
  };

  Authenticate = () => {
    const myUser = secureStorage.getItem('user');
    let isAuthenticated = false;
    let haveChangePass = true;
    let URLs = [];
    let url = "";
    let rol = "";
    let id = -1;
    if (myUser) {
      isAuthenticated = true;
      haveChangePass = myUser.Url === "/changepassword";
      URLs = myUser.MenuItems;
      rol = myUser.Rol;
      url = myUser.Url;
      id = myUser.Id;
    }
    return {
      isAuthenticated,
      haveChangePass,
      URLs,
      rol,
      url,
      id
    };
  };

  showAppFrame = path => {
    let showAppFram = false;
    switch (path) {
      case "/":
        showAppFram = false;
        break;
      case "/login":
        showAppFram = false;
        break;
      case "/teamsConfig":
        showAppFram = false;
        break;
      case "/changepassword":
        showAppFram = false;
        break;
      default:
        showAppFram = true;
        break;
    }
    this.setState({ showAppFram });
    return showAppFram;
  };

  redirect = () => {
    const auth = this.Authenticate();
    if (auth.isAuthenticated) {
      return <Redirect to={{ pathname: auth.url }} />;
    } else {
      return <Redirect to={{ pathname: "/" }} />;
    }
  };

  render() {
    const { openLegalWarning, showAppFram } = this.state;
    const { sweetAlert, load, showLegalTerms } = this.props;
    const auth = this.Authenticate();
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return(
            <Router>
            <AppFrame show={showAppFram}>
              <AppLoader load={load} size={150} />
              <Switch>
                <PrivateRoute
                  key={4}
                  exact
                  path="/myuser"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={MyUserContainer}
                />
                <PrivateRoute
                  key={5}
                  exact
                  path="/workflow"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={WorkFlowContainer}
                />
                <PrivateRoute
                  key={6}
                  exact
                  path="/eventsearch"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={ERSearchContainer}
                  newFilter = {false}
                />
                <PrivateRoute
                  key={18}
                  exact
                  path="/productSearch"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={ERSearchContainer}
                  newFilter = {true}
                />
                <PrivateRoute
                  key={7}
                  exact
                  path="/reportableevent/:meta/view/:step/:type?"
                  auth={auth}
                  step={true}
                  meta={true}
                  type={true}
                  showAppFrame={this.showAppFrame}
                  component={EReportContainer}
                />
                <PrivateRoute
                  key={8}
                  exact
                  path="/reportableevent/:meta/edit/:type?"
                  auth={auth}
                  step={true}
                  meta={true}
                  type={true}
                  showAppFrame={this.showAppFrame}
                  component={EReportContainer}
                />
                <PrivateRoute
                  key={9}
                  exact
                  path="/audittrail"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={AuditTrailContainer}
                />
                <PrivateRoute
                  key={10}
                  exact
                  path="/indicators"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={IndicatorsContainer}
                />
                <PrivateRoute
                  key={11}
                  exact
                  path="/dataimport"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={DataImportContainer}
                />
                <PrivateRoute
                  key={12}
                  exact
                  path="/adminusers"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={UserAdminContainer}
                />
                <PrivateRoute
                  key={13}
                  exact
                  path="/adminusers/:userId"
                  auth={auth}
                  userId={true}
                  showAppFrame={this.showAppFrame}
                  component={UserAdminContainer}
                />
                <PrivateRoute
                  key={14}
                  exact
                  path="/metrics"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={MetricsContainer}
                />
                <PrivateRoute
                  key={15}
                  exact
                  path="/settings"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={SettingContainer}
                />
                <PrivateRoute
                  key={16}
                  exact
                  path="/parameters"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={ParametersContainer}
                />

                <PrivateRoute
                  key={17}
                  exact
                  path="/nokpis"
                  auth={auth}
                  showAppFrame={this.showAppFrame}
                  component={NoKpisContainer}
                />

                <Route
                  key={1}
                  exact
                  path="/teamsConfig"
                  render={props => <TeamsConfig showAppFrame={this.showAppFrame} />}
                />
                <Route
                  key={2}
                  exact
                  path="/"
                  render={props => (
                    <HomeContainer showAppFrame={this.showAppFrame} />
                  )}
                />
                <Route
                  key={3}
                  exact
                  path="/login"
                  render={props => (
                    <LoginContainer showAppFrame={this.showAppFrame} />
                  )}
                />
                <Route
                  key={4}
                  exact
                  path="/changepassword"
                  render={props => (
                    <ChangePasswordContainer showAppFrame={this.showAppFrame} />
                  )}
                />
                {this.redirect()}
              </Switch>
              <LegalWarningModal
                open={openLegalWarning || showLegalTerms}
                toggle={this.cancelLegalWarning}
                acept={this.aceptLegalWarning}
              />
              <SweetAlert
                type={sweetAlert.type}
                show={sweetAlert.show}
                title={sweetAlert.title}
                text={sweetAlert.text}
                onConfirm={() =>
                  this.props.setSweetAlert({
                    type: "info",
                    title: "",
                    text: "",
                    show: false
                  })
                }
              />
              <ReduxToastr
                timeOut={10000}
                newestOnTop={true}
                position="top-center"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
              />
            </AppFrame>
          </Router>
          );
      
        }}
      </CacheBuster>
    );
  }
}

const mapDispatchToProps = {
  setLoad,
  userLogOut,
  setSweetAlert,
  MyUser,
  showError
};

const mapStateToProps = state => ({
  load: getLoad(state),
  myUser: getMyUser(state),
  sweetAlert: getSweetAlert(state),
  showLegalTerms: getShowLegalTerms(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

import  React from 'react';
import {Link} from 'react-router-dom';
import ScrollIntoView from 'react-scroll-into-view'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import '../../LoginComponents/LoginForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faLock, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const HomeMainCarrousel = React.forwardRef((props, ref) => (
    // <!-- Imagen principal -->
    <div className="full-page-wrap" id='inicio' ref={ref}>
        <div className={`top-menu-wrap ${props.className? props.className:''}`}>

            {/* <!-- Logo --> */}
            <img id='main-logo' className="logoCim"
                  src={require('../images/cim-logo-blanco.png') }
                  alt='CIM - Centro Interamericano de Monitoreo'
                  title="CIM - Centro Interamericano de Monitoreo"
            />

            {/* <!-- Static Menu --> */}
                {window.screen.width < 800 ?
                    (   <ul className='main-menu white-menu'>
                            <li className="login-bt ingresar-menu" onClick={props.handleLogin}><FontAwesomeIcon icon={faLock} className="m-r-sm"/><span>Ingresar</span></li>
                        </ul>
                    ) :
                    (
                        <ul className='main-menu white-menu'>
                            <li className='objetivos-bt'><ScrollIntoView alignToTop={true} selector="#objetivos"><Link to="#" >Quiénes Somos</Link></ScrollIntoView></li>
                            <li className='reportes-y-consultas-bt'><ScrollIntoView alignToTop={true} selector="#reportes-y-consultas"><Link to="#">Reportar</Link></ScrollIntoView></li>
                            <li className='companias-bt'><ScrollIntoView alignToTop={true} selector="#companias"><Link to="#">Compañías</Link></ScrollIntoView></li>
                            <li className='faqs-bt'><ScrollIntoView alignToTop={true} selector="#faqs"><Link to="#">Preguntas Frecuentes</Link></ScrollIntoView></li>
                            <li className="login-bt ingresar-menu" onClick={props.handleLogin}><FontAwesomeIcon icon={faLock} className="m-r-sm"/><span>Ingresar</span></li>
                        </ul>
                    )}


        </div>

        {/* <!-- Text --> */}
        <div className="carrousel-content">

            <h1 className='main-header'>
                Centro Interamericano de Monitoreo
            </h1>


           </div>
           <div className="actions-wrap-main report-carrousel " >

            <p className="action-stit">¿Tuvo algún inconviente con uno de nuestros productos?</p>

            <p className="action-text">
                Infórmanos sobre una reacción adversa a medicamento, inconveniente o evento inesperado  que consideres relacionado con los productos de las compañías farmacéuticas a las que prestamos servicios de farmacovigilancia
            </p>

            <Link to='#' className="button action-button" onClick={props.toggle}>
                <FontAwesomeIcon icon={faPaperPlane}/> Reportar
            </Link>


    </div>
        {/* <!-- Arrow --> */}
        <ScrollIntoView className="arrow-home" alignToTop={true} selector="#objetivos">

            <Link to="#" className="home-arrow arrow-3"><FontAwesomeIcon icon={faArrowDown} size="lg"/></Link>
            <Link to="#" className="home-arrow arrow-1"><FontAwesomeIcon icon={faArrowDown} size="lg"/></Link>

        </ScrollIntoView>

        <Carousel
             interval={1000}
             transitionTime={1500}
             infiniteLoop={true}
             showArrows={false}
             showIndicators={false}
             showStatus={false}
             showThumbs={false}
             stopOnHover={false}

             className="carrousel-img-wrap"
            >
                <div>
                    <img alt='bkg-1' src={require('../images/bkg-1-dsk.jpg')} />
                </div>
                <div >
                    <img alt='bkg-2' src={require('../images/bkg-2-dsk.jpg')} />
                </div>
                <div className=''>
                    <img alt='bkg-3' src={require('../images/bkg-3-dsk.jpg')} />
                </div>
        </Carousel>

    </div>
));

export default HomeMainCarrousel;

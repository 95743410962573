import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Row, Col } from 'react-flexbox-grid';
import AppBox from '../AppComponents/AppBox';
import { getAuditUsers as auditUsers, getAuditER, getAuditConfig, getAuditParams } from '../../redux/selectors/Audit';
import { PAGE_SIZE } from '../../redux/constants/get';
import ListAuditUsers from '../ListComponents/List/ListAuditUsers';
import LogDetailModal from '../Modals/LogDetailModal';
import {
  url_getAuditUsers,
  url_getOperatinHistoryERMeta,
  url_getOperatinHistoryByEvent,
  url_getAuditConfigDetails,
  url_getAuditConfig,
  url_getAuditUsersDetail, url_getAuditParameters, url_getAuditParametersDetails } from '../../api/urls';
import api from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconAuditType } from '../../constants/audit';
import AppPaginator from '../AppComponents/AppPaginator';
import { setAuditUsers,setAuditER, setAuditConfig, setAuditParams } from '../../redux/actions/audit';
import { getCodiguerasContext, setLoad } from '../../redux/actions/context';
import { FieldDatePicker, FieldText, FieldSelect } from '../Fields';
import { Button } from '@material-ui/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ListAuditER from '../ListComponents/List/ListAuditER';
import { showError } from '../../redux/actions/error';
import ListAuditConfig from '../ListComponents/List/ListAuditConfig';
import { getCodigueraContext } from '../../redux/selectors/Context';
import { getCodiguera } from '../../codigueras';

class AuditTrailComponent extends Component {

    state={
        pageUser:1,
        pageER:1,
        pageConfig:1,
        pageParams:1,
        byERHide:false,
        bySERClose:false,
        open:false,
        log: null,
        load:"",
        loadListUser:false,
        loadListER:false,
        loadListConfig:false,
        loadListParams:false,
    }

    loadAuditER = async (page)=>{
      this.setState({ loadListER:true, pageER:page });
        try{
          const data = {
              meta:null,
              referencia:(this.props.filters && this.props.filters.FilterER && this.props.filters.FilterER.NumberReference)? this.props.filters.FilterER.NumberReference : null,
              from: (this.props.filters && this.props.filters.FilterER && this.props.filters.FilterER.DateFrom) ? this.props.filters.FilterER.DateFrom : null,
              to: (this.props.filters && this.props.filters.FilterER && this.props.filters.FilterER.DateTo) ? this.props.filters.FilterER.DateTo : null,
              orderBy:'Date DESC',
              pageNumber:page,
              pageSize:PAGE_SIZE,
          };
          const response = await api.post(url_getOperatinHistoryERMeta, data);
          this.props.setAuditER(response.data);
          this.setState({ loadListER: false })
        }catch(error){
          this.props.showError(error);
        }
    }

    handleActionER = async (item) =>{
        this.setState({load:`${item.DateUTC}`, log:null, title:item.Date});
        try{
          const response = await api.post(url_getOperatinHistoryByEvent, item);
          item.log = response.data;
          this.setState({
            open:true,
            log: item,
            load:""
          })
        }catch(error){
          this.props.showError(error);
        }finally{

        }
    }

    loadAuditUser = async (page) => {
        this.setState({loadListUser:true,pageUser:page});
        try{
          const data = {
              from:(this.props.filters &&  this.props.filters.FilterUser && this.props.filters.FilterUser.DateFrom) ? this.props.filters.FilterUser.DateFrom  : null,
              to: (this.props.filters && this.props.filters.FilterUser &&  this.props.filters.FilterUser.DateTo) ? this.props.filters.FilterUser.DateTo : null,
              name:(this.props.filters && this.props.filters.FilterUser &&  this.props.filters.FilterUser.Name) ? this.props.filters.FilterUser.Name : null,
              orderBy:'Date DESC',
              pageNumber:page,
              pageSize:PAGE_SIZE,
          };
          const response = await api.post(url_getAuditUsers, data);
          this.props.setAuditUsers(response.data);
        }catch(error){
          this.props.showError(error);
        }finally{
          this.setState({ loadListUser: false });
        }
    }

    handleActionUser = async (item) =>{
        this.setState({load:`${item.DateUTC}`,log:null,title:item.Date})
        try {
          const data = {
            userId:item.PKId,
            date:item.DateUTC
          }
          const response = await api.post(url_getAuditUsersDetail, data);
          item.log = response.data;
          this.setState({
            open:true,
            log: item,
            load:""
          })

        } catch (e) {
          this.props.showError(e);
        }
      }

    loadAuditConfig = async (page) => {
        this.setState({loadListConfig:true,pageConfig:page});
        try {
          const data = {
              from:(this.props.filters &&  this.props.filters.FilterConfig && this.props.filters.FilterConfig.DateFrom) ? this.props.filters.FilterConfig.DateFrom  : null,
              to: (this.props.filters && this.props.filters.FilterConfig &&  this.props.filters.FilterConfig.DateTo) ? this.props.filters.FilterConfig.DateTo : null,
              orderBy:'Date DESC',
              pageNumber:page,
              pageSize:PAGE_SIZE,
          };
          const response = await api.post(url_getAuditConfig, data);
          this.props.setAuditConfig(response.data);
        } catch (e) {
          this.props.showError(e);
        }finally{
          this.setState({ loadListConfig:false });
        }
    }

    handleActionConfig = async (item) =>{
        this.setState({ load:`${item.DateUTC}`, log:null, title:item.Date });
        try{
            const data = {
            userId: item.PKId,
            date: item.DateUTC
          };
          const response = await api.post(url_getAuditConfigDetails, data);
          item.log = response.data;
          this.setState({
            open: true,
            log: item,
            load:""
          })
        }catch(er){
          this.props.showError(er);
        }
    }

    loadAuditParameters = async (page) => {
        this.setState({loadListParams:true,pageParams:page});
        try {
          const data = {
            from:(this.props.filters &&  this.props.filters.FilterParams && this.props.filters.FilterParams.DateFrom) ? this.props.filters.FilterParams.DateFrom  : null,
            to: (this.props.filters && this.props.filters.FilterParams &&  this.props.filters.FilterParams.DateTo) ? this.props.filters.FilterParams.DateTo : null,
            tabla :(this.props.filters && this.props.filters.FilterParams &&  this.props.filters.FilterParams.Tabla) ? this.props.filters.FilterParams.Tabla !== 'none' ? this.props.filters.FilterParams.Tabla : null  : null,
            orderBy:'Date DESC',
            pageNumber:page,
            pageSize:PAGE_SIZE,
          }
          const response = await api.post(url_getAuditParameters, data);
          this.props.setAuditParams(response.data);
        } catch (e) {
          this.props.showError(e);
        }finally{
          this.setState({loadListParams: false});
        }
    }

    handleActionParameters = async (item) =>{
        this.setState({ load:`${item.DateUTC}`, log: null, title: item.Date })
        try{
          const data = {
            userId:item.PKId,
            date:item.DateUTC
          };
          const response = await api.post(url_getAuditParametersDetails, data);
          item.log = response.data;
          this.setState({
            open: true,
            log: item,
            load: ""
          });
        }catch(e){
          this.props.showError(e);
        }
    }

    toggle = () => this.setState({open: !this.state.open, log:null});

    render() {
        const { users, ers, configs,params, codiguera } = this.props;
        const { load, open, log, loadListUser, loadListER, loadListConfig, pageER, pageUser, pageConfig, loadListParams, pageParams} = this.state;
        return (
            <div className='p-l-md p-r-md' >
                <Row>
                    <Col sm={6} xs={12} className="text-center m-t">
                        <Col xs={12} >
                        <AppBox title='Evento Reportable' hiddenTools={false} className=' text-left'>
                            <Row>
                                <Col sm={12} md={4} ><FieldText label='Referencia' name='FilterER.NumberReference' /></Col>
                                <Col sm={6} md={3}><FieldDatePicker allowFuture={false} allowPast={true} name={'FilterER.DateFrom'} label='Desde'/></Col>
                                <Col sm={6} md={3}><FieldDatePicker allowFuture={false} allowPast={true} name={'FilterER.DateTo'} label='Hasta'/></Col>
                                <Col sm={12} md={1} ><Button className='m-t-25 button-next' onClick={()=>this.loadAuditER(pageER)}><FontAwesomeIcon icon={faSearch}/></Button></Col>
                            </Row>
                            <ListAuditER loadList={loadListER} list={ers && ers.Result} handleAction={this.handleActionER} load={load}  />
                            <AppPaginator message='' result={ers && ers} handlePagination={this.loadAuditER} />
                        </AppBox>
                        </Col>
                    </Col>
                    <Col sm={6} xs={12} className="text-center m-t">
                        <Col xs={12}>
                            <AppBox title='Usuarios' hiddenTools={false}className=' text-left' >
                                <Row>
                                    <Col sm={6} md={3} ><FieldText name={'FilterUser.Name'} label='Nombre' type="text"/></Col>
                                    <Col sm={6} md={3} ><FieldDatePicker allowFuture={false} allowPast={true} name={'FilterUser.DateFrom'} label='Desde'/></Col>
                                    <Col sm={6} md={3} ><FieldDatePicker allowFuture={false} allowPast={true} name={'FilterUser.DateTo'} label='Hasta'/></Col>
                                    <Col sm={12} md={1}><Button className='m-t-25 button-next'  onClick={()=>this.loadAuditUser(pageUser)}><FontAwesomeIcon icon={faSearch}/></Button></Col>
                                </Row>
                                <ListAuditUsers loadList={loadListUser} list={users && users.Result} handleAction={this.handleActionUser} load={load}  />
                                <AppPaginator message='' result={users && users} handlePagination={this.loadAuditUser} />
                            </AppBox>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} xs={12} className="text-center m-t">
                        <Col xs={12} >
                                <AppBox title='Configuración' hiddenTools={false} className=' text-left' >
                                    <Row>
                                        <Col sm={6} md={3} ><FieldDatePicker allowFuture={false} allowPast={true} name={'FilterConfig.DateFrom'} label='Desde'/></Col>
                                        <Col sm={6} md={3} ><FieldDatePicker allowFuture={false} allowPast={true} name={'FilterConfig.DateTo'} label='Hasta'/></Col>
                                        <Col sm={12} md={1} mdOffset={4} ><Button className='m-t-25 button-next'  onClick={()=>this.loadAuditConfig(pageConfig)}><FontAwesomeIcon icon={faSearch}/></Button></Col>
                                    </Row>
                                    <ListAuditConfig loadList={loadListConfig} list={configs && configs.Result} handleAction={this.handleActionConfig} load={load}  />
                                    <AppPaginator message='' result={configs && configs} handlePagination={this.loadAuditConfig} />
                                </AppBox>
                            </Col>
                    </Col>
                    <Col sm={6} xs={12} className="text-center m-t">
                        <Col xs={12} >
                            <AppBox title='Parametros' hiddenTools={false} className='text-left' >
                                <Row>
                                    <Col sm={6} md={3} ><FieldSelect items={codiguera && getCodiguera("CodigueraNombres",codiguera)} textField="Descripcion" valueField="Nombre" name={'FilterParams.Tabla'} label='Tabla'/></Col>
                                    <Col sm={6} md={3} ><FieldDatePicker allowFuture={false} allowPast={true} name={'FilterParams.DateFrom'} label='Desde'/></Col>
                                    <Col sm={6} md={3} ><FieldDatePicker allowFuture={false} allowPast={true} name={'FilterParams.DateTo'} label='Hasta'/></Col>
                                    <Col sm={12} md={1} ><Button className='m-t-25 button-next'  onClick={()=>this.loadAuditParameters(pageParams)}><FontAwesomeIcon icon={faSearch}/></Button></Col>
                                </Row>
                                <ListAuditConfig loadList={loadListParams} list={params && params.Result} handleAction={this.handleActionParameters} load={load}  />
                                <AppPaginator message='' result={params && params} handlePagination={this.loadAuditParameters} />
                            </AppBox>
                        </Col>
                    </Col>
                </Row>
                {(open && log) && <LogDetailModal log={log} open={open} toggle={this.toggle}  title={<span><FontAwesomeIcon color="#01b5af" className='m-r-sm' icon={iconAuditType(log.OperationType)}/> {log.ReferenceNumber}</span>}/>}
            </div>
        );
    }
}

const mapStateToProps = (state)=>({
    users: auditUsers(state),
    ers: getAuditER(state),
    configs:getAuditConfig(state),
    params: getAuditParams(state),
    filters: getFormValues('AuidtTrail')(state),
    codiguera: getCodigueraContext(state),

});

const mapDispatchToProps = {
    setAuditUsers,
    setAuditER,
    setAuditConfig,
    setAuditParams,
    getCodiguerasContext,
    setLoad,
    showError
}

const validate = values =>{
    const error ={};

    return error;
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({form: 'AuidtTrail', validate})(AuditTrailComponent));

    import React from 'react';
import { getElementCodiguera } from '../../../../codigueras';
import { Row, Col } from 'react-flexbox-grid';
import { FieldLabel, LabelDate } from '../../../Fields';

const SummaryStep4 = ({values,codiguera,isMedic}) => {
    return (
        <div className='form-step'>
            {(values && values.Medicamento && values.Medicamento.length > 0) && values.Medicamento.map((r,i) => <SummaryMedicamento key={i} values={r} codiguera={codiguera} isMedic={isMedic}/>)}
        </div>
    );
};

export default SummaryStep4;

const SummaryMedicamento = ({values,codiguera,isMedic})=> {
    return (<div>
        <h3>Medicamento / producto sospechoso</h3>
        <Row className="form-step-row">
          <Col sm={12} md={8}>
              <FieldLabel type="text" label="Medicamento" value={values.Producto[0].ComercialName}/>
          </Col>
          <Col sm={12} md={4}>
              <FieldLabel type="text" label="Vía de administración" value={codiguera && getElementCodiguera('via', values.Via, codiguera) }/>
          </Col>
          <Col sm={12} md={4}>
              <FieldLabel type="text" label="Intervalo entre dosis" value={`${getElementCodiguera('frecuencia', values.FrecuenciaTipo,codiguera)} ${values.Frecuencia ? values.Frecuencia +' horas':'---'} `}/>
          </Col>
          <Col sm={12} md={4}>
              <FieldLabel  type="text" label="Dosis" value={values.Dosis}/>
          </Col>
          <Col sm={12} md={4}>
              <FieldLabel type="text" label="Unidad de dosis" value={codiguera && getElementCodiguera('unidadUso', values.UnidadUso, codiguera)}/>
          </Col>
          <Col sm={12} md={4}>
              <FieldLabel type="date" label="Fecha de inicio de la administración" value={values.FechaIni}/>
          </Col>
          <Col sm={12} md={4}>
              <FieldLabel type="date" label="Fecha de finalización de la administración" value={values.FechaFin}/>
          </Col>
          <Col sm={12}>
              <FieldLabel type="text" label="Motivo de indicación" value={values.Indicacion}/>
          </Col>
      </Row>
  </div>);
}
import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
import "./../node_modules/@fortawesome/free-solid-svg-icons";
const rootComponent = (
  <Provider store={store}>
    <App />
  </Provider>
);
ReactDOM.render(rootComponent, document.getElementById("root"));
registerServiceWorker();

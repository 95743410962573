import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Details from '../../EReportView/Steps/Step0_Details';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner, faPaperPlane, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { url_postERcreate, url_postInsertReporteSeguimiento, url_nav_ReportableEvent} from '../../../../api/urls';
import api from '../../../../api';
import { setLoad } from '../../../../redux/actions/context';
import { showError } from '../../../../redux/actions/error';
import { getWorkFlowList } from '../../../../redux/actions/eventoReportable';
import { getER,
    getERPaciente,
    getEROriginReport,
    getERMedicamentosSosp,
    getERRAMS,
    getERWhoReport, getWorkFlowList as workflow } from '../../../../redux/selectors/ER';
import Signature from '../../../Signature';

class Summary extends Component {
    state={
        load:false,
        signatureOpen:false
    }

    componentDidMount= () => {}

    handleTopPage = () =>{
        window.scrollTo(0, 0)
    }

    handleSignature = () => {
        this.setState((previus) =>({
            signatureOpen: !previus.signatureOpen
        }));
    }

    handleSend = async (comentario)=> {

        this.setState({ load: true });
        this.props.setLoad(true);
        const ev = this.props.ER;
        const patient = this.props.ER.Paciente;
        const medicamentos = this.props.ER.MedicamentosRelacionados;
        const rams = this.props.ER.Rams;
        const origin = this.props.ER.OrigenReporte;
        try{
          if(this.props.type === '2'){
            const response = await api.post(url_postInsertReporteSeguimiento, {
              ev, comentario, patient, medicamentos, rams, origin
            });
          
           this.props.history.push(url_nav_ReportableEvent(response.data.MetaData));

          }else{
            await api.post(url_postERcreate, {Event: ev, comentario: comentario});
          
           this.props.history.push(url_nav_ReportableEvent(this.props.meta));
          }
        }catch(error){
          this.props.showError(error);
        }finally{
          this.setState({ load: false });
          this.props.setLoad(false);
        }
    }

    render() {
        const {meta, handleBack, handleBtnEdit} = this.props;
        const {load, signatureOpen} = this.state;
        const titulo = this.props.type === "2" ? "Finalizar la creación del Reporte de Seguimiento":"Finalizar la creación del evento reportable"

        return (
            <div>
                <Details meta={meta} summary={true} handleBtnEdit={handleBtnEdit}/>
                <CardActions className="card-actions p-t-lg">
                    <div>
                        <Button type="button" disabled={load} className="card-actions-button button-back" variant="contained" onClick={handleBack} >
                            <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                        </Button>
                        <Button type="button" className="card-actions-button" variant="contained" onClick={this.handleTopPage} >
                            <FontAwesomeIcon size="lg" icon={faArrowUp} />
                        </Button>
                        <Button type="button" disabled={load} className="card-actions-button button-next" variant="contained" onClick={this.handleSignature} >
                            <FontAwesomeIcon size="lg" icon={load ? faSpinner : faPaperPlane} pulse={load} />
                        </Button>
                    </div>
                </CardActions>
                <Signature
                    toggle={this.handleSignature}
                    handleConfirm={this.handleSend}
                    open={signatureOpen}
                    title={titulo}
                    message="Esta acción realizará las notificaciones a los usuarios correspondientes ¿Está seguro qué quiere generar el reporte?"  />
            </div>
        );
    }
}

Summary.propTypes = {
    meta: PropTypes.string,
    ER: PropTypes.object,
};

const mapDispatchToProps = {
    setLoad,
    getWorkFlowList,
    showError
};

const mapStateToProps = state=> ({
    ER: {
        ER : getER(state),
        Paciente: getERPaciente(state),
        OrigenReporte: getEROriginReport(state),
        MedicamentosRelacionados: getERMedicamentosSosp(state),
        Rams: getERRAMS(state),
        Contacto: getERWhoReport(state),
    },
    workflow : workflow(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Summary));

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PacienteDetail from "./PacienteDetail";
import {
  getER,
  getERPaciente,
  getEROriginReport,
  getERMedicamentosSosp,
  getERRAMS,
  getERWhoReport,
  getERContactoMedico,
  getERHomeReport,
  getMedicationHistory
} from "./../../../../../redux/selectors/ER";
import OriginReportDetail from "./OriginReportDetail";
import SuspectMedicationsDetail from "./SuspectMedicationsDetail";
import AdverseReactionsDetail from "./AdverseReactionsDetail";
import LastMonthsDetail from "./LastMonthsDetail";
import RelevantInformationDetail from "./RelevantInformationDetail";
import ContactDataDetail from "./ContactDataDetail";
import { stepsERedit } from "../../../../../constants/steps";
import MedicalContact from "./MedicalContact";
import { getCodigueraContext } from "../../../../../redux/selectors/Context";
import { SummaryERHome } from "../../../EReportView/Steps/Step0_Details/SummaryERHome";

const Details = props => {
  return (
    <div>
      {(props.ER.IdEventoHome !== null && props.ERHome)  ? (
        <SummaryERHome
          title={stepsERedit[1]}
          ehome={props.ERHome }
          codiguera={props.codiguera}
        />
      ) :
      (
        <OriginReportDetail
          title={stepsERedit[1]}
          OriginReport={props.OriginReport}
          handleBtnEdit={props.summary && props.handleBtnEdit}
          step={props.summary && 1}
        />
      )}
      <PacienteDetail
        title={stepsERedit[0]}
        paciente={props.Paciente}
        handleBtnEdit={props.summary && props.handleBtnEdit}
        step={props.summary && 0}
      />
      {props.Medications &&
        props.Medications.map(med => (
          <SuspectMedicationsDetail
            codiguera={props.codiguera}
            title={stepsERedit[2]}
            key={med.Id}
            medication={med}
            handleBtnEdit={props.summary && props.handleBtnEdit}
            step={props.summary && 2}
          />
        ))}
      {props.RAMS &&
        props.RAMS.map(ram => (
          <AdverseReactionsDetail
            codiguera={props.codiguera}
            title={stepsERedit[3]}
            key={ram.Id}
            RAM={ram}
            handleBtnEdit={props.summary && props.handleBtnEdit}
            step={props.summary && 3}
          />
        ))}
      <LastMonthsDetail
        title={stepsERedit[4]}
        ER={props.ER}
        medicamentosAnteriores={props.MedicationsAnt}
        handleBtnEdit={props.summary && props.handleBtnEdit}
        step={props.summary && 4}
        codiguera={props.codiguera}
      />
      <RelevantInformationDetail
        title={stepsERedit[6]}
        ER={props.ER}
        handleBtnEdit={props.summary && props.handleBtnEdit}
        step={props.summary && 6}
      />
      <MedicalContact
        title={stepsERedit[5]}
        step={props.summary && 5}
        handleBtnEdit={props.summary && props.handleBtnEdit}
        contactoMed={props.ContactMedical}
      />
      <ContactDataDetail
        codiguera={props.codiguera}
        title={"Datos de contacto"}
        ER={props.ER}
        WR={props.WhoReport}
        paciente={props.Paciente}
        medico={props.ContactMedical}
        handleBtnEdit={props.summary && props.handleBtnEdit}
      />
    </div>
  );
};

Details.propTypes = {
  ER: PropTypes.object,
  Paciente: PropTypes.object,
  OriginReport: PropTypes.object,
  Medications: PropTypes.array,
  RAMS: PropTypes.array,
  WhoReport: PropTypes.object
};

const mapStateToProps = state => ({
  ER: getER(state),
  Paciente: getERPaciente(state),
  OriginReport: getEROriginReport(state),
  Medications: getERMedicamentosSosp(state),
  RAMS: getERRAMS(state),
  WhoReport: getERWhoReport(state),
  ContactMedical: getERContactoMedico(state),
  codiguera: getCodigueraContext(state),
  ERHome: getERHomeReport(state),
  MedicationsAnt: getMedicationHistory(state)
});

export default connect(mapStateToProps, null)(Details);

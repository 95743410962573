import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import { LabelDate } from '../../Fields';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListActions from './ListActions';


const actions = (handleAction, Item) => [
    <Link to={'#'} onClick={() => handleAction(Item)}>{<FontAwesomeIcon icon={faEye} size={'lg'}/>}</Link>
];

const ListItemEventodeSeguimiento = ({Item,handleAction, classProp}) => {
    return (
        <tr className={`list-item ${classProp}`}>
            <td>{LabelDate(Item.FechaUltMod)}hs</td>
            <td>{Item.UsuarioNom}</td>
            <td>{Item.NumeroReferencia}</td>
            <td className="p-t-sm"><ListActions  actions={actions(handleAction,Item)}/></td>
        </tr>
    );
};

ListItemEventodeSeguimiento.propTypes = {
    Item: PropTypes.object,
};

export default ListItemEventodeSeguimiento;
import React from "react";
import { Row, Col } from "react-flexbox-grid";
import {
  FieldText,
  FieldRadioGroup,
  FieldCheck,
  FieldDatePicker,
  FieldSelect
} from "../../../../Fields";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import { emailValidator } from "../../../../../helpers/utilities";
import { getCodiguera } from "../../../../../codigueras";

const Step7 = props => {
  const { aceptaContacto, showTerms, isMedic, codiguera } = props;
  return (
    <div className="form-step">
      <h3>Información del reportante</h3>
      <Row>
        <Col md={12} sm={12}>
          <FieldRadioGroup
            isRequired={true}
            name="AceptaContacto"
            labelBlock={true}
            inline={true}
            items={[
              { value: "SI", text: "Si" },
              { value: "NO", text: "No" }
            ]}
            label="¿Autoriza usted a ser contactado por nosotros en caso de ser necesaria información adicional para la evaluación?"
            textField="text"
            valueField="value"
          />
        </Col>
      </Row>
      {isMedic && aceptaContacto === "SI" && (
        <Row>
          <Col sm={12} md={6}>
            <FieldText
              isRequired={true}
              name="Medico.Nombre"
              label="Nombre"
              type="text"
            />
          </Col>
          <Col sm={12} md={6}>
            <FieldText
              isRequired={true}
              name="Medico.Profesion"
              label="Profesión"
              type="text"
            />
          </Col>
          <Col sm={12} md={6}>
            <FieldText
              isRequired={true}
              name="Medico.Email"
              label="Correo eletrónico"
              type="text"
            />
          </Col>
          <Col sm={12} md={6}>
            <FieldText name="Medico.Telefono" label="Teléfono" type="text" />
          </Col>
          <Col sm={12} md={6}>
            <FieldText
              name="Medico.CentroAtencion"
              label="Centro de atención"
              type="text"
            />
          </Col>
          <Col md={6} sm={12}>
            <FieldDatePicker
              name={`Medico.FechaContacto`}
              allowFuture={false}
              allowPast={true}
              label="Fecha de contacto médico"
            />
          </Col>
          <Col sm={12}>
            <FieldText
              name={`Medico.Intervencion`}
              label="Intervención realizada"
              type="textarea"
            />
          </Col>
        </Row>
      )}
      {!isMedic && aceptaContacto === "SI" && (
        <Row>
          <Col sm={12} md={6}>
            <FieldSelect
                name="Reporta.Parentesco"
                label="Indique la relación con el paciente"
                items={codiguera && getCodiguera("Parentesco", codiguera)}
                valueField="Id"
                textField="Nombre"
                typeValue="number"
            />

          </Col>

          <Col sm={12} md={6}>
            <FieldText
              isRequired={true}
              name="Reporta.Email"
              label="Correo eletrónico"
              type="email"
            />
          </Col>
          <Col sm={12} md={6}>
            <FieldText name="Reporta.Telefono" label="Teléfono" type="text" />
          </Col>
        </Row>
      )}
      {showTerms && (
        <div className="text-center">
          <FieldCheck
            name="AceptaTerminos"
            label={
              <span>
                He leído y acepto los{" "}
                <Link to="#" onClick={showTerms}>
                  términos legales de comunicación de datos personales.
                </Link>
              </span>
            }
            isRequired={true}
            className=""
          />
        </div>
      )}
    </div>
  );
};

const validate = (values, props) => {
  let error = {};
  if (!values.AceptaTerminos) {
    error.NotificadoPorOtro =
      "Debe aceptar los terminos para reportar el acontecimiento adverso / reacción adversa.";
  }
  if (!values.AceptaContacto) {
    error.AceptaContacto =
      "Debe indicar sí desea ser contactado en caso de ser necesaria más información para la evaluación";
  }
  if (values.AceptaContacto === "SI") {
    if (props.isMedic) {
      if (!values.Medico || !values.Medico.Nombre) {
        if (!error.Medico) error.Medico = {};

        error.Medico.Nombre = "El campo es obligatorio";
      }
      if (!values.Medico || !values.Medico.Profesion) {
        if (!error.Medico) error.Medico = {};
        error.Medico.Email = "El campo es obligatorio";
      }
      if (!values.Medico || !values.Medico.Email) {
        if (!error.Medico) error.Medico = {};
        error.Email = "Debe ingresar un correo para ser contactado";
      } else if (
        values.Medico &&
        values.Medico.Email &&
        !emailValidator(values.Medico.Email)
      ) {
        if (!error.Medico) error.Medico = {};
        error.Medico.Email = "El formato del correo es incorrecto";
      }
    } else {
      if (!values.Reporta || !values.Reporta.Email) {
        if (!error.Reporta) error.Reporta = {};
        error.Email = "Debe ingresar un correo para ser contactado";
      } else if (
        values.Reporta &&
        values.Reporta.Email &&
        !emailValidator(values.Reporta.Email)
      ) {
        if (!error.Reporta) error.Reporta = {};
        error.Reporta.Email = "El formato del correo es incorrecto";
      }
    }
  }
  if (!values.AceptaTerminos) {
    error.AceptaTerminos = "Debe aceptar los terminos para poder continuar";
  }
  return error;
};

const mapStateToProps = state => ({
  aceptaTerminos: formValueSelector("HomeStep7")(state, "AceptaTerminos"),
  aceptaContacto: formValueSelector("HomeStep7")(state, "AceptaContacto")
});
export default connect(
  mapStateToProps,
  null
)(reduxForm({ form: "HomeStep7", validate })(Step7));

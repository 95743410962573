import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { AllowedFileAccept, MaxFileSize } from '../../constants/fileAccept';
import FileAcceptedLoad from './fileAcceptedLoad';
import './style.css';
import { FormGroup, Label } from 'reactstrap';


class UploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listAcceptedFiles : [],
            listRejectedFiles : []};
    }
    dropzoneRef = {};

    renderAcceptedFile = files =>  files.map((f, i) => (<FileAcceptedLoad key={i} file={f} index={i} handleDelete={this.handleDeleteAcceptedFileLoad}/>));

    handleDeleteAcceptedFileLoad = index => {
        let files = this.state.listAcceptedFiles;
        files.splice(index, 1);
        this.setState({listAcceptedFiles : files});
    }

    handleOnDrop = (acceptedFiles, rejectedFiles) => {

        this.setState({
            listAcceptedFiles : acceptedFiles,
            listRejectedFiles : rejectedFiles
        });
    }

    componentDidMount() {
        if(this.props.fileAnts){
            this.setState({listAcceptedFiles:this.props.fileAnts});
        }
    }
    render(){
        const { multiple, label, handleAddFile } = this.props;
        const { listAcceptedFiles } = this.state;
        return (
            <FormGroup>
                {label && <Label>{label}</Label>}
                <Dropzone className="my-dropzone" hidden={listAcceptedFiles.length>0}
                    ref={(node) => { this.dropzoneRef = node; }}
                    onDropAccepted={handleAddFile}
                    onDrop={this.handleOnDrop}
                    accept={AllowedFileAccept}
                    maxSize={MaxFileSize}
                    multiple={multiple}
                ><p>Arrastrar archivo (o hacer click aqui)</p></Dropzone>
                {listAcceptedFiles.length > 0 && this.renderAcceptedFile(listAcceptedFiles)}
            </FormGroup>
        );
    }

};

UploadFile.propTypes = {
    handleSaveDocAndNote: PropTypes.func,

};

export default UploadFile;

import React, { Component } from "react";
import { connect } from "react-redux";
import Step1 from "./Form/Step1";
import Step2 from "./Form/Step2";
import Step3 from "./Form/Step3";
import Step4 from "./Form/Step4";
import Step5 from "./Form/Step5";
import Step6 from "./Form/Step6";
import Step7 from "./Form/Step7";
import FormTitle from "./Form/FormTitle";
import { getHomeCodiguera } from "../../../redux/selectors/home";
import { showError } from '../../../redux/actions/error';
import { setLoad } from '../../../redux/actions/context';
import {
  setHomeMedicamentos,
  setHomeCodiguera
} from "../../../redux/actions/home";
import FormFooter from "./Form/FormFooter";
import { getForm } from "../../../redux/selectors/Forms";
import {
  url_getMedicamentosByCountry,
  url_postreporthome
} from "../../../api/url_home";
import { url_postreporthomeusuario } from "../../../api/urls";
import api from "../../../api";
import Summary from "./Summary";
import AppLoader from "../../AppComponents/AppLoader";
import FormFinished from "./Form/FormFinished";
import { getElementCodiguera } from "../../../codigueras";

class HomeForm extends Component {
  state = {
    step: 1,
    load: false,
    sending: false,
    toggleTerms: false,
    form: {},
    errors: {},
    identificador: ""
  };

  componentDidMount = () => {
    if (!this.props.isMedic) {
      this.setState({ step: 2 });
    }
  };

  handleCancel = () => {
    this.setState({
      step: 1,
      load: false,
      sending: false,
      form: {}
    });
    this.props.toggle();
  };

  handleChangePais = async (e) => {
    const { value } = e.target;
    try{
      const response = await api.get(url_getMedicamentosByCountry(value));
      this.props.setHomeMedicamentos(response.data);
    }catch(error){
      this.props.showError(error);
    }
  }

  renderStep = step => {
    const { isMedic, codiguera } = this.props;
    const { form, identificador } = this.state;
    switch (step) {
      case 1:
        return (
          <Step1
            view="edit"
            initialValues={form.step1}
            handleChangePais={this.handleChangePais}
          />
        );
      case 2:
        return (
          <Step2
            view="edit"
            initialValues={form.step2}
            handleChangePais={this.handleChangePais}
            isMedic={isMedic}
          />
        );
      case 3:
        return (
          <Step3 view="edit" initialValues={form.step3} isMedic={isMedic} />
        );
      case 4:
        return (
          <Step4 view="edit" initialValues={form.step4} isMedic={isMedic} />
        );
      case 5:
        return (
          <Step5 view="edit" initialValues={form.step5} isMedic={isMedic} />
        );
      case 6:
        return (
          <Step6 view="edit" initialValues={form.step6} isMedic={isMedic} />
        );
      case 7:
        return (
          <Step7
            view="edit"
            initialValues={form.step7}
            isMedic={isMedic}
            showTerms={this.props.showLegalWarning}
            codiguera={codiguera}
          />
        );
      case 8:
        return <Summary isMedic={isMedic} form={form} codiguera={codiguera} />;
      case 9:
        return (
          <FormFinished
            identificador={identificador}
            contacto={codiguera.Contacto}
          />
        );
      default:
        return null;
    }
  };

  handleChangeStep = option => {
    const { step } = this.state;
    const next = option === "next" ? step + 1 : step - 1;
    if (step < 8) {
      let form = {};
      form = {
        ...this.state.form,
        [`step${step}`]: this.props[`step${step}`].values
      };
      if (step === 7) {
        let step1;
        let step2 = form.step2;
        if (this.props.isMedic) {
          step1 = form.step1;
          step2.Pais = step1.Pais;
        } else {
          step1 = {
            Pais: step2.Pais,
            SubGroup: "0"
          };
        }
        form = { ...form, step1, step2 };

        this.setState({ form });
      }

      if ((option === "next" && this.validateStep(step)) || option === "back") {
        this.setState({ step: next, form });
      }
    } else {
      this.setState({ step: next });
    }
  };

  validateStep = step => {
    let errors = {};
    let valid = true;
    if (!this.props[`step${step}`].valid) {
      valid = false;
      errors = { ...errors, [`step${step}`]: this.props[`step${step}`].errors };
    }
    this.setState({ errors });
    return valid;
  };

  handleSend = async (reCaptchaToken) => {
    let { form } = this.state;
    form.isMedic = this.props.isMedic;

    const requestConfig = {
      headers: {
        'recaptcha-token': reCaptchaToken
      }
    }

    this.setState({ form, load: true });
    const report = {
      isMedic: form.isMedic,
      Tipo: form.step1,
      Paciente: form.step2,
      RAM: form.step3.RAM,
      Medicamento: form.step4.Medicamento,
      Concomitante: form.step5 && form.step5.Concomitante,
      ContactoMedico: form.step6,
      InformacionContacto: form.step7
    };

    if (report.Tipo.SubGroup !== "0") {
      report.Tipo.SubGroupName = getElementCodiguera("subGroup");
    }

    if (this.props.home) {
      try{
        const response = await api.post(url_postreporthome, report, requestConfig);
        this.setState({ identificador: response.data, step:9});

      }catch(error){
        this.props.showError(error);
      }finally{
        this.setState({ load: false });
      }

    } else {
      try{
        const response = await api.post(url_postreporthomeusuario, report, requestConfig);
        this.setState({ identificador: response.data, step: 9 });
      }catch(error){
        this.setState({ load: false });
      }finally{
        this.setState({ load: false });
      }

    }
  }

  render() {
    const { step, load } = this.state;
    const { show, isMedic } = this.props;
    return (
      <div className={`cover-wrap full-wrap ${show ? "show" : ""}`}>
        {load && <AppLoader size={120} load={load} />}
        <div className="modal-wrap form">
          <FormTitle
            title="Formulario de reporte"
            handleClose={this.handleCancel}
          />
          {isMedic !== null && this.renderStep(step)}
          <FormFooter
            step={step}
            handleSend={this.handleSend}
            handleChangeStep={this.handleChangeStep}
            isMedic={isMedic}
            load={load}
            handleClose={this.handleCancel}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  codiguera: getHomeCodiguera(state),
  step1: getForm("HomeStep1", state),
  step2: getForm("HomeStep2", state),
  step3: getForm("HomeStep3", state),
  step4: getForm("HomeStep4", state),
  step5: getForm("HomeStep5", state),
  step6: getForm("HomeStep6", state),
  step7: getForm("HomeStep7", state)
});

const mapDispatchToProps = {
  setHomeCodiguera,
  setHomeMedicamentos,
  showError,
  setLoad
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeForm);

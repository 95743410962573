import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppContainer from '../components/AppComponents/AppContainer';
import setPropsAsInitial from '../helpers/setPropsAsInitial';
import ParametersComponent from '../components/ParametersComponents';
import { Row, Col } from 'react-flexbox-grid';
import { setLoad } from '../redux/actions/context';
import { showError } from '../redux/actions/error';
import api from '../api';
import { url_getCodiguerasAll, url_createElementCodiguera, url_updateElementCodiguera, url_deleteElementCodiguera } from '../api/urls';
import { setSweetAlert } from '../redux/actions/sweetAlert';

let itemAux = null;
let codigueraAux = null;

class ParametersContainer extends Component {

    state = {
        codiguera: null,
        codigueraOr: null,
        load: '',
    }

    componentWillMount = () => {
        this.props.setLoad(false);
        this.props.showAppFrame(this.props.location.pathname);

    }

    componentDidMount = () => {
        this._handleGetCodigueras();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.load !== nextState.load) {
            return true;
        }
        return false;
    }


    getDescriptionParameterByName = name => {
        const { codiguera } = this.state;
        if (codiguera && codiguera.CodigueraNombres) {
            return codiguera.CodigueraNombres.find(x => x.Nombre === name).Descripcion;

        }
        return "";
    }

    _handleGetCodigueras = async () => {
        this.props.setLoad(true);
        this.setState({ load: "codigueras_load" });
        try {
            const response = await api.get(url_getCodiguerasAll);
            this.setState({ codiguera: response.data, codigueraOr: response.data, load: '' });
        } catch (error) {
            this.props.showError(error);
        } finally {
            this.props.setLoad(false);
            this.setState({ load: '' });
        }
    }

    _handleCodigueraNameProsses = codigueras => {
        const props = Object.keys(codigueras).reduce((acc, cod) => {
            if (cod !== "Contacto" && cod !== "Roles" && cod !== "Status" && cod !== "CodigueraNombres")
                return [...acc, cod];
            else
                return [...acc];
        }, [])
        const aux = props.sort((a, b) => {
            let ret = 0;
            a = this.getDescriptionParameterByName(a);
            b = this.getDescriptionParameterByName(b);
            if (a > b)
                ret = 1;
            else if (a < b)
                ret = -1;
            return ret;
        });
        const codA = aux.reduce((acc, cod, index) => {
            if (index % 2 > 0)
                return [...acc, cod];
            else
                return [...acc];
        }, []);
        const codB = aux.reduce((acc, cod, index) => {
            if (index % 2 === 0)
                return [...acc, cod];
            else
                return [...acc];
        }, []);
        return [codA, codB];
    }


    _handleCodigueraProsses = codiguera => {
        return codiguera.map(cod => this._handleProssesValue(cod));
    }

    _handleCreate = (codiguera, elem) => {
        this.setState({ load: `${codiguera}-add` });
        return api.post(url_createElementCodiguera, { codiguera, elem });
    }

    getDeleteRequesByTable = (tableName, element) => {

        switch (tableName) {
            case 'AlturaUnidad':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.NombreCompleto
                    }
                }
            case 'Especialidad':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.Description
                    }
                }
            case 'Grupo':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.Title
                    }
                }
            case 'MedicamentoFrecuencia':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.Unidad
                    }
                }
            case 'MedicamentoUnidad':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.Unidad
                    }
                }
            case 'MedicamentoUnidadUso':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.Unidad
                    }
                }
            case 'PesoUnidad':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.NombreCompleto
                    }
                }
            case 'RAMTipos':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.Type
                    }
                }
            case 'RAMCategorias':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.Type
                    }
                }
            case 'Via':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.Descripcion
                    }
                }
            case 'Status':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.Estado
                    }
                }
            case 'Roles':
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.Name
                    }
                }
            default:
                return {
                    codiguera: tableName,
                    elem: {
                        Id: element.Id,
                        Eliminado: element.Eliminado,
                        Nombre: element.Nombre
                    }
                }
        }
    }

    getKeysByTable = (codiguera, keys) => {
        switch (codiguera) {
            case 'Especialidad':
                return ["Descripcion"]
            case 'Grupo':
                return ["Titulo"]
            case 'RAMCategorias':
            case 'RAMTipos':
                return ["Tipo"]
            case 'SubGrupo':
                let filteredKeys = keys.filter(function (item) {
                    return item !== "GrupoTitulo"
                });
                return filteredKeys;
            default:
                return keys;
        }
    }

    _handleUpdate = (codiguera, elem) => {
        this.setState({ load: `${codiguera}-edit` });
        return api.post(url_updateElementCodiguera, { codiguera, elem });
    }

    _handleDelete = async (codiguera, elem) => {
        elem.Eliminado = !elem.Eliminado
        this.setState({ load: `${codiguera}-delete` })
        const request = this.getDeleteRequesByTable(codiguera, elem)
        return await api.post(url_deleteElementCodiguera, request)
    }

    SendAction = (codiguera, action, obj, setView, setForEdit) => {
        let request = {}
        switch (action) {
            case 'create': {
                if (itemAux.Id === null) {
                    const Keys = Object.keys(itemAux).filter(x => x !== "Id" && x !== "Eliminado" && x !== "ImportCodigo")
                    const Values = Keys.reduce((acc, ky) => {
                        return [...acc, itemAux[ky]];
                    }, []);
                    request = {
                        Keys: this.getKeysByTable(codiguera, Keys),
                        Values
                    }
                    this._handleCreate(codiguera, request)
                        .then(r => {
                            this.props.setSweetAlert({
                                type: 'success',
                                title: "Creado correctamente",
                                show: true
                            });
                            this.setState(
                                {
                                    codiguera: r.data,
                                    codigueraOr: r.data,
                                    load: '',
                                }
                            );
                            setView('list');
                            setForEdit({ Id: -1 });
                            codigueraAux = null;
                            itemAux = null;
                        })
                        .catch(e => {
                            this.handleCancelEdit();
                            this.showError(e);
                            this.setState(
                                {
                                    load: '',
                                }
                            );
                        });
                }
                break;
            }
            case 'edit': {
                if (itemAux && obj.Id === itemAux.Id) {
                    const Keys = Object.keys(itemAux).filter(x => x !== "Id" && x !== "Eliminado" && x !== "ImportCodigo" && x !== "Value")
                    const Values = Keys.reduce((acc, ky) => {
                        return [...acc, itemAux[ky]];
                    }, []);

                    request = {
                        id: itemAux.Id,
                        Keys: this.getKeysByTable(codiguera, Keys),
                        Values
                    }
                    this._handleUpdate(codiguera, request)
                        .then(r => {
                            codigueraAux = null;
                            itemAux = null;
                            setView('list');
                            setForEdit({ Id: -1 });
                            this.props.setSweetAlert({
                                type: 'success',
                                title: "Actualizado correctamente",
                                text: `El elemento fue actualizado`,
                                show: true
                            });
                            this.setState(
                                {
                                    codiguera: r.data,
                                    codigueraOr: r.data,
                                    load: '',
                                }
                            );
                        })
                        .catch(e => {
                            this.handleCancelEdit();
                            this.showError(e);
                            this.setState(
                                {
                                    load: '',
                                }
                            );
                        });
                }
                break;
            }
            case 'delete': {
                this._handleDelete(codiguera, obj)
                    .then(r => {
                        this.setState({ codiguera: r.data, codigueraOr: r.data, load: '' });
                        setView('list');
                        setForEdit({ Id: -1 });
                        this.props.setSweetAlert({
                            type: 'success',
                            title: `${obj.Eliminado ? "Deshabilitado" : "Habilitado"} correctamente`,
                            text: `El elemento fue  ${obj.Eliminado ? "deshabilitado" : "habilitado"} `,
                            show: true
                        });
                    })
                    .catch(e => {
                        this.handleCancelEdit();
                        this.showError(e);
                        setView('list');
                        setForEdit({ Id: -1 });
                        this.setState(
                            {
                                load: '',
                            }
                        );
                    });
                break;
            }
            default:
                break;
        }
    }

    showError = (error) => {
        let msg;
        if (error.response) {
            msg = error.response.message;
        } else if (error.request) {
            msg = error.request;
        } else {
            // Something happened in setting up the request and triggered an Error
            msg = error.message;
        }
        this.props.setSweetAlert({
            type: 'error',
            title: "No se pudo realizar cambios en la configuración",
            text: `${msg}`,
            show: true
        });
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        const [codiguera, id, property] = name.split('|');
        let auxItem = null
        if (itemAux !== null && codigueraAux === codiguera) {
            auxItem = itemAux
        }
        else {
            auxItem = this.state.codiguera[codiguera].find(x => x.Id === null || x.Id.toString() === id);
        }
        auxItem = { ...auxItem, [property]: value };
        codigueraAux = codiguera;
        itemAux = auxItem;
    }

    handleCancelEdit = () => {
        this.setState({
            codiguera: this.state.codigueraOr
        });
    }

    render() {
        const { codiguera, load } = this.state;
        return (
            <AppContainer className='background-f4f4f4 p-b-n' title="Parametros">
                <Row>
                    {codiguera && this._handleCodigueraNameProsses(codiguera).map((cod, idx) => (
                        <Col key={idx} md={6} sm={12}>
                            {cod.map((name, idx) => <Col key={idx} sm={12} >
                                <ParametersComponent load={load}
                                    name={name}
                                    description={this.getDescriptionParameterByName(name)}
                                    handleCancelEdit={this.handleCancelEdit}
                                    handleInputChange={this.handleInputChange}
                                    items={codiguera[name]}
                                    handleAction={this.SendAction} />
                            </Col>)}
                        </Col>)
                    )}
                </Row>
            </AppContainer>
        );
    }
}

const mapDispatchToProps = {
    setLoad,
    setSweetAlert,
    showError
}


export default connect(null, mapDispatchToProps)(((setPropsAsInitial)(ParametersContainer)));

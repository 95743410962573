import React from 'react';

const HomeCompromiso = React.forwardRef((props, ref) => (
     <div ref={ref} className="content-wrap" id="compromiso">
        <h1>Compromiso</h1>
        <p>
            Mediante el CIM cumplimos con las Buenas Prácticas de Farmacovigilancia (GVP EMA/213497/2017 e ICH E2A-E2F) 
            y con las Normativas de Farmacovigilancia Locales, definidas por la Autoridad Sanitaria de cada país latinoamericano.
        </p>
        <p>
            La información recibida por el CIM es procesada en busca de señales y transmitida en forma anónima a la Autoridad 
            Sanitaria de cada país.
        </p>
    </div>
))
export default HomeCompromiso;
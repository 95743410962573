import React, { Component } from 'react';
import {connect} from 'react-redux';
import UserFilters from './UserFilters';
import { getCodigueraContext } from '../../../redux/selectors/Context';
import { setLoad } from '../../../redux/actions/context';
import { showError } from '../../../redux/actions/error';
import ListUser from '../../ListComponents/List/ListUser';
import AppPaginator from '../../AppComponents/AppPaginator';
import { getForm } from '../../../redux/selectors/Forms';
import { getUsersFilters } from '../../../redux/actions/user';
import { getUsersFilters as listUserFilters } from '../../../redux/selectors/user';
import { url_getFilterUsers } from '../../../api/urls';
import { PAGE_SIZE } from '../../../redux/constants/get';
import api from '../../../api';
import UserComponent from '../../UsersComponents/User';
import {withRouter} from 'react-router-dom';

class UserSerch extends Component {
    state = {
        hiddenFilters:false,
        hiddenUser:true,
        selectedUserId:null,
        userView:'create',
        create: 0,
        filters:{
            country:null,
            name:'',
            email:'',
            userType:'',
            isActive:null,
            pageNumber:1,
            orderBy:'Nombre ASC',
            pageSize: PAGE_SIZE,
        },
    }

    componentDidMount() {
        this.handleSerchUser(1);
        this.props.history.push(`/adminusers/${this.props.userId}`);
    }

    toogleHiddenFilters = () =>  this.setState({hiddenFilters: !this.state.hiddenFilters});



    handleSerchUser = async (pageNumber) =>{
        const axuFilters = this.props.filterForm.values;
        const stateFilters = this.state.filters;
        let filter =  stateFilters;
         if(axuFilters){
            filter.name = axuFilters.name ? axuFilters.name : null ;
            filter.email = axuFilters.email ? axuFilters.email : null ;
             filter.country = axuFilters.country === "none" ? null : axuFilters.country;
             filter.isActive = axuFilters.isActive === "none" ? null : axuFilters.isActive;
             filter.userType = axuFilters.userType === "none" ? null : axuFilters.userType;
        }
        filter.pageNumber = pageNumber;
        try {
          this.props.setLoad(true);
          const response = await api.post(url_getFilterUsers, filter);
          if(response.status === 200){
            this.props.getUsersFilters(response.data);
          }
        } catch (e) {
          this.props.showError(e);
        } finally {
          this.props.setLoad(false);
        }
    }

    handleOpenEditUser=(user)=>{
        this.props.setLoad(false);
        this.setState({
            userView:'edit',
            selectedUserId:user.UserID,
            hiddenUser: false,
            create: 1
        });
        
    }

    handleOpenAddUser=()=>{
        this.setState({
            userView:'create',
            selectedUserId:null,
            hiddenUser: false,
            create: 2
        });
    }

    createSuccess= (user)=>{
        this.setState({
            userView:'',
            selectedUserId:null,
            hiddenUser:true
        });
        this.props.history.push(`/adminusers/${user.UserID}`)
    }

    updateSuccess= ()=>{
        this.handleSerchUser(1);
        this.setState({
            userView:'',
            selectedUserId:null,
            hiddenUser:true
        });
 
    }
    cancelUser = () =>{

        this.setState({
            userView:'',
            selectedUserId:null,
            hiddenUser:true
        });
    
    }
    render() {
        const { codiguera, list,userId }=this.props;
        const { hiddenFilters } = this.state;
        let {hiddenUser, userView, create }= this.state;
        let selectedUserId = null 
        if(create === 0){
            userView= 'edit';
            hiddenUser=false;
          
            if (!selectedUserId)
            {
                hiddenUser=true;
            }
        }
        else if(create === 1){
            const param = this.props.match.params.userId
            if(param !== undefined){
                selectedUserId = param
            }
           
        }
        else if(create === 2){
            selectedUserId = null
        }
        return (
            <div>
                <UserComponent goBack={this.cancelUser} createSuccess={this.createSuccess} updateSuccess={this.updateSuccess} hidden={hiddenUser} view={userView}
                userId={selectedUserId}/>
                <div >
                    <UserFilters
                        countries={codiguera && codiguera.Paises}
                        roles={codiguera && codiguera.Roles}
                        handleSerch={this.handleSerchUser}
                        handleOpenAdd={this.handleOpenAddUser}
                        hidden={hiddenFilters}
                        handleHidden={this.toogleHiddenFilters}/>
                    <div className='m-l-md m-r-md'>
                        <ListUser list={list && list.Result} handleAction={this.handleOpenEditUser}/>
                        <AppPaginator result={list} message='usuarios' handlePagination={this.handleSerchUser} />
                    </div>
                </div>
               
            </div>
        );
    }
}

const mapDispatchToProps = {
    setLoad,
    getUsersFilters,
    showError
}
const mapStateToProps = state => ({
    codiguera: getCodigueraContext(state),
    filterForm: getForm('FilterUsers',state),
    list: listUserFilters(state),
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSerch));

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form'; 
import { Row, Col } from 'react-flexbox-grid';
import { FieldText, FieldSelect } from '../../Fields';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faAngleDoubleUp, faAngleDoubleDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { getForm } from '../../../redux/selectors/Forms';


const UserFilters = (props) => {
    const {hidden,handleHidden,countries,roles,handleSerch,handleOpenAdd} = props;
    return (
        <div className='p-r-md p-l-md'>
            <Row hidden={hidden}>
                <Col md={4} >
                    <FieldText label='Nombre' name='name' />
                </Col>
                <Col md={4} >
                    <FieldText label='Correo eléctronico' name='email' />
                </Col>
                <Col md={4} >
                    <FieldSelect label='País de origen' name='country' typeValue='number' valueField='Id' textField='Nombre' items={countries} />
                </Col>
                <Col md={4} >
                    <FieldSelect label='Habilitado' typeValue='boolean' name='isActive' valueField='Id' textField='Name' items={[{Id:'true',Name:'SI'},{Id:'false',Name:'NO'}]} />
                </Col>
                <Col md={4} >
                    <FieldSelect label='Rol' name='userType' valueField='Name' textField='Name' items={roles} /> 
                </Col>
            </Row>
            <Row className="p-l p-r p-b-md">
                <div className="div-button-right">
                    <Button className="pull-left m-r button-back text-primary" onClick={handleOpenAdd}><FontAwesomeIcon className="m-r" icon={faUserPlus} /></Button>
                    <Button className={`pull-rigth m-r text}`} onClick={()=>handleHidden()}><FontAwesomeIcon size='lg' className="m-l-sm"  icon={!hidden ? faAngleDoubleUp : faAngleDoubleDown }/></Button>
                    <Button className="pull-rigth button-next" onClick={()=> handleSerch(1)} ><FontAwesomeIcon icon={faSearch}/></Button>
                </div>
            </Row>
        </div>
    );
};
const validate = values =>{
    const error ={};
    return error;
}

const mapStateToProps = state => ({
    filters: getForm('FilterUsers',state),
})

export default connect(mapStateToProps, null)(reduxForm({form: 'FilterUsers', validate})(UserFilters));
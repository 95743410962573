import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {LabelDate} from "../../Fields/index";
import moment from "moment-timezone";

export const stringToDate = (value, desc) => {

    let isFecha= false;
    if(desc != null){
        isFecha = desc.includes("Fecha");
        //isFechaUTC= desc.includes("Fecha de Solicitud"); #9961
        if(!isFecha)
            return value
    }
    if(desc === "Fecha de Solicitud" && value != '---') {
        return moment(value, "MMM Do YYYY, h:mm:ss a").format("DD/MM/YYYY HH:mm")
    }
    let stringValue = value;
    let valueWithSpace = stringValue.substring(0, 17) + " " + stringValue.substring(17, stringValue.length);
    let fechaDate = new Date(valueWithSpace);
    const formatoFecha= "DD/MM/YYYY";
    const formatoHora= "HH:mm";
    const formatoFechaHora= formatoFecha + " " + formatoHora;
    const soloFecha = stringValue.substring(0, 17).toString();
    const notHasHora= (soloFecha.length === valueWithSpace.trim().length);
    let formato="";
    if ( valueWithSpace.includes("12:00 AM") || (notHasHora)){
        
         formato = formatoFecha;
    }
    else
    {
         formato = formatoFechaHora;
    }
    let fecha = "";

    if(!notHasHora)
    {
        fecha= LabelDate(fechaDate);
    }
    else
    {
        fecha = moment(fechaDate).format(formato);
    }
    if (fecha != "Invalid date"){
        return fecha;
    } else {
        return value;
    }
};

const ListItemAuditDetail = ({Item,classProp,parent, handleAction }) => {



    switch(parent.OperationType){
        case 'I':{
            return <tr className={`list-item ${classProp}`}>
                <td>{Item.Description}</td>
                <td>{stringToDate(Item.NewValue, Item.Description)} {Item.IsFile && <Link className="action-download m-l" to={'#'} onClick={()=>handleAction("new",parent,Item)} ><FontAwesomeIcon size="lg" icon={faCloudDownloadAlt} /></Link>}</td>

            </tr>;
        }
        default:{
            return <tr className={`list-item ${classProp}`}>
                <td>{Item.Description}</td>
                <td>{stringToDate(Item.OldValue, Item.Description)} {Item.IsFile && <Link className="action-download m-l" to={'#'} onClick={()=>handleAction("old",parent,Item)} ><FontAwesomeIcon size="lg" icon={faCloudDownloadAlt} /></Link>}</td>
                <td>{stringToDate(Item.NewValue, Item.Description)} {Item.IsFile && <Link className="action-download m-l" to={'#'} onClick={()=>handleAction("new",parent,Item)} ><FontAwesomeIcon size="lg" icon={faCloudDownloadAlt} /></Link>}</td>

            </tr>;
        }
    }

};

export default ListItemAuditDetail;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListImputability from '../../../../ListComponents/List/ListImputability';
import { getERImputEvaluation } from '../../../../../redux/selectors/ER';
import { connect } from 'react-redux';
import ImputabilityEvaluation from './ImputabilityEvaluation';

class ImputabilityComponent extends Component {
  
    state = {
        open:false,
        evaluation:null,
        
    }

  
    openEvaluation = (evaluation) => {
        this.closeEvaluation();
        this.setState({
            evaluation,
            open:true
        });
    }

    closeEvaluation = () =>{
        this.setState({open: false,evaluation:null});
    }


    render() {
        const { list, UserContext,meta, er } = this.props;
        const { evaluation, open } = this.state;
        return (
            <div>
                {(open && evaluation) &&  <ImputabilityEvaluation meta={meta} UserContext={UserContext}  er={er} initialValues={evaluation} closeEvaluation={this.closeEvaluation} />}
                <ListImputability list={list} handleAction={this.openEvaluation} View={'edit'}/>
            </div>
        );
    }
}

ImputabilityComponent.propTypes = {
    list: PropTypes.array,
};


const mapStateToProps = state =>({
    list: getERImputEvaluation(state),
})

export default connect(mapStateToProps, null)(ImputabilityComponent);

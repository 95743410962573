import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const FormTitle = ({title,handleClose}) => {
    return (
        <div className='form-title'>
            <h2>{title}   <Link to="#" onClick={()=>handleClose()} className="close-modal-home close-form"><FontAwesomeIcon icon={faTimes} size="lg"/></Link></h2>
        </div>
    );
};

export default FormTitle;
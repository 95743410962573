import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  questionYesNO,
  eventEvolutionStep3
} from "../../../../../constants/steps";
import {
  FieldSelect,
  FieldText,
  FieldAsyncAutoComplete,
  iconTrash,
  FieldDatePicker
} from "../../../../Fields";
import { getHomeCodiguera } from "../../../../../redux/selectors/home";

const RAM = props => {
  const {
    index,
    open,
    toggle,
    allowDelete,
    handleDelete,
    loading,
    llts,
    handleSearchLlt,
    RAM,
    isMedic,
    codiguera
  } = props;
  return (
    <div>
      <h3>
        Información sobre el acontecimiento adverso / reacción adversa
        {allowDelete && (
          <Link to="#" onClick={() => handleDelete(index)} className="m-l">
            {iconTrash("#B64C92", "#d6abc8")}
          </Link>
        )}
        <Link to="#" onClick={() => toggle(index)} className="pull-right">
          <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
        </Link>
      </h3>
      <Row hidden={!open}>
        {isMedic && (
          <Col md={6} sm={12}>
            <FieldAsyncAutoComplete
              searchText="Buscando..."
              isLoading={loading}
              onSearch={query => handleSearchLlt(index, query)}
              name={`RAM[${index}].MedDRA`}
              labelKey="llt_name"
              items={llts}
              selected={
                RAM && RAM.length > 0 && RAM[index] && RAM[index].MedDRA
                  ? RAM[index].MedDRA
                  : null
              }
              highlightOnlyResult={true}
              label="Termino MedDRA"
              multiple={false}
              emptyLabel="No hay resultados"
            />
          </Col>
        )}
        {isMedic && (
          <Col md={6} sm={12}>
            <FieldSelect
              name={`RAM[${index}].Grave`}
              isRequired={true}
              label="¿El acontecimiento / la reacción descrita es grave?"
              items={questionYesNO}
              textField="label"
              valueField="value"
            />
          </Col>
        )}

        <Col sm={12}>
          <FieldText
            name={`RAM[${index}].Reaccion`}
            isRequired={true}
            type="textarea"
            label="Descripción del acontecimiento adverso/reacción adversa"
          />
        </Col>

        <Col md={6} sm={12}>
          <FieldDatePicker
            name={`RAM[${index}].FechaIniReaccion`}
            allowFuture={false}
            allowPast={true}
            label="Fecha de inicio"
          />
        </Col>
        <Col md={6} sm={12}>
          <FieldDatePicker
            name={`RAM[${index}].FechaFinReaccion`}
            allowFuture={false}
            allowPast={true}
            label="Fecha de fin (sí aplica)"
          />
        </Col>
        <Col md={6} sm={12} hidden={!isMedic}>
          <FieldSelect
            name={`RAM[${index}].Evolucion`}
            label="Estado actual del paciente"
            items={eventEvolutionStep3}
            valueField="Text"
            textField="Text"
          />
        </Col>
        <Col md={6} sm={12} hidden={!isMedic}>
          <FieldSelect
            name={`RAM[${index}].AccionMeddicamento`}
            label="Acción tomada con el medicamento"
            items={codiguera && codiguera.AccionMedicamento}
            valueField="Id"
            textField="Nombre"
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  RAM: formValueSelector("HomeStep3")(state, "RAM"),
  codiguera: getHomeCodiguera(state)
});

export default connect(mapStateToProps, null)(RAM);

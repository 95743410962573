import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListDataImports from '../ListComponents/List/ListDataImports';
import { url_getdataimportcode } from '../../api/urls';
import api from '../../api';
import { setDataImportsCode, setDataImports, setCleanDataImportsCode } from '../../redux/actions/dataimport';
import { getImportsData } from '../../redux/selectors/dataimports';
import { showError } from '../../redux/actions/error';


class DataImportComponent extends Component {
    state = {
        imports:[],
        load:false,
    }

    _handleGetImportCode = async (item, show) =>{
        if(!show){
            this.setState({load:true});
            try {
              const response = await api.get(url_getdataimportcode(item.code_import));
              this.props.setDataImportsCode(response.data);
            } catch (e) {
              showError(e);
            } finally{
              this.setState({ load:false });
            }
        }else{
            this.props.setCleanDataImportsCode(item.code_import);
            this.setState({load:false});
        }
    }

    render() {
        const { load } = this.state;
        const { imports } = this.props;
        return (
            <div className='m-r m-l m-t'>
                <ListDataImports list={imports} loadList={load} handleAction={this._handleGetImportCode}/>
            </div>
        );
    }
}
const mapDispatchToProps = {
    setDataImports,
    setDataImportsCode,
    setCleanDataImportsCode
}

const mapStateToProps = (state)=>({
    imports: getImportsData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataImportComponent);

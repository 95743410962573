import { urlBase } from "../environment";
import { PAGE_SIZE } from "../redux/constants/get";
export const urlBaseApi = `${urlBase}api/`;
export const url_login = `${urlBaseApi}login`;
export const url_userresetpassword = `${urlBaseApi}userresetpassword`;
export const url_signature = `${urlBaseApi}logedUserSignature`;
export const url_getContext = `${urlBaseApi}getContext`;
export const url_userchangepassword = `${urlBaseApi}userchangepassword`;
/*HUBS*/
export const url_hubs = `${urlBase}signalr/hubs`;
/*Navigation*/
export const url_nav_ReportableEvent = meta => `/reportableEvent/${meta}/view/details`;
/*GET*/
export const url_getEventsNoKpi = `${urlBaseApi}getEventsNoKpi`
export const url_getMetricsTime = `${urlBaseApi}getAllMetricsTime`
export const url_getMyUser = `${urlBaseApi}getMyUser`
export const url_getCodiguerasBasic = `${urlBaseApi}getCodiguerasBasic`;
export const url_getCodiguerasAll = `${urlBaseApi}getCodiguerasAll`;
export const url_getSubGroups = `${urlBaseApi}getSubGroups`;
export const url_getCountries = `${urlBaseApi}getCountries`;
export const url_userDetails = id => `${urlBaseApi}getUserDetails/${id}`;
export const url_createUserDetails = `${urlBaseApi}createUserDetails`;
export const url_updateUserDetails = `${urlBaseApi}updateUserDetails`;
export const url_getPrincipioActivoByFamilia = familia =>
  `${urlBaseApi}getPrincipioActivoByFamilia/${familia}`;
export const url_getPrincipioActivoByMR = mR =>
  `${urlBaseApi}getPrincipioActivoByMR/${mR}`;
export const url_getFilterSearch = `${urlBaseApi}getFilters`;
export const url_getProductFilters = id =>
  `${urlBaseApi}getProductFiltersByLabId/${id}`;
export const url_getFilterUsers = `${urlBaseApi}getUserFilters`;
export const url_getWorkFlow = (
  pageNumber,
  orderBy,
  estadoId,
  idEr,
  desde,
  hasta,
  category,
  subGroupId
) =>
  `${urlBaseApi}getWorkFlow/${pageNumber}/${PAGE_SIZE}/${orderBy}/${estadoId}/${idEr}/${desde}/${hasta}/${category}/${subGroupId}`;
export const url_getWorkFlowInvert = (pageNumber, orderBy) =>
  `${urlBaseApi}getWorkFlowInvert/${pageNumber}/${PAGE_SIZE}/${orderBy}`;
export const url_getMedicalCountryByMeta = meta =>
  `${urlBaseApi}getProductsCountryByMeta/${meta}`;
export const url_getlaboraotriesByProductsCountry = (productId, country) =>
  `${urlBaseApi}getlaboraotriesByProductsCountry/${productId}/${country}`;
export const url_getERByFilters = `${urlBaseApi}getERByFilters`;
export const url_getERMeta = meta => `${urlBaseApi}getERByMeta/${meta}`;
export const url_getPacienteERMeta = meta =>
  `${urlBaseApi}getPacienteByERMeta/${meta}`;
export const url_getOriginReportERMeta = meta =>
  `${urlBaseApi}getOriginReportByMeta/${meta}`;
export const url_getMedicalSuspMeta = meta =>
  `${urlBaseApi}getProductsRelationsByMeta/${meta}`;
export const url_getMedicalSuspShortMeta = meta =>
  `${urlBaseApi}getProductsRelationsShortByMeta/${meta}`;
export const url_getRAMERMeta = meta => `${urlBaseApi}getRAMByMeta/${meta}`;
export const url_getDocsAndNotesMeta = meta =>
  `${urlBaseApi}getDocsAndNotesByMeta/${meta}`;
export const url_getWhoReportERMeta = meta =>
  `${urlBaseApi}getERWhoReportByMeta/${meta}`;
export const url_getFollowUpERMeta = meta =>
  `${urlBaseApi}getERFollowUpByMeta/${meta}`;
export const url_getOperatinHistoryERMeta = `${urlBaseApi}getERHistoryByMeta`;
export const url_getImputEvaluationByMeta = meta =>
  `${urlBaseApi}getImputEvaluationByMeta/${meta}`;
export const url_getActionsERByMeta = meta =>
  `${urlBaseApi}getActionsERByMeta/${meta}`;
export const url_getOperatinHistoryByEvent = `${urlBaseApi}getERHistoryByEvent`;
export const url_getContactoMedicoByMeta = meta =>
  `${urlBaseApi}getContactoMedicoByMeta/${meta}`;
export const url_getRAMCategoriaByRAMId = id =>
  `${urlBaseApi}getRAMRAMCategoryByRAMId/${id}`;
export const url_getMedicationHistoryByMeta = meta =>
  `${urlBaseApi}MedicamentoAnteriorByMeta/${meta}`;
export const url_getERNew = `${urlBaseApi}getERNew`;
export const url_getRAMNew = `${urlBaseApi}getRAMNew`;
export const url_getlaboraotriesByCountry = c =>
  `${urlBaseApi}getlaboraotriesByCountry/${c}`;
export const url_getLinesByLaboratory = lab =>
  `${urlBaseApi}getLinesByLaboratory/${lab}`;
export const url_getMedDRALLT = llt => `${urlBaseApi}getMedDRALLT/${llt}`;
export const url_getApprovedFabricByMeta = meta =>
  `${urlBaseApi}getApprovedFabricByMeta/${meta}`;
export const url_getPdfsFiles = id =>
  `${urlBaseApi}listERPDF/${id}`;

export const url_getEventosSegui = id =>
  `${urlBaseApi}Seguimiento/${id}`;

export const url_getApprovedFabricByLab = labId =>
  `${urlBaseApi}getApprovedFabricByLab/${labId}`;
export const url_getReportHome = (
  pN,
  oB,
  id,
  isMedic,
  estado,
  country,
  desde,
  hasta
) =>
  `${urlBaseApi}getReportHome/${pN}/${PAGE_SIZE}/${oB}/${id}/${isMedic}/${estado}/${country}/${desde}/${hasta}`;
export const url_getWFReportHome = (pN, oB) =>
  `${urlBaseApi}getWFReportHome/${pN}/${PAGE_SIZE}/${oB}`;

export const url_getEventoDeHomeByMeta = meta =>
  `${urlBaseApi}getEventoDeHomeByMeta/${meta}`;

/*GET INDICATOR */
export const url_indicator_getValores = (paisId, labId) =>
  `${urlBaseApi}getIndicatorsValuesPanel/${paisId}/${labId}`;
export const url_indicator_getEstados = (paisId, labId) =>
  `${urlBaseApi}getIndicatorsEventsStatus/${paisId}/${labId}`;
export const url_indicator_getGrupos = (paisId, labId) =>
  `${urlBaseApi}getIndicatorsEventsTypeGroup/${paisId}/${labId}`;
export const url_indicator_getAges = (paisId, labId) =>
  `${urlBaseApi}getIndicatorsEventsAges/${paisId}/${labId}`;

export const url_indicators_filtercountry = `${urlBaseApi}filtercountryindicators`;
export const url_indicators_filterlab = paisId =>
  `${urlBaseApi}filterlabindicators/${paisId}`;

/*Downloads*/
export const downloadOriginReport = idEr =>
  `${urlBaseApi}downloadOriginReportById/${idEr}`;
export const downloadFollowUp = id => `${urlBaseApi}downloadFollowUpById/${id}`;
export const downloadDocAndNotes = id =>
  `${urlBaseApi}downloadDocAndNotesById/${id}`;
export const downloadContactoMedico = id =>
  `${urlBaseApi}downloadFileContactoMedicoById/${id}`;
export const downloadFileLocalSanitaryNotificactionById = id =>
  `${urlBaseApi}downloadFileLocalSanitaryNotificactionById/${id}`;
export const downloadFileProviderNotificationById = id =>
  `${urlBaseApi}downloadFileProviderNotificationById/${id}`;
export const downloadFileRecProductById = id =>
  `${urlBaseApi}downloadFileRecProductById/${id}`;
export const url_downloadAuditFile = `${urlBaseApi}downloadAuditFile`;

export const url_downloadERPdf = id =>  `${urlBaseApi}getERPDF/${id}`;


/*Uploads*/
export const uploadDocAndNotes = `${urlBaseApi}uploadDocAndNotes`;
export const uploadContactoMedico = `${urlBaseApi}uploadFileContactoMedico`;
export const uploadFileOriginReport = `${urlBaseApi}uploadFileOriginReport`;
export const uploadFileLocalSanitaryNotificaction = `${urlBaseApi}uploadFileLocalSanitaryNotificaction`;
export const uploadFileProviderNotification = `${urlBaseApi}uploadFileProviderNotification`;
export const uploadFileRecProduct = `${urlBaseApi}uploadFileRecProduct`;
export const uploadMeDRA = `${urlBaseApi}uploadmeddra`;

/*POST*/
export const url_postInsertReporteSeguimiento = `${urlBaseApi}insertES`;
export const url_postImputEvaluation = `${urlBaseApi}setImputEvaluation`;
export const url_postRAMInsert = `${urlBaseApi}insertRAM`;
export const url_postRAMSInsert = `${urlBaseApi}insertRAMS`;
export const url_postRAMUpdate = `${urlBaseApi}updateRAM`;
export const url_postERinsert = `${urlBaseApi}insertER`;
export const url_postERupdate = `${urlBaseApi}updateER`;
export const url_postERcreate = `${urlBaseApi}createER`;
export const url_postERAdministeredMedication = `${urlBaseApi}updateAdminMedication`;
export const url_postPacienteUpdate = `${urlBaseApi}setPaciente`;
export const url_postOriginReportInsert = `${urlBaseApi}insertOriginReport`;
export const url_postOriginReportUpdate = `${urlBaseApi}updateOriginReport`;
export const url_postMedicamentoRelacionadoInsert = `${urlBaseApi}insertMedicamentoRelacionado`;
export const url_postMedicamentoRelacionadoUpdate = `${urlBaseApi}updateMedicamentoRelacionado`;
export const url_postMedicamentoRecuperadoUpdate = `${urlBaseApi}updateMedicamentoRecuperado`;
export const url_postActiveRol = `${urlBaseApi}setActiveRol`;
export const url_sendAccion = `${urlBaseApi}sendAccion`;
export const url_postContactoMedicoInsert = `${urlBaseApi}insertContactoMedico`;
export const url_postContactoMedicoUpdate = `${urlBaseApi}updateContactoMedico`;
export const url_postRAMRAMCategoriaInsert = `${urlBaseApi}insertRAMRAMCategory`;
export const url_postRAMRAMCategoriaUpdate = `${urlBaseApi}updateRAMRAMCategory`;
export const url_postERMedicationHistorySet = `${urlBaseApi}setMedicamentoAnterior`;
export const url_postAprrovedFacbricInsert = `${urlBaseApi}setApprovedFabric`;
export const url_postAprrovedFacbricUpdate = `${urlBaseApi}updateApprovedFabric`;
export const url_postLaboratoryUser = `${urlBaseApi}setLaboratoryUser`;
export const url_acceptTerms = `${urlBaseApi}acceptTerms`;
export const url_createerfromerhome = `${urlBaseApi}createerfromerhome`;
export const url_updatestatiserfromerhome = `${urlBaseApi}updatestatiserfromerhome`;
export const url_createElementCodiguera = `${urlBaseApi}createElementCodiguera`;
export const url_updateElementCodiguera = `${urlBaseApi}updateElementCodiguera`;
export const url_postreporthomeusuario = `${urlBaseApi}postreporthomeusuario`;

/*DELETE*/
export const url_deleteMedicamentoRelacionado = `${urlBaseApi}deleteMedicamentoRelacionado`;
export const url_deleteMedicamentoAnterior = `${urlBaseApi}deleteMedicamentoAnterior`;
export const url_delER = `${urlBaseApi}delER`;
export const url_deleteElementCodiguera = `${urlBaseApi}deleteElementCodiguera`;
export const url_deleteDocAndNotes = `${urlBaseApi}deleteDocAndNotes`;
/*AUIDT TRAIL */
export const url_getAuditUsers = `${urlBaseApi}getAuditUsers`;
export const url_getAuditUsersDetail = `${urlBaseApi}getAuditUsersDetails`;
export const url_getAuditConfig = `${urlBaseApi}getAuditConfig`;
export const url_getAuditConfigDetails = `${urlBaseApi}getAuditConfigDetails`;
export const url_getAuditParameters = `${urlBaseApi}getAuditParameters`;
export const url_getAuditParametersDetails = `${urlBaseApi}getAuditParametersDetails`;

// SETTINGS
export const url_getSettings = `${urlBaseApi}settingGetAll`;
export const url_getSettingByName = n => `${urlBaseApi}settingGetByName/${n}`;
export const url_getSettingsByGroup = n =>
  `${urlBaseApi}settingGetByGroup/${n}`;
export const url_updateSetting = `${urlBaseApi}settingUpdate`;

//Imports
export const url_getmeddraimport = `${urlBaseApi}getmeddraimport`;
export const url_getmeddraimportcode = code =>
  `${urlBaseApi}getmeddraimportcode/${code}`;
export const url_getdataimport = `${urlBaseApi}getdataimport`;
export const url_getdataimportcode = code =>
  `${urlBaseApi}getdataimportcode/${code}`;

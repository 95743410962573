import React, { Component } from 'react';
import setPropsAsInitial  from '../helpers/setPropsAsInitial';
import AppContainer from '../components/AppComponents/AppContainer';
import MetricsComponets from '../components/MetricsComponets';
import { withRouter }  from 'react-router-dom';


class MetricsContainer extends Component {
    componentWillMount = () =>{
        this.props.showAppFrame(this.props.location.pathname);
    }
    render() {
        return (
            <AppContainer title="Metricas" className="background-f4f4f4 p-t-n">
                <MetricsComponets/>
            </AppContainer>
        );
    }
}

export default withRouter(((setPropsAsInitial)(MetricsContainer)));

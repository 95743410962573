import React from "react";
import { Row, Col } from "react-flexbox-grid";
import SubGroupSelect from "./subGroup";

const SubGroupComponent = ({ selected, onSelect, subGroups }) => {
  return (
    <Row>
      <Col sm={12} md={6} lg={4} className="m-b-sm">
        <SubGroupSelect
          selected={selected}
          onSelectSubGroup={onSelect}
          subgroups={subGroups.filter(x => x.GrupoId === 1)}
        />
      </Col>
      <Col sm={12} md={6} lg={4} className="m-b-sm">
        <SubGroupSelect
          selected={selected}
          onSelectSubGroup={onSelect}
          subgroups={subGroups.filter(x => x.GrupoId === 2)}
        />
      </Col>
      <Col sm={12} md={6} lg={4} className="m-b-sm">
        <SubGroupSelect
          selected={selected}
          onSelectSubGroup={onSelect}
          subgroups={subGroups.filter(x => x.GrupoId === 3)}
        />
      </Col>
      <Col sm={12} md={6} lg={4}>
        <SubGroupSelect
          selected={selected}
          onSelectSubGroup={onSelect}
          subgroups={subGroups.filter(x => x.GrupoId === 4)}
        />
      </Col>
      <Col sm={12} md={6} lg={4}>
        <SubGroupSelect
          selected={selected}
          onSelectSubGroup={onSelect}
          subgroups={subGroups.filter(x => x.GrupoId === 5)}
        />
      </Col>
    </Row>
  );
};
export default SubGroupComponent;

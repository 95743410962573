import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ListActions from './ListActions';

const actions = (handleAction, Item) => [
    <Link to={'#'} onClick={() => handleAction("pdf",Item)}>{<FontAwesomeIcon icon={faFilePdf} size={'lg'}/>}</Link>
];

const ListItemHisroiPdf = ({Item, handleAction, classProp}) => {
    return (
        <tr className={`list-item ${classProp}`}>
            <td>{Item}</td>
            <td className="p-t-sm"><ListActions  actions={actions(handleAction,Item)}/></td>
        </tr>
    );
};

ListItemHisroiPdf.propTypes = {
    Item: PropTypes.object,
};

export default ListItemHisroiPdf;
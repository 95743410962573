const Residencias = [
  { Id: "Capital", Nombre: "Capital" },
  { Id: "Interior", Nombre: "Interior" },
  { Id: "Rural", Nombre: "Rural" },
  { Id: "Urbana", Nombre: "Urbana" }
];

export const getCodiguera = (c, codigueras) => {
  switch (c) {
    case "Residencias":
      return Residencias;
    default:
      if (codigueras) return codigueras && codigueras[c];
      else return [];
  }
};
export const getElementCodiguera = (name, id, codigueras) => {
  if (codigueras && (id !== null && id !== undefined) && name) {
    let elem;
    switch (name) {
      case "residencias": {
        elem = codigueras.Residencias.find(s => s.Id === id);
        return elem ? elem.Nombre : "---";
      }
      case "subGroup": {
        elem = codigueras.SubGrupo.find(x => x.Id === Number(id));
        return elem ? elem.Nombre : "---";
      }

      case "estado": {
        elem = codigueras.Status.find(s => s.Id === Number(id));
        return elem ? elem.Estado : "---";
      }

      case "parentesco": {
        elem = codigueras.Parentesco.find(s => s.Id === Number(id));
        return elem ? elem.Nombre : "---";
      }

      case "pais": {
        elem = codigueras.Paises.find(c => c.Codigo === id);
        return elem ? elem.Nombre : "---";
      }
      case "paisOrigenes": {
        elem = codigueras.PaisOrigen.find(c => c.Codigo === id);
        return elem ? elem.Nombre : "---";
      }
      case "frecuencia": {
        elem = codigueras.MedicamentoFrecuencia.find(s => s.Id === Number(id));
        return elem ? elem.Unidad : "---";
      }
      case "peso": {
        elem = codigueras.PesoUnidad.find(s => s.Id === Number(id));
        return elem ? elem.Abreviacion : "---";
      }
      case "altura": {
        elem = codigueras.AlturaUnidad.find(s => s.Id === Number(id));
        return elem ? elem.Abreviacion : "---";
      }
      case "via": {
        elem = codigueras.Via.find(s => s.Id === Number(id));
        return elem ? elem.Descripcion : "---";
      }

      case "unidadUso": {
        elem = codigueras.MedicamentoUnidadUso.find(s => s.Id === Number(id));
        return elem ? elem.Unidad : "---";
      }
      case "unidadMedicamento": {
        elem = codigueras.MedicamentoUnidadUso.find(s => s.Id === Number(id));
        return elem ? elem.Unidad : "---";
      }

      case "origenesReportes": {
        elem = codigueras.ReporteOriginador_Origen.find(
          s => s.Id === Number(id)
        );
        return elem ? elem.Nombre : "---";
      }

      case "receptoresReportes": {
        elem = codigueras.ReporteOriginador_Receptor.find(
          s => s.Id === Number(id)
        );
        return elem ? elem.Nombre : "---";
      }

      case "tiposOrigenesReportes": {
        elem = codigueras.ReporteOriginador_Tipo.find(s => s.Id === Number(id));
        return elem ? elem.Nombre : "---";
      }
      case "viaDeComunicacion": {
        elem = codigueras.ViaDeComunicacion.find(s => s.Id === Number(id));
        return elem ? elem.Nombre : "---";
      }

      case "accionesMedicamentos": {
        elem = codigueras.AccionMedicamento.find(s => s.Id === Number(id));
        return elem ? elem.Nombre : "---";
      }

      case "ramTypes": {
        elem = codigueras.RAMTipos.find(s => s.Id === Number(id));
        return elem ? elem.Type : "---";
      }

      case "ramCategories": {
        elem = codigueras.RAMCategorias.find(s => s.Id === Number(id));
        return elem ? elem.Type : "---";
      }

      case "tiposMedAnteriores": {
        elem = codigueras.MedicamentosAnteriores_Tipo.find(
          s => s.Id === Number(id)
        );
        return elem ? elem.Nombre : "---";
      }

      default:
        return "---";
    }
  } else return "";
};

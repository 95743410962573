import React from 'react';
import PropTypes from 'prop-types';
import LIstItemDN from '../Item/LIstItemDN';
import ListComponent from '..';

const ListDocsAndNotes = ({list,handleAction}) => {
    return (
        <ListComponent handleAction={handleAction} empty={'documentos y notas'} titles={['Archivo','Tipo de documento','Comentario','Usuario','Fecha','']} list={list} ListComponent={LIstItemDN}  />
    );
};

ListDocsAndNotes.propTypes = {
    list: PropTypes.array,
};

export default ListDocsAndNotes;
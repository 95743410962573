import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';

const SectionTitle = ({title, edit, step,add}) => {
    return (
        <div className="sectionTitle">
            <span className="title">{title}</span>
           {add && <Link to={'#'} title='Agregar' className="pull-right m-t p-r-md" style={{color:'#fff'}} onClick={() => add()}><FontAwesomeIcon icon={faPlus} size={'lg'}/> </Link>}
           {edit && <Link to={'#'} title='Editar' className="pull-right m-t p-r-sm" style={{color:'#fff'}} onClick={() => edit(step)}><FontAwesomeIcon icon={faPencilAlt} size={'lg'}/> </Link>}
        </div>
    );
};

export default SectionTitle;
import React from 'react';
import PropTypes from 'prop-types';

const ListItemComments = ({Item}) => {
    return (
        <tr className={`list-item`}>
            <td>{Item}</td>
        </tr>
    );
};

ListItemComments.propTypes = {
    Item: PropTypes.string,
};

export default ListItemComments;
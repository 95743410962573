import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { FieldLabel, LabelDate } from "../../../Fields";

const SummaryStep6 = ({ values, isMedic }) => {
  return (
    <div className="form-step">
      <h3>{isMedic ? "Historia clínica" : "Contacto médico"}</h3>
      <Row className="form-step-row">
        {isMedic && (
          <Col sm={12}>
            <FieldLabel
              type="text"
              label="Datos importantes de la historia clínica 
                                (ej. Diagnósticos previos, fumador, consume alcohol, historial de alergias, embarazos previos, 
                                toma anticonceptivos, operaciones previas relevantes, enfermedades
                                crónicas, drogadicción, etc.)"
              value={values.HistoriaClinica}
            />
          </Col>
        )}
        {isMedic && (
          <Col sm={12} md={6}>
            <FieldLabel
              type="text"
              label="¿El acontecimiento adverso / reacción adversa ha sido notificada a través de otra vía?"
              value={values.NotificadoPorOtro}
            />
          </Col>
        )}
        {isMedic && values.NotificadoPorOtro === "SI" && (
          <Col sm={12} md={6}>
            <FieldLabel
              type="text"
              label="Otras vías en las que fue notificado"
              value={
                values.OtraViaNotificado ? values.OtraViaNotificado : "---"
              }
            />
          </Col>
        )}
        {!isMedic &&  values.ContactoMedico && (
          <Col sm={6}>
            <FieldLabel
              type="text"
              label="A informado a su médico de esta reacción"
              value={
                values.ContactoMedico === undefined
                  ? "---"
                  : values.ContactoMedico
              }
            />
          </Col>
        )}
        {!isMedic && values.ContactoMedico==="SI"  &&(
          <Col sm={6}>
            <FieldLabel
              type="date"
              label="Fecha de contacto médico"
              value={ values.Medico && values.Medico.FechaContacto
                  ? values.Medico.FechaContacto
                  : "---"
              }
            />
          </Col>
        )}
        {!isMedic &&
          values.ContactoMedico === "SI" &&
          values.PermitirContactoMedico === "SI" && (
            <Col sm={12} md={12}>
              <FieldLabel
                type="text"
                label="Dió su consentimiento para que contactemos a su médico"
                value={
                  values.PermitirContactoMedico === undefined
                    ? "---"
                    : values.PermitirContactoMedico
                }
              />
            </Col>
          )}
        {!isMedic &&
          values.ContactoMedico === "SI" &&
          values.PermitirContactoMedico === "SI" && (
            <Col sm={12} md={4}>
              <FieldLabel
                type="text"
                label="Nombre del médico"
                value={
                  values.Medico && values.Medico.Nombre && values.Medico.Nombre
                }
              />
            </Col>
          )}
        {!isMedic &&
          values.ContactoMedico === "SI" &&
          values.PermitirContactoMedico === "SI" && (
            <Col sm={12} md={4}>
              <FieldLabel
                type="text"
                label="Especialidad del médico"
                value={
                  values.Medico &&
                  values.Medico.Profesion &&
                  values.Medico.Profesion
                }
              />
            </Col>
          )}
        {!isMedic &&
          values.ContactoMedico === "SI" &&
          values.PermitirContactoMedico === "SI" && (
            <Col sm={12} md={4}>
              <FieldLabel
                type="text"
                label="Correo eletrónico"
                value={
                  values.Medico && values.Medico.Email && values.Medico.Email
                }
              />
            </Col>
          )}
          {!isMedic &&
          values.ContactoMedico === "SI" &&
          values.PermitirContactoMedico === "SI" && (
            <Col sm={12} md={4}>
              <FieldLabel
                type="text"
                label="Teléfono"
                value={
                  values.Medico && values.Medico.Telefono && values.Medico.Telefono
                }
              />
            </Col>
          )}
      </Row>
    </div>
  );
};

export default SummaryStep6;

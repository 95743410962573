import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronUp, faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import './AppStyle.css';

class AppBox extends Component {

    state = {
        hide:false,
        close:false,
    }

    handleHide = () => this.setState({hide:!this.state.hide});

    handleClose = () => this.setState({close:!this.state.close});

    equalsProps = (nextProps) =>{
        return (this.props.title === nextProps.title && this.props.className === nextProps.className &&  this.props.content === nextProps.content && this.props.children===nextProps.children);
    }
    equalsState = (nextState) =>{
        return (this.state.hide === nextState.hide && this.state.close === nextState.close);
    }

    shouldComponentUpdate = (nextProps, nextState, nextContext) => {
         return ((this.equalsProps(nextProps) && this.equalsState(nextProps)) === false);
    }

    componentWillMount() {
        if(this.props.hide){
            this.handleHide();
        }
    }

    _handlePlus = () => {
        this.setState({hide:false});
        this.props.handlePlus();
    }

    render(){
        const { title, content, className, closeButtom , handlePlus, children, hiddenTools,toolsCustom } = this.props;
        const { hide, close } = this.state;
        return (
            <div className={`ibox ${close && 'hiden'}`}>
                <div className="ibox-title">
                    <h5>{title && title}</h5>
                    {toolsCustom &&
                     <div className="ibox-tools">
                        {toolsCustom}
                    </div>
                    }
                    {!toolsCustom && !hiddenTools &&
                    <div className="ibox-tools">
                        { handlePlus && <Link className="plus-link" to={'#'}>
                            <FontAwesomeIcon  icon={faPlus} onClick={this._handlePlus}/>
                        </Link>}
                        <Link className="collapse-link" to={'#'}  onClick={this.handleHide}>
                            <FontAwesomeIcon icon={!hide ? faChevronUp : faChevronDown}/>
                        </Link>
                        {closeButtom && <Link className="close-link" to={'#'} onClick={this.handleClose}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </Link>}
                    </div>}
                </div>
                <div className={`ibox-content ${className} ${hide && 'hiden'}`}>
                    {content && content}
                    {children && children }
                </div>
            </div>
        );
    }
};

AppBox.propTypes = {
    title: PropTypes.string,
    content: PropTypes.element,
    className:PropTypes.string,
};

export default AppBox;

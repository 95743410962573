import React, { Component } from 'react';
import api from '../../../../../api';
import ListEventosSeguimiento from '../../../../ListComponents/List/ListEventoSeguimiento';
import { url_getEventosSegui } from '../../../../../api/urls';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setLoad } from '../../../../../redux/actions/context';
import { showError } from '../../../../../redux/actions/error';


class EventosdeSeguimiento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            load:"",
            items:[]
        };
    }

    componentDidMount = async () => {
      try {
        this.props.setLoad(true);
        const response = await api.get(url_getEventosSegui(this.props.er.NumeroReferencia));
        this.setState({ items: response.data });
      } catch (e) {
        this.props.showError(e);
      } finally {
        this.props.setLoad(false);
      }
    }

    handleAction = (item) =>{
        this.props.history.push(`/reportableEvent/${item.Meta}/view/details/3`)
    }


    render(){
        const { load } = this.state;
        return (
            <div>
                <ListEventosSeguimiento handleAction={this.handleAction} list={this.state.items} load={load}/>
            </div>
        );
    }
};


const mapDispatchToProps = {
    setLoad,
    showError
};

const mapStateToProps = state=> ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventosdeSeguimiento));

import {toastr} from 'react-redux-toastr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faBug, faShieldAlt, faFrown } from '@fortawesome/free-solid-svg-icons';
import React from 'react';


const statusCode = {
    OK: 200,
    Created: 201,
    Accepted: 202,
    BadRequest: 400,
    Unauthorized: 401,
    PaymentRequired: 402,
    Forbidden: 403,
    NotFound: 404,
    InternalServerError:500,

}
const statusNetwork ={  NetworkError: "Network Error"}

export const showError =(error)=>{
    let text="";
    let title="";
    if(error.response){
        const {response} = error;

        if(response.status===statusCode.BadRequest){
            title=response.data.Message;
            toastr.warning(title,
                text,
                {
                    icon: <FontAwesomeIcon icon={faExclamationTriangle} size="3x" className="m-t"/>
                }
            )
        }
        else if(response.status===statusCode.Unauthorized){
            title=response.data.Message;
            toastr.info(title,
                text,
                {
                    icon: <FontAwesomeIcon icon={faShieldAlt} size="3x" className="m-t"/>
                }
            )
        }
        else if(response.status===statusCode.NotFound){
            title="Datos no encontrados";
            text=`El sistema no encontró datos para mostrar`;
            toastr.info(title,
                text,
                {
                    icon: <FontAwesomeIcon icon={faShieldAlt} size="3x" className="m-t"/>
                }
            )
        }
        else{
            title=response.data.ExceptionMessage;
            text=response.data.InnerException.ExceptionMessage;
            toastr.error(title,
                text,
                {
                    icon: <FontAwesomeIcon icon={faBug} size="3x" className="m-t"/>
                }
            )
        }
    }else if(error.message===statusNetwork.NetworkError){
            title="Ooops! Algo salió mal";
            text=`El sistema está desconectado - Contacte al administrador`;
            toastr.error(title,
                text,
                {
                    icon: <FontAwesomeIcon icon={faFrown} size="3x" className="m-t"/>
                }
            )
    }
}

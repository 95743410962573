import React from 'react';
import ListItemMetric from '../Item/ListItemMetric';
import ListComponent from '..';

const ListMetrics = ({list,handleAction ,View }) => {
    const titles = ['Métrica','Sigla','Tiempo',''];
    return (
       <ListComponent
        titles={titles}
        list={list}
        View={View}
        handleAction={handleAction}
        ListComponent={ListItemMetric}
        empty="metricas" />
    );
};

export default ListMetrics;

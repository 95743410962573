import React, { Component } from 'react';
import AppContainer from '../components/AppComponents/AppContainer';
import AuditTrailComponent from '../components/AuditTrailComponents';
import  setPropsAsInitial  from '../helpers/setPropsAsInitial';
import { withRouter }  from 'react-router-dom';

class AuditTrailContainer extends Component {
    componentWillMount = () =>{
        this.props.showAppFrame(this.props.location.pathname);
    }
    render(){
        return (
        <AppContainer title="Audit Trail" className="background-f4f4f4 p-t-n">
            <AuditTrailComponent/>
        </AppContainer>
    );
    }
    
}
export default withRouter(((setPropsAsInitial)(AuditTrailContainer)));
const setItem = (key, data, config) => {
  let params = "";
  if (config.hasOwnProperty("path")) {
    params += `path=${config.path};`;
  }
  if (config.hasOwnProperty("domain")) {
    params += `domain=${config.domain};`;
  }
  if (config.hasOwnProperty("maxAge")) {
    params += `max-age=${config.maxAge};`;
  }
  if (config.hasOwnProperty("expires")) {
    params += `expires=${config.expires};`;
  }
  if (config.hasOwnProperty("secure")) {
    params += `secure=${config.secure};`;
  }
  if (config.hasOwnProperty("samesite")) {
    params += `samesite=${config.samesite};`;
  }
  if (config) {
    data += `; ${params}`;
  }
  document.cookie = `${key}=${data}`;
};

const getItem = key => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + key + "=");
  if (parts.length === 2)
    return parts
      .pop()
      .split(";")
      .shift();
};
const clear = () => {
  document.cookie = `access_token=`;
};

const CookiePrvider = {
  setItem,
  getItem,
  clear
};
export default CookiePrvider;

import React from 'react';
import { getElementCodiguera } from '../../../../codigueras';
import { Row, Col } from 'react-flexbox-grid';
import { FieldLabel, LabelDate } from '../../../Fields';

const SummaryStep5 = ({values,codiguera,isMedic}) => {
    if(values && values.Concomitante){
    return (
        <div className='form-step'>
            {(values.Concomitante.length > 0) && values.Concomitante.map((r,i) => <SummaryConcomitante key={i} values={r} codiguera={codiguera} isMedic={isMedic} />)}
        </div>
    );
    }
    else{
        return null;
    }
};

export default SummaryStep5;

const SummaryConcomitante = ({values,codiguera,isMedic}) => {
    return (<div>
        <h3>{isMedic ? 'Medicamento concomitante' : 'Otra sustancia que se administra (Concomitante)'}</h3>
        <Row className="form-step-row">
            <Col sm={12} md={isMedic ? 8 : 12} >
                <FieldLabel type="text" label="Medicamento, vitaminas, minerales, suplementos y/o remedios herbales" value={values.Medicamento ? values.Medicamento : '---'} />
            </Col>
            <Col sm={12} md={4}  hidden={!isMedic}>
                <FieldLabel type="text" label="Tipo" value={codiguera && getElementCodiguera('tiposMedAnteriores', values.Tipo, codiguera)} />
            </Col>
          
            <Col sm={12} md={3} >
                <FieldLabel type="date" label="Fecha de inicio de la administración" value={values.FechaIni}/>
            </Col>
            <Col sm={12} md={3} >
                <FieldLabel type="date" label="Fecha de finalización de la administración" value={values.FechaFin}/>
            </Col>
        </Row>
        </div>);
}

import React from 'react';
import { getElementCodiguera } from '../../../../codigueras';
import { Row, Col } from 'react-flexbox-grid';
import { FieldLabel, LabelDate } from '../../../Fields';

const SummaryStep2 = ({values,codiguera,isMedic}) => {
    return (
        <div className='form-step'>
            <h3>Paciente</h3>
            <Row className="form-step-row">
                {!isMedic && <Col md={4} sm={6}>
                    <FieldLabel type="text" label="País" value={codiguera && getElementCodiguera("pais",values.Pais,codiguera)} />
                </Col> }
                <Col md={4} sm={6}>
                    <FieldLabel type="text" label="Iniciales" value={values.Iniciales} />
                </Col>
                <Col md={4} sm={6}>
                    <FieldLabel type="text" label="Sexo" value={values.Sexo==="M" ? "Masculino" : "Femenino"}/>
                </Col>
                <Col md={4} sm={6}>
                    <FieldLabel type="date" label="Fecha de nacimiento" value={values.FechaNacimiento}/>
                </Col>
                <Col md={4} sm={6}>
                    <FieldLabel type="text" label="Peso Kg." value={values.Peso ? values.Peso: '---' } />
                </Col>
                <Col md={4} sm={6}>
                    <FieldLabel type="text" label="Altura Mts." value={values.Altura ? values.Altura: '---' } />
                </Col>
                {values.Sexo==="F" &&  <Col md={4} sm={6}>
                    <FieldLabel type="text" label="Embarazo" value={values.Embarazo===undefined ? '---' : values.Embarazo} />
                </Col>}
                {values.Sexo==="F" && <Col md={4} sm={6}>
                    <FieldLabel type="text" label="Gestación" value={values.Gestacion ? values.Gestacion: '---' } />
                </Col>}
                {values.Sexo==="F" && <Col md={4} sm={6}>
                    <FieldLabel type="text" label="En lactancia" value={values.Lactancia===undefined ? '---' : values.Lactancia} />
                </Col>}
                {!isMedic &&<Col md={6} sm={12}>
                    <FieldLabel type="text" label="Hospital / Mutualista / Centro de atención médica" value={values.CentroMedico ? values.CentroMedico: '---' } />
                </Col>}
            </Row>
        </div>
    );
};

export default SummaryStep2;
import React from "react";
import PropTypes from "prop-types";
import { FieldLabel } from "../../../../Fields";
import Section from "../../../Section";
import { Row, Col } from "react-flexbox-grid";
import { getElementCodiguera } from "../../../../../codigueras";

const renderBody = (medication, codiguera) => {
  let concentraciones = '';
  const concentracionesArray = [];
  let formaFarmaceuticas = '';
  const formaFarmaceuticaArray = [];
  medication.PrincipiosActivos.forEach(function (principio, index){    
    if (principio.Concentracion !== null && principio.UnidadTipo !== null) {
      concentracionesArray.push(principio.Concentracion);
      formaFarmaceuticaArray.push(principio.UnidadTipo)
    }    
  });
  concentraciones = concentracionesArray.join(' + ');
  formaFarmaceuticas = formaFarmaceuticaArray.join(' + ');
  return (
    <div>
      <Row className="noRow m-t-md">
        <Col md={4}>
          <FieldLabel label={"Fabricante"} value={medication.Linea.Nombre} />
        </Col>
        <Col md={4}>
          <FieldLabel label={"Producto"} value={medication.NombreComercial} />
        </Col>
        <Col md={4}>
          <FieldLabel label={"Principio activo"} value={medication.Familia} />
        </Col>
      </Row>
      <Row className="noRow">
            <Col md={4}>
                <FieldLabel
                 label={'Forma farmacéutica'}
                 value={formaFarmaceuticas}
                 />
            </Col>
            <Col md={4}>
                <FieldLabel
                 label={'Concentración'}
                 value={concentraciones}
                 />
            </Col>
            <Col md={4}>
                <FieldLabel
                 label={'Unidad'}
                 value={medication.UnidadUso}
                 />
            </Col>
      </Row>
      <Row className="noRow">
        <Col md={12}>
          <FieldLabel
            label={"Motivo de la indicación"}
            value={medication.Indication}
          />
        </Col>
      </Row>
      <Row className="noRow">
        <Col md={4}>
          <FieldLabel
            label={"Fecha de inicio de la administración"}
            value={medication.DateStart}
            type="date"
          />
        </Col>
        <Col md={4}>
          <FieldLabel
            label={"Fecha de fin de la administración"}
            value={medication.DateEnd}
            type="date"
          />
        </Col>
      </Row>
      <Row className="noRow">
        <Col md={4}>
          <FieldLabel
            label={"Vía de administración"}
            value={getElementCodiguera("via", medication.ViaId, codiguera)}
          />
        </Col>
        <Col md={4}>
          <FieldLabel
            label={"Frecuencia"}
            value={`${getElementCodiguera(
              "frecuencia",
              medication.FrecuenciaTipo,
              codiguera
            )} ${medication.FrecId ? medication.FrecId + " horas" : "---"} `}
          />
        </Col>
        <Col md={4}>
          <FieldLabel
            label={"Cantidad - Unidad"}
            value={
              medication.Cant
                ? `${medication.Cant} - ${getElementCodiguera(
                    "unidadUso",
                    medication.UnitUsoId,
                    codiguera
                  )}`
                : "---"
            }
          />
        </Col>
      </Row>
      <Row className="noRow">
        <Col md={4}>
          <FieldLabel
            label={"¿Aún conserva el producto?"}
            type="bool"
            value={medication.HaveProduct}
          />
        </Col>
        <Col md={4}>
          <FieldLabel label={"Lote"} value={medication.LotNumber} />
        </Col>
        <Col md={4}>
          <FieldLabel
            label={"Vencimiento del lote"}
            value={medication.DateExpiration}
          />
        </Col>
      </Row>
    </div>
  );
};

const SuspectMedicationsDetail = ({
  medication,
  handleBtnEdit,
  step,
  title,
  codiguera
}) => {
  return (
    <Section title={title} edit={handleBtnEdit} step={step}>
      {medication.Id && renderBody(medication, codiguera)}
    </Section>
  );
};

SuspectMedicationsDetail.propTypes = {
  medication: PropTypes.object
};

export default SuspectMedicationsDetail;

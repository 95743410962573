import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getERFollowUp } from '../../../../../redux/selectors/ER'
import ListFollowUp from '../../../../ListComponents/List/ListFollowUp';

const FollowUp = props => {
    return (
       <ListFollowUp list={props.ERFollowUp} />
    );
};

FollowUp.propTypes = {
    ERFollowUp: PropTypes.array,
};

const mapStateToProps = state => ({
    ERFollowUp: getERFollowUp(state),
});

export default connect(mapStateToProps,null)(FollowUp);
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LoginForm.css';
import { Input, FormGroup,FormFeedback,Badge, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { enterPressed, esacpePressed } from '../../helpers/utilities';
import ReCAPTCHA from "react-google-recaptcha";
import { reCaptchaSiteKey } from '../../environment';

const _renderInputEmail = (valid,handleChangeInput,msgValidEmail,_keyPress)  => (
    <FormGroup className="inputWithIcon">
        {valid &&
            <div className="m-b-lg">
                <Input name="email" placeholder="e-mail" autoComplete="username" className="m-t-n" type="email" onKeyPress={_keyPress}  onChange={e=> handleChangeInput(e)}/>
            </div>
        }
        {!valid &&
            <div className="m-b-md">
                <Input name="email" invalid placeholder="e-mail" autoComplete="username" className="m-t-n" type="email" onKeyPress={_keyPress}  onChange={e=> handleChangeInput(e)}/>
                <FormFeedback tooltip className="t-inherit">{msgValidEmail}</FormFeedback>
            </div>
        }
    </FormGroup>
)

const _renderInputPass = (valid, handleChangeInput,_keyPress) =>(
    <FormGroup  className="inputWithIcon m-t-md-m">
        {valid &&
            <div className="m-b-sm">
                <Input name="pass" placeholder="contraseña" autoComplete="current-password" className="m-t-n " type="password" onKeyPress={_keyPress} onChange={e=> handleChangeInput(e)}/>
            </div>
        }
        {!valid &&
            <div className="m-b-sm">
                <Input name="pass" invalid placeholder="contraseña" autoComplete="current-password" className="m-t-n" onKeyPress={_keyPress} type="password" onChange={e=> handleChangeInput(e)}/>
                <FormFeedback tooltip className="t-inherit">Debe ingresar una contraseña</FormFeedback>
            </div>
        }
    </FormGroup>
)
const _renderInputReset = (handleChangeInput,_keyPress)  => (
    <FormGroup className="inputWithIcon">
        <div className="m-b-lg">
            <Input name="reset" placeholder="e-mail" autoComplete="username"  className="m-t-n" type="email" onKeyPress={_keyPress}  onChange={e=> handleChangeInput(e)}/>
        </div>
    </FormGroup>
)

const LoginForm = ({handleLogin, handleChangeInput, validEmail, validPass,msgValidEmail,msgError,handleResetPassword,msgReset,handleToggle,show}) => {
    
    const [isValidLoginCaptcha, setIsValidLoginCaptcha] = useState(false);
    const [loginCaptchaToken, setLoginCaptchaToken] = useState(null);
    const [isValidPasswordResetCaptcha, setIsValidPasswordResetCaptcha] = useState(false);
    const [passwordResetCaptchaToken, setPasswordResetCaptchaToken] = useState(null);
    const [isPasswordResetForm, setIsPasswordResetForm] = useState(false);

    const passwordResetRecaptchaRef = React.useRef();
    const loginRecaptchaRef = React.useRef();
    
    const _keyPress = (e) => {
        if(enterPressed(e)){
            if(show ==='reset'){
                handleResetPassword(passwordResetCaptchaToken);
            }else{
                handleLogin(loginCaptchaToken);
            }
        }else if(esacpePressed(e)){
            if(show ==='reset'){
                handleToggle();
            }else{

            }

        }
    }

    //password reset captcha

    const onPasswordResetReCaptchaChange = (tokenValue) => {
        setPasswordResetCaptchaToken(tokenValue);
        setIsValidPasswordResetCaptcha(true);
    };

    const onPasswordResetRecaptchaInvalid = () => {
        setIsValidPasswordResetCaptcha(false);
    };

    const handlePasswordResetButtonClick = (e) => {
        handleResetPassword(passwordResetCaptchaToken);
        passwordResetRecaptchaRef.current.reset();
        setIsValidPasswordResetCaptcha(false);
        setIsPasswordResetForm(false);
    }
    //login captcha

    const onLoginReCaptchaChange = (tokenValue) => {
        setLoginCaptchaToken(tokenValue);
        setIsValidLoginCaptcha(true);
    };

    const onLoginRecaptchaInvalid = () => {
        setIsValidLoginCaptcha(false);
    };

    const handleLoginButtonClick = (e) => {
        handleLogin(loginCaptchaToken);
        loginRecaptchaRef.current.reset();
        setIsValidLoginCaptcha(false);
    }

    return (
        <div>
            <div className="login-form">
                <img className="img-cim" alt="logo CIM" src={require("../AppComponents/CIM_cim.png")}/>
                <img className="img-label" alt="logo CIM" src={require("../AppComponents/CIM_label.png")}/>
                
                <div className={`login-form-group m-t-md flip-card ${show ==='reset' ? 'show-back' : ''}`}>
                    <div className="flip-card-inner ">
                        <form autoComplete="off" className="flip-card-front login-flip">
                            <h3 className="login-title">Ingresar</h3>
                            {
                                _renderInputEmail(validEmail,handleChangeInput,msgValidEmail,_keyPress)
                            }
                            {
                                _renderInputPass(validPass,handleChangeInput,_keyPress)
                            }
                            
                            <div className="d-flex justify-content-center">
                                {!isPasswordResetForm ? (
                                    <ReCAPTCHA
                                        sitekey={reCaptchaSiteKey}
                                        onChange={onLoginReCaptchaChange}
                                        onExpired={onLoginRecaptchaInvalid}
                                        onErrored={onLoginRecaptchaInvalid}
                                        ref={loginRecaptchaRef}
                                    />
                                ) : null}
                            </div>

                            {msgError && <div className="error"><Badge color="danger" pill>{msgError}</Badge></div>}
                            <div className="divforgetpass ">
                                <Link className="forgetpass" to="#" onClick={(ev) => {
                                    handleToggle();
                                    setIsPasswordResetForm(true);
                                }} >¿Olvidó su contraseña?</Link>
                            </div>
                            <div className='login-button m-t-lg'>
                                <Button outline  className="login-btn" onClick={handleLoginButtonClick} disabled={!isValidLoginCaptcha} > Iniciar Sesión  </Button>
                            </div>
                        </form>
                        <form autoComplete="on" className="flip-card-back login-flip">
                            <h3 className="login-title m-b-sm">Recuperar contraseña</h3>
                            <label className="m-b-md">Puede solicitar su contraseña informando el correo electrónico con el que se registró; la contraseña será enviada a dicha dirección.</label>
                            {
                                _renderInputReset(handleChangeInput,_keyPress)
                            }
                            <div className="d-flex justify-content-center">
                                {isPasswordResetForm ? (
                                    <ReCAPTCHA
                                        sitekey={reCaptchaSiteKey}
                                        onChange={onPasswordResetReCaptchaChange}
                                        onExpired={onPasswordResetRecaptchaInvalid}
                                        onErrored={onPasswordResetRecaptchaInvalid}
                                        ref={passwordResetRecaptchaRef}
                                    />
                                ) : null}
                            </div>
                            {msgReset && <div className="error"><Badge color="success" pill>{msgReset}</Badge></div>}
                            <div className='login-button m-t-lg'>
                                <Button outline  className="login-btn" onClick={handlePasswordResetButtonClick } disabled={!isValidPasswordResetCaptcha}> Recuperar  </Button>
                                <Button outline  className="login-btn inverse" onClick={(evt) => {
                                    handleToggle(evt);
                                    setIsPasswordResetForm(false);
                                }}> Cancelar  </Button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
};

LoginForm.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    handleChangeInput: PropTypes.func.isRequired,
    validEmail: PropTypes.bool,
    validPass: PropTypes.bool,
    msgValidEmail:PropTypes.string,
    msgError:PropTypes.string
};

export default LoginForm;

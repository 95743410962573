import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getWorkFlowList,
  getWorkFlowListInvert,
  getHOMEReports
} from "../redux/selectors/ER";
import {
  getWorkFlowList as workFlowList,
  getWorkFlowListInvert as workFlowListInvert,
  setWorkFlowList,
  delERLisWork,
  getWFHOMEReports as HOMEReports,
  setHOMEREports
} from "../redux/actions/eventoReportable";
import { setLoad } from "../redux/actions/context";
import { showError } from '../redux/actions/error'
import AppContainer from "../components/AppComponents/AppContainer";
import CommentsModal from "../components/Modals/CommentsModal";
import FollowUpModal from "../components/Modals/FollowUpModal";
import {
  url_getFollowUpERMeta,
  url_getWorkFlow,
  url_delER,
  url_getWFReportHome
} from "../api/urls";
import ListWorkFlow from "../components/ListComponents/List/ListWorkFlow";
import setPropsAsInitial from "../helpers/setPropsAsInitial";
import { Col, Row } from "react-flexbox-grid";
import AppBox from "../components/AppComponents/AppBox";
import AppPaginator from "../components/AppComponents/AppPaginator";
import api from "../api";
import Signature from "../components/Signature";
import ListERHome from "../components/ListComponents/List/ListERHome";
import { getCodigueraContext, getMyUser } from "../redux/selectors/Context";
import SummaryERHomeModal from "../components/Modals/SummaryERHomeModal";
import { urlBase } from "../environment";
import { setSweetAlert } from '../redux/actions/sweetAlert';


const $ = window.$;


class WorkFlowContainer extends Component {
  state = {
    delItem: null,
    modalComments: false,
    modalFollowUp: false,
    openDelete: false,
    ER: { NumeroReferencia: "" },
    FollowUps: [],
    Load: "",
    load:"",
    loadWF: false,
    loadHome: false,
    openERHome: false,
    ERHome: null,
    notificationHub: null,
    notificationMsg: null
  };

  componentWillMount = () => {
    this.props.showAppFrame(this.props.location.pathname);
    this.props.setLoad(false);
  };


  notificaciones = () => {
      this.props.setSweetAlert({
        type:'info',
        title: "Nuevos Eventos",
        text: "Se ha creado un nuevo evento, para poder visualizarlo recargue la página.",
        show:true
       });
  }

  notificaciones2 = () => {
    this.props.setSweetAlert({
      type:'info',
      title: "Cambio en la configuración",
      text: "Se ha modificado uno o más parámetros, para poder visualizarlo recargue la página.",
      show:true
     });
  }

  componentDidMount = () => {
    this.handleUpdateList();
   

    if ($.connection.hub && $.connection.hub.state === 4) {
      $.connection.hub.url = urlBase + "signalr"
      $.connection.hub.start()
      .done(function(){
        console.log('Now connected, connection ID=' + $.connection.hub.id);
      })
      .fail(function(err){
        console.log('Could not Connect!');
        console.log(err);
      });


      var eventHub = $.connection.EventosHub;

      if(eventHub.client)
      {
        eventHub.client.updateStateEvent = this.notificaciones;
        eventHub.client.paramHubEvent= this.notificaciones2;
      
      }
      this.setState({
        notificationHub: eventHub
      });
    }

    const {
      result,
      homeReports,
      workFlowList,
      HOMEReports,
      myUser
    } = this.props;

    const params = {
      pageNumber:1,
      orderBy: 'FechaSolicitud DESC',
      statusId: null,
      idEr: null,
      from: null,
      to: null,
      categoryId: null,
      subGroupId: null
    }

    if (!result) {
      workFlowList(
        params
      );
    }

    if (myUser.Rol !== "Fabricante" && !homeReports) {
      const parameters = {
        pageNumber:1,
        orderBy:"FechaCreacion DESC",
        statusId: null,
        idEr: null,
        from: null,
        to: null,
        categoryId: null,
        subGroupId: null
      }
      HOMEReports(parameters);
    }
  };

  handleAction = async (action, ER) => {
    switch (action) {
      case "comments": {
        this.setState({
          ER: ER,
          modalComments: true
        });
        break;
      }
      case "followUp": {
        try{
          this.props.setLoad(true);
          this.setState({ Load: `FollowUp${ER.Meta}` });
          await api.get(url_getFollowUpERMeta(ER.Meta)).then(r => {
            this.setState({
              FollowUps: r,
              Load: "",
              ER: ER,
              modalFollowUp: true
            });
          });

        }catch(error){
          this.props.showError(error);
        }finally{
          this.props.setLoad(false);
          break;
        }
      }
      case "del": {
        this.setState({ ER, openDelete: true });
        break;
      }
      default:
        this.props.history.push(action);
        break;
    }
  };

  handleActionER = (action, data) => {
    switch (action) {
      case "show": {
        this.handleShowERHome(data);
        break;
      }
      default:
        break;
    }
  };

  toggleFollowUp = () => {
    this.setState({
      modalFollowUp: !this.state.modalFollowUp
    });
  };

  toggleComments = () => {
    this.setState({
      modalComments: !this.state.modalComments
    });
  };

  toggleERHome = () =>
    this.setState({
      openERHome: !this.state.openERHome
    });

  handleShowERHome = data =>
    this.setState({
      ERHome: data,
      openERHome: true
    });

  handleSearch = async page => {
    this.setState({ loadWF: true });
    window.scroll(0, 0);
    try{
      this.props.setLoad(true);
      const response = await api.get(url_getWorkFlow(
          page,
          "FechaSolicitud DESC",
          null,
          null,
          null,
          null,
          null,
          null
        ));
        this.props.setWorkFlowList(response.data);
        this.setState({ loadWF: false });

    }catch(error){
      this.props.showError(error);
    }finally{
      this.props.setLoad(false);
      this.setState({ loadWF: false });
    }
  };

  handleSearchHome = async page => {
    this.setState({ loadHome: true });
    window.scroll(0, 0);
    try{
      this.props.setLoad(true);
      const response = await api.get(url_getWFReportHome(page, "FechaCreacion DESC"));
      this.props.setHOMEREports(response.data);
     
    }catch(error){
      this.props.showError(error);
      this.setState({ loadHome: false });
    }finally{
      this.props.setLoad(false);
      this.setState({ loadWF: false, loadHome:false, Load:false  });
    }
  };

  handleUpdateList = all => {
    
    if (all) {
      this.handleSearchHome(1);
      this.handleSearch(1);
    }
    else
    {
      this.handleSearch(1);
    }
  
  };
  toggleDel = () => this.setState({ openDelete: !this.state.openDelete });

  delReport = async coment => {
    try{
      this.props.setLoad(true);
      this.setState({ delItem: this.state.ER.Meta });
      await api.post(url_delER, this.state.ER);
      this.props.delERLisWork(this.state.ER);
      this.setState({
         ER: {
           NumeroReferencia: ""
         },
         delItem : null
      });

    }catch (error){
      this.props.showError(error);
    }finally{
      this.props.setLoad(false);
    }
  };

  renderBody = (resultER, resultHome) => {
    const {
      modalComments,
      ER,
      modalFollowUp,
      Load,
      FollowUps,
      byListClose,
      loadWF,
      openDelete,
      delItem,
      loadHome,
      ERHome,
      openERHome
    } = this.state;
    const { myUser } = this.props;
    return (
      <div className="p-l-md p-r-md">
        <Row>
          { (myUser.Rol !== "CL" && myUser.Rol !== "ADMIN") && (
            <Col xs={12} md={7} xl={5} hidden={byListClose}>
              <AppBox className="wf-list" title="Mis acciones pendientes">
                <div>
                  <ListWorkFlow
                    mini={true}
                    delItem={delItem && delItem}
                    loadList={resultER === null || loadWF}
                    load={Load}
                    list={resultER && resultER.Result}
                    handleAction={this.handleAction}
                    empty="reportes"
                  />
                  <AppPaginator
                    mini={true}
                    message="Eventos reportables"
                    result={resultER}
                    handlePagination={this.handleSearch}
                  />
                </div>
              </AppBox>
            </Col>
          )}
          {((myUser.Rol !== "Fabricante" &&
            myUser.Rol !== "CL" &&
            (resultHome === null || loadHome)) ||
            (resultHome && resultHome.Result.length > 0)) && (
            <Col xs={12} md={5} xl={5}>
              <AppBox
                className="wf-list"
                title="Reportes pendientes de médico / paciente"
              >
                <div>
                  <ListERHome
                    mini={true}
                    loadList={resultHome === null || loadHome}
                    list={resultHome && resultHome.Result}
                    handleAction={this.handleActionER}
                    empty="reportes"
                  />
                  <AppPaginator
                    mini={true}
                    message="Eventos reportables"
                    result={resultHome}
                    handlePagination={this.handleSearchHome}
                  />
                </div>
              </AppBox>
            </Col>
          )}
        </Row>
        <SummaryERHomeModal
          handleUpdateList={this.handleUpdateList}
          open={openERHome}
          toggle={this.toggleERHome}
          item={ERHome}
        />
        <CommentsModal
          open={modalComments}
          toggle={this.toggleComments}
          ER={ER}
        />
        <FollowUpModal
          open={modalFollowUp}
          list={FollowUps}
          toggle={this.toggleFollowUp}
          ER={ER}
        />
        <Signature
          handleConfirm={this.delReport}
          toggle={this.toggleDel}
          open={openDelete}
          title={`Anular reportable ${ER && ER.NumeroReferencia}`}
          message={`¿Está seguro que quiere anular el reporte ${ER &&
            ER.NumeroReferencia}?`}
        />
      </div>
    );
  };

  render() {
    const { result, homeReports } = this.props;
    return (
      <div>
        <AppContainer
          className="background-f4f4f4"
          title="Tareas"
          menuSelected="workflow"
        >
          {this.renderBody(result, homeReports)}
        </AppContainer>

      </div>
    );
  }
}

const mapDispatchToProps = {
  workFlowList,
  workFlowListInvert,
  setWorkFlowList,
  setLoad,
  delERLisWork,
  HOMEReports,
  setHOMEREports,
  setSweetAlert,
  showError
};

const mapStateToProps = state => ({
  result: getWorkFlowList(state),
  homeReports: getHOMEReports(state),
  listInvert: getWorkFlowListInvert(state),
  codiguera: getCodigueraContext(state),
  myUser: getMyUser(state)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(setPropsAsInitial(WorkFlowContainer))
);

import React from "react";
import Summary from "../../../HomeComponents/components/Summary";
import { CardContent } from "@material-ui/core";
import { CardHeader } from "@material-ui/core";
import { CardActions } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";


export const SummaryERHome = ({
  eReportHome,
  codiguera,
  handleBack,
  handleSubmit
}) => {
  const form = {
    step1: eReportHome.Data.Tipo,
    step2: eReportHome.Data.Paciente,
    step3: { RAM: eReportHome.Data.RAM },
    step4: { Medicamento: eReportHome.Data.Medicamento },
    step5: { Concomitante: eReportHome.Data.Concomitante },
    step6: eReportHome.Data.ContactoMedico,
    step7: eReportHome.Data.InformacionContacto
  };
  return (
    <div>
      <CardHeader
        className="p-l-n p-t-n"
        title={`Origen de reporte ( ${eReportHome.Reference} )`}
      />
      <CardContent className="summary-modal-body no-scroll">
        <Summary
          form={form}
          isMedic={eReportHome.IsMedic}
          codiguera={codiguera}
        />
      </CardContent>
      <CardActions className="card-actions p-t-lg">
        <div>
          <Button
            type="button"
            className="card-actions-button button-back"
            variant="contained"
            onClick={handleBack}
          >
            <FontAwesomeIcon size="lg" icon={faArrowLeft} />
          </Button>
          <Button
            type="button"
            className="card-actions-button button-next"
            variant="contained"
            onClick={handleSubmit}
          >
            <FontAwesomeIcon size="lg" icon={faArrowRight} />
          </Button>
        </div>
      </CardActions>
    </div>
  );
};

import React from 'react';
import ListComponent from '..';
import ListItemNoKpis from '../Item/ListItemNoKpis';


const ListNoKpis = ({list}) => {
    const titles = ['Evento','Estado','Fecha','KPI','Tiempo KPI']; 
    return (
        <ListComponent 
        empty={'Eventos que no cumplan con los KPIs'} 
        titles={titles} 
        list={list} 
        ListComponent={ListItemNoKpis}  />
    );
};

export default ListNoKpis;
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from '@material-ui/core';

class LegalWarningModal extends Component {
    render() {
        const {acept, toggle, open} = this.props;
        return (
        <Modal backdrop={'static'} fade={false} isOpen={open} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()} >Terminos y condiciones</ModalHeader>
            <ModalBody>
                <div style={{overflowY:'scroll',height:'60vh'}}>
                    <h4>1. Aceptación</h4>
                    <p>1.1.	El acceso y el uso de este sitio está sujeto a los siguientes términos y condiciones, así como a todas las leyes aplicables de acuerdo a la jurisdicción correspondiente. </p>
                    <p>1.2.	Al acceder y navegar por este sitio Usted acepta, sin limitación ni condición alguna, estos Términos y Condiciones y reconoce que cualquier otro acuerdo sobre el uso de este Sitio entre usted y Mega Labs es reemplazado por estos Términos y Condiciones y queda sin efecto en este acto.</p>
                    <h4>2.	Información Médica. Condiciones</h4>
                    <p>2.1.	La información médica de los Productos -farmacéuticos o de cualquier tipo- incluida en este sitio web la proporciona Mega Labs y está destinada a propósitos de información general. Algunos de los productos se encuentran disponibles para los usuarios de este sitio solamente bajo prescripción de un profesional médico calificado y no todos los productos pueden estar disponibles en todos los países. </p>
                    <p>2.2.	La información del producto no deberá considerarse información médica completa. Si Usted tuviera alguna condición médica, PUNTUALMENTE CONSULTAR a su médico o proveedor de servicios de salud. Dicha información NO ES UNA OFERTA PERSONALIZADA, DIAGNÓSTICO MÉDICO O CONSEJO DE TRATAMIENTO ESPECÍFICO PARA LOS USUARIOS DE ESTE SITIO.</p>
                    <p>2.3.	Usted siempre debe obtener información médica completa sobre los medicamentos o dispositivos médicos que utilice o considere utilizar -con o sin prescripción médica-, incluyendo sus usos médicos beneficiosos y los posibles efectos adversos, al momento de discutir el uso adecuado de cualquier medicamento o dispositivo médico directamente con su médico tratante o, en su caso, con cualquier otro consejero médico.</p>
                    <p>2.4.	Los profesionales médicos pueden obtener información médica completa de la ficha técnica del producto. La información sobre estos productos puede variar según el país.</p>
                    <p>2.5.	Los pacientes, los médicos y otros profesionales de la medicina podrán verificar la información contenida en este sitio con las autoridades locales si desean garantizarla fehacientemente. Además, la normativa vigente limita (o incluso prohíbe, en algunos países) la capacidad de este sitio para proporcionar información y / o para responder directamente a las preguntas de los pacientes con respecto a sus productos.</p>
                    <p>2.6.	Mega Labs, va a responder a las preguntas de los usuarios de este sitio de buena fe y proporcionará información a su médico o profesional de la salud calificado, de acuerdo con las regulaciones locales.</p>
                    <h4>3.	Uso de la Información.</h4>
                    <p>3.1.	Usted puede navegar libremente por este sitio, pero solamente podrá acceder, descargar o utilizar la información de este sitio para su propio uso, no comercial.  dicha información comprende, pero no se limita a: textos, imágenes, audio y vídeo.</p>
                    <p>3.2.	Usted no puede distribuir, modificar, transmitir, reutilizar, reenviar o utilizar la información para fines comerciales, sin la autorización por escrito de Mega Labs. </p>
                    <p>3.3.	Usted debe conservar y reproducir todos y cada uno de los avisos de derechos de autor u otros derechos de propiedad contenidos en la información que descarga. </p>
                    <p>3.4.	Usted debe asumir que todo lo que vea o lea en este sitio es propiedad exclusiva de Mega Labs, o de sus Afiliadas, a menos que se indique expresamente lo contrario, y que no puede ser utilizado salvo lo dispuesto en estos Términos y Condiciones. Sin perjuicio de lo permitido en el presente apartado, Mega Labs no garantiza ni declara que el uso de materiales exhibidos en este sitio no infringe derechos de terceros.</p>
                    <p>3.5.	No se le otorga ni se le confiere a Usted ninguna licencia o derecho sobre la información, o cualquier derecho de autor de Mega Labs o de cualquier otra parte de la información.</p>
                    <h4>4.	Marcas. Derechos de Propiedad.</h4>
                    <p>4.	Marcas. Derechos de Propiedad.</p>
                    <p>4.2.	Este sitio también puede contener otros derechos de propiedad de Mega Labs y/o de sus afiliadas, Incluyendo, pero sin limitarse a: patentes de referencia, propiedad de la información, tecnologías, productos y procesos.</p>
                    <p>4.3.	Ninguna licencia o derecho sobre dichas marcas, patentes, secretos comerciales, tecnologías, productos, procesos y otros derechos de propiedad de Mega Labs y/o de sus afiliadas, se le otorga ni se le confiere a Usted. Todos los nombres de productos que aparecen en cursiva en este sitio son marcas registradas propiedad de o con licencia para Mega Labs.</p>
                    <h4>5.	Exclusión de Garantías.</h4>
                    <p>5.1.	Mega Labs realizará esfuerzos razonables para asegurar que la información de los productos sea precisa y se encuentre actualizada. Sin perjuicio de ello, la información puede contener imprecisiones o errores tipográficos y Mega Labs se reserva el derecho de hacer cambios, correcciones y/o mejoras a la información, y a los productos y programas incluidos en la Información, en cualquier momento y sin previo aviso. </p>
                    <p>5.2.	Mega Labs no ofrece ninguna garantía o representación en cuanto a la exactitud de la Información y no asume ninguna responsabilidad por cualquier error u omisión en el contenido de este sitio. TODA LA INFORMACIÓN SE PROPORCIONA “TAL CUAL”.</p>
                    <p>5.3.	Mega Labs no ofrece garantías acerca de la integridad o exactitud de la información en este sitio o de sus usos posibles. </p>
                    <p>5.4.	En razón de lo establecido en el presente capítulo, la información debe ser cuidadosamente evaluada por los usuarios del sitio; NI Mega Labs, NI SUS AFILIADAS, NI NINGUNA OTRA PARTE INVOLUCRADA EN LA CREACIÓN, PRODUCCIÓN O MANTENIMIENTO DE ESTE SITIO SERÁ RESPONSABLE DE CUALQUIER DAÑO DIRECTO, INDIRECTO, INCIDENTAL O PUNITIVO QUE SURJA EN OCASIÓN DEL USO O IMPOSIBILIDAD DE USO DE ESTE SITIO, O DE CUALQUIER ERROR U OMISIÓN EN EL CONTENIDO DE ESTE SITIO. </p>
                    <p>5.5.	Las jurisdicciones de algunos países no permiten la exclusión de garantías implícitas, por lo que la exclusión anterior no será aplicable en dichos países.</p>
                    <p>5.6.	Mega Labs no asume ninguna responsabilidad, y no será responsable por ningún daño o virus que puedan infectar los equipos informáticos u otra propiedad de los usuarios del sitio como consecuencia de su acceso o uso de la información allí contenida. </p>
                    <p>5.7.	Mega Labs se reserva el derecho de interrumpir y/o dar de baja este sitio en cualquier momento sin previo aviso y sin responsabilidad alguna para ella ni para ninguna de sus afiliadas.</p>
                    <h4>6.	La Información que nos Proporcione.</h4>
                    <p>6.1.	A excepción de la información protegida por nuestra Política de Privacidad, cualquier comunicación o material que Usted transmita a la web por correo electrónico u otro medio, incluyendo datos, preguntas, comentarios, sugerencias o similares, será tratado como información no confidencial. </p>
                    <p>6.2.	Cualquier información que usted transmita o publique se convierte en propiedad de Mega Labs y puede ser utilizada para cualquier propósito, incluyendo, pero no limitándose a la: reproducción, divulgación, transmisión, publicación, difusión y envío. </p>
                    <p>6.3.	Mega Labs es libre de utilizar -sin compensación alguna a favor de Usted- cualquier idea, concepto, know-how o técnicas contenidas en cualquier información que Usted envíe al sitio para cualquier propósito, incluyendo, pero no limitándose a: desarrollo, fabricación y comercialización de productos.</p>
                    <h4>7.	Productos en todo el mundo.</h4>
                    <p>Este sitio puede contener información sobre productos y servicios en todo el mundo, los cuales no están disponibles en todos los países del mundo. Una referencia a un producto o servicio en este sitio no implica que dicho producto o servicio esté o estará disponible en todos los países del mundo. Los productos mencionados en este sitio pueden estar sujetos a diferentes requisitos normativos en función del país de uso. En consecuencia, los visitantes de este sitio podrán ser notificados de que ciertas secciones de este sitio están destinadas sólo para ciertos grupos de usuarios expertos o sólo para los usuarios en ciertos países. No se debe interpretar nada en este sitio como una promoción o publicidad de cualquier producto o para el uso de cualquier producto que no esté autorizado por las leyes y reglamentos de su país de residencia.</p>
                    <h4>8.	Enlaces a esta web.</h4>
                    <p>Mega Labs no ha revisado ninguno de los sitios web de terceros que contienen enlaces a esta web y no es responsable del contenido de dichas páginas fuera de este sitio u otras vinculadas a estas. Si desea enlazar su dominio web a este dominio, sólo puede tener un enlace a la página principal. El usuario no puede enlazar a otras páginas dentro de este sitio sin el consentimiento previo por escrito de Mega Labs.</p>
                    <h4>9.	Los enlaces a otras webs.</h4>
                    <p>Enlaces a dominios de terceros pueden ser proporcionados por el interés o la comodidad de los visitantes a esta web.</p>
                    <p>Haremos todo lo posible para informarle cuando Usted esté dejando este dominio que las condiciones de uso y la política de privacidad del sitio de terceros pueden ser diferentes.</p>
                    <p>Mega Labs no se hace responsable de los enlaces de CIM a otros, y, en particular, no es responsable de la exactitud o legalidad del contenido de los mismos; no asumiendo ninguna responsabilidad derivada de un incumplimiento u omisión en las políticas de privacidad de terceros.</p>
                    <h4>10.	El material enviado a esta web.</h4>
                    <p>Aunque Mega Labs puede de vez en cuando, controlar o revisar debates, chats, publicaciones, transmisiones, tablones de anuncios y similares en el Sitio, Mega Labs está bajo ninguna obligación de hacerlo y no asume ninguna responsabilidad derivada del contenido de cualquier de tales elementos ni por cualquier error, difamación, injuria, calumnia, omisión, falsedad, materiales promocionales, obscenidad, pornografía, blasfemia, peligro divulgación de la privacidad o inexactitud contenida en cualquier información dentro de tales lugares en el sitio. </p>
                    <p>Se le prohíbe al Usuario publicar o transmitir cualquier material ilegal, promocional, amenazante, difamatorio, obsceno, escandaloso, pornográfico o profano, o cualquier material que pudiera constituir o alentar una conducta que pueda ser considerada una ofensa criminal, dar lugar a responsabilidad civil responsabilidad civil o violar cualquier ley.</p>
                    <p>Mega Labs cooperará completamente con cualquier autoridad legal u orden judicial que solicite revelar la identidad de cualquier persona que publique dicha información o materiales.</p>
                    <h4>11.	Consecuencias.</h4>
                    <p>Si nos damos cuenta de que ha violado cualquiera de los términos y condiciones contenidos en esta Declaración Legal, podemos tomar inmediatamente medidas correctivas, incluyendo la prevención de que el usuario utilice los servicios ofrecidos por Mega Labs y la eliminación de cualquier información, datos y contenidos puestos en el sitio por parte del usuario, en cualquier momento y sin previo aviso. </p>
                    <p>Si hemos sido heridos por su violación es posible que, en nuestra única discreción, tratar de recuperar los daños de usted.</p>
                    <h4>12.	Las revisiones.</h4>
                    <p>Mega Labs puede en cualquier momento revisar estos Términos y Condiciones actualizando esta publicación. Usted está obligado por tales revisiones y, por lo tanto, debe visitar periódicamente esta página para revisar los Términos y Condiciones vigentes a los que está vinculado.</p>
                    </div>
            </ModalBody>
            <ModalFooter hidden={!acept}>
                <Button type="button" className="card-actions-button button-back" onClick={toggle} variant="contained">Cancelar</Button>
                <Button type="button" className="card-actions-button button-next" onClick={acept} variant="contained">
                    ACEPTAR TERMINOS Y CONDICIONES
                </Button>
            </ModalFooter>
        </Modal>
        );
    }
}

export default LegalWarningModal;

import React from "react";
import SummaryStep1 from "./SummaryStep1";
import SummaryStep2 from "./SummaryStep2";
import SummaryStep3 from "./SummaryStep3";
import SummaryStep4 from "./SummaryStep4";
import SummaryStep5 from "./SummaryStep5";
import SummaryStep6 from "./SummaryStep6";
import SummaryStep7 from "./SummaryStep7";

const Summary = ({ form, isMedic, codiguera, edit, onChangeSubGroup }) => {
  return (
    <div className="form-summary">
      <h2>Resumen del reporte</h2>
      {(edit || form.step1.SubGroup !== "0") && (
        <SummaryStep1
          values={form.step1}
          codiguera={codiguera}
          edit={edit}
          onChangeSubGroup={onChangeSubGroup}
        />
      )}
      <SummaryStep2
        values={form.step2}
        isMedic={isMedic}
        codiguera={codiguera}
      />
      <SummaryStep3
        values={form.step3}
        isMedic={isMedic}
        codiguera={codiguera}
      />
      <SummaryStep4
        values={form.step4}
        isMedic={isMedic}
        codiguera={codiguera}
      />
      <SummaryStep5
        values={form.step5}
        isMedic={isMedic}
        codiguera={codiguera}
      />
      <SummaryStep6 values={form.step6} isMedic={isMedic} />
      <SummaryStep7 
        values={form.step7} 
        isMedic={isMedic} 
        codiguera={codiguera}
      />
    </div>
  );
};

export default Summary;

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {Link,withRouter,NavLink} from 'react-router-dom';
import ListActions from './ListActions';
import { iconAuditType, titleAuditType } from '../../../constants/audit';
import { DateTime } from 'luxon';
import { LabelDate } from "../../Fields";   
import { secureStorage } from "../../../api/secureStorage";

const showDate = (date) =>{
    const newDate = DateTime.fromISO(date).toFormat('f')
    return newDate;
}

class ListItemAudit extends Component {
    actions = (Item,handleAction,load) => [
        <Link to={'#'} onClick={() => handleAction(Item)}>{load===`${Item.DateUTC}` ?<CircularProgress size={15} />:<FontAwesomeIcon icon={faEye} size={'lg'}/>}</Link>
    ];
    render(){
        const {Item, classProp,load, handleAction}  = this.props;
        const user = secureStorage.getItem("user");
        
        var content = user && user.Rol !== "ADMIN" ?
            <NavLink to={`/reportableevent/${Item.Metadata}/view/details`}>{Item.ReferenceNumber}</NavLink>
            : Item.ReferenceNumber;

        return (
            <tr className={`list-item ${classProp}`}>
                <td>{<FontAwesomeIcon color="#01b5af" title={titleAuditType(Item.OperationType)} icon={iconAuditType(Item.OperationType)}/>}</td>
                <td className='text-left' >{content}</td>
                <td>{Item.Instance}</td>
                <td>{Item.User}</td>
                <td>{LabelDate(Item.DateUTC)}hs.</td>
                <td><ListActions actions={this.actions(Item, handleAction, load)}/></td>
            </tr>
        );
    }
};

export default withRouter(ListItemAudit);

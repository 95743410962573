import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { theme } from '../../constants/theme';

class AppLoader extends Component {

    render(){
        const { size, load } = this.props;
        if(load){
            return (
                <div className="loader-bk">
                    <MuiThemeProvider theme={theme}>
                        <CircularProgress  color="primary" className="loader-cr" size={size}/>
                    </MuiThemeProvider>
                </div>
            );
        }else{
            return null;
        }
    }
};

export default AppLoader;

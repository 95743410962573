import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import ListActions from './ListActions';

const actions = (handleAction, Item) => [
    <NavLink to={`/adminusers/${Item.UserID}`}  onClick={handleAction}><FontAwesomeIcon icon={faPencilAlt} size={'lg'}/></NavLink>
];

const ListItemUser = ({Item, handleAction, classProp,load}) => {
    return (
        <tr className={`list-item ${classProp}`}>
            <td>{Item.FirstName} {Item.LastName}</td>
            <td>{Item.Email}</td>
            <td>{Item.Country}</td>
            <td>{Item.UserType}</td>
            <td>{Item.IsActive ? 'SI' : 'NO'}</td>
            <td className="p-t-sm"><ListActions  actions={actions(handleAction,Item)}/></td>
        </tr>
    );
};

export default ListItemUser;